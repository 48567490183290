/* eslint-disable */
// File generated by i18n-js on 2024-09-15 06:27:29 +0000

import I18n from 'i18n-js';

I18n.translations || (I18n.translations = {});
I18n.translations = {
  "de": {
    "actions": {
      "approve": "Genehmigen",
      "bulk_editing": "Massenbearbeitung",
      "cancel": "Abbrechen",
      "choose_entity": "Wählen Sie %{entity}",
      "clear": "Entfernen",
      "confirm": "Bestätigen",
      "create": "Erstellen",
      "delete": "Löschen",
      "discard": "Verwerfen",
      "done": "Fertig",
      "duplicate": "Duplizieren",
      "edit": "Bearbeiten",
      "export": "Exportieren nach .xls",
      "export_template": "Vorlage .xls herunterladen",
      "import": "Importieren aus .xls",
      "import_file": "Datei importieren",
      "loading": "Laden",
      "more": "Mehr",
      "new": "Neu",
      "next": "Weiter",
      "no": "Nein",
      "past": {
        "cancel": "Verworfen",
        "complete": "Abgeschlossen",
        "confirm": "Bestätigt",
        "delete": "Gelöscht",
        "destroy": "Gelöscht",
        "reject": "Abgelehnt",
        "revert": "rückgängig gemacht",
        "update": "Aktualisiert"
      },
      "prev": "Zurück",
      "reject": "Ablehnen",
      "reset": "Zurücksetzen",
      "revert": "Rückgängig machen",
      "save": "Speichern",
      "save_button_keyboard": "Speichern (Strg + Eingabe)",
      "save_exit": "Speichern & Beenden",
      "saving": "Speichern...",
      "schedule": "Zeitplan",
      "skip": "Überspringen",
      "unsaved_changes": "Nicht gespeicherte Änderungen",
      "upload_file": "Datei hochladen",
      "upload_picture": "Bild hochladen",
      "yes": "Ja"
    },
    "api_internal_messages": {
      "cant_duplicate_clustered_request": "Es ist nicht möglich, eine geclusterte Anfrage zu duplizieren",
      "cant_duplicate_progress": "Die Dienstleistungen können aufgrund der folgenden Fehler nicht dupliziert werden...",
      "cant_duplicate_request": "Aufmaß, Kalkulation und Dienstleistungen können nicht dupliziert werden, da folgende Fehler aufgetreten sind...",
      "cant_duplicate_scope": "Die Dienstleistungen können nicht dupliziert werden, da folgende Fehler auftreten...",
      "only_estimate_service_copy": "Es wird versucht, nur das Element \"Kalkulation\" zu kopieren.",
      "only_progress_copy": "Es wird versucht, nur den Progress zu kopieren.",
      "only_request_copy": "Es wird versucht, nur die Anfrage zu kopieren.",
      "only_scope_copy": "Nur das Aufmaß-Element versucht, kopiert zu werden."
    },
    "cancel_modal": {
      "cancel": "Nicht jetzt",
      "description": "Möchten Sie %{model} \"%{id}\" wirklich stornieren?",
      "ok": "Ja Abbrechen",
      "title": "Artikel stornieren?"
    },
    "clear_records": {
      "modal": {
        "cancel": "Nein, abbrechen",
        "description": "Sind Sie sicher, dass Sie alle %{model}-Datensätze entfernen möchten?",
        "ok": "Ja, alle entfernen",
        "title": "%{model} entfernen?"
      },
      "success_message": "%{model} wurde erfolgreich entfernt!",
      "title": "Datensätze entfernen"
    },
    "collections": {
      "clusters": "Cluster",
      "create_by_package": "Nach Paket erstellen",
      "duplicate": "Duplizieren",
      "edit": "Bearbeiten",
      "estimate": "Schätzen",
      "new": "Neu",
      "schedule": "Zeitplan",
      "sub_requests": "Unteranträge",
      "view": "Ansicht"
    },
    "complete_modal": {
      "cancel": "Nicht jetzt",
      "description": "Möchten Sie %{model} wirklich als abgeschlossen markieren?",
      "ok": "Ja, als erledigt markieren",
      "title": "Als abgeschlossen markieren?"
    },
    "confirmation_modal": {
      "cancel": "Abbrechen",
      "description": "Diese Aktion kann nicht rückgängig gemacht werden!",
      "ok": "OK",
      "title": "Sind Sie sicher?"
    },
    "contract_services": {
      "title": "Vertragsleistungen"
    },
    "datasheets": {
      "datasheets_detail": {
        "side_panel_title": "Datenblattdetails"
      },
      "id": "ID",
      "item": "Element",
      "title": "Datenblätter"
    },
    "delete_modal": {
      "cancel": "Nein, verwerfen",
      "description": "Sind Sie sicher, dass Sie %{model} \"%{id}\" löschen möchten?",
      "ok": "Ja, löschen",
      "title": "Element löschen?"
    },
    "delete_recurrencies": {
      "modal": {
        "cancel": "Nein, abbrechen",
        "description": "Möchten Sie wirklich alle zugehörigen Wiederholungen löschen?",
        "ok": "Ja, alles löschen",
        "title": "Alle Wiederholungen löschen?"
      },
      "success_message": "Wiederholungen erfolgreich gelöscht!",
      "title": "Alle Wiederholungen löschen"
    },
    "dialogs": {
      "columns_batch_assigner": {
        "actions": "Aktionen",
        "add_action": "Aktion hinzufügen",
        "add_value_to": "%{value} hinzufügen zu",
        "column": "Spalte",
        "done": "Fertig",
        "header": "Batch bearbeiten",
        "multiple_success": "%{numberOfItems} Elemente wurden aktualisiert.",
        "new_value": "Neuer Wert",
        "single_success": "1 Element wurde aktualisiert.",
        "subtitle": "Weisen Sie allen ausgewählten Elementen einen Wert zu: %{numberOfItems}",
        "title": "Aktion erstellen",
        "update": "Aktualisieren"
      }
    },
    "discard_modal": {
      "cancel": "Weiter bearbeiten",
      "description": "Sind Sie sicher, dass Sie Ihre Änderungen verwerfen möchten?",
      "ok": "Ja, verwerfen",
      "title": "Änderungen verwerfen"
    },
    "eav_templates": {
      "title": "Schablonen"
    },
    "empty_state": {
      "empty": "Kein %{model}",
      "not_created": "Sie haben noch kein %{model} erstellt"
    },
    "environment": {
      "project": {
        "select_project": "Projekt auswählen..."
      },
      "select_project_subproject": "Wählen Sie Projekt und Subprojekt",
      "subproject": {
        "select_subproject": "Subprojekt auswählen…"
      }
    },
    "estimate": {
      "accept": "Kalkulation annehmen",
      "application": "Kosten der Anwendung",
      "back": "Alle Anfragen",
      "budget_hours": "Budget-Stunden",
      "confirmation": {
        "cancel": "Nein, Abbrechen",
        "confirm": "Ja, bestätigen",
        "description": "Sind Sie sicher, dass Sie diese Kalkulation für diese Anfrage genehmigen möchten?",
        "title": "Bestellung bestätigen"
      },
      "description": "Sie haben %{number} Dienstleistungen für die Kasse. Bitte überprüfen Sie die Details unten.",
      "equipment": "Kosten der Ausrüstung",
      "estimate_details": "Details zur Kalkulation",
      "estimate_success": "Kalkulation erfolgreich genehmigt",
      "info_message": "Diese Kalkulation wurde bereits genehmigt. Er ist nur zum Lesen verfügbar.",
      "material": "Materialkosten",
      "no_estimate_message": "Mit dieser Anfrage ist keine Kalkulation verbunden.",
      "norm_hours": "Norm-Stunden",
      "others": "Sonstige Kosten",
      "save_draft": "Entwurf speichern",
      "service_details": "Leistungsdetails",
      "team_target_hours": "Team-Soll-Stunden",
      "title": "Check-out Dienst",
      "tooltip": {
        "application": "Anwendung",
        "equipment": "Ausrüstung",
        "material": "Material",
        "others": "Sonstiges"
      },
      "total_request": "Gesamtkosten"
    },
    "estimate_services": {
      "application_price": "Anwendungspreis",
      "automatically_prices": "Automatisch berechnete Preise",
      "back": "Alle Aufmaße",
      "budget_target_hours": "Budget-Soll-Stunden",
      "choose_modal": {
        "package": "Dienstleistungen aus Paket",
        "package_description": "Erstellung mehrerer Dienstleistungen auf der Grundlage derselben Aktivität",
        "single": "Einzelne Dienstleistung",
        "single_description": "Erstellung von nur einer Dienstleistung",
        "title": "Neu erstellen"
      },
      "contract_service": "Vertragsleistung",
      "crew_size": "Größe des Teams",
      "crews": "Anzahl der Teams",
      "description": "Kalkulation",
      "description_label": "Beschreibung",
      "discipline": "Fachgebiet",
      "equipment_price": "Equipmentpreis",
      "estimate": "Kalkulation",
      "estimate_services_detail": {
        "side_panel_title": "Details der Dienstleistung"
      },
      "factor": "Faktor",
      "id": "ID",
      "materials_price": "Materialpreis",
      "no_template": "Sie müssen eine Vorlage für die Kalkulation von Dienstleistungen erstellen",
      "norm_hours": "Norm-Stunden",
      "other_price": "Sonstiger Preis",
      "prices": "Preise",
      "quantity": "Menge",
      "service": "Dienstleistung",
      "service_package": "Dienstleistungspaket",
      "team_target_hours": "Team-Soll-Stunden",
      "title": "Kalkulation",
      "title_packageable": "Kalkulation für Dienstleistungen nach Dienstleistungspaket",
      "total": "Gesamt",
      "unit": "Einheit",
      "weight": "Gewicht",
      "working_hours": "Arbeitsstunden"
    },
    "fields": {
      "image_not_found": "Bild nicht gefunden",
      "na": "K.A.",
      "signature": "Unterschrift.Jpg"
    },
    "filter_steps_modal": {
      "filter_result": "Auswahl-Ergebnis",
      "step": "Schritt",
      "subtitle": "Bitte treffen Sie eine Auswahl zu diesem Filter: %{title}"
    },
    "filtering": {
      "between": " das ist zwischen",
      "contains": " die Folgendes enthält ",
      "eq": " die gleich ist mit ",
      "invalid_date": "Um richtig zu filtern, müssen Startdatum und Enddatum ausgefüllt",
      "invalid_value": "Ungültiger Wert",
      "neq": " die nicht gleich ist, mit"
    },
    "form": {
      "back": "Alle %{entity}",
      "cancel": "Abbrechen",
      "create_entity": "%{entity} erstellen",
      "create_error": " %{entity} konnte nicht erstellt werden",
      "create_success": " %{entity} wurde erfolgreich erstellt",
      "duplicate_entity": "Duplizieren %{entity}",
      "duplicate_success": "%{entity} und sämtliche Abhängigkeiten wurden erfolgreich dupliziert!",
      "general_information": "Allgemeine Informationen",
      "hide": "Ausblenden",
      "inputs": {
        "attachment": {
          "add_error": "Fehler beim Hinzufügen der Datei",
          "add_evidence": "Nachweis der Kundenautorisierung hinzufügen",
          "allowed_extensions": "Die zulässigen Erweiterungen sind",
          "drag_drop": "Dateien durch Ziehen und Ablegen hinzufügen",
          "invalid_extension": "Dateityp nicht erlaubt.",
          "max_size": "Die Datei muss weniger als 20 MB groß sein.",
          "maximum_num_uploads_error": "Sie können nur bis zu %{max_num} Dateien pro flexible",
          "one_file": "Laden Sie jeweils eine Datei hoch",
          "or_drop_file_here": "oder Datei hier ablegen",
          "remove_error": "Entfernen fehlgeschlagen",
          "remove_success": "Entfernt Erfolgreich",
          "select_files": "Dateien auswählen",
          "submit": "Datei hochladen",
          "upload_error": "Hochladen fehlgeschlagen",
          "upload_success": "Erfolgreich hochgeladen"
        },
        "contract_service": {
          "add": "Vertragsleistung hinzufügen",
          "subtitle": "Vertragsleistung"
        },
        "datasheet_filter": {
          "add_filter": "Filter hinzufügen",
          "title": "Datenblatt-Filter"
        },
        "date_range": {
          "between": "%{start} bis %{end}",
          "end": "Endtermin",
          "start": "Starttermin"
        },
        "drop_formula": {
          "formula_select": "Bitte wählen Sie eine Formel aus..."
        },
        "errors": {
          "drop_formula_result": "%{field} berechnetes Formelergebnis",
          "mandatory": "%{field} ist erforderlich.",
          "not_found": "%{value}' wurde in der Referenz nicht gefunden. Wählen Sie eine neue aus."
        },
        "formula": {
          "result": "Ergebnis"
        },
        "gps": {
          "save_location": "Ort speichern",
          "search": "Standort suchen...",
          "without_location": "Ohne Standort"
        },
        "picture": {
          "edition_only": "Nur in der Ausgabe verfügbar",
          "five_pictures_limit_warning": "Es sind nur maximal 5 Bilder erlaubt",
          "no_image_on_clipboard": "Es wurden keine Bilddaten in Ihrer Zwischenablage gefunden. Bitte kopieren Sie zuerst ein Bild.",
          "remove_error": "Entfernen fehlgeschlagen",
          "remove_success": "Erfolgreich entfernt",
          "upload_error": "Hochladen fehlgeschlagen",
          "upload_success": "Erfolgreich hochgeladen"
        },
        "placeholder": "Geben Sie hier %{field} ein",
        "value_selector": {
          "apply": "Anwenden",
          "done": "Fertig",
          "search": "Hier etwas suchen...",
          "waiting": "... Warten"
        }
      },
      "loading": "Laden...",
      "schedule_entity": "Terminieren %{entity}",
      "schedule_success": "Ihre Anfrage zur Planung von %{entity} wurde erfolgreich abgeschlossen",
      "show": "Anzeigen",
      "subtitle": "Bitte treffen Sie eine Auswahl zu diesem Filter: %{entity}",
      "subtitle_error": "Das Formular konnte nicht geladen werden, überprüfen Sie Ihre",
      "update_entity": "Bearbeiten %{entity}",
      "update_error": " %{entity} konnte nicht aktualisiert werden",
      "update_success": " %{entity} wurde erfolgreich aktualisiert"
    },
    "formula_modal": {
      "back_button_text": "Zurück zum Formular",
      "calculate": "Berechnen Sie",
      "cancel": "Abbrechen",
      "mandatory": "Sie müssen die Werte für die folgenden Felder eingeben: %{fields}",
      "ok": "Formel speichern"
    },
    "grid": {
      "all": "Alle",
      "clear_filters": "Filter entfernen",
      "editable": {
        "error": {
          "mandatory": "Mindestens ein Pflichtfeld wurde leer gelassen"
        },
        "title": "Bearbeitungsmodus",
        "update": {
          "error": "%{quantity} konnte nicht gespeichert werden. Überprüfen Sie den Grund auf der linken Seite jeder hervorgehobenen Zeile",
          "success": "%{quantity} Datensätze wurden erfolgreich gespeichert"
        }
      },
      "empty_after_filtering": "Es gibt keine Datensätze für die angewandten Filter",
      "grid_filters": {
        "menu_title": "Benutzerdefinierte Filter"
      },
      "menu": {
        "visibility": "Spalten"
      },
      "of": "Von",
      "other": "Andere",
      "productivity_factor": "Produktivitätsfaktor",
      "select_items": "Elemente auswählen",
      "selected": "Ausgewählt",
      "selecteds": "Ausgewählt",
      "totals": "Summen"
    },
    "history_popup": {
      "back": "Zurück",
      "comments": "Kommentare",
      "crew_size": "Teamgröße",
      "daily_hours": "Tägliche Stunden",
      "date": "Datum",
      "form_create_progress_service_title": "Erstellen Sie den Verlauf von Performance-Service-Elementen",
      "form_create_progress_title": "Verlauf des Fortschrittselements erstellen",
      "form_edit_progress_service_title": "Verlauf des Leistungsdienstartikels bearbeiten",
      "form_edit_progress_title": "Verlauf des Fortschrittselements bearbeiten",
      "form_error_body": "Alle Pflichtfelder des Formulars müssen ausgefüllt werden.",
      "form_error_title": "Das Verlaufsformular konnte nicht gespeichert werden",
      "form_subtitle": "Fügen Sie einen Verlauf nach Menge oder Prozentsatz für Ihre Aktivität",
      "modal_create_title": "Verlauf erstellen",
      "modal_edit_title": "Verlauf bearbeiten",
      "number_of_crews": "Anzahl der Teams",
      "number_of_workers": "Anzahl der Arbeiter",
      "other_value": "Anderer Wert",
      "percentage": "Prozentsatz",
      "progress_by": "Fortschritt durch",
      "quantity": "Menge",
      "singular": "Verlauf",
      "working_hours": "Arbeitszeit"
    },
    "home": {
      "available_on": "verfügbar auf",
      "banner_description": "Verwalten von Aufgaben, Verfolgen von Prozessen und Erzielen der besten Ergebnisse über eine einzige Plattform",
      "create_request": {
        "text": "Als Erstes müssen Sie im DPMS eine Anfrage des Kunden mit den Details zu Ihrem Auftrag erstellen.",
        "title": "Erstellen Sie eine Anfrage"
      },
      "dpms_mobile": "DPMS Mobile App",
      "getting_started": "Erste Schritte",
      "home_title": "Startseite",
      "inspection": {
        "text": "Führen Sie Qualitätskontrollen vor Ort durch und erfassen Sie Beeinträchtigungen.",
        "title": "Inspektion"
      },
      "performance": {
        "text": "Erfassen Sie Fortschritt und Zeit basierend auf den geplanten Aktivitäten jedes Teams und messen Sie die Produktivität.",
        "title": "Durchführung"
      },
      "scoping": {
        "text": "Messen Sie eine Anfrage des Auftraggebers auf. Weisen Sie Dienstleistungen und Pakete aus dem Vertrag zu.",
        "title": "Aufmaß"
      },
      "welcome_user": "Willkommen, %{user}"
    },
    "importation_drop": {
      "choose_error": "Wählen Sie ein Element"
    },
    "importation_popup": {
      "file_upload_message": "Click or drag file to this area to upload",
      "importation_error": "Der Import ist fehlgeschlagen. Prüfen Sie den Grund auf der Seite Jobs.",
      "importation_success": "Imprt Erfolg",
      "importation_timeout": "Der Import dauert zu lange. Prüfen Sie den Status auf der Seite Aufträge.",
      "instructions": "Files must be a .xls and no larger than xxmb.<br> Your .xls file should contain the column - Date, Client, Project, Task, Notes....<br> The order of the columns should be same as the order of the template.<br> Date should be in - YYYY/MM/DD.",
      "requirements": "Anforderungen",
      "submit_spreadsheet": "Tabellenkalkulation einreichen"
    },
    "inspections": {
      "acceptance": "Annahme",
      "acceptance_modal": {
        "inspection_acceptance": "Inspektionsannahme",
        "reject": "Ablehnen",
        "subtitle": "Geben Sie Informationen über die Inspektion an, um mit der Abnahme fortzufahren."
      },
      "actions": {
        "approve": "Genehmigen",
        "more": "Mehr",
        "reject": "Ablehnen"
      },
      "choose_modal": {
        "schedule": "Planen Sie Inspektionsgegenstände",
        "schedule_description": "Planen Sie eine oder mehrere Inspektionen basierend auf",
        "single": "Einzelner Inspektionsgegenstand",
        "single_description": "Erstellung von nur einer Inspektion",
        "title": "Erstellen Sie eine Inspektion als:"
      },
      "description": "Inspektion",
      "every": "Jede",
      "id": "Kennung",
      "inspect_date": "Inspektionstermin",
      "inspect_status_id": "Status",
      "inspected": "Geprüft",
      "inspected_detail": "Geprüftes Detail",
      "inspected_modules": {
        "progress": "Durchführung",
        "request": "Anfrage",
        "scope": "Aufmaß"
      },
      "inspections_detail": {
        "side_panel_title": "Details der Inspektion"
      },
      "module": "Modul",
      "notifications": {
        "approve_successful": "Die Inspektion wurde erfolgreich genehmigt",
        "reject_successful": "Die Inspektion wurde erfolgreich abgelehnt"
      },
      "recurrency_interval": "Wiederholungsintervall",
      "repeat": "Wiederholung",
      "repeat_every_value": {
        "day": "Tag",
        "month": "Monat",
        "week": "Woche",
        "year": "Jahr"
      },
      "repeats_on": "Wiederholung am",
      "request_id": "DPMSID",
      "responsible_id": "Verantwortlich",
      "schedule_detail": "Zeitplan Detail",
      "schedule_form": {
        "acceptance_step": "Akzeptanz definieren (optional)",
        "detailed_item_step": "Wählen Sie die Position für Inspektions-Aufmaß/Durchführung",
        "detailed_template_step": "Wählen Sie die Vorlage für Inspektions-Aufmaß/Durchführung",
        "further_step_information": "Die flexiblen Felder können nach dem Schritt der Wiederholungserstellung individuell ausgefüllt werden",
        "general_step": "Allgemeine Informationen zur Inspektion definieren",
        "hints": "Für monatliche und jährliche Wiederholungen plant das System die Inspektion passend zum Starttermin, das im Feld Intervall angegeben ist",
        "monthly_hint": "Monatlich wiederholen an jedem Tag %{day}",
        "recurrence_step": "Wiederholung"
      },
      "scheduled_exception": "Geplant",
      "statuses": {
        "approved": "Genehmigt",
        "completed": "Abgeschlossen",
        "pending": "Ausstehend",
        "progress": "In Bearbeitung",
        "rejected": "Abgelehnt"
      },
      "template": "Vorlage wählen",
      "title": "Inspektionen"
    },
    "inspects": {
      "statuses": {
        "approved": "Genehmigt",
        "completed": "Abgeschlossen",
        "pending": "Ausstehend",
        "progress": "In Bearbeitung",
        "rejected": "Abgelehnt"
      }
    },
    "kendo": {
      "upload": "Klicken oder ziehen Sie die Dateien zum Hochladen hierher"
    },
    "kendo_translations": {
      "calendar": {
        "today": "Heute"
      },
      "dateinput": {
        "decrement": "Wert vermindern",
        "increment": "Wert erhöhen"
      },
      "datepicker": {
        "toggleCalendar": "Kalender umschalten"
      },
      "daterangepicker": {
        "end": "Ende",
        "separator": 0,
        "start": "Anfang",
        "swapStartEnd": "Start- und Endwerte vertauschen"
      },
      "datetimepicker": {
        "cancel": "Abbrechen",
        "date": "Datum",
        "set": "Einstellen",
        "time": "Uhrzeit",
        "toggleDateTimeSelector": "Datum/Uhrzeit-Auswahl umschalten"
      },
      "dropdowns": {
        "clear": "Entfernen",
        "nodata": "Keine Daten gefunden"
      },
      "editor": {
        "addColumnAfter": "Spalte auf der rechten Seite hinzufügen",
        "addColumnBefore": "Spalte auf der linken Seite hinzufügen",
        "addRowAfter": "Zeile unten hinzufügen",
        "addRowBefore": "Zeile oben hinzufügen",
        "alignCenter": "Text zentrieren",
        "alignJustify": "Ausrichten",
        "alignLeft": "Text links ausrichten",
        "alignRight": "Text rechtsbündig ausrichten",
        "bold": "Fettdruck",
        "bulletList": "Ungeordnete Liste einfügen",
        "createTable": "Eine Tabelle erstellen",
        "deleteColumn": "Spalte löschen",
        "deleteRow": "Zeile löschen",
        "deleteTable": "Tabelle löschen",
        "fontName": "Schriftartenname",
        "fontSize": "Schriftgröße",
        "format": "Format",
        "hyperlink": "Hyperlink einsetzen",
        "hyperlink-dialog-cancel": "Absagen",
        "hyperlink-dialog-content-address": "Webadresse",
        "hyperlink-dialog-content-newwindow": "Link in neuem Fenster öffnen",
        "hyperlink-dialog-content-title": "Titel",
        "hyperlink-dialog-insert": "Einfügung",
        "hyperlink-dialog-title": "Hyperlink einsetzen",
        "image": "Bild einfügen",
        "image-address": "Webadresse",
        "image-altText": "Alternativer Text",
        "image-cancel": "Absagen",
        "image-dialog-title": "Bild einfügen",
        "image-height": "Höhe (px)",
        "image-insert": "Einfügung",
        "image-title": "Titel",
        "image-width": "Breite (Pixel)",
        "indent": "Einzug",
        "insertTableHint": "Erstellen Sie eine 0 x 1-Tabelle",
        "italic": "Kursiv",
        "mergeCells": "Zellen verbinden",
        "orderedList": "Geordnete Liste einfügen",
        "outdent": "Ausrücken",
        "redo": "Wiederholen",
        "splitCell": "Geteilte Zelle",
        "strikethrough": "Durchgestrichen",
        "subscript": "Index",
        "superscript": "Hochgestellt",
        "underline": "Unterstreichen",
        "undo": "Rückgängig machen",
        "unlink": "Entfernen Sie den Hyperlink",
        "viewHtml": "HTML anzeigen",
        "viewHtml-cancel": "Absagen",
        "viewHtml-dialog-title": "HTML anzeigen",
        "viewHtml-update": "Aktualisieren"
      },
      "filter": {
        "addExpression": "Ausdruck hinzufügen",
        "addGroup": "Gruppe hinzufügen",
        "afterOperator": "Ist danach",
        "afterOrEqualOperator": "Ist nach oder gleich",
        "andLogic": "Und",
        "beforeOperator": "Ist vor",
        "beforeOrEqualOperator": "Ist vor oder gleich",
        "close": "Nah dran",
        "containsOperator": "Enthält",
        "endsWithOperator": "Endet mit",
        "eqOperator": "Ist gleich",
        "gtOperator": "Ist größer als",
        "gteOperator": "Ist größer als oder gleich",
        "isEmptyOperator": "Ist leer",
        "isFalse": "Ist falsch",
        "isNotEmptyOperator": "Ist nicht leer",
        "isNotNullOperator": "Ist nicht null",
        "isNullOperator": "Ist Null",
        "isTrue": "Ist wahr",
        "ltOperator": "Ist weniger als",
        "lteOperator": "Ist kleiner oder gleich",
        "notContainsOperator": "Beinhaltet nicht",
        "notEqOperator": "Ist ungleich zu",
        "orLogic": "Oder",
        "startsWithOperator": "Beginnt mit"
      },
      "grid": {
        "filterAfterOperator": "Ist danach",
        "filterAfterOrEqualOperator": "Ist nach oder gleich",
        "filterBeforeOperator": "Ist vor",
        "filterBeforeOrEqualOperator": "Ist vor oder gleich",
        "filterBetweenOperator": "Ist zwischen",
        "filterBooleanAll": "(Alle)",
        "filterClearButton": "Entfernen",
        "filterContainsOperator": "Enthält",
        "filterEndsWithOperator": "Endet mit",
        "filterEqOperator": "Ist gleich",
        "filterGtOperator": "Ist größer als",
        "filterGteOperator": "Ist größer als oder gleich",
        "filterIsEmptyOperator": "Ist leer",
        "filterIsFalse": "Ist falsch",
        "filterIsNotEmptyOperator": "Ist nicht leer",
        "filterIsNotNullOperator": "Ist nicht null",
        "filterIsNullOperator": "Ist Null",
        "filterIsTrue": "Ist wahr",
        "filterLtOperator": "Ist weniger als",
        "filterLteOperator": "Ist kleiner oder gleich",
        "filterNotContainsOperator": "Beinhaltet nicht",
        "filterNotEqOperator": "Ist ungleich zu",
        "filterStartsWithOperator": "Beginnt mit",
        "filterSubmitButton": "Filtern",
        "filterTitle": "Filter",
        "groupPanelEmpty": "Ziehen Sie eine Spaltenüberschrift und legen Sie sie hier ab,",
        "noRecords": "Keine Aufzeichnungen verfügbar.",
        "pagerFirstPage": "Gehen Sie zur ersten Seite",
        "pagerInfo": "0 - 1 von 2 Artikeln",
        "pagerItemsPerPage": "Elemente pro Seite",
        "pagerLastPage": "Gehen Sie zur letzten Seite",
        "pagerNextPage": "Gehen Sie zur nächsten Seite",
        "pagerOf": "Von",
        "pagerPage": "Buchseite",
        "pagerPreviousPage": "Gehen Sie zur vorherigen Seite"
      },
      "numerictextbox": {
        "decrement": "Wert verringern",
        "increment": "Wert steigern"
      },
      "pager": {
        "firstPage": "Gehen Sie zur ersten Seite",
        "info": "0 - 1 von 2 Artikeln",
        "itemsPerPage": "Elemente pro Seite",
        "lastPage": "Gehen Sie zur letzten Seite",
        "nextPage": "Gehen Sie zur nächsten Seite",
        "of": "Von",
        "page": "Buchseite",
        "previousPage": "Gehen Sie zur vorherigen Seite"
      },
      "scheduler": {
        "agendaViewTitle": "Agenda",
        "allDay": "den ganzen Tag",
        "allEvents": "Alle Veranstaltungen",
        "dateTitle": "Datum",
        "dayViewTitle": "Tag",
        "deleteConfirmation": "Möchten Sie dieses Ereignis wirklich löschen?",
        "deleteDialogTitle": "Ereignis löschen",
        "deleteOccurrence": "Aktuelles Vorkommen löschen",
        "deleteRecurringConfirmation": "Möchten Sie nur dieses Ereignis oder die ganze",
        "deleteRecurringDialogTitle": "Wiederkehrendes Element löschen",
        "deleteSeries": "Serie löschen",
        "deleteTitle": "Löschen",
        "editorCancel": "Absagen",
        "editorDelete": "Löschen",
        "editorEventAllDay": "Ganztägige Veranstaltung",
        "editorEventDescription": "Beschreibung",
        "editorEventEnd": "Ende",
        "editorEventEndTimeZone": "Endzeitzone",
        "editorEventSeparateTimeZones": "Ende in einer anderen Zeitzone",
        "editorEventStart": "Anfang",
        "editorEventStartTimeZone": "Zeitzone starten",
        "editorEventTimeZone": "Zeitzone angeben",
        "editorEventTitle": "Titel",
        "editorOccurrence": "Aktuelles Vorkommen bearbeiten",
        "editorRecurringConfirmation": "Möchten Sie nur dieses Ereignis oder die ganze",
        "editorRecurringDialogTitle": "Wiederkehrendes Element bearbeiten",
        "editorSave": "Speichern",
        "editorSeries": "Bearbeiten Sie die Serie",
        "editorTitle": "Vorfall",
        "editorValidationEnd": "Die Endzeit muss nach der Startzeit liegen.",
        "editorValidationRequired": "Feld ist erforderlich.",
        "editorValidationStart": "Die Startzeit muss vor der Endzeit liegen.",
        "eventTitle": "Vorfall",
        "monthViewTitle": "Monat",
        "nextTitle": "Weiter",
        "noEvents": "keine Ereignisse",
        "previousTitle": "Vorherige",
        "recurrenceEditorDailyInterval": "Tage)",
        "recurrenceEditorDailyRepeatEvery": "Wiederhole jeden",
        "recurrenceEditorEndAfter": "Nach",
        "recurrenceEditorEndLabel": "Ende",
        "recurrenceEditorEndNever": "Niemals",
        "recurrenceEditorEndOccurrence": "Vorkommen",
        "recurrenceEditorEndOn": true,
        "recurrenceEditorFrequenciesDaily": "Täglich",
        "recurrenceEditorFrequenciesMonthly": "Monatlich",
        "recurrenceEditorFrequenciesNever": "Niemals",
        "recurrenceEditorFrequenciesWeekly": "Wöchentlich",
        "recurrenceEditorFrequenciesYearly": "Jährlich",
        "recurrenceEditorMonthlyDay": "Tag",
        "recurrenceEditorMonthlyInterval": "Monat(E)",
        "recurrenceEditorMonthlyRepeatEvery": "Wiederhole jeden",
        "recurrenceEditorMonthlyRepeatOn": "Wiederholen Sie auf",
        "recurrenceEditorOffsetPositionsFirst": "Zuerst",
        "recurrenceEditorOffsetPositionsFourth": "Vierte",
        "recurrenceEditorOffsetPositionsLast": "Letzte",
        "recurrenceEditorOffsetPositionsSecond": "Zweite",
        "recurrenceEditorOffsetPositionsThird": "Dritte",
        "recurrenceEditorRepeat": "Wiederholen",
        "recurrenceEditorWeekdaysDay": "Tag",
        "recurrenceEditorWeekdaysWeekday": "Wochentag",
        "recurrenceEditorWeekdaysWeekendday": "Wochenend Tag",
        "recurrenceEditorWeeklyInterval": "Wochen)",
        "recurrenceEditorWeeklyRepeatEvery": "Wiederhole jeden",
        "recurrenceEditorWeeklyRepeatOn": "Wiederholen Sie auf",
        "recurrenceEditorYearlyInterval": "Jahre)",
        "recurrenceEditorYearlyOf": "Von",
        "recurrenceEditorYearlyRepeatEvery": "Wiederhole jeden",
        "recurrenceEditorYearlyRepeatOn": "Wiederholen Sie auf",
        "showFullDay": "Ganztägig zeigen",
        "showWorkDay": "Öffnungszeiten anzeigen",
        "timeTitle": "Zeit",
        "timelineViewTitle": "Zeitleiste",
        "today": "Heute",
        "weekViewTitle": "Woche",
        "workWeekViewTitle": "Arbeitswoche"
      },
      "sortable": {
        "noData": "Keine Daten"
      },
      "timepicker": {
        "cancel": "Absagen",
        "now": "Jetzt",
        "selectNow": "Wählen Sie Jetzt aus",
        "set": "Satz",
        "toggleClock": "Uhr umschalten",
        "toggleTimeSelector": "Toggle TimeSelector"
      },
      "treelist": {
        "filterAfterOperator": "Ist danach",
        "filterAfterOrEqualOperator": "Ist nach oder gleich",
        "filterBeforeOperator": "Ist vor",
        "filterBeforeOrEqualOperator": "Ist vor oder gleich",
        "filterBooleanAll": "(Alle)",
        "filterClearButton": "Entfernen",
        "filterContainsOperator": "Enthält",
        "filterEndsWithOperator": "Endet mit",
        "filterEqOperator": "Ist gleich",
        "filterGtOperator": "Ist größer als",
        "filterGteOperator": "Ist größer als oder gleich",
        "filterIsEmptyOperator": "Ist leer",
        "filterIsFalse": "Ist falsch",
        "filterIsNotEmptyOperator": "Ist nicht leer",
        "filterIsNotNullOperator": "Ist nicht null",
        "filterIsNullOperator": "Ist Null",
        "filterIsTrue": "Ist wahr",
        "filterLtOperator": "Ist weniger als",
        "filterLteOperator": "Ist kleiner oder gleich",
        "filterNotContainsOperator": "Beinhaltet nicht",
        "filterNotEqOperator": "Ist ungleich zu",
        "filterStartsWithOperator": "Beginnt mit",
        "noRecords": "Keine Aufzeichnungen verfügbar"
      },
      "upload": {
        "cancel": "Absagen",
        "clearSelectedFiles": "Entfernen",
        "dropFilesHere": "oder Datei hier ablegen",
        "files": "Dateien",
        "headerStatusUploaded": "Fertig",
        "headerStatusUploading": "Hochladen...",
        "invalidFileExtension": "Dateityp nicht erlaubt.",
        "invalidFiles": "Ungültige Datei(en). Bitte überprüfen Sie die Anforderungen für",
        "invalidMaxFileSize": "Dateigröße zu groß.",
        "invalidMinFileSize": "Dateigröße zu klein.",
        "remove": "Entfernen",
        "retry": "Wiederholen",
        "select": "Datei aussuchen",
        "total": "Gesamt",
        "uploadSelectedFiles": "Hochladen"
      }
    },
    "loading_batch_entities": {
      "page_of": "Seite %{page} von %{total}",
      "title": "Seiten abrufen"
    },
    "login": {
      "again": "Um fortzufahren, melden Sie sich bitte erneut an",
      "continue_mykaefer": "Weiter mit MyKaefer",
      "copyright": "®%{year} Alle Rechte vorbehalten. Kaefer®",
      "forgot_password": "Passwort vergessen?",
      "iframe_info": "Zugriff auf Seite anfordern: %{link}",
      "log_in": "Einloggen",
      "password": "Kennwort",
      "title_1": "Ein leistungsstarkes Werkzeug",
      "title_2": "für die Projektverwaltung",
      "username": "Benutzername",
      "welcome_back": "Willkommen zurück"
    },
    "main_dialog": {
      "failure_message": "Ihre Anfrage konnte nicht abgeschlossen werden",
      "failure_title": "Fehlgeschlagen",
      "loading_message": "Bitte warten Sie, während wir alles für Sie vorbereiten!",
      "loading_title": "Wird geladen...",
      "service_title": "Warten auf den Abschluss des Auftrags ...",
      "success_message": "Ihre Anfrage war erfolgreich! Sie können jetzt mit Ihrer Arbeit fortfahren.",
      "success_title": "Erfolgreich!"
    },
    "notification": {
      "access_denied": "Zugriff verweigert",
      "action_success": "%{model} %{id} war %{action}",
      "bad_formula_setup": "Es gibt ein Setup-Problem mit der Formel '%{title}', bitte",
      "correct_subproject": "Wählen Sie das richtige Teilprojekt dieses Elements aus",
      "dismiss_all": "Alle entlassen",
      "error": "Fehler",
      "error_cookies": "Wir konnten keine Kommunikation zwischen den verschiedenen Quellen herstellen. Überprüfen Sie die Cookie-Einstellungen in Ihrem Browser und deaktivieren Sie \"Third-Party Cookie blocking\".",
      "error_job": "Fehlgeschlagen! Überprüfen Sie den Grund auf der Seite Jobs.",
      "error_msg": "Entschuldigung! Etwas ist schief gelaufen!",
      "info": "Info",
      "large_file_body": "Bitte wenden Sie einige Filter an, um die Ausgabedatei kleiner",
      "large_file_title": "Das Raster enthält mehr als 1000 Datensätze",
      "page_denied": "Die Seite konnte nicht geladen werden.",
      "please": "Bitte",
      "select_subproject": "Wählen Sie ein Projekt und ein Teilprojekt aus, um fortzufahren",
      "success": "Erfolg",
      "success_msg": "Aktion mit Erfolg ausgeführt!",
      "warning": "Warnung"
    },
    "orders": {
      "add_evidence": "Fügen Sie einen Nachweis der Client-Autorisierung hinzu",
      "attachment": "Anhang",
      "comments": "Kommentare",
      "date": "Datum",
      "drag_and_drop": "Fügen Sie Dateien per Drag-and-Drop hinzu",
      "responsible": "Verantwortlich",
      "title": "Bestellungen"
    },
    "performance": {
      "title": "Durchführung"
    },
    "performances": {
      "title": "Durchführungsverzeichnis"
    },
    "performances_wip": {
      "performance_item": "Durchführung",
      "title": "Leistung"
    },
    "planning": {
      "item_no": "Element-Nr.",
      "service_no": "Service-Nr.",
      "title": "Planung",
      "view": "Planungsansicht"
    },
    "plannings": {
      "title": "Planung"
    },
    "progress_histories": {
      "add_new_progress": "Fügen Sie einen neuen Fortschritt hinzu",
      "empty_historic": "Es gibt kein Historienelement und es ist nicht möglich, Historien",
      "hours": "Std",
      "production_factor": "PF",
      "progress": "Fortschritt",
      "progress_badge": "Artikel %{number}",
      "progress_history": "Fortschrittsverlauf",
      "progress_service_badge": "Dienst %{number}",
      "progress_service_history": "Leistungsverlauf",
      "progress_service_title": "Leistungsverlauf",
      "progress_title": "Artikelhistorien",
      "quantity": "Menge",
      "subtitle": "Verwalten Sie den täglichen Fortschritt basierend auf den von Ihrem"
    },
    "progress_services": {
      "actual_div_hours": "Ist-/Team-Sollstunden",
      "actual_div_quantity": "Ist-/Sollmenge",
      "actual_hours": "IST-Stunden",
      "actual_quantity": "Ist-Menge",
      "application_price": "Anwendungspreis",
      "back": "Zurück",
      "budget_target_hours": "Budgetzielstunden",
      "canceled": "Storniert",
      "choose_modal": {
        "package": "Dienstleistungen aus Paket",
        "package_description": "Erstellung mehrerer Leistungen auf Basis des gleichen Aktivitätsmodells",
        "single": "Einzelne Dienstleistung",
        "single_description": "Eine Dienstleistung zur Durchführung hinzufügen",
        "title": "Neu erstellen"
      },
      "contract_service": "Vertragsleistung",
      "contribution": "Beitrag",
      "contribution_info": "Werte automatisch berechnet",
      "crew": "Team",
      "crew_size": "Teamgröße",
      "crews": "Teams",
      "date_filter": {
        "all": "Alle",
        "today": "Heute",
        "tomorrow": "Morgen",
        "week": "Woche",
        "yesterday": "Gestern"
      },
      "delta_hours": "Delta-Stunden",
      "delta_qtt": "Delta-Menge",
      "description": "Dienstleistung",
      "discipline": "Disziplin",
      "end_date": "Endtermin",
      "equipment_price": "Equipmentpreis",
      "factor": "Faktor",
      "flag": "Flagge",
      "flag_filter": "Flag-Filter",
      "id": "ID",
      "materials_price": "Materialpreis",
      "no_crews": "Anzahl der Teams",
      "no_workers": "Anzahl der Arbeiter",
      "norm_hours": "Normstunden",
      "not_planned": "Nicht geplant",
      "other_price": "Sonstiger Preis",
      "package_factor": "Faktor",
      "people": "Personen",
      "person": "Person",
      "pf": "Fs",
      "pf_doing_bad": "Fortschritt nicht wie geplant",
      "pf_doing_good": "Fortschritt nach Plan!",
      "pf_doing_neutral": "Fortschritt konnte nicht berechnet werden",
      "planned": "Geplant",
      "planning_chart": {
        "actual": "IST-",
        "actual_qty": "IST-Menge",
        "date": "Datum",
        "forecasted": "Voraussichtlich",
        "forecasted_qty": "Prognostizierte Menge",
        "target": "Ziel",
        "target_qty": "Zielmenge",
        "title": "Planungstabelle"
      },
      "planning_timeline": {
        "day": "Tag",
        "day_advance": "Tag im Voraus",
        "day_delayed": "Tag verzögert",
        "days": "Tage",
        "days_advance": "Tage im Voraus",
        "days_delayed": "Tage verzögert",
        "end_date": "Endtermin",
        "forecasted": "Voraussichtlich",
        "on_time": "Pünktlich",
        "remaining_days": "Verbleibende Tage",
        "start_date": "Starttermin",
        "title": "Zeitplanung"
      },
      "progress": "Fortschritt",
      "progress_services_detail": {
        "side_panel_title": "Details des Dienstes"
      },
      "quantity": "Menge",
      "responsible": "Verantwortlich",
      "scoped_quantity": "Aufgemessene Menge",
      "service": "Dienstleistung",
      "service_package": "Dienstleistungspaket",
      "service_planning": "Teamplanung",
      "service_progress": "Dienstleistungsfortschritt",
      "service_weight": "Gewicht der Dienstleistung",
      "start_date": "Starttermin",
      "statuses": {
        "canceled": "Abgesagt",
        "done": "Abgeschlossen",
        "extra": "Extra",
        "in_planning": "Nicht geplant",
        "planned": "Geplant"
      },
      "team_target_hours": "Team-Sollstunden",
      "title": "Dienstleistungen",
      "title_packageable": "Leistungsservices nach Servicepaket",
      "total": "Gesamt",
      "total_hours": "Gesamtstunden",
      "unit": "Einheit",
      "worker": "Arbeiter",
      "workers": "Arbeitskräfte",
      "working_hours": "Arbeitszeit"
    },
    "progresses": {
      "cancel": "Absagen",
      "choose_modal": {
        "single": "Neue Durchführung",
        "single_description": "Neues Aufmaß während der Durchführung anlegen"
      },
      "complete": "Vervollständigen",
      "delta_hours": "∆ Stunden",
      "delta_qty": "∆ Menge",
      "description": "Beschreibung",
      "extra": "Extra",
      "flag": "Flagge",
      "history": "Verlauf",
      "id": "ICH WÜRDE",
      "item": "Element",
      "label": "Etikett",
      "pf": "PF",
      "progress": "Fortschritt",
      "progresses_detail": {
        "side_panel_title": "Leistungsdetails"
      },
      "request_status_restriction": "Die Anfrage sollte den Status In Planung oder In Ausführung",
      "revised": "Überarbeitet",
      "title": "Fortschritte"
    },
    "projects": {
      "workdays": {
        "fri": "Fr.",
        "mon": "Mo.",
        "sat": "Sa.",
        "sun": "So.",
        "thu": "Do.",
        "tue": "Di.",
        "wed": "Mi."
      }
    },
    "requests": {
      "actual_hours": "IST-Stunden",
      "actual_progress": "IST-Fortschritt",
      "all": "Alle",
      "are_you_sure_complete": "Sind Sie sicher, dass Sie diese Anfrage abschließen werden?",
      "are_you_sure_complete_no_history": "Sind Sie sicher, dass Sie eine Anfrage ohne eine Fortschrittsangabe abschließen wollen?",
      "are_you_sure_deletion": "Sind Sie sicher, dass Sie die Anfrage löschen wollen?",
      "are_you_sure_revertion": "Sind Sie sicher, dass Sie den Vorgang rückgängig machen und damit die Daten, die Sie in diesem Modul eingegeben haben, verlieren wollen?",
      "area": "Bereich",
      "authorization_pending": "Autorisierung ausstehend",
      "authorize_form": {
        "modal_title": "Genehmigung",
        "subtitle": "Füge weitere Informationen hinzu, um mit der Anfrage fortzufahren",
        "title": "Anfrage genehmigen"
      },
      "canceled": "Abgebrochen",
      "clustered": "Geclustert",
      "comments": "Kommentare",
      "confirmation_modal": {
        "cancel_description": "Die Anfrage wird storniert",
        "cancel_title": "Anfrage abbrechen?",
        "complete_description": "Diese Anfrage wird abgeschlossen",
        "complete_title": "Anfrage abschließen?",
        "confirm_action": "Weiter zu Import Aufmaß",
        "confirm_cancel": "Nicht jetzt",
        "confirm_confirmation": "Ja, ich möchte bestätigen",
        "confirm_description": "Möchten Sie die Anfrage bestätigen?",
        "confirm_title": "Anfrage-Bestätigung",
        "delete_description": "Die Entfernungsaktion kann nicht rückgängig gemacht werden.",
        "delete_title": "Anfrage löschen?",
        "reject_description": "Dieses Element kehrt in den Status \"Im Aufmaß\" zurück.",
        "reject_title": "Anfrage ablehnen?",
        "revert_description": "Das Rückgängig machen ist endgültig. Sind Sie sicher?",
        "revert_title": "Anfrage rückgängig machen?"
      },
      "confirmed": "Bestätigt",
      "creation_form": {
        "proceed": "Weiter zu Schritt 2"
      },
      "creation_page": {
        "add_template": "Vorlage hinzufügen",
        "first_step": "Anfrage erstellen",
        "second_step": "Aufmaße importieren (optional)",
        "third_step": "Dienstleistungen aus Paket anwenden (optional)"
      },
      "description": "Anfrage",
      "discipline": "Gewerk",
      "done": "Fertig",
      "duplicated_reference": "Doppelte Referenzen zulassen",
      "eav_template": "Vorlage",
      "eav_template_id": "Vorlage-ID",
      "edit_dialog": {
        "reason": "Name der Unteranforderung"
      },
      "elevation": "Höhenangaben",
      "errors": {
        "not_found": "Anfrage mit der ID: %{id} kann nicht gefunden werden"
      },
      "estimates_authorization": "Autorisierung der Kalkulation erforderlich",
      "forecast_end_date": "Voraussichtlicher Endtermin",
      "id": "DPMSID",
      "in_performance": "In Durchführung",
      "in_planning": "In Planung",
      "in_scoping": "Im Aufmaß",
      "information": "Zusätzliche Informationen",
      "notification": {
        "authorization_success": "Die Anfrage wurde erfolgreich genehmigt.",
        "deleting_sub_request": "Die Unteranforderung %{id} wird gelöscht. Bitte überprüfen",
        "missing_order_error": "Es ist kein Auftrag mit dieser Anfrage verbunden"
      },
      "on_hold": "in Wartestellung",
      "progress_end_date": "Fortschritt Endtermin",
      "progress_responsible": "Verantwortlich für den Fortschritt",
      "progress_responsible_id": "Verantwortlich für den Fortschritt",
      "progress_start_date": "Datum des Beginns des Fortschritts",
      "reason": "Referenz",
      "reference": "Verweis",
      "registered": "Registriert",
      "request_date": "Datum der Anfrage",
      "request_detail": {
        "edit": "Bearbeiten",
        "estimates": "Kostenvoranschläge",
        "inspections": "Inspektionen",
        "performance": "Durchführung",
        "planning": "Planung",
        "scopes": "Aufmaße",
        "show": "Anzeigen",
        "side_panel_title": "Details zur Anfrage"
      },
      "request_end_date": "Endtermin der Anfrage",
      "request_responsible": "Verantwortlich für die Anfrage",
      "request_responsible_id": "Verantwortlich für die Anfrage",
      "request_start_date": "Starttermin der Anfrage",
      "schedule": "Zeitplan der Anfrage",
      "schedule_form": {
        "date": "Datum",
        "days": "%{number} Tage",
        "performance": "Durchführung",
        "progress": "Fortschritt",
        "request": "Anfrage",
        "responsible": "Verantwortlich",
        "schedule": "Zeitplan",
        "scoping": "Aufmaß",
        "subtitle": "Um einen Zeitplan für diese Anfrage zu erstellen, werden zusätzliche Informationen benötigt",
        "title": "Anfrage Zeitplan"
      },
      "scope_end_date": "Aufmaß-Endtermin",
      "scope_responsible": "Verantwortlich für Aufmaß",
      "scope_responsible_id": "Verantwortlich für Aufmaß",
      "scope_start_date": "Aufmaß-Starttermin",
      "scopes": "Aufmaße",
      "service_desired_date": "Gewünschter Termin",
      "skip_scoping": "Aufmaß-Phase überspringen",
      "status": "Status",
      "statuses": {
        "authorization_pending": "Ausstehend",
        "canceled": "Abgesagt",
        "clustered": "Geclustert",
        "confirmed": "Im Aufmaß",
        "deleting": "Löschen",
        "done": "Fertig",
        "in_performance": "Wird ausgeführt",
        "in_planning": "Bei der Leistung",
        "in_scoping": "Im Aufmaß",
        "on_hold": "In Wartestellung",
        "registered": "Erstellt"
      },
      "sub_request": "Sub-Anfrage",
      "sub_requests": "Teil-Anforderungen",
      "subarea": "Teilbereich",
      "team_target_hours": "Team Soll-Stunden",
      "title": "Anfragen"
    },
    "scopes": {
      "add": "Aufmaß hinzufügen",
      "choose_modal": {
        "single": "Neues Aufmaß",
        "single_description": "Neues Aufmaß-Protokoll starten"
      },
      "clusters": {
        "title": "Aufmaß-Cluster"
      },
      "count": "Anzahl",
      "crew_size": "Teamgröße",
      "crews": "Anzahl der Teams",
      "date": "Datum",
      "description": "Aufmaß",
      "hours": "Stunden (h)",
      "id": "ID",
      "item": "Artikel",
      "label": "Etikett",
      "label_assigner": {
        "success": "Ihre Aufmaß Labels wurden erfolgreich zugewiesen! Sie können jetzt mit Ihrem Plan fortfahren."
      },
      "label_error": "Etiketten konnten nicht abgerufen werden",
      "popup_label": {
        "create_label": "Etikett erstellen",
        "labels": "Etiketten:",
        "name_label": "Name des Etiketts",
        "new_label": "Neues Label"
      },
      "quantity": "Menge",
      "remaining_hours": "Verbleibende Stunden",
      "remaining_quantity": "Verbleibende Menge",
      "request_status_restriction": "Die Anfrage sollte den Status „Bestätigt“ oder „In",
      "scopes_detail": {
        "side_panel_title": "Aufmaßdetails"
      },
      "title": "Aufmaßverzeichnis",
      "unit": "Einheit",
      "working_hours": "Arbeitszeit"
    },
    "search": {
      "loading": "Laden...",
      "no_result_found": "Kein Ergebnis gefunden",
      "placeholder": "Filtern..."
    },
    "settings": {
      "title": "Einstellungen"
    },
    "settings_shortcuts": "Abkürzungen für Einstellungen",
    "shortcuts": {
      "access_control": "Zugriffskontrolle",
      "areas": "Bereiche und Teilbereiche",
      "areas_subareas": "Bereiche und Unterbereiche",
      "assets": "Vermögenswerte",
      "background_jobs": "Hintergrundjobs",
      "branches": "Niederlassungen",
      "cities": "Städte",
      "client_sites": "Kunden-Standorte",
      "clients": "Kunden",
      "clients_info": "Kunden-Infos",
      "columns_remaining": "Verbleibende Kolumnen",
      "columns_renaming": "Spalten umbenennen",
      "companies": "Unternehmen",
      "contract_services": "Vertragsleistungen",
      "contracts": "Verträge",
      "countries": "Länder",
      "customer_sites": "Kundenseiten",
      "customers": "Kunden",
      "disciplines": "Disziplinen",
      "employees": "Mitarbeiter",
      "equipaments": "Ausrüstungen",
      "equipments": "Ausrüstungen",
      "formulas": "Formeln",
      "location": "Standort",
      "materials": "Materialien",
      "matrices": "Matrizen",
      "measures": "Maße",
      "mobile_details": "Mobile Details",
      "position_names": "Positionsnamen",
      "preferences": "Präferenzen",
      "project_assets": "Vermögenswerte",
      "project_contracts": "Verträge",
      "projects": "Projekte und Teilprojekte",
      "projects_contracts": "Projekte und Verträge",
      "projects_subprojects": "Projekte und Teilprojekte",
      "provinces": "Staaten / Provinzen",
      "regions": "Regionen",
      "routes": "Routen",
      "specialities": "Spezialitäten",
      "staff": "Personal",
      "states_provinces": "Staaten / Provinzen",
      "translations": "Spaltenumbenennung",
      "users": "Nutzer",
      "visible_fields": "Mobile Details",
      "work_positions": "Positionsnamen"
    },
    "side_menu": {
      "contracts": "Verträge",
      "datasheets": "Datenblätter",
      "new": "Neu",
      "report": "Berichte",
      "search": "Suche",
      "settings": "Einstellungen",
      "template": {
        "asset": "Anlage",
        "contract_service": "Vertragsleistungen",
        "datasheet": "Datenblatt",
        "estimate_service": "Kalkulation",
        "inspection": "Inspektion",
        "performance": "Durchführung",
        "report": "Berichte",
        "request": "Anfrage",
        "scoping": "Aufmaß",
        "valuation": "Bewertung"
      },
      "templates": "Vorlagen"
    },
    "sub_requests": {
      "creation_page": {
        "all": "Alle Unteranfragen",
        "back": "Alle Bereiche",
        "title": "Unteranfragen"
      },
      "left_panel": {
        "add_scoping": "Aufmaß hinzufügen",
        "allocation": "Zuweisung",
        "cards_header_title": "Aufmaße",
        "summary": "Zusammenfassung",
        "total_allocation": "Gesamte Zuweisung"
      },
      "scope_card": {
        "scope": "Aufmaß"
      }
    },
    "tabs": {
      "error": "Tabs können nicht geladen werden"
    },
    "templates": {
      "asset": "Vermögenswerte",
      "contract_service": "Vertragsleistung",
      "datasheet": "Datenblätter",
      "estimate_service": "Kalkulation",
      "inspection": "Inspektionen",
      "no_template": "Sie müssen eine Vorlage erstellen",
      "progress": "Leistung",
      "progress_service": "Fortschritt",
      "report": "Berichte",
      "request": "Anfragen",
      "scoping": "Aufmaß",
      "title": "Vorlagen für %{type}"
    },
    "tickets": {
      "icon_alt": "Ein Ticket-Symbol",
      "title": "Eintrittskarten"
    },
    "top_menu": {
      "authorization": "Autorisierung",
      "estimates": "Kalkulation",
      "inspections": "Inspektionen",
      "performance": "Durchführung",
      "planning": "Planung",
      "requests": "Anfragen",
      "scoping": "Aufmaß"
    },
    "track_block": {
      "id": "DPMSID"
    },
    "user_menu": {
      "about_version": "Über Version",
      "logout": "Abmelden",
      "profile": "Profil"
    },
    "users": {
      "edit_title": "Profil aktualisieren"
    }
  },
  "en": {
    "actions": {
      "approve": "Approve",
      "bulk_editing": "Bulk Editing",
      "cancel": "Cancel",
      "choose_entity": "Choose %{entity}",
      "clear": "Clear",
      "confirm": "Confirm",
      "create": "Create",
      "delete": "Delete",
      "discard": "Discard",
      "done": "Done",
      "duplicate": "Duplicate",
      "edit": "Edit",
      "export": "Export to .xls",
      "export_template": "Download template .xls",
      "import": "Import from .xls",
      "import_file": "Import file",
      "loading": "Loading",
      "more": "More",
      "new": "New",
      "next": "Next",
      "no": "No",
      "past": {
        "cancel": "canceled",
        "complete": "completed",
        "confirm": "confirmed",
        "delete": "deleted",
        "destroy": "deleted",
        "reject": "rejected",
        "revert": "reverted",
        "update": "updated"
      },
      "prev": "Prev",
      "reject": "Reject",
      "reset": "Reset",
      "revert": "Revert",
      "save": "Save",
      "save_button_keyboard": "Save (Ctrl + Enter)",
      "save_exit": "Save & Exit",
      "saving": "Saving...",
      "schedule": "Schedule",
      "skip": "Skip",
      "unsaved_changes": "Unsaved Changes",
      "upload_file": "Upload file",
      "upload_picture": "Upload picture",
      "yes": "Yes"
    },
    "activerecord": {
      "errors": {
        "messages": {
          "record_invalid": "Validation failed: %{errors}",
          "restrict_dependent_destroy": {
            "has_many": "Cannot delete record because dependent %{record} exist",
            "has_one": "Cannot delete record because a dependent %{record} exists"
          }
        }
      }
    },
    "api_internal_messages": {
      "cant_duplicate_clustered_request": "Is not possible to duplicate a Clustered Request",
      "cant_duplicate_progress": "Cannot duplicate the Services because of the following errors...",
      "cant_duplicate_request": "Cannot duplicate Scopes, Estimate and Services because of the following errors...",
      "cant_duplicate_scope": "Cannot duplicate the Services because of the following errors...",
      "only_estimate_service_copy": "Only the Estimate Service item is trying to be copied.",
      "only_progress_copy": "Only the Progress is trying to be copied.",
      "only_request_copy": "Only the Request is trying to be copied.",
      "only_scope_copy": "Only the Scoping item is trying to be copied."
    },
    "cancel_modal": {
      "cancel": "Not now",
      "description": "Are you sure you'd like to cancel the %{model} \"%{id}\"?",
      "ok": "Yes, cancel",
      "title": "Cancel item?"
    },
    "clear_records": {
      "modal": {
        "cancel": "No, cancel",
        "description": "Are you sure you'd like to clear all %{model} records?",
        "ok": "Yes, clear all",
        "title": "Clear %{model}?"
      },
      "success_message": "%{model} cleared successfully!",
      "title": "Clear records"
    },
    "collections": {
      "clusters": "Clusters",
      "create_by_package": "Create by package",
      "duplicate": "Duplicate",
      "edit": "Edit",
      "estimate": "Estimate",
      "new": "New",
      "schedule": "Schedule",
      "sub_requests": "Sub Requests",
      "view": "View"
    },
    "complete_modal": {
      "cancel": "Not now",
      "description": "Are you sure you'd like to mark %{model} as completed?",
      "ok": "Yes, mark as completed",
      "title": "Mark as completed?"
    },
    "confirmation_modal": {
      "cancel": "Cancel",
      "description": "This action cannot be undone!",
      "ok": "Ok",
      "title": "Are you sure?"
    },
    "contract_services": {
      "title": "Contract Services"
    },
    "datasheets": {
      "datasheets_detail": {
        "side_panel_title": "Datasheet Details"
      },
      "id": "ID",
      "item": "item",
      "title": "Datasheets"
    },
    "date": {
      "abbr_day_names": [
        "Sun",
        "Mon",
        "Tue",
        "Wed",
        "Thu",
        "Fri",
        "Sat"
      ],
      "abbr_month_names": [
        null,
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
      ],
      "day_names": [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
      ],
      "formats": {
        "default": "%Y-%m-%d",
        "long": "%B %d, %Y",
        "short": "%b %d"
      },
      "month_names": [
        null,
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ],
      "order": [
        "year",
        "month",
        "day"
      ]
    },
    "datetime": {
      "distance_in_words": {
        "about_x_hours": {
          "one": "about 1 hour",
          "other": "about %{count} hours"
        },
        "about_x_months": {
          "one": "about 1 month",
          "other": "about %{count} months"
        },
        "about_x_years": {
          "one": "about 1 year",
          "other": "about %{count} years"
        },
        "almost_x_years": {
          "one": "almost 1 year",
          "other": "almost %{count} years"
        },
        "half_a_minute": "half a minute",
        "less_than_x_minutes": {
          "one": "less than a minute",
          "other": "less than %{count} minutes"
        },
        "less_than_x_seconds": {
          "one": "less than 1 second",
          "other": "less than %{count} seconds"
        },
        "over_x_years": {
          "one": "over 1 year",
          "other": "over %{count} years"
        },
        "x_days": {
          "one": "1 day",
          "other": "%{count} days"
        },
        "x_minutes": {
          "one": "1 minute",
          "other": "%{count} minutes"
        },
        "x_months": {
          "one": "1 month",
          "other": "%{count} months"
        },
        "x_seconds": {
          "one": "1 second",
          "other": "%{count} seconds"
        }
      },
      "prompts": {
        "day": "Day",
        "hour": "Hour",
        "minute": "Minute",
        "month": "Month",
        "second": "Seconds",
        "year": "Year"
      }
    },
    "delete_modal": {
      "cancel": "No, cancel",
      "description": "Are you sure you'd like to delete the %{model} \"%{id}\"?",
      "ok": "Yes, delete",
      "title": "Delete item?"
    },
    "delete_recurrencies": {
      "modal": {
        "cancel": "No, cancel",
        "description": "Are you sure you'd like to delete the all related recurrencies?",
        "ok": "Yes, delete all",
        "title": "Delete all recurrencies?"
      },
      "success_message": "Recurrencies deleted successfully!",
      "title": "Delete all recurrencies"
    },
    "dialogs": {
      "columns_batch_assigner": {
        "actions": "Actions",
        "add_action": "Add action",
        "add_value_to": "Add %{value} to",
        "column": "Column",
        "done": "Done",
        "header": "Batch edit",
        "multiple_success": "%{numberOfItems} items were updated.",
        "new_value": "New value",
        "single_success": "1 item was updated.",
        "subtitle": "Assign a value to the %{numberOfItems} selected item(s)",
        "title": "Create action",
        "update": "Update"
      }
    },
    "discard_modal": {
      "cancel": "Keep editing",
      "description": "Are you sure you'd like to discard your changes ?",
      "ok": "Yes, discard",
      "title": "Discard Changes"
    },
    "eav_templates": {
      "title": "Templates"
    },
    "empty_state": {
      "empty": "No %{model}",
      "not_created": "You have not created any %{model} yet"
    },
    "environment": {
      "project": {
        "select_project": "Select Project..."
      },
      "select_project_subproject": "Select project and subproject",
      "subproject": {
        "select_subproject": "Select Subproject..."
      }
    },
    "errors": {
      "format": "%{attribute} %{message}",
      "messages": {
        "accepted": "must be accepted",
        "blank": "can't be blank",
        "confirmation": "doesn't match %{attribute}",
        "empty": "can't be empty",
        "equal_to": "must be equal to %{count}",
        "even": "must be even",
        "exclusion": "is reserved",
        "greater_than": "must be greater than %{count}",
        "greater_than_or_equal_to": "must be greater than or equal to %{count}",
        "in": "must be in %{count}",
        "inclusion": "is not included in the list",
        "invalid": "is invalid",
        "less_than": "must be less than %{count}",
        "less_than_or_equal_to": "must be less than or equal to %{count}",
        "model_invalid": "Validation failed: %{errors}",
        "not_a_number": "is not a number",
        "not_an_integer": "must be an integer",
        "odd": "must be odd",
        "other_than": "must be other than %{count}",
        "password_too_long": "is too long",
        "present": "must be blank",
        "required": "must exist",
        "taken": "has already been taken",
        "too_long": {
          "one": "is too long (maximum is 1 character)",
          "other": "is too long (maximum is %{count} characters)"
        },
        "too_short": {
          "one": "is too short (minimum is 1 character)",
          "other": "is too short (minimum is %{count} characters)"
        },
        "wrong_length": {
          "one": "is the wrong length (should be 1 character)",
          "other": "is the wrong length (should be %{count} characters)"
        }
      }
    },
    "estimate": {
      "accept": "Accept Estimate",
      "application": "Application cost",
      "back": "All Requests",
      "budget_hours": "Budget Hours",
      "confirmation": {
        "cancel": "No, Cancel",
        "confirm": "Yes, Confirm",
        "description": "Are you sure you want to authorize this estimate for this Request?",
        "title": "Confirm Order"
      },
      "description": "You have %{number} services for checkout. Please check details below.",
      "equipment": "Equipment cost",
      "estimate_details": "Estimation Details",
      "estimate_success": "Estimate successfully approved",
      "info_message": "This estimate has already been approved. It is available for read only.",
      "material": "Material cost",
      "no_estimate_message": "There is no Estimate associated with this request.",
      "norm_hours": "Norm Hours",
      "others": "Other cost",
      "save_draft": "Save Draft",
      "service_details": "Service Details",
      "team_target_hours": "Team Target Hours",
      "title": "Checkout Service",
      "tooltip": {
        "application": "Application",
        "equipment": "Equipment",
        "material": "Material",
        "others": "Others"
      },
      "total_request": "Total cost"
    },
    "estimate_services": {
      "application_price": "Application Price",
      "automatically_prices": "Automatically calculated prices",
      "back": "All Scopes",
      "budget_target_hours": "Budget Target Hours",
      "choose_modal": {
        "package": "Services from Package",
        "package_description": "Creation of several services based on the same activity model",
        "single": "Single service item",
        "single_description": "Creation of only one service",
        "title": "Create New"
      },
      "contract_service": "Contract Service",
      "crew_size": "Crew Size",
      "crews": "Number of Crews",
      "description": "Estimate Service",
      "description_label": "Description",
      "discipline": "Discipline",
      "equipment_price": "Equipment Price",
      "estimate": "Estimate",
      "estimate_services_detail": {
        "side_panel_title": "Service's details"
      },
      "factor": "Factor",
      "id": "ID",
      "materials_price": "Materials Price",
      "no_template": "You need to create template for Estimate services",
      "norm_hours": "Norm Hours",
      "other_price": "Other Price",
      "prices": "Prices",
      "quantity": "Quantity",
      "service": "Service",
      "service_package": "Service Package",
      "team_target_hours": "Team Target Hours",
      "title": "Estimate Services",
      "title_packageable": "Estimate Services by Service Package",
      "total": "Total",
      "unit": "Unit",
      "weight": "Weight",
      "working_hours": "Working Hours"
    },
    "fields": {
      "image_not_found": "Image Not Found",
      "na": "N/A",
      "signature": "Signature.jpg"
    },
    "filter_steps_modal": {
      "filter_result": "Filter Result",
      "step": "Step",
      "subtitle": "Provide some information about the %{title}"
    },
    "filtering": {
      "between": " that is between ",
      "contains": " that contains ",
      "eq": " that is equal to ",
      "invalid_date": "To filter correctly, start date and end date must be filled",
      "invalid_value": "invalid value",
      "neq": " that is not equal to "
    },
    "form": {
      "back": "All %{entity}",
      "cancel": "Cancel",
      "create_entity": "Create %{entity}",
      "create_error": "%{entity} could not be created",
      "create_success": "%{entity} was successfully created",
      "duplicate_entity": "Duplicate %{entity}",
      "duplicate_success": "The %{entity} and its dependencies were successfully duplicated!",
      "general_information": "General information",
      "hide": "Hide",
      "inputs": {
        "attachment": {
          "add_error": "Error adding File",
          "add_evidence": "Add evidence of the client authorization",
          "allowed_extensions": "The allowed extensions are",
          "drag_drop": "Add files using drag and drop",
          "invalid_extension": "File type not allowed.",
          "max_size": "The file must be less than %{max_size}",
          "maximum_num_uploads_error": "You can only load up to %{max_num} files per flexible column.",
          "one_file": "Upload one file at a time",
          "or_drop_file_here": "or drop file here",
          "remove_error": "Failed to Remove",
          "remove_success": "Removed Successfully",
          "select_files": "Select Files",
          "submit": "Submit your attachment",
          "upload_error": "Upload Failed",
          "upload_success": "Uploaded Successfully"
        },
        "contract_service": {
          "add": "Add a Contract Service",
          "subtitle": "Contract Service"
        },
        "datasheet_filter": {
          "add_filter": "Add Filter",
          "title": "Datasheet Filter"
        },
        "date_range": {
          "between": "%{start} until %{end}",
          "end": "End Date",
          "start": "Start Date"
        },
        "drop_formula": {
          "formula_select": "Please, select a formula..."
        },
        "errors": {
          "mandatory": "%{field} is required.",
          "not_found": "'%{value}' was not found in the reference. Select a new one."
        },
        "formula": {
          "result": "Result"
        },
        "gps": {
          "save_location": "Save Location",
          "search": "Search Location...",
          "without_location": "Without Location"
        },
        "picture": {
          "edition_only": "Only available when editing",
          "five_pictures_limit_warning": "Only 5 pictures at maximum are allowed",
          "no_image_on_clipboard": "No image data was found in your clipboard. Please copy an image first.",
          "remove_error": "Failed to Remove",
          "remove_success": "Removed Successfully",
          "upload_error": "Upload Failed",
          "upload_success": "Uploaded Successfully"
        },
        "placeholder": "Enter %{field} here",
        "value_selector": {
          "apply": "Apply",
          "done": "Done",
          "search": "Search something here...",
          "waiting": "... Wait"
        }
      },
      "loading": "Loading...",
      "schedule_entity": "Schedule %{entity}",
      "schedule_success": "Your request to schedule %{entity} has finish successfully",
      "show": "Show",
      "subtitle": "Please provide some basic information about the %{entity}.",
      "subtitle_error": "The form could not be loaded, check your %{entity} or template data.",
      "update_entity": "Edit %{entity}",
      "update_error": "%{entity} could not be updated",
      "update_success": "%{entity} was successfully updated"
    },
    "formula_modal": {
      "back_button_text": "Back to the Form",
      "calculate": "Calculate",
      "cancel": "Cancel",
      "mandatory": "You must fill the values for the following fields: %{fields}",
      "ok": "Save Formula"
    },
    "grid": {
      "all": "All",
      "clear_filters": "Clear filters",
      "editable": {
        "error": {
          "mandatory": "At least one required field was left empty"
        },
        "title": "Edit Mode",
        "update": {
          "error": "%{quantity} could not be saved. Check the reason on the left side of each row highlighted",
          "success": "%{quantity} records was successfully saved"
        }
      },
      "empty_after_filtering": "There are no records for the applied filters",
      "grid_filters": {
        "menu_title": "Custom Filters"
      },
      "menu": {
        "visibility": "Columns"
      },
      "of": "of",
      "other": "Others",
      "productivity_factor": "Productivity Factor",
      "select_items": "Select items",
      "selected": "Selected",
      "selecteds": "Selected",
      "totals": "Totals"
    },
    "helpers": {
      "select": {
        "prompt": "Please select"
      },
      "submit": {
        "create": "Create %{model}",
        "submit": "Save %{model}",
        "update": "Update %{model}"
      }
    },
    "history_popup": {
      "back": "Back",
      "comments": "Comments",
      "crew_size": "Crew Size",
      "daily_hours": "Daily Hours",
      "date": "Date",
      "form_create_progress_service_title": "Create performance service item history",
      "form_create_progress_title": "Create progress item history",
      "form_edit_progress_service_title": "Edit performance service item history",
      "form_edit_progress_title": "Edit progress item history",
      "form_error_body": "All required fields on the form must be filled.",
      "form_error_title": "It was not possible to save the history form",
      "form_subtitle": "Insert a history by quantity or percentage for your activity.",
      "modal_create_title": "Create History",
      "modal_edit_title": "Edit History",
      "number_of_crews": "No. of Crews",
      "number_of_workers": "No. of Workers",
      "other_value": "Other Value",
      "percentage": "Percentage",
      "progress_by": "Progress by",
      "quantity": "Quantity",
      "singular": "History",
      "working_hours": "Working Hours"
    },
    "home": {
      "available_on": "available on",
      "banner_description": "Manage tasks, track processes and get the best results using a single platform",
      "create_request": {
        "text": "First things first, you need to create a Request from the Client in the DPMS with the details about your order.",
        "title": "Create a request"
      },
      "dpms_mobile": "DPMS Mobile App",
      "getting_started": "Getting started",
      "home_title": "Home",
      "inspection": {
        "text": "Conduct quality inspections in the field and record hindrances.",
        "title": "Inspection"
      },
      "performance": {
        "text": "Record Progress and Time based on the planned activities of each team and measure productivity.",
        "title": "Performance"
      },
      "scoping": {
        "text": "Scope a Request from the Client. Assign Services and Service Packages from your contract.",
        "title": "Scoping"
      },
      "welcome_user": "Welcome, %{user}"
    },
    "i18n_tasks": {
      "add_missing": {
        "added": {
          "one": "Added %{count} key",
          "other": "Added %{count} keys"
        }
      },
      "cmd": {
        "args": {
          "default_text": "Default: %{value}",
          "desc": {
            "all_locales": "Do not expect key patterns to start with a locale, instead apply them to all locales implicitly.",
            "config": "Override config location",
            "confirm": "Confirm automatically",
            "data_format": "Data format: %{valid_text}.",
            "keep_order": "Keep the order of the keys",
            "key_pattern": "Filter by key pattern (e.g. 'common.*')",
            "locale": "i18n_tasks.common.locale",
            "locale_to_translate_from": "Locale to translate from",
            "locales_filter": "Locale(s) to process. Special: base",
            "missing_types": "Filter by types: %{valid}",
            "nostdin": "Do not read from stdin",
            "out_format": "Output format: %{valid_text}",
            "pattern_router": "Use pattern router: keys moved per config data.write",
            "strict": "Avoid inferring dynamic key usages such as t(\"cats.#{cat}.name\"). Takes precedence over the config setting if set.",
            "translation_backend": "Translation backend (google or deepl)",
            "value": "Value. Interpolates: %%{value}, %%{human_key}, %%{key}, %%{default}, %%{value_or_human_key}, %%{value_or_default_or_human_key}"
          }
        },
        "desc": {
          "add_missing": "add missing keys to locale data, optionally match a pattern",
          "check_consistent_interpolations": "verify that all translations use correct interpolation variables",
          "check_normalized": "verify that all translation data is normalized",
          "config": "display i18n-tasks configuration",
          "cp": "copy the keys in locale data that match the given pattern",
          "data": "show locale data",
          "data_merge": "merge locale data with trees",
          "data_remove": "remove keys present in tree from data",
          "data_write": "replace locale data with tree",
          "eq_base": "show translations equal to base value",
          "find": "show where keys are used in the code",
          "gem_path": "show path to the gem",
          "health": "is everything OK?",
          "irb": "start REPL session within i18n-tasks context",
          "missing": "show missing translations, optionally match a pattern",
          "mv": "rename/merge the keys in locale data that match the given pattern",
          "normalize": "normalize translation data: sort and move to the right files",
          "remove_unused": "remove unused keys",
          "rm": "remove the keys in locale data that match the given pattern",
          "translate_missing": "translate missing keys with Google Translate or DeepL Pro, optionally match a pattern",
          "tree_convert": "convert tree between formats",
          "tree_filter": "filter tree by key pattern",
          "tree_merge": "merge trees",
          "tree_mv_key": "rename/merge/remove the keys matching the given pattern",
          "tree_set_value": "set values of keys, optionally match a pattern",
          "tree_subtract": "tree A minus the keys in tree B",
          "tree_translate": "Google Translate a tree to root locales",
          "unused": "show unused translations"
        },
        "encourage": [
          "Good job!",
          "Well done!",
          "Perfect!"
        ],
        "enum_list_opt": {
          "invalid": "%{invalid} is not in: %{valid}."
        },
        "enum_opt": {
          "invalid": "%{invalid} is not one of: %{valid}."
        },
        "errors": {
          "invalid_format": "invalid format: %{invalid}. valid: %{valid}.",
          "invalid_locale": "invalid locale: %{invalid}",
          "invalid_missing_type": {
            "one": "invalid type: %{invalid}. valid: %{valid}.",
            "other": "unknown types: %{invalid}. valid: %{valid}."
          },
          "pass_forest": "pass locale forest"
        }
      },
      "common": {
        "continue_q": "Continue?",
        "key": "Key",
        "locale": "Locale",
        "n_more": "%{count} more",
        "value": "Value"
      },
      "data_stats": {
        "text": "has %{key_count} keys across %{locale_count} locales. On average, values are %{value_chars_avg} characters long, keys have %{key_segments_avg} segments, a locale has %{per_locale_avg} keys.",
        "text_single_locale": "has %{key_count} keys in total. On average, values are %{value_chars_avg} characters long, keys have %{key_segments_avg} segments.",
        "title": "Forest (%{locales})"
      },
      "deepl_translate": {
        "errors": {
          "no_api_key": "Setup DeepL Pro API key via DEEPL_AUTH_KEY environment variable or translation.deepl_api_key in config/i18n-tasks.yml. Get the key at https://www.deepl.com/pro.",
          "no_results": "DeepL returned no results.",
          "specific_target_missing": "You must supply a specific variant for the given target language e.g. en-us instead of en."
        }
      },
      "google_translate": {
        "errors": {
          "no_api_key": "Set Google API key via GOOGLE_TRANSLATE_API_KEY environment variable or translation.google_translate_api_key in config/i18n-tasks.yml. Get the key at https://code.google.com/apis/console.",
          "no_results": "Google Translate returned no results. Make sure billing information is set at https://code.google.com/apis/console."
        }
      },
      "health": {
        "no_keys_detected": "No keys detected. Check data.read in config/i18n-tasks.yml."
      },
      "inconsistent_interpolations": {
        "none": "No inconsistent interpolations found."
      },
      "missing": {
        "details_title": "Value in other locales or source",
        "none": "No translations are missing."
      },
      "openai_translate": {
        "errors": {
          "no_api_key": "Set OpenAI API key via OPENAI_API_KEY environment variable or translation.openai_api_key in config/i18n-tasks.yml. Get the key at https://openai.com/.",
          "no_results": "OpenAI returned no results."
        }
      },
      "remove_unused": {
        "confirm": {
          "one": "%{count} translation will be removed from %{locales}.",
          "other": "%{count} translation will be removed from %{locales}."
        },
        "noop": "No unused keys to remove",
        "removed": "Removed %{count} keys"
      },
      "translate_missing": {
        "translated": "Translated %{count} keys"
      },
      "unused": {
        "none": "Every translation is in use."
      },
      "usages": {
        "none": "No key usages found."
      },
      "yandex_translate": {
        "errors": {
          "no_api_key": "Set Yandex API key via YANDEX_API_KEY environment variable or translation.yandex_api_key in config/i18n-tasks.yml. Get the key at https://tech.yandex.com/translate.",
          "no_results": "Yandex returned no results."
        }
      }
    },
    "importation_drop": {
      "choose_error": "Choose an item"
    },
    "importation_popup": {
      "file_upload_message": "Click or drag file to this area to upload",
      "importation_error": "You can review this message on the jobs page.",
      "importation_success": "Importation success.",
      "importation_timeout": "The importation is taking too long. Check its status in Jobs page.",
      "instructions": "Files must be a .xls and no larger than xxmb.<br> Your .xls file should contain the column - Date, Client, Project, Task, Notes....<br> The order of the columns should be same as the order of the template.<br> Date should be in - YYYY/MM/DD.",
      "requirements": "Requirements",
      "submit_spreadsheet": "Submit your spreadsheet"
    },
    "inspections": {
      "acceptance": "Acceptance",
      "acceptance_modal": {
        "inspection_acceptance": "Inspection Acceptance",
        "reject": "Reject",
        "subtitle": "Provide information about the inspection to go on with acceptance."
      },
      "actions": {
        "approve": "Approve",
        "more": "More",
        "reject": "Reject"
      },
      "choose_modal": {
        "schedule": "Schedule Inspection Items",
        "schedule_description": "Schedule one or several inspections based on date interval",
        "single": "Single Inspection Item",
        "single_description": "Creation of only one Inspection",
        "title": "Create an Inspection as:"
      },
      "description": "Inspection",
      "every": "Every",
      "id": "ID",
      "inspect_date": "Inspection Date",
      "inspect_status_id": "Status",
      "inspected": "Inspected",
      "inspected_detail": "Inspected Detail",
      "inspected_modules": {
        "progress": "Performance",
        "request": "Request",
        "scope": "Scoping"
      },
      "inspections_detail": {
        "side_panel_title": "Inspection's details"
      },
      "module": "Module",
      "notifications": {
        "approve_successful": "The Inspection was successfully approved",
        "reject_successful": "The Inspection was successfully rejected"
      },
      "recurrency_interval": "Recurrence interval",
      "repeat": "Repeat",
      "repeat_every_value": {
        "day": "Day",
        "month": "Month",
        "week": "Week",
        "year": "Year"
      },
      "repeats_on": "Repeat on",
      "request_id": "DPMSID",
      "responsible_id": "Responsible",
      "schedule_detail": "Schedule Detail",
      "schedule_form": {
        "acceptance_step": "Define Acceptance (optional)",
        "detailed_item_step": "Choose Inspection Scoping/Performance Item",
        "detailed_template_step": "Choose Inspection Scoping/Performance Template",
        "further_step_information": "The flexible fields are ready to be filled individualy after the recurrence creation step",
        "general_step": "Define Inspection General Information",
        "hints": "For monthly and yearly recurrences, the system will schedule the inspection matching the start date given on Interval Field",
        "monthly_hint": "Repeat monthly on every day %{day}",
        "recurrence_step": "Recurrence"
      },
      "scheduled_exception": "Scheduled",
      "statuses": {
        "approved": "Approved",
        "completed": "Completed",
        "pending": "Pending",
        "progress": "In Progress",
        "rejected": "Rejected"
      },
      "template": "Choose Template",
      "title": "Inspections"
    },
    "inspects": {
      "statuses": {
        "approved": "Approved",
        "completed": "Completed",
        "pending": "Pending",
        "progress": "In Progress",
        "rejected": "Rejected"
      }
    },
    "kendo": {
      "upload": "Click or Drag files here to upload"
    },
    "kendo_translations": {
      "calendar": {
        "today": "Today"
      },
      "dateinput": {
        "decrement": "Decrease value",
        "increment": "Increase value"
      },
      "datepicker": {
        "toggleCalendar": "Toggle calendar"
      },
      "daterangepicker": {
        "end": "End",
        "separator": 0,
        "start": "Start",
        "swapStartEnd": "Swap start and end values"
      },
      "datetimepicker": {
        "cancel": "Cancel",
        "date": "Date",
        "set": "Set",
        "time": "Time",
        "toggleDateTimeSelector": "Toggle date-time selector"
      },
      "dropdowns": {
        "clear": "Clear",
        "nodata": "No Data Found"
      },
      "editor": {
        "addColumnAfter": "Add column on the right",
        "addColumnBefore": "Add column on the left",
        "addRowAfter": "Add row below",
        "addRowBefore": "Add row above",
        "alignCenter": "Center text",
        "alignJustify": "Justify",
        "alignLeft": "Align text left",
        "alignRight": "Align text right",
        "bold": "Bold",
        "bulletList": "Insert unordered list",
        "createTable": "Create a table",
        "deleteColumn": "Delete column",
        "deleteRow": "Delete row",
        "deleteTable": "Delete table",
        "fontName": "Font Name",
        "fontSize": "Font Size",
        "format": "Format",
        "hyperlink": "Insert hyperlink",
        "hyperlink-dialog-cancel": "Cancel",
        "hyperlink-dialog-content-address": "Web address",
        "hyperlink-dialog-content-newwindow": "Open link in new window",
        "hyperlink-dialog-content-title": "Title",
        "hyperlink-dialog-insert": "Insert",
        "hyperlink-dialog-title": "Insert hyperlink",
        "image": "Insert image",
        "image-address": "Web address",
        "image-altText": "Alternate text",
        "image-cancel": "Cancel",
        "image-dialog-title": "Insert image",
        "image-height": "Height (px)",
        "image-insert": "Insert",
        "image-title": "Title",
        "image-width": "Width (px)",
        "indent": "Indent",
        "insertTableHint": "Create a 0 x 1 table",
        "italic": "Italic",
        "mergeCells": "Merge cells",
        "orderedList": "Insert ordered list",
        "outdent": "Outdent",
        "redo": "Redo",
        "splitCell": "Split cell",
        "strikethrough": "Strikethrough",
        "subscript": "Subscript",
        "superscript": "Superscript",
        "underline": "Underline",
        "undo": "Undo",
        "unlink": "Remove hyperlink",
        "viewHtml": "View HTML",
        "viewHtml-cancel": "Cancel",
        "viewHtml-dialog-title": "View HTML",
        "viewHtml-update": "Update"
      },
      "filter": {
        "addExpression": "Add Expression",
        "addGroup": "Add Group",
        "afterOperator": "Is after",
        "afterOrEqualOperator": "Is after or equal to",
        "andLogic": "And",
        "beforeOperator": "Is before",
        "beforeOrEqualOperator": "Is before or equal to",
        "close": "Close",
        "containsOperator": "Contains",
        "endsWithOperator": "Ends with",
        "eqOperator": "Is equal to",
        "gtOperator": "Is greater than",
        "gteOperator": "Is greater than or equal to",
        "isEmptyOperator": "Is empty",
        "isFalse": "Is false",
        "isNotEmptyOperator": "Is not empty",
        "isNotNullOperator": "Is not null",
        "isNullOperator": "Is null",
        "isTrue": "Is true",
        "ltOperator": "Is less than",
        "lteOperator": "Is less than or equal to",
        "notContainsOperator": "Does not contain",
        "notEqOperator": "Is not equal to",
        "orLogic": "Or",
        "startsWithOperator": "Starts with"
      },
      "grid": {
        "filterAfterOperator": "Is after",
        "filterAfterOrEqualOperator": "Is after or equal to",
        "filterBeforeOperator": "Is before",
        "filterBeforeOrEqualOperator": "Is before or equal to",
        "filterBetweenOperator": "Is between",
        "filterBooleanAll": "(All)",
        "filterClearButton": "Clear",
        "filterContainsOperator": "Contains",
        "filterEndsWithOperator": "Ends with",
        "filterEqOperator": "Is equal to",
        "filterGtOperator": "Is greater than",
        "filterGteOperator": "Is greater than or equal to",
        "filterIsEmptyOperator": "Is empty",
        "filterIsFalse": "Is false",
        "filterIsNotEmptyOperator": "Is not empty",
        "filterIsNotNullOperator": "Is not null",
        "filterIsNullOperator": "Is null",
        "filterIsTrue": "Is true",
        "filterLtOperator": "Is less than",
        "filterLteOperator": "Is less than or equal to",
        "filterNotContainsOperator": "Does not contain",
        "filterNotEqOperator": "Is not equal to",
        "filterStartsWithOperator": "Starts with",
        "filterSubmitButton": "Filter",
        "filterTitle": "Filter",
        "groupPanelEmpty": "Drag a column header and drop it here to group by that column",
        "noRecords": "No records available.",
        "pagerFirstPage": "Go to the first page",
        "pagerInfo": "0 - 1 of 2 items",
        "pagerItemsPerPage": "items per page",
        "pagerLastPage": "Go to the last page",
        "pagerNextPage": "Go to the next page",
        "pagerOf": "of",
        "pagerPage": "Page",
        "pagerPreviousPage": "Go to the previous page"
      },
      "numerictextbox": {
        "decrement": "Decrease value",
        "increment": "Increase value"
      },
      "pager": {
        "firstPage": "Go to the first page",
        "info": "0 - 1 of 2 items",
        "itemsPerPage": "items per page",
        "lastPage": "Go to the last page",
        "nextPage": "Go to the next page",
        "of": "of",
        "page": "Page",
        "previousPage": "Go to the previous page"
      },
      "scheduler": {
        "agendaViewTitle": "Agenda",
        "allDay": "all day",
        "allEvents": "All events",
        "dateTitle": "Date",
        "dayViewTitle": "Day",
        "deleteConfirmation": "Are you sure you want to delete this event?",
        "deleteDialogTitle": "Delete Event",
        "deleteOccurrence": "Delete current occurrence",
        "deleteRecurringConfirmation": "Do you want to delete only this event occurrence or the whole series?",
        "deleteRecurringDialogTitle": "Delete Recurring Item",
        "deleteSeries": "Delete the series",
        "deleteTitle": "Delete",
        "editorCancel": "Cancel",
        "editorDelete": "Delete",
        "editorEventAllDay": "All Day Event",
        "editorEventDescription": "Description",
        "editorEventEnd": "End",
        "editorEventEndTimeZone": "End Time Zone",
        "editorEventSeparateTimeZones": "End in different Time Zone",
        "editorEventStart": "Start",
        "editorEventStartTimeZone": "Start Time Zone",
        "editorEventTimeZone": "Specify Time Zone",
        "editorEventTitle": "Title",
        "editorOccurrence": "Edit current occurrence",
        "editorRecurringConfirmation": "Do you want to edit only this event occurrence or the whole series?",
        "editorRecurringDialogTitle": "Edit Recurring Item",
        "editorSave": "Save",
        "editorSeries": "Edit the series",
        "editorTitle": "Event",
        "editorValidationEnd": "End time must be after Start time.",
        "editorValidationRequired": "Field is required.",
        "editorValidationStart": "Start time must be be before End time.",
        "eventTitle": "Event",
        "monthViewTitle": "Month",
        "nextTitle": "Next",
        "noEvents": "no events",
        "previousTitle": "Previous",
        "recurrenceEditorDailyInterval": "day(s)",
        "recurrenceEditorDailyRepeatEvery": "Repeat every",
        "recurrenceEditorEndAfter": "After",
        "recurrenceEditorEndLabel": "End",
        "recurrenceEditorEndNever": "Never",
        "recurrenceEditorEndOccurrence": "occurrence(s)",
        "recurrenceEditorEndOn": true,
        "recurrenceEditorFrequenciesDaily": "Daily",
        "recurrenceEditorFrequenciesMonthly": "Monthly",
        "recurrenceEditorFrequenciesNever": "Never",
        "recurrenceEditorFrequenciesWeekly": "Weekly",
        "recurrenceEditorFrequenciesYearly": "Yearly",
        "recurrenceEditorMonthlyDay": "Day",
        "recurrenceEditorMonthlyInterval": "month(s)",
        "recurrenceEditorMonthlyRepeatEvery": "Repeat every",
        "recurrenceEditorMonthlyRepeatOn": "Repeat on",
        "recurrenceEditorOffsetPositionsFirst": "First",
        "recurrenceEditorOffsetPositionsFourth": "Fourth",
        "recurrenceEditorOffsetPositionsLast": "Last",
        "recurrenceEditorOffsetPositionsSecond": "Second",
        "recurrenceEditorOffsetPositionsThird": "Third",
        "recurrenceEditorRepeat": "Repeat",
        "recurrenceEditorWeekdaysDay": "Day",
        "recurrenceEditorWeekdaysWeekday": "Weekday",
        "recurrenceEditorWeekdaysWeekendday": "Weekend Day",
        "recurrenceEditorWeeklyInterval": "week(s)",
        "recurrenceEditorWeeklyRepeatEvery": "Repeat every",
        "recurrenceEditorWeeklyRepeatOn": "Repeat on",
        "recurrenceEditorYearlyInterval": "year(s)",
        "recurrenceEditorYearlyOf": "of",
        "recurrenceEditorYearlyRepeatEvery": "Repeat every",
        "recurrenceEditorYearlyRepeatOn": "Repeat on",
        "showFullDay": "Show full day",
        "showWorkDay": "Show business hours",
        "timeTitle": "Time",
        "timelineViewTitle": "Timeline",
        "today": "Today",
        "weekViewTitle": "Week",
        "workWeekViewTitle": "Work Week"
      },
      "sortable": {
        "noData": "No Data"
      },
      "timepicker": {
        "cancel": "Cancel",
        "now": "NOW",
        "selectNow": "Select Now",
        "set": "Set",
        "toggleClock": "Toggle Clock",
        "toggleTimeSelector": "Toggle TimeSelector"
      },
      "treelist": {
        "filterAfterOperator": "Is after",
        "filterAfterOrEqualOperator": "Is after or equal to",
        "filterBeforeOperator": "Is before",
        "filterBeforeOrEqualOperator": "Is before or equal to",
        "filterBooleanAll": "(All)",
        "filterClearButton": "Clear",
        "filterContainsOperator": "Contains",
        "filterEndsWithOperator": "Ends with",
        "filterEqOperator": "Is equal to",
        "filterGtOperator": "Is greater than",
        "filterGteOperator": "Is greater than or equal to",
        "filterIsEmptyOperator": "Is empty",
        "filterIsFalse": "Is false",
        "filterIsNotEmptyOperator": "Is not empty",
        "filterIsNotNullOperator": "Is not null",
        "filterIsNullOperator": "Is null",
        "filterIsTrue": "Is true",
        "filterLtOperator": "Is less than",
        "filterLteOperator": "Is less than or equal to",
        "filterNotContainsOperator": "Does not contain",
        "filterNotEqOperator": "Is not equal to",
        "filterStartsWithOperator": "Starts with",
        "noRecords": "No records available"
      },
      "upload": {
        "cancel": "Cancel",
        "clearSelectedFiles": "Clear",
        "dropFilesHere": "or drop file here",
        "files": "files",
        "headerStatusUploaded": "Done",
        "headerStatusUploading": "Uploading...",
        "invalidFileExtension": "File type not allowed.",
        "invalidFiles": "Invalid file(s). Please check file upload requirements.",
        "invalidMaxFileSize": "File size too large.",
        "invalidMinFileSize": "File size too small.",
        "remove": "Remove",
        "retry": "Retry",
        "select": "Select file",
        "total": "Total",
        "uploadSelectedFiles": "Upload"
      }
    },
    "loading_batch_entities": {
      "page_of": "Page %{page} of %{total}",
      "title": "Fetching pages"
    },
    "login": {
      "again": "To continue, please log in again",
      "continue_mykaefer": "Continue with MyKaefer",
      "copyright": "®%{year} All Rights Reserved. Kaefer®",
      "forgot_password": "Forgot password?",
      "iframe_info": "Requesting access to page: %{link}",
      "log_in": "Log in",
      "password": "Password",
      "title_1": "A powerful tool",
      "title_2": "for project management",
      "username": "Username",
      "welcome_back": "Welcome back"
    },
    "main_dialog": {
      "failure_message": "Your request could not be completed",
      "failure_title": "Failure",
      "loading_message": "Please wait while we set things up for you!",
      "loading_title": "Loading...",
      "service_title": "Waiting for job to finish...",
      "success_message": "Your request has been successful! You can now continue your job.",
      "success_title": "Successful!"
    },
    "notification": {
      "access_denied": "Access Denied",
      "action_success": "%{model} %{id} was %{action}",
      "bad_formula_setup": "There is a setup problem with the formula '%{title}', please check your setup!",
      "correct_subproject": "Select the correct subproject of this item",
      "dismiss_all": "Dismiss All",
      "error": "Error",
      "error_cookies": "We were unable to establish communication between cross sources. Review cookies settings in your browser and disable 'Third-Party Cookie blocking'.",
      "error_job": "Failed! Check the reason in Jobs page.",
      "error_msg": "Sorry! Something went wrong!",
      "info": "Info",
      "large_file_body": "Please apply some filters to make the output file smaller.",
      "large_file_title": "The grid has more than 1000 records",
      "page_denied": "The page could not be loaded.",
      "please": "Please",
      "select_subproject": "Select a Project and Subproject to continue",
      "success": "Success",
      "success_msg": "Action executed with success!",
      "warning": "Warning"
    },
    "number": {
      "currency": {
        "format": {
          "delimiter": ",",
          "format": "%u%n",
          "negative_format": "-%u%n",
          "precision": 2,
          "separator": ".",
          "significant": false,
          "strip_insignificant_zeros": false,
          "unit": "$"
        }
      },
      "format": {
        "delimiter": ",",
        "precision": 3,
        "round_mode": "default",
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false
      },
      "human": {
        "decimal_units": {
          "format": "%n %u",
          "units": {
            "billion": "Billion",
            "million": "Million",
            "quadrillion": "Quadrillion",
            "thousand": "Thousand",
            "trillion": "Trillion",
            "unit": ""
          }
        },
        "format": {
          "delimiter": "",
          "precision": 3,
          "significant": true,
          "strip_insignificant_zeros": true
        },
        "storage_units": {
          "format": "%n %u",
          "units": {
            "byte": {
              "one": "Byte",
              "other": "Bytes"
            },
            "eb": "EB",
            "gb": "GB",
            "kb": "KB",
            "mb": "MB",
            "pb": "PB",
            "tb": "TB",
            "zb": "ZB"
          }
        }
      },
      "nth": {
      },
      "percentage": {
        "format": {
          "delimiter": "",
          "format": "%n%"
        }
      },
      "precision": {
        "format": {
          "delimiter": ""
        }
      }
    },
    "orders": {
      "add_evidence": "Add evidence of the client authorization",
      "attachment": "Attachment",
      "comments": "Comments",
      "date": "Date",
      "drag_and_drop": "Add files using drag and drop",
      "responsible": "Responsible",
      "title": "Orders"
    },
    "performance": {
      "title": "Performance"
    },
    "performances": {
      "title": "Performances"
    },
    "performances_wip": {
      "performance_item": "Performance Item",
      "title": "Performance"
    },
    "planning": {
      "item_no": "Item No.",
      "service_no": "Service No.",
      "title": "Planning",
      "view": "Planning View"
    },
    "plannings": {
      "title": "Planning"
    },
    "progress_histories": {
      "add_new_progress": "Add a new Progress",
      "empty_historic": "There is no history item and it is not possible to create histories when the quantity is zero",
      "hours": "Hours",
      "production_factor": "PF",
      "progress": "Progress",
      "progress_badge": "Item %{number}",
      "progress_history": "Progress History",
      "progress_service_badge": "Service %{number}",
      "progress_service_history": "Service History",
      "progress_service_title": "Service histories",
      "progress_title": "Item histories",
      "quantity": "Quantity",
      "subtitle": "Manage daily progress based on activities planned by your team."
    },
    "progress_services": {
      "actual_div_hours": "Actual / Team Target Hours",
      "actual_div_quantity": "Actual / Target Quantity",
      "actual_hours": "Actual Hours",
      "actual_quantity": "Actual Quantity",
      "application_price": "Application Price",
      "back": "All Performances",
      "budget_target_hours": "Budget Target Hours",
      "canceled": "Canceled",
      "choose_modal": {
        "package": "Services from Package",
        "package_description": "Creation of several services based on the same activity model",
        "single": "Single service item",
        "single_description": "Creation of only one service",
        "title": "Create New"
      },
      "contract_service": "Contract Service",
      "contribution": "Contribution",
      "contribution_info": "Values automatically calculated",
      "crew": "crew",
      "crew_size": "Crew Size",
      "crews": "crews",
      "date_filter": {
        "all": "All",
        "today": "Today",
        "tomorrow": "Tomorrow",
        "week": "Week",
        "yesterday": "Yesterday"
      },
      "delta_hours": "Delta Hours",
      "delta_qtt": "Delta Quantity",
      "description": "Performance Service",
      "discipline": "Discipline",
      "end_date": "End Date",
      "equipment_price": "Equipment Price",
      "factor": "Factor",
      "flag": "Flag",
      "flag_filter": "Flag Filter",
      "id": "ID",
      "materials_price": "Materials Price",
      "no_crews": "No. of Crews",
      "no_workers": "No. of Workers",
      "norm_hours": "Norm Hours",
      "not_planned": "Not Planned",
      "other_price": "Other Price",
      "package_factor": "Factor",
      "people": "people",
      "person": "person",
      "pf": "PF",
      "pf_doing_bad": "You're not doing great",
      "pf_doing_good": "You're doing great!",
      "pf_doing_neutral": "Could not calculate the pf",
      "planned": "Planned",
      "planning_chart": {
        "actual": "Actual",
        "actual_qty": "Actual Qty",
        "date": "Date",
        "forecasted": "Forecasted",
        "forecasted_qty": "Forecasted Qty",
        "target": "Target",
        "target_qty": "Target Qty",
        "title": "Planning Chart"
      },
      "planning_timeline": {
        "day": "day",
        "day_advance": "day in advance",
        "day_delayed": "day delayed",
        "days": "days",
        "days_advance": "days in advance",
        "days_delayed": "days delayed",
        "end_date": "End Date",
        "forecasted": "Forecasted",
        "on_time": "On time",
        "remaining_days": "Remaining Days",
        "start_date": "Start Date",
        "title": "Planning Timeline"
      },
      "progress": "Progress",
      "progress_services_detail": {
        "side_panel_title": "Service's details"
      },
      "quantity": "Quantity",
      "responsible": "Responsible",
      "scoped_quantity": "Scoped Quantity",
      "service": "Service",
      "service_package": "Service Package",
      "service_planning": "Service Planning",
      "service_progress": "Service Progress",
      "service_weight": "Service Weight",
      "start_date": "Start Date",
      "statuses": {
        "canceled": "Canceled",
        "done": "Completed",
        "extra": "Extra",
        "in_planning": "Not Planned",
        "planned": "Planned"
      },
      "team_target_hours": "Team Target Hours",
      "title": "Performance Services",
      "title_packageable": "Performance Services by Service Package",
      "total": "Total",
      "total_hours": "Total Hours",
      "unit": "Unit",
      "worker": "worker",
      "workers": "workers",
      "working_hours": "Working Hours"
    },
    "progresses": {
      "cancel": "Cancel",
      "choose_modal": {
        "single": "Another performance item",
        "single_description": "Creation of only one performance item"
      },
      "complete": "Complete",
      "delta_hours": "∆ Hours",
      "delta_qty": "∆ Qty",
      "description": "Description",
      "extra": "Extra",
      "flag": "Flag",
      "history": "History",
      "id": "ID",
      "item": "Item",
      "label": "Label",
      "pf": "PF",
      "progress": "Progress",
      "progresses_detail": {
        "side_panel_title": "Performance Details"
      },
      "request_status_restriction": "Request should have status In Planning or In Performance",
      "revised": "Revised",
      "title": "Performances"
    },
    "projects": {
      "workdays": {
        "fri": "Fri",
        "mon": "Mon",
        "sat": "Sat",
        "sun": "Sun",
        "thu": "Thu",
        "tue": "Tue",
        "wed": "Wed"
      }
    },
    "requests": {
      "actual_hours": "Actual Hours",
      "actual_progress": "Actual Progress",
      "all": "All",
      "are_you_sure_complete": "Are you sure to complete this request?",
      "are_you_sure_complete_no_history": "Are you sure to complete a request without any given progress?",
      "are_you_sure_deletion": "Are you sure you want to delete the request",
      "are_you_sure_revertion": "Are you sure you want to revert and by that loose the data that was entered in this Module",
      "area": "Area",
      "authorization_pending": "Authorization Pending",
      "authorize_form": {
        "modal_title": "Authorization",
        "subtitle": "Provide some information about the request to go on with approbation",
        "title": "Authorize Request"
      },
      "canceled": "Canceled",
      "clustered": "Clustered",
      "comments": "Comments",
      "confirmation_modal": {
        "cancel_description": "The request will be canceled",
        "cancel_title": "Cancel Request?",
        "complete_description": "This request will be completed",
        "complete_title": "Complete request?",
        "confirm_action": "Proceed to Import Scopes",
        "confirm_cancel": "Not now",
        "confirm_confirmation": "Yes, I want to Confirm",
        "confirm_description": "Do you want to confirm the request?",
        "confirm_title": "Request Confirmation",
        "delete_description": "The removal action cannot be undone. Are you sure?",
        "delete_title": "Delete Request?",
        "reject_description": "This item will return to the status In Scoping",
        "reject_title": "Reject Request?",
        "revert_description": "The revert action cannot be undone. Are you sure?",
        "revert_title": "Revert Request?"
      },
      "confirmed": "Confirmed",
      "creation_form": {
        "proceed": "Proceed to Step 2"
      },
      "creation_page": {
        "add_template": "Add template",
        "first_step": "Create Request",
        "second_step": "Import Scopes (Optional)",
        "third_step": "Apply Service Packages (Optional)"
      },
      "description": "Request",
      "discipline": "Discipline",
      "done": "Done",
      "eav_template": "Template",
      "eav_template_id": "Template ID",
      "edit_dialog": {
        "reason": "Subrequest name"
      },
      "elevation": "Elevation",
      "errors": {
        "not_found": "Unable to find Request with id: %{id}"
      },
      "estimates_authorization": "Estimates authorization required",
      "forecast_end_date": "Forecasted End Date",
      "id": "DPMSID",
      "in_performance": "In Performance",
      "in_planning": "In Planning",
      "in_scoping": "In Scoping",
      "information": "Additional Information",
      "notification": {
        "authorization_success": "The request is successfully authorized",
        "deleting_sub_request": "The Sub Request %{id} is being deleted. Please check the jobs page to learn about it's progress",
        "missing_order_error": "There is not any pending order associated with this request"
      },
      "on_hold": "On Hold",
      "progress_end_date": "Progress end date",
      "progress_responsible": "Responsible for Progress",
      "progress_responsible_id": "Responsible for Progress",
      "progress_start_date": "Progress start date",
      "reason": "Reference",
      "reference": "Reference",
      "registered": "Registered",
      "request_date": "Date of request",
      "request_detail": {
        "edit": "Edit",
        "estimates": "Estimates",
        "inspections": "Inspections",
        "performance": "Performance",
        "planning": "Planning",
        "scopes": "Scopings",
        "show": "Show",
        "side_panel_title": "Request details"
      },
      "request_end_date": "Request end date",
      "request_responsible": "Responsible for Request",
      "request_responsible_id": "Responsible for Request",
      "request_start_date": "Request start date",
      "schedule": "Request Schedule",
      "schedule_form": {
        "date": "Date",
        "days": "%{number} days",
        "performance": "Performance",
        "progress": "Progress",
        "request": "Request",
        "responsible": "Responsible",
        "schedule": "Schedule",
        "scoping": "Scoping",
        "subtitle": "To create a schedule for this request, we first need some additional information.",
        "title": "Request schedule"
      },
      "scope_end_date": "Scoping end date",
      "scope_responsible": "Responsible for Scoping",
      "scope_responsible_id": "Responsible for Scoping",
      "scope_start_date": "Scoping start date",
      "scopes": "Scopes",
      "service_desired_date": "Desired Date",
      "skip_scoping": "Skip Scoping phase",
      "status": "Status",
      "statuses": {
        "authorization_pending": "Pending",
        "canceled": "Canceled",
        "clustered": "Clustered",
        "confirmed": "In Scoping",
        "deleting": "Deleting",
        "done": "Done",
        "in_performance": "In Performance",
        "in_planning": "In Performance",
        "in_scoping": "In Scoping",
        "on_hold": "On Hold",
        "registered": "Created"
      },
      "sub_request": "Sub Request",
      "sub_requests": "Sub Requests",
      "subarea": "Subarea",
      "team_target_hours": "Team Target Hours",
      "title": "Requests"
    },
    "scopes": {
      "add": "Add scoping item",
      "choose_modal": {
        "single": "Another scope item",
        "single_description": "Creation of only one scoping item"
      },
      "clusters": {
        "title": "Scoping Clusters"
      },
      "count": "Count",
      "crew_size": "Crew Size",
      "crews": "Number of Crews",
      "date": "Date",
      "description": "Scope",
      "hours": "Hours (h)",
      "id": "ID",
      "item": "Item",
      "label": "Label",
      "label_assigner": {
        "success": "Your Scopes Labels has been assigned successfully! You can continue your plan now."
      },
      "label_error": "Could not fetch labels",
      "popup_label": {
        "create_label": "Create Label",
        "labels": "Labels:",
        "name_label": "Name of Label",
        "new_label": "New Label"
      },
      "quantity": "Qty",
      "remaining_hours": "Remaining Hours",
      "remaining_quantity": "Remaining Qty",
      "request_status_restriction": "Request should have status Confirmed or In Scoping",
      "scopes_detail": {
        "side_panel_title": "Scoping details"
      },
      "title": "Scopings",
      "unit": "Unit",
      "working_hours": "Working Hours"
    },
    "search": {
      "loading": "loading...",
      "no_result_found": "No result found",
      "placeholder": "Filter..."
    },
    "settings": {
      "title": "Settings"
    },
    "settings_shortcuts": "Settings shortcuts",
    "shortcuts": {
      "access_control": "Access Control",
      "areas": "Areas and Subareas",
      "areas_subareas": "Areas and Subareas",
      "assets": "Assets",
      "background_jobs": "Background Jobs",
      "branches": "Branches",
      "cities": "Cities",
      "client_sites": "Client Sites",
      "clients": "Clients",
      "clients_info": "Clients Info",
      "columns_renaming": "Columns Renaming",
      "companies": "Companies",
      "contract_services": "Contract Services",
      "contracts": "Contracts",
      "countries": "Countries",
      "customer_sites": "Client Sites",
      "customers": "Clients",
      "disciplines": "Disciplines",
      "employees": "Staff",
      "equipaments": "Equipments",
      "equipments": "Equipments",
      "formulas": "Formulas",
      "location": "Location",
      "materials": "Materials",
      "matrices": "Matrices",
      "measures": "Measures",
      "mobile_details": "Mobile Details",
      "position_names": "Position Names",
      "preferences": "Preferences",
      "project_assets": "Assets",
      "project_contracts": "Contracts",
      "projects": "Projects and Subprojects",
      "projects_contracts": "Projects & Contracts",
      "projects_subprojects": "Projects and Subprojects",
      "provinces": "States / Provinces",
      "regions": "Regions",
      "routes": "Routes",
      "specialities": "Specialities",
      "staff": "Staff",
      "states_provinces": "States / Provinces",
      "translations": "Column Renaming",
      "users": "Users",
      "visible_fields": "Mobile Details",
      "work_positions": "Position Names"
    },
    "side_menu": {
      "contracts": "Contracts",
      "datasheets": "Datasheets",
      "new": "New",
      "report": "Reports",
      "search": "Search",
      "settings": "Settings",
      "template": {
        "asset": "Assets",
        "contract_service": "Contract Services",
        "datasheet": "Datasheets",
        "estimate_service": "Estimate Services",
        "inspection": "Inspections",
        "performance": "Performance",
        "report": "Reports",
        "request": "Requests",
        "scoping": "Scoping",
        "valuation": "Valuation"
      },
      "templates": "Templates"
    },
    "sub_requests": {
      "creation_page": {
        "all": "All Sub Requests",
        "back": "All Scopes",
        "title": "sub requests"
      },
      "left_panel": {
        "add_scoping": "Add scoping item",
        "allocation": "Allocation",
        "cards_header_title": "Scopes",
        "summary": "Summary",
        "total_allocation": "Total allocation"
      },
      "scope_card": {
        "scope": "Scope"
      }
    },
    "support": {
      "array": {
        "last_word_connector": ", and ",
        "two_words_connector": " and ",
        "words_connector": ", "
      }
    },
    "tabs": {
      "error": "Impossible to load tabs"
    },
    "templates": {
      "asset": "Assets",
      "contract_service": "Contract Services",
      "datasheet": "Datasheets",
      "estimate_service": "Estimate Services",
      "inspection": "Inspections",
      "no_template": "You need to create template",
      "progress": "Performance",
      "progress_service": "Valuation",
      "report": "reports",
      "request": "Requests",
      "scoping": "Scoping",
      "title": "Templates for %{type}"
    },
    "time": {
      "am": "am",
      "formats": {
        "default": "%a, %d %b %Y %H:%M:%S %z",
        "long": "%B %d, %Y %H:%M",
        "short": "%d %b %H:%M"
      },
      "pm": "pm"
    },
    "top_menu": {
      "authorization": "Authorization",
      "estimates": "Estimates",
      "inspections": "Inspections",
      "performance": "Performance",
      "planning": "Planning",
      "requests": "Requests",
      "scoping": "Scoping"
    },
    "track_block": {
      "id": "DPMSID"
    },
    "user_menu": {
      "about_version": "About Version",
      "logout": "Logout",
      "profile": "Profile"
    },
    "users": {
      "edit_title": "Update Profile"
    }
  },
  "es": {
    "actions": {
      "approve": "Aprobar",
      "bulk_editing": "Edición masiva",
      "cancel": "Cancelar",
      "choose_entity": "Elija %{entity}",
      "clear": "Limpiar",
      "confirm": "Confirmar",
      "create": "Crear",
      "delete": "Eliminar",
      "discard": "Descarte",
      "done": "Completar",
      "duplicate": "Duplicar",
      "edit": "Editar",
      "export": "Exportar a .xls",
      "export_template": "Descargar plantilla .xls",
      "false": "No",
      "import": "Importar desde .xls",
      "import_file": "Importar archivo",
      "loading": "Cargando",
      "more": "Más",
      "new": "Nuevo",
      "next": "próximo",
      "no": "No",
      "past": {
        "approve": "aprobado",
        "cancel": "cancelado",
        "complete": "terminado",
        "confirm": "confirmado",
        "delete": "eliminado",
        "destroy": "destruido",
        "reject": "rechazado",
        "revert": "revirtió",
        "update": "actualizado"
      },
      "prev": "Anterior",
      "reject": "Rechazar",
      "reset": "Reiniciar",
      "revert": "Revertir",
      "save": "Ahorrar",
      "save_button_keyboard": "Guardar (Ctrl + Enter)",
      "save_exit": "Guardar & Salir",
      "saving": "Ahorro...",
      "schedule": "Programar",
      "skip": "Omitir",
      "true": "Si",
      "unsaved_changes": "Cambios no guardados",
      "upload_file": "Upload archivo",
      "upload_picture": "Subir foto",
      "yes": "Si"
    },
    "api_internal_messages": {
      "cant_duplicate_clustered_request": "No es posible duplicar una solicitud agrupada",
      "cant_duplicate_progress": "No se pueden duplicar los Servicios debido a los siguientes errores ...",
      "cant_duplicate_request": "No se pueden duplicar alcances, estimaciones y servicios debido a los siguientes errores ...",
      "cant_duplicate_scope": "No se pueden duplicar los Servicios debido a los siguientes errores ...",
      "only_estimate_service_copy": "Solo se está intentando copiar el elemento de servicio estimado.",
      "only_progress_copy": "Solo se está intentando copiar el progreso.",
      "only_request_copy": "Solo la solicitud está intentando copiarse.",
      "only_scope_copy": "Solo se está intentando copiar el elemento de alcance."
    },
    "cancel_modal": {
      "cancel": "Ahora no",
      "description": "¿Está seguro de que desea cancelar %{model} \"%{id}\"?",
      "ok": "Si cancelar",
      "title": "¿Cancelar artículo?"
    },
    "clear_records": {
      "modal": {
        "cancel": "No, cancelar",
        "description": "¿Está seguro de que desea borrar todos los registros %{model}?",
        "ok": "Si, borra todo",
        "title": "¿Borrar %{model}?"
      },
      "success_message": "¡%{model} se borró correctamente!",
      "title": "Limpiar registros"
    },
    "collections": {
      "clusters": "Agrupaciones",
      "create_by_package": "Crear por paquete",
      "duplicate": "Duplicar",
      "edit": "Editar",
      "estimate": "Estimar",
      "new": "Nuevo",
      "schedule": "Calendario",
      "sub_requests": "Sub solicitudes",
      "view": "Ver"
    },
    "complete_modal": {
      "cancel": "Ahora no",
      "description": "¿Está seguro de que desea marcar %{model} como completado?",
      "ok": "Sí, marcar como completado",
      "title": "¿Marcar como completado?"
    },
    "confirmation_modal": {
      "cancel": "Cancelar",
      "description": "¡Esta acción no se puede deshacer!",
      "ok": "Okay",
      "title": "¿Estás seguro?"
    },
    "contract_services": {
      "title": "Servicios por contrato"
    },
    "datasheets": {
      "datasheets_detail": {
        "side_panel_title": "Detalles de la hoja de datos"
      },
      "id": "ID",
      "item": "Articulo",
      "title": "Hojas de datos"
    },
    "delete_modal": {
      "cancel": "No, cancelar",
      "description": "¿Está seguro de que desea eliminar %{model} \"%{id}\"?",
      "ok": "Si, borrar",
      "title": "¿Eliminar elemento?"
    },
    "delete_recurrencies": {
      "modal": {
        "cancel": "No, cancelar",
        "description": "¿Está seguro de que desea eliminar todas las recurrencias relacionadas?",
        "ok": "Sí, borrar todo",
        "title": "¿Eliminar todas las recurrencias?"
      },
      "success_message": "¡Recurrencias eliminadas exitosamente!",
      "title": "Eliminar todas las recurrencias"
    },
    "dialogs": {
      "columns_batch_assigner": {
        "actions": "Comportamiento",
        "add_action": "Agregar acción",
        "add_value_to": "Agregar %{value} a",
        "column": "Columna",
        "done": "Hecho",
        "header": "Edición por lotes",
        "multiple_success": "Se actualizaron los elementos %{numberOfItems}.",
        "new_value": "Nuevo valor",
        "single_success": "Se actualizó 1 elemento.",
        "subtitle": "Asignar un valor a los elementos seleccionados %{numberOfItems}",
        "title": "Crear acción",
        "update": "Actualizar"
      }
    },
    "discard_modal": {
      "cancel": "Sigue editando",
      "description": "¿Está seguro de que desea descartar sus cambios?",
      "ok": "Si, descartar",
      "title": "Descartar los cambios"
    },
    "eav_templates": {
      "title": "Plantillas"
    },
    "empty_state": {
      "empty": "No %{model}",
      "not_created": "Aún no ha creado ningún %{model}"
    },
    "environment": {
      "project": {
        "select_project": "Seleccionar proyecto ..."
      },
      "select_project_subproject": "Seleccionar proyecto y subproyecto",
      "subproject": {
        "select_subproject": "Seleccionar subproyecto ..."
      }
    },
    "estimate": {
      "accept": "Aceptar estimación",
      "application": "Solicitud",
      "back": "Todas las solicitudes",
      "budget_hours": "Presupuesto",
      "confirmation": {
        "cancel": "No, cancelar",
        "confirm": "Si, confirmar",
        "description": "¿Está seguro de que desea confirmar este presupuesto para esta solicitud?",
        "title": "Confirmar pedido"
      },
      "description": "Tiene servicios %{number} para pagar. Consulte los detalles a continuación.",
      "equipment": "Equipo",
      "estimate_details": "Detalles de la estimación",
      "estimate_success": "Estimación aprobada con éxito",
      "info_message": "Este servicio de estimación ya ha sido aprobado. Está disponible solo para lectura.",
      "material": "Material",
      "no_estimate_message": "No hay una estimación asociada a esta solicitud.",
      "norm_hours": "Horas Norm",
      "others": "Otros",
      "save_draft": "Guardar borrador",
      "service_details": "Detalles del servicio",
      "team_target_hours": "Horas objetivo del equipo",
      "title": "Servicio de pago",
      "tooltip": {
        "application": "Solicitud",
        "equipment": "Equipo",
        "material": "Material",
        "others": "Otros"
      },
      "total_request": "Solicitud total"
    },
    "estimate_services": {
      "application_price": "Precio de la aplicación",
      "automatically_prices": "Precios calculados automáticamente",
      "back": "Todos Alcances",
      "budget_target_hours": "Horas objetivo de presupuesto",
      "choose_modal": {
        "package": "Servicios del paquete",
        "package_description": "Creación de varios servicios basados en un mismo modelo de actividad",
        "single": "Artículo de servicio único",
        "single_description": "Creación de un solo servicio",
        "title": "Crear nuevo"
      },
      "contract_service": "Servicio por contrato",
      "crew_size": "Tamaño de la tripulación",
      "crews": "Número de tripulaciones",
      "description": "Descripción",
      "description_label": "Descripción",
      "discipline": "Disciplina",
      "equipment_price": "Precio del equipo",
      "estimate": "Servicio de pago",
      "estimate_services_detail": {
        "side_panel_title": "Detalles del servicio"
      },
      "factor": "Factor",
      "id": "CARNÉ DE IDENTIDAD",
      "materials_price": "Precio de los materiales",
      "no_template": "Necesita crear una plantilla para los servicios de estimación",
      "norm_hours": "Horas Norm",
      "other_price": "Otro precio",
      "prices": "Precios",
      "quantity": "Cantidad",
      "service": "Servicio",
      "service_package": "Paquete de servicios",
      "team_target_hours": "Horas objetivo del equipo",
      "title": "Servicios de alcance",
      "title_packageable": "Estimar servicios por paquete de servicios",
      "total": "Total",
      "unit": "Unidad",
      "weight": "Peso",
      "working_hours": "Horas Laborales"
    },
    "fields": {
      "image_not_found": "Imagen no encontrada",
      "na": "N/A",
      "signature": "Firma.jpg"
    },
    "filter_steps_modal": {
      "filter_result": "Resultado del filtro",
      "step": "Paso",
      "subtitle": "Proporcione información sobre %{title}"
    },
    "filtering": {
      "between": " eso es entre",
      "contains": " que contiene ",
      "eq": " que es igual a ",
      "invalid_date": "Para filtrar correctamente, se debe completar la fecha de inicio y la fecha de finalización",
      "invalid_value": "valor no válido",
      "neq": " que no es igual a "
    },
    "form": {
      "back": "Todas %{entity}",
      "cancel": "Cancelar",
      "create_entity": "Crear %{entity}",
      "create_error": "%{entity} no se pudo crear",
      "create_success": "%{entity} se creó con éxito",
      "duplicate_entity": "%{entity} duplicado",
      "duplicate_success": "¡El %{entity} y sus dependencias se duplicaron con éxito!",
      "general_information": "Información general",
      "hide": "Esconder",
      "inputs": {
        "attachment": {
          "add_error": "Error al agregar archivo",
          "add_evidence": "Agregar evidencia de la autorización del cliente",
          "allowed_extensions": "Las extensiones permitidas son",
          "drag_drop": "Agregar archivos usando arrastrar y soltar",
          "invalid_extension": "Tipo de archivo no permitido.",
          "max_size": "El archivo debe ser menor que %{max_size}",
          "maximum_num_uploads_error": "Solo puede cargar hasta %{max_num} archivos por columna flexible.",
          "one_file": "Sube un archivo a la vez",
          "or_drop_file_here": "o suelte el archivo aquí",
          "remove_error": "No se pudo quitar",
          "remove_success": "Eliminado con éxito",
          "select_files": "Selecciona archivos",
          "submit": "Envíe su adjunto",
          "upload_error": "Subida fallida",
          "upload_success": "Subido con éxito"
        },
        "contract_service": {
          "add": "Agregar un servicio por contrato",
          "subtitle": "Servicio por contrato"
        },
        "datasheet_filter": {
          "add_filter": "Añadir filtro",
          "title": "Filtro de hoja de datos"
        },
        "date_range": {
          "between": "%{start} hasta %{end}",
          "end": "Fecha final",
          "start": "Fecha de inicio"
        },
        "drop_formula": {
          "formula_select": "Por favor, seleccione una fórmula ..."
        },
        "errors": {
          "mandatory": "%{field} es obligatorio.",
          "not_found": "'%{value}' no se encontró en la referencia. Seleccione uno nuevo."
        },
        "formula": {
          "result": "Resultado"
        },
        "gps": {
          "save_location": "Guardar dirección",
          "search": "Buscar ubicación ...",
          "without_location": "Sin ubicación"
        },
        "picture": {
          "edition_only": "Solo disponible al editar",
          "five_pictures_limit_warning": "Solo se permiten 5 imágenes como máximo",
          "no_image_on_clipboard": "No se encontraron datos de imagen en su portapapeles. Por favor, copie una imagen primero.",
          "remove_error": "Error al eliminar",
          "remove_success": "Eliminado con éxito",
          "upload_error": "Subida fallida",
          "upload_success": "Subido con éxito"
        },
        "placeholder": "Ingrese el %{field} aquí",
        "value_selector": {
          "apply": "Aplicar",
          "done": "Hecho",
          "search": "Busca algo aquí ...",
          "waiting": "... Espere"
        }
      },
      "loading": "Cargando...",
      "schedule_entity": "Horario %{entity}",
      "schedule_success": "Su solicitud para programar %{entity} ha finalizado correctamente",
      "show": "Mostrar",
      "subtitle": "Proporcione información básica sobre %{entity}.",
      "subtitle_error": "No se pudo cargar el formulario, verifique su %{entity} o los datos de la plantilla.",
      "update_entity": "Actualizar %{entity}",
      "update_error": "%{entity} no se pudo actualizar",
      "update_success": "%{entity} se actualizó correctamente"
    },
    "formula_modal": {
      "back_button_text": "Volver al formulario",
      "calculate": "Calcular",
      "cancel": "Cancelar",
      "mandatory": "Debe completar los valores para los siguientes campos: %{fields}",
      "ok": "Guardar fórmula"
    },
    "grid": {
      "all": "Todos",
      "clear_filters": "Borrar filtros",
      "edit_mode": "Modo de edición",
      "editable": {
        "error": {
          "mandatory": "Al menos un campo obligatorio se dejó vacío"
        },
        "title": "Modo de edición",
        "update": {
          "error": "%{quantity} no se pudo guardar. Verifique el motivo en el lado izquierdo de cada fila resaltada",
          "success": "Los registros %{quantity} se guardaron correctamente"
        }
      },
      "empty_after_filtering": "No hay registros para los filtros aplicados",
      "grid_filters": {
        "menu_title": "Filtros personalizados"
      },
      "menu": {
        "visibility": "Columnas"
      },
      "of": "de",
      "other": "Otros",
      "productivity_factor": "Factor de productividad",
      "select_items": "Seleccionar articulos",
      "selected": "Seleccionado",
      "selecteds": "Seleccionados",
      "totals": "Totales"
    },
    "history_popup": {
      "back": "Atrás",
      "comments": "Comentarios",
      "crew_size": "Tamaño de la tripulación",
      "daily_hours": "Horas diarias",
      "date": "Fecha",
      "form_create_progress_service_title": "Crear historial de elementos de servicio de rendimiento",
      "form_create_progress_title": "Crear historial de elementos de progreso",
      "form_edit_progress_service_title": "Editar historial de elementos de servicio de rendimiento",
      "form_edit_progress_title": "Editar el historial de elementos de progreso",
      "form_error_body": "Se deben completar todos los campos obligatorios del formulario.",
      "form_error_title": "No fue posible guardar el formulario de historial.",
      "form_subtitle": "Inserta un historial por cantidad o porcentaje para tu actividad.",
      "modal_create_title": "Crear historial",
      "modal_edit_title": "Editar historial",
      "number_of_crews": "No. de tripulaciones",
      "number_of_workers": "No. de trabajadores",
      "other_value": "Otro valor",
      "percentage": "Porcentaje",
      "progress_by": "Progreso por",
      "quantity": "Cantidad",
      "singular": "Historia",
      "working_hours": "Horas Laborales"
    },
    "home": {
      "available_on": "disponible en",
      "banner_description": "Gestione tareas, realice un seguimiento de los procesos y obtenga los mejores resultados utilizando una única plataforma",
      "create_request": {
        "text": "Lo primero es lo primero, debe crear una Solicitud del Cliente en el DPMS con los detalles de su pedido.",
        "title": "Crea una solicitud"
      },
      "dpms_mobile": "Aplicación móvil DPMS",
      "getting_started": "Empezando",
      "home_title": "Página de Inicio",
      "inspection": {
        "text": "Realice inspecciones de calidad en el campo y registre los obstáculos.",
        "title": "Inspección"
      },
      "performance": {
        "text": "Registre el Progreso y el Tiempo en base a las actividades planificadas de cada equipo y mida la productividad.",
        "title": "Actuación"
      },
      "scoping": {
        "text": "Alcance una solicitud del cliente. Asigne servicios y paquetes de servicios de su contrato.",
        "title": "Alcance"
      },
      "welcome_user": "Bienvenido, %{user}"
    },
    "importation_drop": {
      "choose_error": "Elige un artículo"
    },
    "importation_popup": {
      "file_upload_message": "Haga clic o arrastre el archivo a esta área para cargar",
      "importation_error": "Puede revisar este mensaje en la página de Trabajos.",
      "importation_success": "Importación exitosa.",
      "importation_timeout": "La importación está tardando demasiado. Verifique su estado en la página Trabajos.",
      "instructions": "Los archivos deben ser .xls y no más grandes que xxmb. <br> Su archivo .xls debe contener la columna: Fecha, Cliente, Proyecto, Tarea, Notas ... <br> El orden de las columnas debe ser el mismo que el de la columna. orden de la plantilla. <br> La fecha debe estar en - AAAA / MM / DD.",
      "requirements": "Requisitos",
      "submit_spreadsheet": "Envía tu hoja de cálculo"
    },
    "inspections": {
      "acceptance": "Aceptación",
      "acceptance_modal": {
        "inspection_acceptance": "Aceptación de inspección",
        "reject": "Rechazar",
        "subtitle": "Proporcione información sobre la inspección para continuar con la aceptación."
      },
      "actions": {
        "approve": "Aprobar",
        "more": "Más",
        "reject": "Rechazar"
      },
      "choose_modal": {
        "schedule": "Programar elementos de inspección",
        "schedule_description": "Programe una o varias inspecciones según el intervalo de fechas",
        "single": "Artículo de inspección único",
        "single_description": "Creación de una única inspección",
        "title": "Cree una inspección como:"
      },
      "description": "Inspección",
      "every": "Cada",
      "id": "ID",
      "inspect_date": "Fecha de inspección",
      "inspect_status_id": "Estado",
      "inspected": "Inspeccionado",
      "inspected_detail": "Detalle inspeccionado",
      "inspected_modules": {
        "progress": "Actuación",
        "request": "Solicitud",
        "scope": "Alcance"
      },
      "inspections_detail": {
        "side_panel_title": "Detalles de la inspección"
      },
      "module": "Módulo",
      "notifications": {
        "approve_successful": "La inspección fue aprobada con éxito",
        "reject_successful": "La inspección fue rechazada con éxito"
      },
      "recurrency_interval": "Intervalo de recurrencia",
      "repeat": "Repetir",
      "repeat_every_value": {
        "day": "Día",
        "month": "Mes",
        "week": "Semana",
        "year": "Año"
      },
      "repeats_on": "Repetir en",
      "request_id": "DPMSID",
      "responsible_id": "Responsable",
      "schedule_detail": "Detalle del horario",
      "schedule_form": {
        "acceptance_step": "Definir aceptación (opcional)",
        "detailed_item_step": "Elegir alcance de inspección / artículo de rendimiento",
        "detailed_template_step": "Elija la plantilla de rendimiento / alcance de inspección",
        "further_step_information": "Los campos flexibles están listos para ser llenados individualmente después del paso de creación de repetición",
        "general_step": "Definir información general de inspección",
        "hints": "Para las repeticiones mensuales y anuales, el sistema programará la inspección que coincida con la fecha de inicio indicada en el campo de intervalo.",
        "monthly_hint": "Repetir mensualmente todos los días %{day}",
        "recurrence_step": "Reaparición"
      },
      "scheduled_exception": "Programado",
      "statuses": {
        "approved": "Aprobado",
        "completed": "Completar",
        "pending": "Pendiente",
        "progress": "En Progreso",
        "rejected": "Disminuido"
      },
      "template": "Elegir la plantilla",
      "title": "Inspecciones"
    },
    "inspects": {
      "statuses": {
        "approved": "Aprobado",
        "completed": "Completar",
        "pending": "Pendiente",
        "progress": "En Progreso",
        "rejected": "Disminuido"
      }
    },
    "kendo": {
      "upload": "Haga clic, arrastre o pegue los archivos aquí para cargar"
    },
    "kendo_translations": {
      "calendar": {
        "today": "Hoy"
      },
      "dateinput": {
        "decrement": "Disminuir valor",
        "increment": "Incrementar valor"
      },
      "datepicker": {
        "toggleCalendar": "Alternar calendario"
      },
      "daterangepicker": {
        "end": "Fin",
        "separator": 0,
        "start": "comienzo",
        "swapStartEnd": "Intercambiar valores iniciales y finales"
      },
      "datetimepicker": {
        "cancel": "Cancelar",
        "date": "Fecha",
        "set": "Aplicar",
        "time": "Hora",
        "toggleDateTimeSelector": "Alternar selector de fecha y hora"
      },
      "dropdowns": {
        "clear": "Limpiar",
        "nodata": "Datos no Encontrados"
      },
      "editor": {
        "addColumnAfter": "Agregar columna a la derecha",
        "addColumnBefore": "Agregar columna a la izquierda",
        "addRowAfter": "Agregar fila a continuación",
        "addRowBefore": "Agregar fila arriba",
        "alignCenter": "Texto central",
        "alignJustify": "Justificar",
        "alignLeft": "Alinear texto a la izquierda",
        "alignRight": "Alinear texto a la derecha",
        "bold": "Negrita",
        "bulletList": "Insertar lista desordenada",
        "createTable": "Crea una tabla",
        "deleteColumn": "Eliminar columna",
        "deleteRow": "Borrar fila",
        "deleteTable": "Eliminar tabla",
        "fontName": "Nombre de la fuente",
        "fontSize": "Tamaño de fuente",
        "format": "Formato",
        "hyperlink": "Insertar hipervínculo",
        "hyperlink-dialog-cancel": "Cancelar",
        "hyperlink-dialog-content-address": "dirección web",
        "hyperlink-dialog-content-newwindow": "Abrir enlace en una nueva ventana",
        "hyperlink-dialog-content-title": "Título",
        "hyperlink-dialog-insert": "Insertar",
        "hyperlink-dialog-title": "Insertar hipervínculo",
        "image": "Insertar imagen",
        "image-address": "dirección web",
        "image-altText": "Texto alternativo",
        "image-cancel": "Cancelar",
        "image-dialog-title": "Insertar imagen",
        "image-height": "Altura (px)",
        "image-insert": "Insertar",
        "image-title": "Título",
        "image-width": "Ancho (px)",
        "indent": "Sangrar",
        "insertTableHint": "Cree una tabla de {0} x {1}",
        "italic": "Itálico",
        "mergeCells": "Combinar células",
        "orderedList": "Insertar lista ordenada",
        "outdent": "Sangrado",
        "redo": "Rehacer",
        "splitCell": "Celda dividida",
        "strikethrough": "Tachado",
        "subscript": "Subíndice",
        "superscript": "Sobrescrito",
        "underline": "Subrayar",
        "undo": "Deshacer",
        "unlink": "Eliminar hipervínculo",
        "viewHtml": "Ver HTML",
        "viewHtml-cancel": "Cancelar",
        "viewHtml-dialog-title": "Ver HTML",
        "viewHtml-update": "Actualizar"
      },
      "filter": {
        "addExpression": "Agregar expresión",
        "addGroup": "Añadir grupo",
        "afterOperator": "Es despues",
        "afterOrEqualOperator": "Es posterior o igual a",
        "andLogic": "Y",
        "beforeOperator": "Es antes",
        "beforeOrEqualOperator": "Es anterior o igual a",
        "close": "Cerrar",
        "containsOperator": "Contiene",
        "endsWithOperator": "Termina con",
        "eqOperator": "Es igual a",
        "gtOperator": "Es mayor que",
        "gteOperator": "Es mayor o igual a",
        "isEmptyOperator": "Esta vacio",
        "isFalse": "Es falso",
        "isNotEmptyOperator": "No está vacío",
        "isNotNullOperator": "No es nulo",
        "isNullOperator": "Es nulo",
        "isTrue": "Es verdad",
        "ltOperator": "Es menos que",
        "lteOperator": "Es menor o igual que",
        "notContainsOperator": "No contiene",
        "notEqOperator": "No es igual a",
        "orLogic": "O",
        "startsWithOperator": "Comienza con"
      },
      "grid": {
        "filterAfterOperator": "Es despues",
        "filterAfterOrEqualOperator": "Es posterior o igual a",
        "filterBeforeOperator": "Es antes",
        "filterBeforeOrEqualOperator": "Es anterior o igual a",
        "filterBetweenOperator": "Está entre",
        "filterBooleanAll": "(Todas)",
        "filterClearButton": "Limpar",
        "filterContainsOperator": "Contiene",
        "filterEndsWithOperator": "Termina con",
        "filterEqOperator": "Es igual a",
        "filterGtOperator": "Es mayor que",
        "filterGteOperator": "Es mayor o igual a",
        "filterIsEmptyOperator": "Esta vacio",
        "filterIsFalse": "Es falso",
        "filterIsNotEmptyOperator": "No está vacío",
        "filterIsNotNullOperator": "No es nulo",
        "filterIsNullOperator": "Es nulo",
        "filterIsTrue": "Es verdad",
        "filterLtOperator": "Es menos que",
        "filterLteOperator": "Es menor o igual que",
        "filterNotContainsOperator": "No contiene",
        "filterNotEqOperator": "No es igual a",
        "filterStartsWithOperator": "Comienza con",
        "filterSubmitButton": "Filtrar",
        "filterTitle": "Filtrar",
        "groupPanelEmpty": "Arrastre un encabezado de columna y suéltelo aquí para agrupar por esa columna",
        "noRecords": "No hay registros disponibles.",
        "pagerFirstPage": "Ir a la primera pagina",
        "pagerInfo": "{0} - {1} de {2} elementos",
        "pagerItemsPerPage": "Artículos por página",
        "pagerLastPage": "Ir a la última página",
        "pagerNextPage": "Ir a la página siguiente",
        "pagerOf": "de",
        "pagerPage": "Página",
        "pagerPreviousPage": "Ir a la pagina anterior"
      },
      "numerictextbox": {
        "decrement": "Disminuir valor",
        "increment": "Incrementar valor"
      },
      "pager": {
        "firstPage": "Ir a la primera pagina",
        "info": "{0} - {1} de {2} elementos",
        "itemsPerPage": "Artículos por página",
        "lastPage": "Ir a la última página",
        "nextPage": "Ir a la página siguiente",
        "of": "de",
        "page": "Página",
        "previousPage": "Ir a la pagina anterior"
      },
      "scheduler": {
        "agendaViewTitle": "Agenda",
        "allDay": "todo el dia",
        "allEvents": "Todos los eventos",
        "dateTitle": "Fecha",
        "dayViewTitle": "Día",
        "deleteConfirmation": "¿Estás seguro de que deseas eliminar este evento?",
        "deleteDialogTitle": "Eliminar evento",
        "deleteOccurrence": "Eliminar ocurrencia actual",
        "deleteRecurringConfirmation": "¿Desea eliminar solo esta ocurrencia de evento o toda la serie?",
        "deleteRecurringDialogTitle": "Eliminar artículo recurrente",
        "deleteSeries": "Eliminar la serie",
        "deleteTitle": "Eliminar",
        "editorCancel": "Cancelar",
        "editorDelete": "Eliminar",
        "editorEventAllDay": "Evento todo el día",
        "editorEventDescription": "Descripción",
        "editorEventEnd": "Fin",
        "editorEventEndTimeZone": "Zona horaria final",
        "editorEventSeparateTimeZones": "Termina en una zona horaria diferente",
        "editorEventStart": "comienzo",
        "editorEventStartTimeZone": "Zona horaria de inicio",
        "editorEventTimeZone": "Especificar zona horaria",
        "editorEventTitle": "Título",
        "editorOccurrence": "Editar ocurrencia actual",
        "editorRecurringConfirmation": "¿Quieres editar solo la ocurrencia de este evento o toda la serie?",
        "editorRecurringDialogTitle": "Editar artículo recurrente",
        "editorSave": "Salvar",
        "editorSeries": "Edita la serie",
        "editorTitle": "Evento",
        "editorValidationEnd": "La hora de finalización debe ser posterior a la hora de inicio.",
        "editorValidationRequired": "Se requiere campo.",
        "editorValidationStart": "La hora de inicio debe ser anterior a la hora de finalización.",
        "eventTitle": "Evento",
        "monthViewTitle": "Mes",
        "nextTitle": "próximo",
        "noEvents": "no hay eventos",
        "previousTitle": "Anterior",
        "recurrenceEditorDailyInterval": "dias)",
        "recurrenceEditorDailyRepeatEvery": "Repite cada",
        "recurrenceEditorEndAfter": "Después",
        "recurrenceEditorEndLabel": "Fin",
        "recurrenceEditorEndNever": "Nunca",
        "recurrenceEditorEndOccurrence": "ocurrencia (s)",
        "recurrenceEditorEndOn": "En",
        "recurrenceEditorFrequenciesDaily": "Diario",
        "recurrenceEditorFrequenciesMonthly": "Mensual",
        "recurrenceEditorFrequenciesNever": "Nunca",
        "recurrenceEditorFrequenciesWeekly": "Semanal",
        "recurrenceEditorFrequenciesYearly": "Anual",
        "recurrenceEditorMonthlyDay": "Día",
        "recurrenceEditorMonthlyInterval": "meses)",
        "recurrenceEditorMonthlyRepeatEvery": "Repite cada",
        "recurrenceEditorMonthlyRepeatOn": "Repetir en",
        "recurrenceEditorOffsetPositionsFirst": "primero",
        "recurrenceEditorOffsetPositionsFourth": "Cuarto",
        "recurrenceEditorOffsetPositionsLast": "Último",
        "recurrenceEditorOffsetPositionsSecond": "Segundo",
        "recurrenceEditorOffsetPositionsThird": "Tercero",
        "recurrenceEditorRepeat": "Repetir",
        "recurrenceEditorWeekdaysDay": "Día",
        "recurrenceEditorWeekdaysWeekday": "Día laborable",
        "recurrenceEditorWeekdaysWeekendday": "Día de fin de semana",
        "recurrenceEditorWeeklyInterval": "semanas)",
        "recurrenceEditorWeeklyRepeatEvery": "Repite cada",
        "recurrenceEditorWeeklyRepeatOn": "Repetir en",
        "recurrenceEditorYearlyInterval": "años)",
        "recurrenceEditorYearlyOf": "de",
        "recurrenceEditorYearlyRepeatEvery": "Repite cada",
        "recurrenceEditorYearlyRepeatOn": "Repetir en",
        "showFullDay": "Mostrar día completo",
        "showWorkDay": "Mostrar horario comercial",
        "timeTitle": "Hora",
        "timelineViewTitle": "Cronología",
        "today": "Hoy",
        "weekViewTitle": "Semana",
        "workWeekViewTitle": "Semana de trabajo"
      },
      "sortable": {
        "noData": "Sin datos"
      },
      "timepicker": {
        "cancel": "Cancelar",
        "now": "AHORA",
        "selectNow": "Seleccionar ahora",
        "set": "Aplicar",
        "toggleClock": "Alternar reloj",
        "toggleTimeSelector": "Alternar TimeSelector"
      },
      "treelist": {
        "filterAfterOperator": "Es despues",
        "filterAfterOrEqualOperator": "Es posterior o igual a",
        "filterBeforeOperator": "Es antes",
        "filterBeforeOrEqualOperator": "Es anterior o igual a",
        "filterBooleanAll": "(Todas)",
        "filterClearButton": "Limpar",
        "filterContainsOperator": "Contiene",
        "filterEndsWithOperator": "Termina con",
        "filterEqOperator": "Es igual a",
        "filterGtOperator": "Es mayor que",
        "filterGteOperator": "Es mayor o igual a",
        "filterIsEmptyOperator": "Esta vacio",
        "filterIsFalse": "Es falso",
        "filterIsNotEmptyOperator": "No está vacío",
        "filterIsNotNullOperator": "No es nulo",
        "filterIsNullOperator": "Es nulo",
        "filterIsTrue": "Es verdad",
        "filterLtOperator": "Es menos que",
        "filterLteOperator": "Es menor o igual que",
        "filterNotContainsOperator": "No contiene",
        "filterNotEqOperator": "No es igual a",
        "filterStartsWithOperator": "Comienza con",
        "noRecords": "No hay registros disponibles"
      },
      "upload": {
        "cancel": "Cancelar",
        "clearSelectedFiles": "Limpar",
        "dropFilesHere": "o suelte el archivo aquí",
        "files": "archivos",
        "headerStatusUploaded": "Hecho",
        "headerStatusUploading": "Subiendo ...",
        "invalidFileExtension": "Tipo de archivo no permitido.",
        "invalidFiles": "Archivo (s) inválidos. Compruebe los requisitos de carga de archivos.",
        "invalidMaxFileSize": "Tamaño de archivo demasiado grande.",
        "invalidMinFileSize": "Tamaño de archivo demasiado pequeño.",
        "remove": "Eliminar",
        "retry": "Rever",
        "select": "Seleccione Archivo",
        "total": "Total",
        "uploadSelectedFiles": "Subir"
      }
    },
    "loading_batch_entities": {
      "page_of": "Página %{page} de %{total}",
      "title": "Obteniendo páginas"
    },
    "loading_batch_modal": {
      "page_of": "Página %{page} de %{total}",
      "title": "Obteniendo páginas"
    },
    "login": {
      "again": "Para continuar, inicie sesión nuevamente",
      "continue_mykaefer": "Continuar con MyKaefer",
      "copyright": "®%{year} Todos los derechos reservados. Kaefer®",
      "forgot_password": "¿Se te olvidó tu contraseña?",
      "iframe_info": "Solicitando acceso a la página: %{link}",
      "log_in": "Iniciar sesión",
      "password": "Contraseña",
      "title_1": "Una herramienta poderosa",
      "title_2": "para gestión de proyectos",
      "username": "Nombre de usuario",
      "welcome_back": "Dar una buena acogida"
    },
    "main_dialog": {
      "failure_message": "No se pudo completar su solicitud.",
      "failure_title": "Fracaso",
      "loading_message": "¡Espere mientras preparamos las cosas para usted!",
      "loading_title": "Cargando...",
      "service_title": "Esperando a que termine el trabajo...",
      "success_message": "Su solicitud ha sido exitosa! Ahora puede continuar su trabajo.",
      "success_title": "Exitoso!"
    },
    "notification": {
      "access_denied": "Acceso denegado",
      "action_success": "%{model} %{id} ha sido %{action}",
      "bad_formula_setup": "Hay un problema de configuración con la fórmula '%{title}', ¡verifique su configuración!",
      "correct_subproject": "Seleccione el subproyecto correcto de este elemento",
      "dismiss_all": "Cerrar todo",
      "error": "Error",
      "error_cookies": "No pudimos establecer comunicación entre fuentes cruzadas. Revise la configuración de cookies en su navegador y desactive el \"Bloqueo de cookies de terceros\".",
      "error_job": "¡Ha fallado! Verifique el motivo en la página de trabajos.",
      "error_msg": "¡Lo siento! ¡Algo salió mal!",
      "info": "Info",
      "large_file_body": "Aplique algunos filtros para reducir el tamaño del archivo de salida.",
      "large_file_title": "La tabla tiene más de 1000 registros.",
      "page_denied": "No se pudo cargar la página.",
      "please": "Por favor",
      "select_subproject": "Seleccione un proyecto y subproyecto para continuar",
      "success": "Éxito",
      "success_msg": "¡Acción ejecutada con éxito!",
      "warning": "Advertencia"
    },
    "orders": {
      "add_evidence": "Agregar evidencia de la autorización del cliente",
      "attachment": "Adjunto archivo",
      "comments": "Comentarios",
      "date": "Fecha",
      "drag_and_drop": "Agregue archivos usando arrastrar y soltar",
      "responsible": "Responsable",
      "title": "Pedidos"
    },
    "performance": {
      "title": "Actuación"
    },
    "performances": {
      "title": "Actuaciones"
    },
    "performances_wip": {
      "performance_item": "Artículo de Rendimiento",
      "title": "Rendimiento"
    },
    "planning": {
      "item_no": "Artículo No.",
      "service_no": "Servicio No.",
      "title": "Planificación",
      "view": "Vista de planificación"
    },
    "plannings": {
      "title": "Planificación"
    },
    "progress_histories": {
      "add_new_progress": "Agregar un nuevo progreso",
      "empty_historic": "No hay ítem de historial y no es posible crear historiales cuando la cantidad es cero",
      "hours": "Horas",
      "production_factor": "PF",
      "progress": "Progreso",
      "progress_badge": "Elemento %{number}",
      "progress_history": "Historial de progreso",
      "progress_service_badge": "Servicio %{number}",
      "progress_service_history": "Historial de servicio",
      "progress_service_title": "Historias de servicios",
      "progress_title": "Historias de artículos",
      "quantity": "Cantidad",
      "subtitle": "Gestione el progreso diario en función de las actividades planificadas por su equipo."
    },
    "progress_services": {
      "actual_div_hours": "Horas objetivo reales / del equipo",
      "actual_div_quantity": "Cantidad real / objetivo",
      "actual_hours": "Horas reales",
      "actual_quantity": "Cantidad actual",
      "application_price": "Precio de la aplicación",
      "back": "Todas las actuaciones",
      "budget_target_hours": "Horas objetivo de presupuesto",
      "canceled": "Cancelado",
      "choose_modal": {
        "package": "Servicios del paquete",
        "package_description": "Creación de varios servicios basados en un mismo modelo de actividad",
        "single": "Artículo de servicio único",
        "single_description": "Creación de un solo servicio",
        "title": "Crear nuevo"
      },
      "contract_service": "Servicio por contrato",
      "contribution": "Contribución",
      "contribution_info": "Valores calculados automáticamente",
      "crew": "equipo",
      "crew_size": "Tamaño del equipo",
      "crews": "equipos",
      "date_filter": {
        "all": "Todos",
        "today": "Hoy dia",
        "tomorrow": "Mañana",
        "week": "Semana",
        "yesterday": "El dia de ayer"
      },
      "delta_hours": "Horas Delta",
      "delta_qtt": "Cantidad delta",
      "description": "Servicio de rendimiento",
      "discipline": "Disciplina",
      "end_date": "Fecha final",
      "equipment_price": "Precio del equipo",
      "factor": "Factor",
      "flag": "Bandera",
      "flag_filter": "Filtro de bandera",
      "flag_filters": "Filtros de bandera",
      "id": "ID",
      "materials_price": "Precio de los materiales",
      "no_crews": "No. de equipos",
      "no_workers": "No. de trabajadores",
      "norm_hours": "Horas Norm",
      "not_planned": "No estaba planeado",
      "other_price": "Otro precio",
      "package_factor": "Factor",
      "people": "gente",
      "person": "persona",
      "pf": "PF",
      "pf_doing_bad": "No lo estás haciendo muy bien",
      "pf_doing_good": "¡Lo estás haciendo genial!",
      "pf_doing_neutral": "No se pudo calcular el pf",
      "planned": "Planificado",
      "planning_chart": {
        "actual": "Real",
        "actual_qty": "Cant. Real",
        "date": "Fecha",
        "forecasted": "Pronosticado",
        "forecasted_qty": "Cant. Pronosticada",
        "target": "Objetivo",
        "target_qty": "Cant. objetivo",
        "title": "Grafico de planificación"
      },
      "planning_timeline": {
        "day": "día",
        "day_advance": "día antes",
        "day_delayed": "día tarde",
        "days": "dias",
        "days_advance": "días antes",
        "days_delayed": "días tarde",
        "end_date": "Fecha final",
        "forecasted": "Pronosticado",
        "on_time": "A tiempo",
        "remaining_days": "Días restantes",
        "start_date": "Fecha de inicio",
        "title": "Cronograma de planificación"
      },
      "progress": "Progreso",
      "progress_services_detail": {
        "side_panel_title": "Detalles del servicio"
      },
      "quantity": "Cantidad",
      "responsible": "Responsable",
      "scoped_quantity": "Cantidad de alcance",
      "service": "Servicio",
      "service_package": "Paquete de servicios",
      "service_planning": "Planificación de servicios",
      "service_progress": "Progreso del servicio",
      "service_weight": "Peso de servicio",
      "start_date": "Fecha de inicio",
      "statuses": {
        "canceled": "Cancelado",
        "done": "Terminado",
        "extra": "Extra",
        "in_planning": "No estaba planeado",
        "planned": "Planificado"
      },
      "team_target_hours": "Horas objetivo del equipo",
      "title": "Servicios de progreso",
      "title_packageable": "Servicios de rendimiento por paquete de servicios",
      "total": "Total",
      "total_hours": "Horas totales",
      "unit": "Unidad",
      "worker": "trabajador",
      "workers": "trabajadores",
      "working_hours": "Horas Laborales"
    },
    "progresses": {
      "cancel": "Cancelar",
      "choose_modal": {
        "single": "Otro elemento de progreso",
        "single_description": "Creación de un solo elemento de progreso"
      },
      "complete": "Completar",
      "delta_hours": "∆ Horas",
      "delta_qty": "∆ Cant",
      "description": "Descripción",
      "extra": "Extra",
      "flag": "Bandera",
      "histories": {
        "item": "Elemento %{number}",
        "item_title": "Historias de artículos",
        "progress_history": "Historial de progreso",
        "service": "Servicio %{number}",
        "service_history": "Historial de servicio",
        "service_title": "Historias de servicios",
        "subtitle": "Gestione el progreso diario según las actividades planificadas por su equipo."
      },
      "history": "Historia",
      "id": "Id",
      "item": "Artículo",
      "label": "Etiqueta",
      "pf": "PF",
      "progress": "Progreso",
      "progresses_detail": {
        "side_panel_title": "Detalles de rendimiento"
      },
      "request_status_restriction": "La solicitud debe tener estado En planificación o En ejecución",
      "revised": "Revisado",
      "title": "Actuación"
    },
    "projects": {
      "workdays": {
        "fri": "Vie",
        "mon": "Lun",
        "sat": "Sáb",
        "sun": "Dom",
        "thu": "Jue",
        "tue": "Mar",
        "wed": "Mié"
      }
    },
    "requests": {
      "actual_hours": "Horas reales",
      "actual_progress": "Progreso actual",
      "all": "Todos",
      "are_you_sure_complete": "¿Estás seguro de completar esta solicitud?",
      "are_you_sure_complete_no_history": "¿Estás seguro de completar una solicitud sin ningún progreso?",
      "are_you_sure_deletion": "¿Está seguro de que desea eliminar la solicitud?",
      "are_you_sure_revertion": "¿Está seguro de que desea revertir y por eso perder los datos que se ingresaron en este Módulo?",
      "area": "Area",
      "authorization_pending": "Pendiente de autorización",
      "authorize_form": {
        "moal_title": "Autorización",
        "modal_title": "Autorización",
        "subtitle": "Proporcione información sobre la solicitud para continuar con la aprobación.",
        "title": "Autorizar solicitud"
      },
      "canceled": "Cancelado",
      "clustered": "Agrupados",
      "comments": "Comentarios",
      "confirmation_modal": {
        "approve_description": "La solicitud será aprobada",
        "approve_title": "¿Aprobar solicitud?",
        "cancel_description": "La solicitud será cancelada",
        "cancel_title": "¿Cancelar petición?",
        "complete_description": "Esta solicitud se completará",
        "complete_title": "Solicitud completa?",
        "confirm_action": "Continuar con la importación de ámbitos",
        "confirm_cancel": "Ahora no",
        "confirm_confirmation": "Si, quiero confirmar",
        "confirm_description": "¿Quieres confirmar la solicitud?",
        "confirm_title": "Solicitar Confirmación",
        "delete_description": "La acción de eliminación no se puede deshacer. ¿Estás seguro?",
        "delete_title": "¿Borrar petición?",
        "reject_description": "Este elemento volverá al estado En alcance",
        "reject_title": "¿Rechazar orden?",
        "revert_description": "La acción de reversión no se puede deshacer. ¿Estás seguro?",
        "revert_title": "¿Revertir orden?"
      },
      "confirmed": "Confirmado",
      "creation_form": {
        "proceed": "Continúe con el paso 2"
      },
      "creation_page": {
        "add_template": "Agregar plantilla",
        "first_step": "Crear solicitud",
        "second_step": "Importar ámbitos (opcional)",
        "third_step": "Aplicar paquetes de servicios (opcional)"
      },
      "description": "Solicitud",
      "discipline": "Disciplina",
      "done": "Finalizado",
      "eav_template": "Modelo",
      "eav_template_id": "ID de plantilla",
      "edit_dialog": {
        "reason": "Nombre de la sub solicitud"
      },
      "elevation": "Elevación",
      "errors": {
        "not_found": "No se puede encontrar la solicitud con id: %{id}"
      },
      "estimates_authorization": "Autorización de estimación requerida",
      "forecast_end_date": "Fecha de finalización prevista",
      "id": "DPMSID",
      "in_performance": "En ejecución",
      "in_planning": "En la planificación",
      "in_scoping": "En medición",
      "information": "Información Adicional",
      "notification": {
        "authorization_success": "La solicitud se autorizó con éxito",
        "deleting_sub_request": "Se está eliminando la sub solicitud %{id}. Consulte la página de trabajos para conocer su progreso",
        "missing_order_error": "No hay ningún pedido pendiente asociado con esta solicitud."
      },
      "on_hold": "En espera",
      "progress_end_date": "Fecha de finalización del progreso",
      "progress_responsible": "Responsable del progreso",
      "progress_responsible_id": "Responsable del progreso",
      "progress_start_date": "Fecha de inicio del progreso",
      "reason": "Razón",
      "reference": "Referencia",
      "registered": "Registrado",
      "request_date": "Fecha de solicitud",
      "request_detail": {
        "edit": "Editar",
        "estimates": "Estimaciones",
        "inspections": "Inspecciones",
        "performance": "Actuación",
        "planning": "Planificación",
        "scopes": "Alcances",
        "show": "Mostrar",
        "side_panel_title": "Detalles de la solicitud"
      },
      "request_end_date": "Fecha de finalización de la solicitud",
      "request_responsible": "Responsable de solicitud",
      "request_responsible_id": "Responsable de solicitud",
      "request_start_date": "Fecha de inicio de la solicitud",
      "schedule": "Horario de solicitud",
      "schedule_form": {
        "date": "Fecha",
        "days": "%{number} días",
        "performance": "Actuación",
        "progress": "Progreso",
        "request": "Solicitud",
        "responsible": "Responsable",
        "schedule": "Programar",
        "scoping": "Alcance",
        "subtitle": "Para crear un horario para esta solicitud, primero necesitamos información adicional.",
        "title": "Horario de Solicitud"
      },
      "scope_end_date": "Fecha de finalización del alcance",
      "scope_responsible": "Responsable del alcance",
      "scope_responsible_id": "Responsable del alcance",
      "scope_start_date": "Fecha de inicio del alcance",
      "scopes": "Alcances",
      "service_desired_date": "Fecha deseada",
      "skip_scoping": "Omitir la fase de Alcance",
      "status": "Estado",
      "statuses": {
        "authorization_pending": "Pendiente",
        "canceled": "Cancelado",
        "clustered": "Agrupados",
        "confirmed": "Em medición",
        "deleting": "Borrando",
        "done": "Hecho",
        "in_performance": "En ejecución",
        "in_planning": "En ejecución",
        "in_scoping": "En medición",
        "on_hold": "En espera",
        "registered": "Creado"
      },
      "sub_request": "Sub solicitud",
      "sub_requests": "Sub solicitudes",
      "subarea": "Subárea",
      "team_target_hours": "Horas objetivo del equipo",
      "title": "Solicitud"
    },
    "scopes": {
      "add": "Agregar elemento de alcance",
      "choose_modal": {
        "single": "Otro elemento de alcance",
        "single_description": "Creación de un solo elemento de alcance"
      },
      "clusters": {
        "title": "Grupos de alcance"
      },
      "count": "Contar",
      "crew_size": "Tamaño de la tripulación",
      "crews": "Número de tripulaciones",
      "date": "Fecha",
      "description": "Alcance",
      "hours": "Horas (h)",
      "id": "ID",
      "item": "Articulo",
      "label": "Etiqueta",
      "label_assigner": {
        "success": "¡Sus etiquetas de alcance se han asignado correctamente! Puede continuar su plan ahora."
      },
      "label_error": "No se pudieron recuperar las etiquetas",
      "popup_label": {
        "create_label": "Crear etiqueta",
        "labels": "Etiquetas:",
        "name_label": "Nombre de etiqueta",
        "new_label": "Nueva etiqueta"
      },
      "quantity": "Cantidad",
      "remaining_hours": "Horas Restantes",
      "remaining_quantity": "Cantidad Restante",
      "request_status_restriction": "La solicitud debe tener el estado Confirmado o Dentro del alcance",
      "scopes_detail": {
        "side_panel_title": "Detalles del alcance"
      },
      "title": "Scope",
      "unit": "Unidad",
      "working_hours": "Horas Laborales"
    },
    "search": {
      "loading": "cargando...",
      "no_result_found": "No se han encontrado resultados",
      "placeholder": "Filtrar..."
    },
    "settings": {
      "title": "Configuraciones"
    },
    "settings_shortcuts": "Atajos de configuración",
    "shortcuts": {
      "access_control": "Control de acceso",
      "areas": "Áreas y subáreas",
      "areas_subareas": "Áreas y subáreas",
      "assets": "Bienes",
      "background_jobs": "Trabajos en segundo plano",
      "branches": "Ramas",
      "cities": "Ciudades",
      "client_sites": "Sitios de clientes",
      "clients": "Clientela",
      "clients_info": "Información de clientes",
      "columns_renaming": "Cambio de nombre de columnas",
      "companies": "Empresas",
      "contract_services": "Servicios por contrato",
      "contracts": "Contratos",
      "countries": "Países",
      "customer_sites": "Sitios de clientes",
      "customers": "Clientela",
      "disciplines": "Disciplinas",
      "employees": "Personal",
      "equipaments": "Equipos",
      "equipments": "Equipos",
      "formulas": "Fórmulas",
      "location": "Ubicación",
      "materials": "Materiales",
      "matrices": "Matrices",
      "measures": "Medidas",
      "mobile_details": "Detalles móviles",
      "position_names": "Nombres de puestos",
      "preferences": "Preferencias",
      "project_assets": "Bienes",
      "project_contracts": "Contratos",
      "projects": "Proyectos y subproyectos",
      "projects_contracts": "Proyectos y Contratos",
      "projects_subprojects": "Proyectos y subproyectos",
      "provinces": "Estados / Provincias",
      "regions": "Regiones",
      "routes": "Rutas",
      "specialities": "Especialidades",
      "staff": "Personal",
      "states_provinces": "Estados / Provincias",
      "translations": "Cambio de nombre de columna",
      "users": "Usuarios",
      "visible_fields": "Detalles móviles",
      "work_positions": "Nombres de puestos"
    },
    "side_menu": {
      "contracts": "Contratos",
      "datasheets": "Ficha de datos",
      "new": "Nuevo",
      "report": "Informes",
      "search": "Buscar",
      "settings": "Configuraciones",
      "template": {
        "asset": "Bienes",
        "contract_service": "Servicios por contrato",
        "datasheet": "Hojas de datos",
        "estimate_service": "Servicios de estimación",
        "inspection": "Inspecciones",
        "performance": "Actuación",
        "report": "Informes",
        "request": "Peticiones",
        "scoping": "Alcance",
        "valuation": "Valuación"
      },
      "templates": "Plantillas"
    },
    "sub_requests": {
      "creation_page": {
        "all": "Todas las solicitudes secundarias",
        "back": "Todos los ámbitos",
        "title": "sub solicitudes"
      },
      "left_panel": {
        "add_scoping": "Agregar elemento de alcance",
        "allocation": "Asignación",
        "cards_header_title": "Alcances",
        "summary": "Resumen",
        "total_allocation": "Asignación total"
      },
      "scope_card": {
        "scope": "Alcance"
      }
    },
    "tabs": {
      "error": "Imposible cargar pestañas"
    },
    "templates": {
      "asset": "Bienes",
      "contract_service": "Servicios por contrato",
      "datasheet": "Hojas de datos",
      "estimate_service": "Servicios de estimación",
      "inspection": "Inspecciones",
      "no_template": "Necesitas crear una plantilla",
      "progress": "Actuación",
      "progress_service": "Valuación",
      "report": "informes",
      "request": "Peticiones",
      "scoping": "Alcance",
      "title": "Plantillas para %{type}"
    },
    "top_menu": {
      "authorization": "Autorizaciones",
      "estimates": "Estimaciones",
      "inspections": "Inspecciones",
      "performance": "Actuación",
      "planning": "Planificación",
      "requests": "Solicitud",
      "scoping": "Alcance"
    },
    "track_block": {
      "id": "DPMSID"
    },
    "user_menu": {
      "about_version": "Acerca de la versión",
      "logout": "Cerrar Sesión",
      "profile": "Perfil"
    },
    "users": {
      "edit_title": "Actualización del perfil"
    }
  },
  "fr": {
    "actions": {
      "approve": "Approuver",
      "bulk_editing": "Édition en bloc",
      "cancel": "Annuler",
      "choose_entity": "Choisissez %{entity}",
      "clear": "Clair",
      "confirm": "Confirmer",
      "create": "Créer",
      "delete": "Supprimer",
      "discard": "Jeter",
      "done": "Achevée",
      "duplicate": "Dupliquer",
      "edit": "Éditer",
      "export": "Exporter vers .xls",
      "export_template": "Télécharger le modèle .xls",
      "false": "Non",
      "import": "Importer depuis .xls",
      "import_file": "Importer le fichier",
      "loading": "Chargement",
      "more": "Plus",
      "new": "Nouveau",
      "next": "Prochain",
      "no": "Non",
      "past": {
        "approve": "approuvé",
        "cancel": "annulé",
        "complete": "terminé",
        "confirm": "confirmé",
        "delete": "supprimé",
        "destroy": "détruit",
        "reject": "rejeté",
        "revert": "retourné",
        "update": "actualisé"
      },
      "prev": "Précédente",
      "reject": "Rejeter",
      "reset": "Réinitialiser",
      "revert": "Revenir",
      "save": "Sauver",
      "save_button_keyboard": "Enregistrer (Ctrl + Entrée)",
      "save_exit": "Enregistrer et quitter",
      "saving": "Économie...",
      "schedule": "Programme",
      "skip": "Sauter",
      "true": "Oui",
      "unsaved_changes": "Modifications non enregistrées",
      "upload_file": "Téléverser un fichier",
      "upload_picture": "Charger une photo",
      "yes": "Oui"
    },
    "api_internal_messages": {
      "cant_duplicate_clustered_request": "Il est impossible de dupliquer une demande groupée",
      "cant_duplicate_progress": "Impossible de dupliquer les services en raison des erreurs suivantes ...",
      "cant_duplicate_request": "Impossible de dupliquer les étendues, l’estimation et les services en raison des erreurs suivantes ...",
      "cant_duplicate_scope": "Impossible de dupliquer les services en raison des erreurs suivantes ...",
      "only_estimate_service_copy": "Seul l’élément du service d’estimation tente d’être copié.",
      "only_progress_copy": "Seul le Progrès essaie d’être copié.",
      "only_request_copy": "Seule la requête essaie d’être copiée.",
      "only_scope_copy": "Seul l’élément de portée tente d’être copié."
    },
    "cancel_modal": {
      "cancel": "Pas maintenant",
      "description": "Voulez-vous vraiment annuler le %{model} \"%{id}\"?",
      "ok": "Oui, annuler",
      "title": "Annuler l’article?"
    },
    "clear_records": {
      "modal": {
        "cancel": "Non, annuler",
        "description": "Voulez-vous vraiment effacer tous les enregistrements %{model}?",
        "ok": "Oui, tout effacer",
        "title": "Effacer %{model}?"
      },
      "success_message": "%{model} effacé avec succès!",
      "title": "Effacer les enregistrements"
    },
    "collections": {
      "clusters": "Clusters",
      "create_by_package": "Créer par package",
      "duplicate": "Dupliquer",
      "edit": "Éditer",
      "estimate": "Estimation",
      "new": "Nouveau",
      "schedule": "Programme",
      "sub_requests": "Sous-demandes",
      "view": "Vue"
    },
    "complete_modal": {
      "cancel": "Pas maintenant",
      "description": "Voulez-vous vraiment marquer %{model} comme terminé ?",
      "ok": "Oui, marquer comme terminé",
      "title": "Marquer comme terminé ?"
    },
    "confirmation_modal": {
      "cancel": "Annuler",
      "description": "Cette action ne peut pas être annulée!",
      "ok": "D’accord",
      "title": "Êtes-vous sûr?"
    },
    "contract_services": {
      "title": "Services contractuels"
    },
    "datasheets": {
      "datasheets_detail": {
        "side_panel_title": "Détails de la fiche technique"
      },
      "id": "ID",
      "item": "Objet",
      "title": "Feuilles de données"
    },
    "delete_modal": {
      "cancel": "Non, annuler",
      "description": "Voulez-vous vraiment supprimer le %{model} \"%{id}\"?",
      "ok": "Oui, supprimer",
      "title": "Effacer l’article?"
    },
    "delete_recurrencies": {
      "modal": {
        "cancel": "Non, annuler",
        "description": "Êtes-vous sûr de vouloir supprimer toutes les récurrences associées ?",
        "ok": "Oui, supprimer tout",
        "title": "Supprimer toutes les récurrences ?"
      },
      "success_message": "Les récurrences ont été supprimées avec succès !",
      "title": "Supprimer toutes les récurrences"
    },
    "dialogs": {
      "columns_batch_assigner": {
        "actions": "Actions",
        "add_action": "Ajouter une action",
        "add_value_to": "Ajouter %{value} à",
        "column": "Colonne",
        "done": "Terminé",
        "header": "Modification par lots",
        "multiple_success": "Les éléments %{numberOfItems} ont été mis à jour.",
        "new_value": "Nouvelle valeur",
        "single_success": "1 élément a été mis à jour.",
        "subtitle": "Attribuer une valeur au (x) élément (s) sélectionné (s) %{numberOfItems}",
        "title": "Créer une action",
        "update": "Mettre à jour"
      }
    },
    "discard_modal": {
      "cancel": "Continue de modifier",
      "description": "Voulez-vous vraiment annuler vos modifications?",
      "ok": "Oui, jeter",
      "title": "Annuler les modifications"
    },
    "eav_templates": {
      "title": "Modèles"
    },
    "empty_state": {
      "empty": "Pas de %{model}",
      "not_created": "Vous n’avez pas encore créé de %{model}"
    },
    "environment": {
      "project": {
        "select_project": "Sélectionnez un projet ..."
      },
      "select_project_subproject": "Sélectionnez un projet et un sous-projet",
      "subproject": {
        "select_subproject": "Sélectionnez un sous-projet ..."
      }
    },
    "estimate": {
      "accept": "Accepter l’estimation",
      "application": "Application",
      "back": "Toutes les demandes",
      "budget_hours": "Budget",
      "confirmation": {
        "cancel": "Non, annuler",
        "confirm": "Oui, confirmez",
        "description": "Voulez-vous vraiment confirmer cette estimation pour cette demande?",
        "title": "Confirmer la commande"
      },
      "description": "Vous disposez des services %{number} pour le paiement. Veuillez vérifier les détails ci-dessous.",
      "equipment": "Équipement",
      "estimate_details": "Détails de l’estimation",
      "estimate_success": "Estimation approuvée avec succès",
      "info_message": "Ce service d’estimation a déjà été approuvé. Il est disponible en lecture seule.",
      "material": "Matériel",
      "no_estimate_message": "Aucune estimation n’est associée à cette demande.",
      "norm_hours": "Heures normales",
      "others": "Autres",
      "save_draft": "Enregistrer le brouillon",
      "service_details": "Détails du service",
      "team_target_hours": "Heures cibles de l’équipe",
      "title": "Service de caisse",
      "tooltip": {
        "application": "Application",
        "equipment": "Équipement",
        "material": "Matériel",
        "others": "Autres"
      },
      "total_request": "Demande totale"
    },
    "estimate_services": {
      "application_price": "Prix de l’application",
      "automatically_prices": "Prix calculés automatiquement",
      "back": "Tous les portées",
      "budget_target_hours": "Heures cibles budgétaires",
      "choose_modal": {
        "package": "Services du package",
        "package_description": "Création de plusieurs services basés sur le même modèle d’activité",
        "single": "Article de service unique",
        "single_description": "Création d’un seul service",
        "title": "Créer un nouveau"
      },
      "contract_service": "Service contractuel",
      "crew_size": "Taille de l’équipage",
      "crews": "Nombre d’équipages",
      "description": "La description",
      "description_label": "La description",
      "discipline": "La discipline",
      "equipment_price": "Prix de l’équipement",
      "estimate": "Service de caisse",
      "estimate_services_detail": {
        "side_panel_title": "Détails du service"
      },
      "factor": "Facteur",
      "id": "ID",
      "materials_price": "Prix des matériaux",
      "no_template": "Vous devez créer un modèle pour les services d’estimation",
      "norm_hours": "Heures normales",
      "other_price": "Autre prix",
      "prices": "Des prix",
      "quantity": "Quantité",
      "service": "Un service",
      "service_package": "Forfait de service",
      "team_target_hours": "Heures cibles de l’équipe",
      "title": "Services de cadrage",
      "title_packageable": "Estimer les services par package de services",
      "total": "Total",
      "unit": "Unité",
      "weight": "Poids",
      "working_hours": "Heures d’ouverture"
    },
    "fields": {
      "image_not_found": "Image non trouvée",
      "na": "N/A",
      "signature": "Signature.jpg"
    },
    "filter_steps_modal": {
      "filter_result": "Résultat du filtre",
      "step": "Étape",
      "subtitle": "Fournir des informations sur le %{title}"
    },
    "filtering": {
      "between": " c’est entre",
      "contains": " qui contient ",
      "eq": " qui est égal à ",
      "invalid_date": "Pour filtrer correctement, la date de début et la date de fin doivent être renseignées",
      "invalid_value": "valeur invalide",
      "neq": " qui n’est pas égal à "
    },
    "form": {
      "back": "Tout %{entity}",
      "cancel": "Annuler",
      "create_entity": "Créer %{entity}",
      "create_error": "%{entity} n’a pas pu être créé",
      "create_success": "%{entity} a été créé avec succès",
      "duplicate_entity": "Dupliquer %{entity}",
      "duplicate_success": "Le %{entity} et ses dépendances ont été dupliqués avec succès!",
      "general_information": "informations générales",
      "hide": "Cacher",
      "inputs": {
        "attachment": {
          "add_error": "Erreur lors de l’ajout du fichier",
          "add_evidence": "Ajouter la preuve de l’autorisation du client",
          "allowed_extensions": "Les extensions autorisées sont",
          "drag_drop": "Ajouter des fichiers par glisser-déposer",
          "invalid_extension": "Type de fichier non autorisé.",
          "max_size": "Le fichier doit être inférieur à %{max_size}",
          "maximum_num_uploads_error": "Vous ne pouvez charger que zxzxxx0 fichiers par colonne flexible.",
          "one_file": "Télécharger un fichier à la fois",
          "or_drop_file_here": "ou déposez le fichier ici",
          "remove_error": "Échec de la suppression",
          "remove_success": "Supprimé avec succès",
          "select_files": "Sélectionnez les fichiers",
          "submit": "Soumettez votre pièce jointe",
          "upload_error": "Échec du téléchargement",
          "upload_success": "Importé avec succès"
        },
        "contract_service": {
          "add": "Ajouter un service contractuel",
          "subtitle": "Service contractuel"
        },
        "datasheet_filter": {
          "add_filter": "Ajouter un filtre",
          "title": "Filtre de feuille de données"
        },
        "date_range": {
          "between": "%{start} jusqu’à %{end}",
          "end": "Date de fin",
          "start": "Date de début"
        },
        "drop_formula": {
          "formula_select": "Veuillez sélectionner une formule ..."
        },
        "errors": {
          "mandatory": "%{field} est requis.",
          "not_found": "'%{value}' n’a pas été trouvé dans la référence. Sélectionnez-en un nouveau."
        },
        "formula": {
          "result": "Résultat"
        },
        "gps": {
          "save_location": "Enregistrer la position",
          "search": "Rechercher un lieu ...",
          "without_location": "Sans emplacement"
        },
        "picture": {
          "edition_only": "Uniquement disponible lors de l’édition",
          "five_pictures_limit_warning": "Seules 5 photos au maximum sont autorisées",
          "no_image_on_clipboard": "Aucune donnée d’image n’a été trouvée dans votre presse-papiers. Veuillez d’abord copier une image.",
          "remove_error": "Échec de la suppression",
          "remove_success": "Supprimé avec succès",
          "upload_error": "Échec du téléchargement",
          "upload_success": "Téléchargé avec succès"
        },
        "placeholder": "Entrez le %{field} ici",
        "value_selector": {
          "apply": "Appliquer",
          "done": "Terminé",
          "search": "Recherchez quelque chose ici ...",
          "waiting": "... Attendre"
        }
      },
      "loading": "Chargement...",
      "schedule_entity": "Horaire %{entity}",
      "schedule_success": "Votre demande de planification de %{entity} s’est terminée avec succès",
      "show": "Montrer",
      "subtitle": "Veuillez fournir quelques informations de base sur %{entity}.",
      "subtitle_error": "Le formulaire n’a pas pu être chargé, vérifiez vos données %{entity} ou modèle.",
      "update_entity": "Mettre à jour %{entity}",
      "update_error": "%{entity} n’a pas pu être mis à jour",
      "update_success": "%{entity} a été mis à jour avec succès"
    },
    "formula_modal": {
      "back_button_text": "Retour au formulaire",
      "calculate": "Calculer",
      "cancel": "Annuler",
      "mandatory": "Vous devez remplir les valeurs des champs suivants: %{fields}",
      "ok": "Enregistrer la formule"
    },
    "grid": {
      "all": "Tout",
      "clear_filters": "Effacer les filtres",
      "edit_mode": "Mode édition",
      "editable": {
        "error": {
          "mandatory": "Au moins un champ obligatoire a été laissé vide"
        },
        "title": "Mode édition",
        "update": {
          "error": "%{quantity} n’a pas pu être enregistré. Vérifiez la raison sur le côté gauche de chaque ligne en surbrillance",
          "success": "%{quantity} enregistrements ont été enregistrés avec succès"
        }
      },
      "empty_after_filtering": "Il n’y a aucun enregistrement pour les filtres appliqués",
      "grid_filters": {
        "menu_title": "Filtres personnalisés"
      },
      "menu": {
        "visibility": "Colonnes"
      },
      "of": "de",
      "other": "Autres",
      "productivity_factor": "Facteur de productivité",
      "select_items": "Sélectionnez les éléments",
      "selected": "Choisi",
      "selecteds": "Sélectionnés",
      "totals": "Totaux"
    },
    "history_popup": {
      "back": "Arrière",
      "comments": "commentaires",
      "crew_size": "Taille de l’équipage",
      "daily_hours": "Heures quotidiennes",
      "date": "Date",
      "form_create_progress_service_title": "Créer un historique des éléments de service de performance",
      "form_create_progress_title": "Créer l’historique des éléments de progression",
      "form_edit_progress_service_title": "Modifier l’historique des éléments de service de performance",
      "form_edit_progress_title": "Modifier l’historique des éléments de progression",
      "form_error_body": "Tous les champs obligatoires du formulaire doivent être remplis.",
      "form_error_title": "Il n’a pas été possible d’enregistrer le formulaire d’historique",
      "form_subtitle": "Insérez un historique par quantité ou pourcentage pour votre activité.",
      "modal_create_title": "Créer un historique",
      "modal_edit_title": "Modifier l’historique",
      "number_of_crews": "Nombre d’équipages",
      "number_of_workers": "Nombre de travailleurs",
      "other_value": "Autre valeur",
      "percentage": "Pourcentage",
      "progress_by": "Progrès par",
      "quantity": "Quantité",
      "singular": "Histoire",
      "working_hours": "Heures d’ouverture"
    },
    "home": {
      "available_on": "Disponible sur",
      "banner_description": "Gérez les tâches, suivez les processus et obtenez les meilleurs résultats en utilisant une seule plateforme",
      "create_request": {
        "text": "Tout d’abord, vous devez créer une demande du client dans le DPMS avec les détails de votre commande.",
        "title": "Créer une demande"
      },
      "dpms_mobile": "Application mobile DPMS",
      "getting_started": "Commencer",
      "home_title": "Accueil",
      "inspection": {
        "text": "Effectuer des inspections de qualité sur le terrain et enregistrer les obstacles.",
        "title": "Inspection"
      },
      "performance": {
        "text": "Enregistrez la progression et le temps en fonction des activités planifiées de chaque équipe et mesurez la productivité.",
        "title": "Performance"
      },
      "scoping": {
        "text": "Portez une demande du client. Attribuez des services et des packages de services à partir de votre contrat.",
        "title": "Cadrage"
      },
      "welcome_user": "Bienvenue, %{user}"
    },
    "importation_drop": {
      "choose_error": "Choisir un élément"
    },
    "importation_popup": {
      "file_upload_message": "Cliquez ou faites glisser le fichier vers cette zone pour télécharger",
      "importation_error": "Vous pouvez consulter ce message sur la page des emplois.",
      "importation_success": "Succès de l’importation.",
      "importation_timeout": "L’importation prend trop de temps. Vérifiez son état dans la page Travaux.",
      "instructions": "Les fichiers doivent être au format .xls et ne pas dépasser xxmb. <br> Votre fichier .xls doit contenir la colonne - Date, Client, Projet, Tâche, Notes .... <br> L’ordre des colonnes doit être le même que le ordre du modèle. <br> La date doit être en - AAAA / MM / JJ.",
      "requirements": "Exigences",
      "submit_spreadsheet": "Soumettez votre feuille de calcul"
    },
    "inspections": {
      "acceptance": "Acceptation",
      "acceptance_modal": {
        "inspection_acceptance": "Acceptation d’inspection",
        "reject": "Rejeter",
        "subtitle": "Fournir des informations sur l’inspection pour continuer avec l’acceptation."
      },
      "actions": {
        "approve": "Approuver",
        "more": "Plus",
        "reject": "Rejeter"
      },
      "choose_modal": {
        "schedule": "Planifier les articles d’inspection",
        "schedule_description": "Planifiez une ou plusieurs inspections en fonction de l’intervalle de dates",
        "single": "Article d’inspection unique",
        "single_description": "Création d’une seule inspection",
        "title": "Créez une inspection en tant que:"
      },
      "description": "Inspection",
      "every": "Chaque",
      "id": "ID",
      "inspect_date": "Date d’inspection",
      "inspect_status_id": "Statut",
      "inspected": "Inspecté",
      "inspected_detail": "Détail inspecté",
      "inspected_modules": {
        "progress": "Performance",
        "request": "Demande",
        "scope": "Cadrage"
      },
      "inspections_detail": {
        "side_panel_title": "Détails de l’inspection"
      },
      "module": "Module",
      "notifications": {
        "approve_successful": "L’inspection a été approuvée avec succès",
        "reject_successful": "L’inspection a été rejetée avec succès"
      },
      "recurrency_interval": "Intervalle de récurrence",
      "repeat": "Répéter",
      "repeat_every_value": {
        "day": "journée",
        "month": "Mois",
        "week": "La semaine",
        "year": "An"
      },
      "repeats_on": "Répéter le",
      "request_id": "DPMSID",
      "responsible_id": "Responsable",
      "schedule_detail": "Détail du calendrier",
      "schedule_form": {
        "acceptance_step": "Définir l’acceptation (facultatif)",
        "detailed_item_step": "Choisissez la portée de l’inspection / l’élément de performance",
        "detailed_template_step": "Choisissez la portée de l’inspection / le modèle de performance",
        "further_step_information": "Les champs flexibles sont prêts à être remplis individuellement après l’étape de création de récurrence",
        "general_step": "Définir les informations générales d’inspection",
        "hints": "Pour les récurrences mensuelles et annuelles, le système planifiera l’inspection correspondant à la date de début indiquée dans le champ d’intervalle.",
        "monthly_hint": "Répétez chaque mois tous les jours %{day}",
        "recurrence_step": "Récurrence"
      },
      "scheduled_exception": "Programmé",
      "statuses": {
        "approved": "Approuvé",
        "completed": "Terminé",
        "pending": "En attente",
        "progress": "En cours",
        "rejected": "Refusé"
      },
      "template": "Choisir le modèle",
      "title": "Inspections"
    },
    "inspects": {
      "statuses": {
        "approved": "Approuvé",
        "completed": "Terminé",
        "pending": "En attente",
        "progress": "En cours",
        "rejected": "Refusé"
      }
    },
    "kendo": {
      "upload": "Cliquez, faites glisser ou collez les fichiers ici pour les télécharger"
    },
    "kendo_translations": {
      "calendar": {
        "today": "Aujourd’hui"
      },
      "dateinput": {
        "decrement": "Diminuer la valeur",
        "increment": "Augmenter la valeur"
      },
      "datepicker": {
        "toggleCalendar": "Basculer le calendrier"
      },
      "daterangepicker": {
        "end": "Fin",
        "separator": 0,
        "start": "Début",
        "swapStartEnd": "Permuter les valeurs de début et de fin"
      },
      "datetimepicker": {
        "cancel": "Annuler",
        "date": "Date",
        "set": "Ensemble",
        "time": "Temps",
        "toggleDateTimeSelector": "Basculer le sélecteur date-heure"
      },
      "dropdowns": {
        "clear": "Clair",
        "nodata": "Aucune Donnée Disponible."
      },
      "editor": {
        "addColumnAfter": "Ajouter une colonne à droite",
        "addColumnBefore": "Ajouter une colonne à gauche",
        "addRowAfter": "Ajouter une ligne ci-dessous",
        "addRowBefore": "Ajouter une ligne ci-dessus",
        "alignCenter": "Texte au centre",
        "alignJustify": "Justifier",
        "alignLeft": "Aligner le texte à gauche",
        "alignRight": "Aligner le texte à droite",
        "bold": "Audacieux",
        "bulletList": "Insérer une liste non ordonnée",
        "createTable": "Créer une table",
        "deleteColumn": "Supprimer la colonne",
        "deleteRow": "Supprimer la ligne",
        "deleteTable": "Supprimer la table",
        "fontName": "Nom de la police",
        "fontSize": "Taille de police",
        "format": "Format",
        "hyperlink": "Insérer un lien hypertexte",
        "hyperlink-dialog-cancel": "Annuler",
        "hyperlink-dialog-content-address": "Adresse web",
        "hyperlink-dialog-content-newwindow": "Ouvre le lien dans une nouvelle fenêtre",
        "hyperlink-dialog-content-title": "Titre",
        "hyperlink-dialog-insert": "Insérer",
        "hyperlink-dialog-title": "Insérer un lien hypertexte",
        "image": "Insérer une image",
        "image-address": "Adresse web",
        "image-altText": "Texte alternatif",
        "image-cancel": "Annuler",
        "image-dialog-title": "Insérer une image",
        "image-height": "Hauteur (px)",
        "image-insert": "Insérer",
        "image-title": "Titre",
        "image-width": "Largeur (px)",
        "indent": "Retrait",
        "insertTableHint": "Créer une table {0} x {1}",
        "italic": "Italique",
        "mergeCells": "Fusionner des cellules",
        "orderedList": "Insérer une liste ordonnée",
        "outdent": "Outdent",
        "redo": "Refaire",
        "splitCell": "Cellule divisée",
        "strikethrough": "Barré",
        "subscript": "Indice",
        "superscript": "Exposant",
        "underline": "Souligner",
        "undo": "annuler",
        "unlink": "Supprimer le lien hypertexte",
        "viewHtml": "Afficher le HTML",
        "viewHtml-cancel": "Annuler",
        "viewHtml-dialog-title": "Afficher le HTML",
        "viewHtml-update": "Mise à jour"
      },
      "filter": {
        "addExpression": "Ajouter une expression",
        "addGroup": "Ajouter un groupe",
        "afterOperator": "Est après",
        "afterOrEqualOperator": "Est après ou égal à",
        "andLogic": "Et",
        "beforeOperator": "Est avant",
        "beforeOrEqualOperator": "Est avant ou égal à",
        "close": "proche",
        "containsOperator": "Contient",
        "endsWithOperator": "Se termine par",
        "eqOperator": "Est égal à",
        "gtOperator": "Est supérieur à",
        "gteOperator": "Est supérieur ou égal à",
        "isEmptyOperator": "Est vide",
        "isFalse": "C’est faux",
        "isNotEmptyOperator": "N’est pas vide",
        "isNotNullOperator": "Est non nulle",
        "isNullOperator": "Est nul",
        "isTrue": "Est vrai",
        "ltOperator": "Est inférieur à",
        "lteOperator": "Est inférieur ou égal à",
        "notContainsOperator": "Ne contient pas",
        "notEqOperator": "N’est pas égal à",
        "orLogic": "Ou",
        "startsWithOperator": "Commence avec"
      },
      "grid": {
        "filterAfterOperator": "Est après",
        "filterAfterOrEqualOperator": "Est après ou égal à",
        "filterBeforeOperator": "Est avant",
        "filterBeforeOrEqualOperator": "Est avant ou égal à",
        "filterBetweenOperator": "Est entre",
        "filterBooleanAll": "(Tout)",
        "filterClearButton": "Clair",
        "filterContainsOperator": "Contient",
        "filterEndsWithOperator": "Se termine par",
        "filterEqOperator": "Est égal à",
        "filterGtOperator": "Est supérieur à",
        "filterGteOperator": "Est supérieur ou égal à",
        "filterIsEmptyOperator": "Est vide",
        "filterIsFalse": "C’est faux",
        "filterIsNotEmptyOperator": "N’est pas vide",
        "filterIsNotNullOperator": "Est non nulle",
        "filterIsNullOperator": "Est nul",
        "filterIsTrue": "Est vrai",
        "filterLtOperator": "Est inférieur à",
        "filterLteOperator": "Est inférieur ou égal à",
        "filterNotContainsOperator": "Ne contient pas",
        "filterNotEqOperator": "N’est pas égal à",
        "filterStartsWithOperator": "Commence avec",
        "filterSubmitButton": "Filtre",
        "filterTitle": "Filtre",
        "groupPanelEmpty": "Faites glisser un en-tête de colonne et déposez-le ici pour le regrouper par cette colonne",
        "noRecords": "Aucun enregistrement disponible.",
        "pagerFirstPage": "Aller à la première page",
        "pagerInfo": "{0} - {1} sur {2} éléments",
        "pagerItemsPerPage": "objets par page",
        "pagerLastPage": "Aller à la dernière page",
        "pagerNextPage": "Aller à la page suivante",
        "pagerOf": "de",
        "pagerPage": "Page",
        "pagerPreviousPage": "Aller à la page précédente"
      },
      "numerictextbox": {
        "decrement": "Diminuer la valeur",
        "increment": "Augmenter la valeur"
      },
      "pager": {
        "firstPage": "Aller à la première page",
        "info": "{0} - {1} sur {2} éléments",
        "itemsPerPage": "objets par page",
        "lastPage": "Aller à la dernière page",
        "nextPage": "Aller à la page suivante",
        "of": "de",
        "page": "Page",
        "previousPage": "Aller à la page précédente"
      },
      "scheduler": {
        "agendaViewTitle": "Ordre du jour",
        "allDay": "toute la journée",
        "allEvents": "Tous les évènements",
        "dateTitle": "Date",
        "dayViewTitle": "journée",
        "deleteConfirmation": "Êtes-vous sûr de vouloir supprimer cet événement?",
        "deleteDialogTitle": "Supprimer l’événement",
        "deleteOccurrence": "Supprimer l’occurrence actuelle",
        "deleteRecurringConfirmation": "Voulez-vous supprimer uniquement cette occurrence d’événement ou la série entière?",
        "deleteRecurringDialogTitle": "Supprimer l’élément récurrent",
        "deleteSeries": "Supprimer la série",
        "deleteTitle": "Supprimer",
        "editorCancel": "Annuler",
        "editorDelete": "Supprimer",
        "editorEventAllDay": "Événement d’une journée",
        "editorEventDescription": "La description",
        "editorEventEnd": "Fin",
        "editorEventEndTimeZone": "Fuseau horaire de fin",
        "editorEventSeparateTimeZones": "Terminer dans un fuseau horaire différent",
        "editorEventStart": "Début",
        "editorEventStartTimeZone": "Fuseau horaire de début",
        "editorEventTimeZone": "Spécifiez le fuseau horaire",
        "editorEventTitle": "Titre",
        "editorOccurrence": "Modifier l’occurrence actuelle",
        "editorRecurringConfirmation": "Voulez-vous modifier uniquement cette occurrence d’événement ou la série entière?",
        "editorRecurringDialogTitle": "Modifier l’élément récurrent",
        "editorSave": "sauvegarder",
        "editorSeries": "Modifier la série",
        "editorTitle": "un événement",
        "editorValidationEnd": "L’heure de fin doit être postérieure à l’heure de début.",
        "editorValidationRequired": "Champ requis.",
        "editorValidationStart": "L’heure de début doit être antérieure à l’heure de fin.",
        "eventTitle": "un événement",
        "monthViewTitle": "Mois",
        "nextTitle": "Suivant",
        "noEvents": "pas d’événements",
        "previousTitle": "précédent",
        "recurrenceEditorDailyInterval": "journées)",
        "recurrenceEditorDailyRepeatEvery": "Répétez chaque",
        "recurrenceEditorEndAfter": "Après",
        "recurrenceEditorEndLabel": "Fin",
        "recurrenceEditorEndNever": "Jamais",
        "recurrenceEditorEndOccurrence": "occurrence (s)",
        "recurrenceEditorEndOn": "Sur",
        "recurrenceEditorFrequenciesDaily": "du quotidien",
        "recurrenceEditorFrequenciesMonthly": "Mensuel",
        "recurrenceEditorFrequenciesNever": "Jamais",
        "recurrenceEditorFrequenciesWeekly": "Hebdomadaire",
        "recurrenceEditorFrequenciesYearly": "Annuel",
        "recurrenceEditorMonthlyDay": "journée",
        "recurrenceEditorMonthlyInterval": "mois)",
        "recurrenceEditorMonthlyRepeatEvery": "Répétez chaque",
        "recurrenceEditorMonthlyRepeatOn": "Répéter le",
        "recurrenceEditorOffsetPositionsFirst": "Première",
        "recurrenceEditorOffsetPositionsFourth": "Quatrième",
        "recurrenceEditorOffsetPositionsLast": "Dernier",
        "recurrenceEditorOffsetPositionsSecond": "Seconde",
        "recurrenceEditorOffsetPositionsThird": "Troisième",
        "recurrenceEditorRepeat": "Répéter",
        "recurrenceEditorWeekdaysDay": "journée",
        "recurrenceEditorWeekdaysWeekday": "Jour de la semaine",
        "recurrenceEditorWeekdaysWeekendday": "Jour de weekend",
        "recurrenceEditorWeeklyInterval": "semaines)",
        "recurrenceEditorWeeklyRepeatEvery": "Répétez chaque",
        "recurrenceEditorWeeklyRepeatOn": "Répéter le",
        "recurrenceEditorYearlyInterval": "années)",
        "recurrenceEditorYearlyOf": "de",
        "recurrenceEditorYearlyRepeatEvery": "Répétez chaque",
        "recurrenceEditorYearlyRepeatOn": "Répéter le",
        "showFullDay": "Afficher toute la journée",
        "showWorkDay": "Afficher les heures d’ouverture",
        "timeTitle": "Temps",
        "timelineViewTitle": "Chronologie",
        "today": "Aujourd’hui",
        "weekViewTitle": "La semaine",
        "workWeekViewTitle": "Semaine de travail"
      },
      "sortable": {
        "noData": "Pas de données"
      },
      "timepicker": {
        "cancel": "Annuler",
        "now": "MAINTENANT",
        "selectNow": "Sélectionnez maintenant",
        "set": "Ensemble",
        "toggleClock": "Basculer l’horloge",
        "toggleTimeSelector": "Toggle TimeSelector"
      },
      "treelist": {
        "filterAfterOperator": "Est après",
        "filterAfterOrEqualOperator": "Est après ou égal à",
        "filterBeforeOperator": "Est avant",
        "filterBeforeOrEqualOperator": "Est avant ou égal à",
        "filterBooleanAll": "(Tout)",
        "filterClearButton": "Clair",
        "filterContainsOperator": "Contient",
        "filterEndsWithOperator": "Se termine par",
        "filterEqOperator": "Est égal à",
        "filterGtOperator": "Est supérieur à",
        "filterGteOperator": "Est supérieur ou égal à",
        "filterIsEmptyOperator": "Est vide",
        "filterIsFalse": "C’est faux",
        "filterIsNotEmptyOperator": "N’est pas vide",
        "filterIsNotNullOperator": "Est non nulle",
        "filterIsNullOperator": "Est nul",
        "filterIsTrue": "Est vrai",
        "filterLtOperator": "Est inférieur à",
        "filterLteOperator": "Est inférieur ou égal à",
        "filterNotContainsOperator": "Ne contient pas",
        "filterNotEqOperator": "N’est pas égal à",
        "filterStartsWithOperator": "Commence avec",
        "noRecords": "Aucun enregistrement disponible"
      },
      "upload": {
        "cancel": "Annuler",
        "clearSelectedFiles": "Clair",
        "dropFilesHere": "ou déposez le fichier ici",
        "files": "des dossiers",
        "headerStatusUploaded": "Terminé",
        "headerStatusUploading": "Téléchargement...",
        "invalidFileExtension": "Type de fichier non autorisé.",
        "invalidFiles": "Fichier (s) non valide (s). Veuillez vérifier les conditions de téléchargement de fichiers.",
        "invalidMaxFileSize": "Taille de fichier trop grande.",
        "invalidMinFileSize": "Taille de fichier trop petite.",
        "remove": "Retirer",
        "retry": "Réessayez",
        "select": "Choisir le dossier",
        "total": "Total",
        "uploadSelectedFiles": "Télécharger"
      }
    },
    "loading_batch_entities": {
      "page_of": "Page %{page} sur %{total}",
      "title": "Récupérer des pages"
    },
    "loading_batch_modal": {
      "page_of": "Page %{page} de %{total}",
      "title": "Récupérer des pages"
    },
    "login": {
      "again": "Pour continuer, veuillez vous reconnecter",
      "continue_mykaefer": "Continuez avec MyKaefer",
      "copyright": "®%{year} Tous droits réservés. Kaefer®",
      "forgot_password": "mot de passe oublié?",
      "iframe_info": "Demande d'accès à la page: %{link}",
      "log_in": "S’identifier",
      "password": "Mot de passe",
      "title_1": "Un outil puissant",
      "title_2": "pour la gestion de projet",
      "username": "Nom d’utilisateur",
      "welcome_back": "Nous saluons le retour"
    },
    "main_dialog": {
      "failure_message": "Votre demande n’a pas pu être complétée",
      "failure_title": "Échec",
      "loading_message": "Veuillez patienter pendant que nous organisons les choses pour vous!",
      "loading_title": "Chargement...",
      "service_title": "En attendant la fin des travaux...",
      "success_message": "Votre demande a été acceptée! Vous pouvez maintenant continuer votre travail.",
      "success_title": "Réussi!"
    },
    "notification": {
      "access_denied": "Accès refusé",
      "action_success": "%{model} %{id} était %{action}",
      "bad_formula_setup": "Il y a un problème de configuration avec la formule '%{title}', veuillez vérifier votre configuration!",
      "correct_subproject": "Sélectionnez le sous-projet correct de cet élément",
      "dismiss_all": "Rejeter la totalité",
      "error": "Erreur",
      "error_cookies": "Nous n’avons pas pu établir de communication entre les sources croisées. Vérifiez les paramètres des cookies dans votre navigateur et désactivez le «blocage des cookies tiers».",
      "error_job": "Échoué! Vérifiez la raison dans la page Emplois.",
      "error_msg": "Pardon! Un problème est survenu!",
      "info": "Info",
      "large_file_body": "Veuillez appliquer des filtres pour réduire la taille du fichier de sortie.",
      "large_file_title": "La grille a plus de 1000 enregistrements",
      "page_denied": "La page n’a pas pu être chargée.",
      "please": "S’il vous plaît",
      "select_subproject": "Sélectionnez un projet et un sous-projet pour continuer",
      "success": "Succès",
      "success_msg": "Action exécutée avec succès!",
      "warning": "Attention"
    },
    "orders": {
      "add_evidence": "Ajouter la preuve de l’autorisation du client",
      "attachment": "Attachement",
      "comments": "commentaires",
      "date": "Date",
      "drag_and_drop": "Ajouter des fichiers par glisser-déposer",
      "responsible": "Responsable",
      "title": "Ordres"
    },
    "performance": {
      "title": "Performance"
    },
    "performances": {
      "title": "Les performances"
    },
    "performances_wip": {
      "performance_item": "L’élément de Performance",
      "title": "Performance"
    },
    "planning": {
      "item_no": "N° d’article",
      "service_no": "N° de service",
      "title": "Planification",
      "view": "Vue Planification"
    },
    "plannings": {
      "title": "Planification"
    },
    "progress_histories": {
      "add_new_progress": "Ajouter une nouvelle progression",
      "empty_historic": "Il n’y a pas d’élément d’historique et il n’est pas possible de créer des historiques lorsque la quantité est nulle",
      "hours": "Les heures",
      "production_factor": "PF",
      "progress": "Le progrès",
      "progress_badge": "Article %{number}",
      "progress_history": "Historique des progrès",
      "progress_service_badge": "Service %{number}",
      "progress_service_history": "Historique des services",
      "progress_service_title": "Historiques de service",
      "progress_title": "Historiques des articles",
      "quantity": "Quantité",
      "subtitle": "Gérez les progrès quotidiens en fonction des activités planifiées par votre équipe."
    },
    "progress_services": {
      "actual_div_hours": "Heures réelles / cibles de l’équipe",
      "actual_div_quantity": "Quantité réelle / cible",
      "actual_hours": "Heures réelles",
      "actual_quantity": "Quantité réelle",
      "application_price": "Prix de l’application",
      "back": "Tous les performances",
      "budget_target_hours": "Heures cibles du budget",
      "canceled": "Annulé",
      "choose_modal": {
        "package": "Services du forfait",
        "package_description": "Création de plusieurs services basés sur le même modèle d’activité",
        "single": "Article de service unique",
        "single_description": "Création d’un seul service",
        "title": "Créer un nouveau"
      },
      "contract_service": "Service contractuel",
      "contribution": "Contribution",
      "contribution_info": "Valeurs calculées automatiquement",
      "crew": "équipage",
      "crew_size": "Taille de l’équipage",
      "crews": "équipages",
      "date_filter": {
        "all": "Tous",
        "today": "Aujourd’hui",
        "tomorrow": "Demain",
        "week": "La semaine",
        "yesterday": "Hier"
      },
      "delta_hours": "Heures Delta",
      "delta_qtt": "Quantité Delta",
      "description": "Service de performances",
      "discipline": "La discipline",
      "end_date": "Date de fin",
      "equipment_price": "Prix de l’équipement",
      "factor": "Facteur",
      "flag": "Drapeau",
      "flag_filter": "Filtre de drapeau",
      "flag_filters": "Filtres de drapeau",
      "id": "ID",
      "materials_price": "Prix des matériaux",
      "no_crews": "Nombre d’équipages",
      "no_workers": "Nombre de travailleurs",
      "norm_hours": "Heures normales",
      "not_planned": "Pas prévu",
      "other_price": "Autre prix",
      "package_factor": "Facteur",
      "people": "personnes",
      "person": "personne",
      "pf": "PF",
      "pf_doing_bad": "Tu ne vas pas bien",
      "pf_doing_good": "Tu le fais bien!",
      "pf_doing_neutral": "Impossible de calculer le pf",
      "planned": "Prévu",
      "planning_chart": {
        "actual": "Réel",
        "actual_qty": "Qté réelle",
        "date": "Date",
        "forecasted": "Prévu",
        "forecasted_qty": "Qté prévue",
        "target": "Cible",
        "target_qty": "Quantité cible",
        "title": "Tableau de planification"
      },
      "planning_timeline": {
        "day": "journée",
        "day_advance": "jour à l’avance",
        "day_delayed": "jour de retard",
        "days": "jours",
        "days_advance": "jours à l’avance",
        "days_delayed": "jours de retard",
        "end_date": "Date de fin",
        "forecasted": "Prévu",
        "on_time": "À temps",
        "remaining_days": "Jours restants",
        "start_date": "Date de début",
        "title": "Calendrier de planification"
      },
      "progress": "Le progrès",
      "progress_services_detail": {
        "side_panel_title": "Détails du service"
      },
      "quantity": "Quantité",
      "responsible": "Responsable",
      "scoped_quantity": "Quantité étendue",
      "service": "Un service",
      "service_package": "Forfait de service",
      "service_planning": "Planification des services",
      "service_progress": "Progression du service",
      "service_weight": "Poids de service",
      "start_date": "Date de début",
      "statuses": {
        "canceled": "Annulé",
        "done": "Complété",
        "extra": "Supplémentaire",
        "in_planning": "Pas prévu",
        "planned": "Prévu"
      },
      "team_target_hours": "Heures cibles de l’équipe",
      "title": "Services de progrès",
      "title_packageable": "Services de performance par ensemble de services",
      "total": "Le total",
      "total_hours": "Heures totales",
      "unit": "Unité",
      "worker": "ouvrier",
      "workers": "ouvriers",
      "working_hours": "Heures d’ouverture"
    },
    "progresses": {
      "cancel": "Annuler",
      "choose_modal": {
        "single": "Un autre élément d’avancement",
        "single_description": "Création d’un seul élément de progression"
      },
      "complete": "Compléter",
      "delta_hours": "∆ Heures",
      "delta_qty": "∆ Qté",
      "description": "La description",
      "extra": "Supplémentaire",
      "flag": "Drapeau",
      "histories": {
        "item": "Article %{number}",
        "item_title": "Historiques des articles",
        "progress_history": "Historique des progrès",
        "service": "Service %{number}",
        "service_history": "Historique des services",
        "service_title": "Historiques de service",
        "subtitle": "Gérez les progrès quotidiens en fonction des activités planifiées par votre équipe."
      },
      "history": "Histoire",
      "id": "identifiant",
      "item": "Article",
      "label": "Étiqueter",
      "pf": "PF",
      "progress": "Le progrès",
      "progresses_detail": {
        "side_panel_title": "Détails des performances"
      },
      "request_status_restriction": "La demande doit avoir le statut En planification ou En exécution",
      "revised": "modifié",
      "title": "Performance"
    },
    "projects": {
      "workdays": {
        "fri": "Ven",
        "mon": "Lun",
        "sat": "Sam",
        "sun": "Dim",
        "thu": "Jeu",
        "tue": "Mar",
        "wed": "Mer"
      }
    },
    "requests": {
      "actual_hours": "Heures réelles",
      "actual_progress": "Progrès réels",
      "all": "Tout",
      "are_you_sure_complete": "Voulez-vous vraiment répondre à cette demande?",
      "are_you_sure_complete_no_history": "Êtes-vous sûr de répondre à une demande sans aucun progrès donné?",
      "are_you_sure_deletion": "Êtes-vous sûr de vouloir supprimer la demande?",
      "are_you_sure_revertion": "Etes-vous sûr de vouloir revenir en arrière et de perdre les données saisies dans ce module?",
      "area": "Zone",
      "authorization_pending": "autorisation en attente",
      "authorize_form": {
        "moal_title": "Autorisation",
        "modal_title": "Autorisation",
        "subtitle": "Fournir des informations sur la demande pour continuer avec l’approbation",
        "title": "Autoriser la demande"
      },
      "canceled": "Annulé",
      "clustered": "Clustered",
      "comments": "commentaires",
      "confirmation_modal": {
        "approve_description": "La demande sera approuvée",
        "approve_title": "Approuver la demande?",
        "cancel_description": "La demande sera annulée",
        "cancel_title": "Demande d’annulation?",
        "complete_description": "Cette demande sera complétée",
        "complete_title": "Demande complète?",
        "confirm_action": "Procéder à l’importation d’étendues",
        "confirm_cancel": "Pas maintenant",
        "confirm_confirmation": "Oui, je veux confirmer",
        "confirm_description": "Voulez-vous confirmer la demande?",
        "confirm_title": "Demande de confirmation",
        "delete_description": "L’action de suppression ne peut pas être annulée. Êtes-vous sûr?",
        "delete_title": "Supprimer la demande?",
        "reject_description": "Cet élément reviendra au statut En cours",
        "reject_title": "Rejeter la commande?",
        "revert_description": "L’action de retour ne peut pas être annulée. Êtes-vous sûr?",
        "revert_title": "Annuler la commande?"
      },
      "confirmed": "Confirmé",
      "creation_form": {
        "proceed": "Passez à l’étape 2"
      },
      "creation_page": {
        "add_template": "Ajouter un modèle",
        "first_step": "Créer une demande",
        "second_step": "Importer des étendues (facultatif)",
        "third_step": "Appliquer des packages de services (facultatif)"
      },
      "description": "Demande",
      "discipline": "Activité",
      "done": "Terminé",
      "eav_template": "Modèle",
      "eav_template_id": "ID du modèle",
      "edit_dialog": {
        "reason": "Nom de sous-demande"
      },
      "elevation": "Élévation",
      "errors": {
        "not_found": "Impossible de trouver la demande avec l'ID: %{id}"
      },
      "estimates_authorization": "L’autorisation de l’Estimation est nécessaire",
      "forecast_end_date": "Date de fin prévue",
      "id": "DPMSID",
      "in_performance": "En performance",
      "in_planning": "En planification",
      "in_scoping": "En portée",
      "information": "Informations complémentaires",
      "notification": {
        "authorization_success": "La demande est autorisée avec succès",
        "deleting_sub_request": "La sous-requête %{id} est en cours de suppression. Veuillez consulter la page des emplois pour en savoir plus sur sa progression",
        "missing_order_error": "Il n’y a aucune commande en attente associée à cette demande"
      },
      "on_hold": "En attente",
      "progress_end_date": "Date de fin du progrès",
      "progress_responsible": "Responsable du progrès",
      "progress_responsible_id": "Responsable du progrès",
      "progress_start_date": "Date de début du progrès",
      "reason": "Référence",
      "reference": "Référence",
      "registered": "Inscrit",
      "request_date": "Date de la demande",
      "request_detail": {
        "edit": "Éditer",
        "estimates": "Budget",
        "inspections": "Visites Chantier",
        "performance": "Performance",
        "planning": "Planification",
        "scopes": "Diagnostic",
        "show": "Afficher",
        "side_panel_title": "Détails de la demande"
      },
      "request_end_date": "Date de fin de la demande",
      "request_responsible": "Responsable de la demande",
      "request_responsible_id": "Responsable de la demande",
      "request_start_date": "Date de début de la demande",
      "schedule": "Calendrier de demande",
      "schedule_form": {
        "date": "Date",
        "days": "%{number} jours",
        "performance": "Performance",
        "progress": "Le progrès",
        "request": "Demande",
        "responsible": "Responsable",
        "schedule": "Programme",
        "scoping": "Cadrage",
        "subtitle": "Pour créer un calendrier pour cette demande, nous avons d’abord besoin d’informations supplémentaires.",
        "title": "Horaire de la demande"
      },
      "scope_end_date": "Date de fin de la portée",
      "scope_responsible": "Responsable de la portée",
      "scope_responsible_id": "Responsable de la portée",
      "scope_start_date": "Date de début de la portée",
      "scopes": "Diagnostic",
      "service_desired_date": "Date souhaitée",
      "skip_scoping": "Passer la phase de cadrage",
      "status": "Statut",
      "statuses": {
        "authorization_pending": "En attente",
        "canceled": "Annulé",
        "clustered": "Groupé",
        "confirmed": "En portée",
        "deleting": "Suppression",
        "done": "Terminé",
        "in_performance": "En performance",
        "in_planning": "En performance",
        "in_scoping": "En portée",
        "on_hold": "En pause",
        "registered": "Établi"
      },
      "sub_request": "Sous-demande",
      "sub_requests": "Sous-demandes",
      "subarea": "Sous-zone",
      "team_target_hours": "Heures cibles de l’équipe",
      "title": "Demandes"
    },
    "scopes": {
      "add": "Ajouter un élément de portée",
      "choose_modal": {
        "single": "Un autre élément de portée",
        "single_description": "Création d’un seul élément de cadrage"
      },
      "clusters": {
        "title": "Clusters de portée"
      },
      "count": "Compter",
      "crew_size": "Taille de l’équipage",
      "crews": "Nombre d’équipages",
      "date": "Fecha",
      "description": "Portée",
      "hours": "Heures (h)",
      "id": "ID",
      "item": "Article",
      "label": "Étiquette",
      "label_assigner": {
        "success": "Vos étiquettes d’étendues ont été attribuées avec succès! Vous pouvez continuer votre plan maintenant."
      },
      "label_error": "Impossible de récupérer les libellés",
      "popup_label": {
        "create_label": "Créer une étiquette",
        "labels": "Étiquettes:",
        "name_label": "Nom de l’étiquette",
        "new_label": "Nouvelle étiquette"
      },
      "quantity": "Qté",
      "remaining_hours": "Heures Restantes",
      "remaining_quantity": "Qté Restante",
      "request_status_restriction": "La demande doit avoir le statut Confirmé ou En cours d’élaboration",
      "scopes_detail": {
        "side_panel_title": "Détails de la portée"
      },
      "title": "Diagnostic",
      "unit": "Unité",
      "working_hours": "Heures d’ouverture"
    },
    "search": {
      "loading": "chargement...",
      "no_result_found": "Aucun résultat trouvé",
      "placeholder": "Filtre..."
    },
    "settings": {
      "title": "Réglages"
    },
    "settings_shortcuts": "Raccourcis des paramètres",
    "shortcuts": {
      "access_control": "Contrôle d’accès",
      "areas": "Zones et sous-zones",
      "areas_subareas": "Zones et sous-zones",
      "assets": "Les atouts",
      "background_jobs": "Emplois d’arrière-plan",
      "branches": "Branches",
      "cities": "Villes",
      "client_sites": "Sites clients",
      "clients": "Clients",
      "clients_info": "Info clients",
      "columns_renaming": "Renommer des colonnes",
      "companies": "Entreprises",
      "contract_services": "Services contractuels",
      "contracts": "Contrats",
      "countries": "Des pays",
      "customer_sites": "Sites clients",
      "customers": "Clients",
      "disciplines": "Disciplines",
      "employees": "Personnel",
      "equipaments": "Équipements",
      "equipments": "Équipements",
      "formulas": "Formules",
      "location": "Emplacement",
      "materials": "Matériaux",
      "matrices": "Matrices",
      "measures": "Les mesures",
      "mobile_details": "Détails du mobile",
      "position_names": "Noms de poste",
      "preferences": "Préférences",
      "project_assets": "Les atouts",
      "project_contracts": "Contrats",
      "projects": "Projets et sous-projets",
      "projects_contracts": "Projets et contrats",
      "projects_subprojects": "Projets et sous-projets",
      "provinces": "États / Provinces",
      "regions": "Régions",
      "routes": "Itinéraires",
      "specialities": "Spécialités",
      "staff": "Personnel",
      "states_provinces": "États / Provinces",
      "translations": "Renommage de colonne",
      "users": "Utilisateurs",
      "visible_fields": "Détails du mobile",
      "work_positions": "Noms de poste"
    },
    "side_menu": {
      "contracts": "Contrats",
      "datasheets": "Fiche technique",
      "new": "Nouveau",
      "report": "Rapports",
      "search": "Chercher",
      "settings": "Réglages",
      "template": {
        "asset": "Les atouts",
        "contract_service": "Services contractuels",
        "datasheet": "Feuilles de données",
        "estimate_service": "Estimer les services",
        "inspection": "Inspections",
        "performance": "Performance",
        "report": "Rapports",
        "request": "Demandes",
        "scoping": "Cadrage",
        "valuation": "Évaluation"
      },
      "templates": "Modèles"
    },
    "sub_requests": {
      "creation_page": {
        "all": "Toutes les sous-demandes",
        "back": "Tous les portées",
        "title": "sous demandes"
      },
      "left_panel": {
        "add_scoping": "Ajouter un élément de portée",
        "allocation": "Allocation",
        "cards_header_title": "Portées",
        "summary": "Sommaire",
        "total_allocation": "Allocation totale"
      },
      "scope_card": {
        "scope": "Portée"
      }
    },
    "tabs": {
      "error": "Impossible de charger les onglets"
    },
    "templates": {
      "asset": "Les atouts",
      "contract_service": "Services contractuels",
      "datasheet": "Feuilles de données",
      "estimate_service": "Estimer les services",
      "inspection": "Inspections",
      "no_template": "Vous devez créer un modèle",
      "progress": "Performance",
      "progress_service": "Évaluation",
      "report": "rapports",
      "request": "Demandes",
      "scoping": "Cadrage",
      "title": "Modèles pour %{type}"
    },
    "top_menu": {
      "authorization": "Autorisation",
      "estimates": "Budget",
      "inspections": "Visites Chantier",
      "performance": "Performance",
      "planning": "Planification",
      "requests": "Demandes",
      "scoping": "Portée"
    },
    "track_block": {
      "id": "DPMSID"
    },
    "user_menu": {
      "about_version": "À propos de la version",
      "logout": "Se déconnecter",
      "profile": "Profil"
    },
    "users": {
      "edit_title": "Mettre à jour le profil"
    }
  },
  "nb": {
    "actions": {
      "approve": "Vedta",
      "bulk_editing": "Bulkredigering",
      "cancel": "Avbryt",
      "choose_entity": "Velg %{entity}",
      "clear": "Klar",
      "confirm": "Bekrefte",
      "create": "Skape",
      "delete": "Slett",
      "discard": "Kast",
      "done": "Ferdig",
      "duplicate": "Duplisere",
      "edit": "Redigere",
      "export": "Eksporter til .xls",
      "export_template": "Last ned mal .xls",
      "false": "Nei",
      "import": "Importer fra .xls",
      "import_file": "Importer fil",
      "loading": "Laster",
      "more": "Mer",
      "new": "Ny",
      "next": "neste",
      "no": "Nei",
      "past": {
        "approve": "godkjent",
        "cancel": "kansellert",
        "complete": "fullført",
        "confirm": "bekreftet",
        "delete": "slettet",
        "destroy": "ødelagt",
        "reject": "avvist",
        "revert": "tilbakeført",
        "update": "oppdatert"
      },
      "prev": "Forrige",
      "reject": "Avvis",
      "reset": "Nullstille",
      "revert": "Tilbakestill",
      "save": "Lagre",
      "save_button_keyboard": "Lagre (Ctrl + Enter)",
      "save_exit": "Lagre og avslutt",
      "saving": "Lagrer ...",
      "schedule": "Rute",
      "skip": "Hopp over",
      "true": "Ja",
      "unsaved_changes": "Ikke lagrede endringer",
      "upload_file": "Last opp fil",
      "upload_picture": "Last opp bilde",
      "yes": "Ja"
    },
    "api_internal_messages": {
      "cant_duplicate_clustered_request": "Det er ikke mulig å duplisere en gruppert forespørsel",
      "cant_duplicate_progress": "Kan ikke duplisere tjenestene på grunn av følgende feil ...",
      "cant_duplicate_request": "Kan ikke duplisere Scopes, Estimate og Services på grunn av følgende feil ...",
      "cant_duplicate_scope": "Kan ikke duplisere tjenestene på grunn av følgende feil ...",
      "only_estimate_service_copy": "Bare elementet Estimate Service prøver å bli kopiert.",
      "only_progress_copy": "Bare Fremgangen prøver å bli kopiert.",
      "only_request_copy": "Bare forespørselen prøver å bli kopiert.",
      "only_scope_copy": "Bare Scoping-elementet prøver å bli kopiert."
    },
    "cancel_modal": {
      "cancel": "Ikke nå",
      "description": "Er du sikker på at du vil avbryte %{model} \"%{id}\"?",
      "ok": "Ja, avbryt",
      "title": "Avbryte varen?"
    },
    "clear_records": {
      "modal": {
        "cancel": "Nei, avbryt",
        "description": "Er du sikker på at du vil tømme alle %{model}-poster?",
        "ok": "Ja, fjern alt",
        "title": "Fjern %{model}?"
      },
      "success_message": "%{model} fjernet vellykket!",
      "title": "Tøm poster"
    },
    "collections": {
      "clusters": "Klynger",
      "create_by_package": "Lag etter pakke",
      "duplicate": "Duplisere",
      "edit": "Redigere",
      "estimate": "anslag",
      "new": "Ny",
      "schedule": "Rute",
      "sub_requests": "Underforespørsler",
      "view": "Utsikt"
    },
    "complete_modal": {
      "cancel": "Ikke nå",
      "description": "Er du sikker på at du vil merke %{model} som fullført?",
      "ok": "Ja, merk som fullført",
      "title": "Merk som fullført?"
    },
    "confirmation_modal": {
      "cancel": "Avbryt",
      "description": "Denne handlingen kan ikke angres!",
      "ok": "Ok",
      "title": "Er du sikker?"
    },
    "contract_services": {
      "title": "Kontrakttjenester"
    },
    "datasheets": {
      "datasheets_detail": {
        "side_panel_title": "Dataarkdetaljer"
      },
      "id": "ID",
      "item": "punkt",
      "title": "Dataark"
    },
    "delete_modal": {
      "cancel": "Nei, avbryt",
      "description": "Er du sikker på at du vil slette %{model} \"%{id}\"?",
      "ok": "Ja, slett",
      "title": "Vil du slette elementet?"
    },
    "delete_recurrencies": {
      "modal": {
        "cancel": "Nei, avbryt",
        "description": "Er du sikker på at du vil slette alle relaterte gjentakelser?",
        "ok": "Ja, slett alle",
        "title": "Vil du slette alle gjentakelser?"
      },
      "success_message": "Gjentakelser slettet!",
      "title": "Slett alle gjentakelser"
    },
    "dialogs": {
      "columns_batch_assigner": {
        "actions": "handlinger",
        "add_action": "Legg til handling",
        "add_value_to": "Legg %{value} til",
        "column": "Kolonne",
        "done": "Ferdig",
        "header": "Batchredigering",
        "multiple_success": "%{numberOfItems} varene ble oppdatert.",
        "new_value": "Ny verdi",
        "single_success": "1 vare ble oppdatert.",
        "subtitle": "Tildel en verdi til det valgte objektet %{numberOfItems}",
        "title": "Lag handling",
        "update": "Oppdater"
      }
    },
    "discard_modal": {
      "cancel": "Fortsetter redigering",
      "description": "Er du sikker på at du vil forkaste endringene?",
      "ok": "Ja, kast",
      "title": "Forkaste endringer"
    },
    "eav_templates": {
      "title": "Maler"
    },
    "empty_state": {
      "empty": "Ingen %{model}",
      "not_created": "Du har ikke opprettet noen %{model} ennå"
    },
    "environment": {
      "project": {
        "select_project": "Velg prosjekt ..."
      },
      "select_project_subproject": "Velg prosjekt og delprosjekt",
      "subproject": {
        "select_subproject": "Velg delprosjekt ..."
      }
    },
    "estimate": {
      "accept": "Godta estimat",
      "application": "applikasjon",
      "back": "Alle forespørsler",
      "budget_hours": "Budsjett",
      "confirmation": {
        "cancel": "Nei, Avbryt",
        "confirm": "Ja, bekreft",
        "description": "Er du sikker på at du vil bekrefte dette anslaget for denne forespørselen?",
        "title": "Bekreft ordre"
      },
      "description": "Du har %{number}-tjenester for kassen. Vennligst sjekk detaljene nedenfor.",
      "equipment": "Utstyr",
      "estimate_details": "Estimeringsdetaljer",
      "estimate_success": "Anslag er godkjent",
      "info_message": "Denne estimeringstjenesten er allerede godkjent. Den er tilgjengelig for skrivebeskyttet.",
      "material": "Materiale",
      "no_estimate_message": "Det er ingen anslag knyttet til denne forespørselen.",
      "norm_hours": "Norm Hours",
      "others": "Andre",
      "save_draft": "Lagre utkast",
      "service_details": "Tjenestedetaljer",
      "team_target_hours": "Lagets måltimer",
      "title": "Kassetjeneste",
      "tooltip": {
        "application": "applikasjon",
        "equipment": "Utstyr",
        "material": "Materiale",
        "others": "Andre"
      },
      "total_request": "Total forespørsel"
    },
    "estimate_services": {
      "application_price": "Søknadspris",
      "automatically_prices": "Automatisk beregnede priser",
      "back": "Alle omfang",
      "budget_target_hours": "Budsjettmåltimer",
      "choose_modal": {
        "package": "Tjenester fra pakke",
        "package_description": "Oppretting av flere tjenester basert på samme aktivitetsmodell",
        "single": "Enkelte serviceelementer",
        "single_description": "Opprettelse av bare en tjeneste",
        "title": "Lag ny"
      },
      "contract_service": "Kontraktservice",
      "crew_size": "Mannskap størrelse",
      "crews": "Antall mannskaper",
      "description": "Beskrivelse",
      "description_label": "Beskrivelse",
      "discipline": "Disiplin",
      "equipment_price": "Utstyrspris",
      "estimate": "Kassetjeneste",
      "estimate_services_detail": {
        "side_panel_title": "Tjenestens detaljer"
      },
      "factor": "Faktor",
      "id": "ID",
      "materials_price": "Materialpris",
      "no_template": "Du må lage mal for Estimate-tjenester",
      "norm_hours": "Norm Hours",
      "other_price": "Annen pris",
      "prices": "Priser",
      "quantity": "Mengde",
      "service": "Service",
      "service_package": "Servicepakke",
      "team_target_hours": "Lagets måltimer",
      "title": "Omfangstjenester",
      "title_packageable": "Anslå tjenester etter tjenestepakke",
      "total": "Total",
      "unit": "Enhet",
      "weight": "Vekt",
      "working_hours": "Arbeidstid"
    },
    "fields": {
      "image_not_found": "Bildet ble ikke funnet",
      "na": "Ikke relevant",
      "signature": "Signatur.jpg"
    },
    "filter_steps_modal": {
      "filter_result": "Filtrer resultat",
      "step": "Steg",
      "subtitle": "Gi litt informasjon om %{title}"
    },
    "filtering": {
      "between": " det er mellom",
      "contains": " som inneholder ",
      "eq": " det er lik ",
      "invalid_date": "For å filtrere riktig, må startdato og sluttdato fylles ut",
      "invalid_value": "ugyldig verdi",
      "neq": " det er ikke lik "
    },
    "form": {
      "back": "Alle %{entity}",
      "cancel": "Avbryt",
      "create_entity": "Lag %{entity}",
      "create_error": "%{entity} kunne ikke opprettes",
      "create_success": "%{entity} ble opprettet",
      "duplicate_entity": "Dupliser %{entity}",
      "duplicate_success": "%{entity} og dens avhengigheter ble vellykket duplisert!",
      "general_information": "Generell informasjon",
      "hide": "Gjemme seg",
      "inputs": {
        "attachment": {
          "add_error": "Feil ved legging av fil",
          "add_evidence": "Legg til bevis på klientautorisasjonen",
          "allowed_extensions": "De tillatte utvidelsene er",
          "drag_drop": "Legg til filer ved å dra og slippe",
          "invalid_extension": "Filtype er ikke tillatt.",
          "max_size": "Filen må være mindre enn %{max_size}",
          "maximum_num_uploads_error": "Du kan bare laste opp til %{max_num}-filer per fleksibel kolonne.",
          "one_file": "Last opp én fil om gangen",
          "or_drop_file_here": "eller slipp filen her",
          "remove_error": "Kunne ikke fjerne",
          "remove_success": "Fjernet vellykket",
          "select_files": "Velg filer",
          "submit": "Send inn vedlegget ditt",
          "upload_error": "Opplasting mislyktes",
          "upload_success": "Lastet opp vellykket"
        },
        "contract_service": {
          "add": "Legg til en kontraktstjeneste",
          "subtitle": "Kontraktservice"
        },
        "datasheet_filter": {
          "add_filter": "Legg til filter",
          "title": "Dataarkfilter"
        },
        "date_range": {
          "between": "%{start} til %{end}",
          "end": "Sluttdato",
          "start": "Startdato"
        },
        "drop_formula": {
          "formula_select": "Velg en formel ..."
        },
        "errors": {
          "mandatory": "%{field} er påkrevd.",
          "not_found": "'%{value}' ble ikke funnet i referansen. Velg en ny."
        },
        "formula": {
          "result": "Resultat"
        },
        "gps": {
          "save_location": "Lagre beliggenhet",
          "search": "Søk etter sted ...",
          "without_location": "Uten beliggenhet"
        },
        "picture": {
          "edition_only": "Kun tilgjengelig ved redigering",
          "five_pictures_limit_warning": "Kun 5 bilder er maksimalt tillatt",
          "no_image_on_clipboard": "Ingen bildedata ble funnet på utklippstavlen. Kopier et bilde først.",
          "remove_error": "Kunne ikke fjerne",
          "remove_success": "Fjernet suksess",
          "upload_error": "Opplasting mislyktes",
          "upload_success": "Lastet opp Successfuly"
        },
        "placeholder": "Skriv inn %{field} her",
        "value_selector": {
          "apply": "Søke om",
          "done": "Ferdig",
          "search": "Søk på noe her ...",
          "waiting": "... Vente"
        }
      },
      "loading": "Laster inn ...",
      "schedule_entity": "Planlegg %{entity}",
      "schedule_success": "Forespørselen din om å planlegge %{entity} er fullført",
      "show": "Vise fram",
      "subtitle": "Oppgi grunnleggende informasjon om %{entity}.",
      "subtitle_error": "Skjemaet kunne ikke lastes inn, sjekk %{entity}- eller maldataene dine.",
      "update_entity": "Oppdater %{entity}",
      "update_error": "%{entity} kunne ikke oppdateres",
      "update_success": "%{entity} ble oppdatert"
    },
    "formula_modal": {
      "back_button_text": "Tilbake til skjemaet",
      "calculate": "Regne ut",
      "cancel": "Avbryt",
      "mandatory": "Du må fylle ut verdiene for følgende felt: %{fields}",
      "ok": "Lagre formel"
    },
    "grid": {
      "all": "Alle",
      "clear_filters": "Fjern filtre",
      "edit_mode": "Redigeringsmodus",
      "editable": {
        "error": {
          "mandatory": "Minst ett obligatorisk felt var tomt"
        },
        "title": "Redigeringsmodus",
        "update": {
          "error": "%{quantity} kunne ikke lagres. Sjekk årsaken på venstre side av hver uthevet rad",
          "success": "%{quantity} poster ble lagret"
        }
      },
      "empty_after_filtering": "Det er ingen poster for de anvendte filtrene",
      "grid_filters": {
        "menu_title": "Egendefinerte filtre"
      },
      "menu": {
        "visibility": "Kolonner"
      },
      "of": "av",
      "other": "Andre",
      "productivity_factor": "Produktivitetsfaktor",
      "select_items": "Velg elementer",
      "selected": "valgt",
      "selecteds": "utvalgte",
      "totals": "Total"
    },
    "history_popup": {
      "back": "Tilbake",
      "comments": "Kommentarer",
      "crew_size": "Mannskap størrelse",
      "daily_hours": "Daglige timer",
      "date": "Dato",
      "form_create_progress_service_title": "Opprett ytelsestjenestevarehistorikk",
      "form_create_progress_title": "Lag fremdriftshistorikk",
      "form_edit_progress_service_title": "Rediger ytelsestjenestevarehistorikk",
      "form_edit_progress_title": "Rediger historikk for fremdriftselementer",
      "form_error_body": "Alle obligatoriske felt på skjemaet må fylles ut.",
      "form_error_title": "Det var ikke mulig å lagre historikkskjemaet",
      "form_subtitle": "Sett inn en historie etter antall eller prosent for aktiviteten din.",
      "modal_create_title": "Lag historie",
      "modal_edit_title": "Rediger historie",
      "number_of_crews": "Antall mannskaper",
      "number_of_workers": "Antall arbeidere",
      "other_value": "Annen verdi",
      "percentage": "Prosentdel",
      "progress_by": "Fremgang av",
      "quantity": "Mengde",
      "singular": "Historie",
      "working_hours": "Arbeidstid"
    },
    "home": {
      "available_on": "tilgjengelig på",
      "banner_description": "Administrer oppgaver, følg prosesser og få de beste resultatene ved hjelp av en enkelt plataform",
      "create_request": {
        "text": "Først og fremst må du opprette en forespørsel fra klienten i DPMS med detaljene om bestillingen din.",
        "title": "Lag en forespørsel"
      },
      "dpms_mobile": "DPMS mobilapp",
      "getting_started": "Starter",
      "home_title": "Hjem",
      "inspection": {
        "text": "Gjennomføre kvalitetsinspeksjoner i felt og registrere hindringer.",
        "title": "Undersøkelse"
      },
      "performance": {
        "text": "Registrer fremgang og tid basert på de planlagte aktivitetene til hvert lag, og mål produktiviteten.",
        "title": "Opptreden"
      },
      "scoping": {
        "text": "Omfang en forespørsel fra klienten. Tilordne tjenester og servicepakker fra kontrakten din.",
        "title": "Omfang"
      },
      "welcome_user": "Velkommen, %{user}"
    },
    "importation_drop": {
      "choose_error": "Velg et element"
    },
    "importation_popup": {
      "file_upload_message": "Klikk eller dra filen til dette området for å laste opp",
      "importation_error": "Du kan se gjennom denne meldingen på jobbsiden.",
      "importation_success": "Importsuksess.",
      "importation_timeout": "Importen tar for lang tid. Sjekk statusen på Jobbsiden.",
      "instructions": "Filene må være .xls og ikke være større enn xxmb. <br> .xls-filen din skal inneholde kolonnen - Dato, klient, prosjekt, oppgave, notater .... rekkefølgen på malen. <br> Dato skal være i - ÅÅÅÅ / MM / DD.",
      "requirements": "Krav",
      "submit_spreadsheet": "Send inn regnearket"
    },
    "inspections": {
      "acceptance": "Godkjennelse",
      "acceptance_modal": {
        "inspection_acceptance": "Godkjenning av inspeksjon",
        "reject": "Avvis",
        "subtitle": "Gi informasjon om inspeksjonen for å fortsette med aksept."
      },
      "actions": {
        "approve": "Vedta",
        "more": "Mer",
        "reject": "Avvis"
      },
      "choose_modal": {
        "schedule": "Planlegg inspeksjonselementer",
        "schedule_description": "Planlegg en eller flere inspeksjoner basert på datointervall",
        "single": "Enkelt inspeksjonsgjenstand",
        "single_description": "Opprettelse av bare én inspeksjon",
        "title": "Opprett en inspeksjon som:"
      },
      "description": "Undersøkelse",
      "every": "Hver",
      "id": "ID",
      "inspect_date": "Inspeksjonsdato",
      "inspect_status_id": "Status",
      "inspected": "Inspisert",
      "inspected_detail": "Inspisert detalj",
      "inspected_modules": {
        "progress": "Opptreden",
        "request": "Be om",
        "scope": "Omfang"
      },
      "inspections_detail": {
        "side_panel_title": "Inspeksjonens detaljer"
      },
      "module": "Modul",
      "notifications": {
        "approve_successful": "Inspeksjonen ble vellykket godkjent",
        "reject_successful": "Inspeksjonen ble avvist"
      },
      "recurrency_interval": "Gjentaksintervall",
      "repeat": "Gjenta",
      "repeat_every_value": {
        "day": "Dag",
        "month": "Måned",
        "week": "Uke",
        "year": "År"
      },
      "repeats_on": "Gjenta på",
      "request_id": "DPMSID",
      "responsible_id": "Ansvarlig",
      "schedule_detail": "Tidsplandetaljer",
      "schedule_form": {
        "acceptance_step": "Definer aksept (valgfritt)",
        "detailed_item_step": "Velg Inspeksjonsomfang / ytelseselement",
        "detailed_template_step": "Velg Inspeksjonsomfang / ytelsesmal",
        "further_step_information": "De fleksible feltene er klare til å fylles ut hver for seg etter trinnet for gjentakelse",
        "general_step": "Definer inspeksjon Generell informasjon",
        "hints": "For månedlige og årlige gjentakelser vil systemet planlegge inspeksjonen som samsvarer med startdatoen gitt i intervallfeltet",
        "monthly_hint": "Gjenta månedlig hver dag %{day}",
        "recurrence_step": "Tilbakefall"
      },
      "scheduled_exception": "planlagt",
      "statuses": {
        "approved": "Godkjent",
        "completed": "Venter på godkjenning",
        "pending": "I påvente av",
        "progress": "I prosess",
        "rejected": "Avvist"
      },
      "template": "Velg Mal",
      "title": "inspeksjoner"
    },
    "inspects": {
      "statuses": {
        "approved": "Godkjent",
        "completed": "Venter på godkjenning",
        "pending": "I påvente av",
        "progress": "I prosess",
        "rejected": "Avvist"
      }
    },
    "kendo": {
      "upload": "Klikk, dra eller lim inn filer her for å laste opp"
    },
    "kendo_translations": {
      "calendar": {
        "today": "I dag"
      },
      "dateinput": {
        "decrement": "Reduser verdien",
        "increment": "Øk verdien"
      },
      "datepicker": {
        "toggleCalendar": "Bytt kalender"
      },
      "daterangepicker": {
        "end": "Slutt",
        "separator": 0,
        "start": "Start",
        "swapStartEnd": "Bytt start- og sluttverdier"
      },
      "datetimepicker": {
        "cancel": "Avbryt",
        "date": "Dato",
        "set": "Sett",
        "time": "Tid",
        "toggleDateTimeSelector": "Bytt dato- og tidvelger"
      },
      "dropdowns": {
        "clear": "Klar",
        "nodata": "Ingen Data Funnet"
      },
      "editor": {
        "addColumnAfter": "Legg til kolonne til høyre",
        "addColumnBefore": "Legg til kolonne til venstre",
        "addRowAfter": "Legg til rad nedenfor",
        "addRowBefore": "Legg til rad over",
        "alignCenter": "Sentertekst",
        "alignJustify": "Rettferdiggjøre",
        "alignLeft": "Juster teksten igjen",
        "alignRight": "Juster teksten til høyre",
        "bold": "Modig",
        "bulletList": "Sett inn uordnet liste",
        "createTable": "Lag et bord",
        "deleteColumn": "Slett kolonne",
        "deleteRow": "Slett rad",
        "deleteTable": "Slett tabellen",
        "fontName": "Skriftnavn",
        "fontSize": "Skriftstørrelse",
        "format": "Format",
        "hyperlink": "Sett inn hyperkobling",
        "hyperlink-dialog-cancel": "Avbryt",
        "hyperlink-dialog-content-address": "nettadresse",
        "hyperlink-dialog-content-newwindow": "Åpne lenke i nytt vindu",
        "hyperlink-dialog-content-title": "Tittel",
        "hyperlink-dialog-insert": "Sett inn",
        "hyperlink-dialog-title": "Sett inn hyperkobling",
        "image": "Sett inn bilde",
        "image-address": "nettadresse",
        "image-altText": "Alternativ tekst",
        "image-cancel": "Avbryt",
        "image-dialog-title": "Sett inn bilde",
        "image-height": "Høyde (px)",
        "image-insert": "Sett inn",
        "image-title": "Tittel",
        "image-width": "Bredde (px)",
        "indent": "Innrykk",
        "insertTableHint": "Lag en {0} x {1} tabell",
        "italic": "Kursiv",
        "mergeCells": "Slå sammen celler",
        "orderedList": "Sett inn bestilt liste",
        "outdent": "Utelukket",
        "redo": "Gjøre om",
        "splitCell": "Delt celle",
        "strikethrough": "Gjennomtrenging",
        "subscript": "Abonnement",
        "superscript": "Overskrift",
        "underline": "Understreke",
        "undo": "Angre",
        "unlink": "Fjern hyperkobling",
        "viewHtml": "Vis HTML",
        "viewHtml-cancel": "Avbryt",
        "viewHtml-dialog-title": "Vis HTML",
        "viewHtml-update": "Oppdater"
      },
      "filter": {
        "addExpression": "Legg til uttrykk",
        "addGroup": "Legg til gruppe",
        "afterOperator": "Er ute etter",
        "afterOrEqualOperator": "Er etter eller lik",
        "andLogic": "Og",
        "beforeOperator": "Er før",
        "beforeOrEqualOperator": "Er før eller lik",
        "close": "Lukk",
        "containsOperator": "Inneholder",
        "endsWithOperator": "Slutter med",
        "eqOperator": "Er lik",
        "gtOperator": "Er større enn",
        "gteOperator": "Er større enn eller lik",
        "isEmptyOperator": "Er tom",
        "isFalse": "Er falsk",
        "isNotEmptyOperator": "Er ikke tom",
        "isNotNullOperator": "Er ikke null",
        "isNullOperator": "Er null",
        "isTrue": "Er sant",
        "ltOperator": "Er mindre enn",
        "lteOperator": "Er mindre enn eller lik",
        "notContainsOperator": "Inneholder ikke",
        "notEqOperator": "Er ikke lik",
        "orLogic": "Eller",
        "startsWithOperator": "Begynner med"
      },
      "grid": {
        "filterAfterOperator": "Er ute etter",
        "filterAfterOrEqualOperator": "Er etter eller lik",
        "filterBeforeOperator": "Er før",
        "filterBeforeOrEqualOperator": "Er før eller lik",
        "filterBetweenOperator": "Er mellom",
        "filterBooleanAll": "(Alle)",
        "filterClearButton": "Klar",
        "filterContainsOperator": "Inneholder",
        "filterEndsWithOperator": "Slutter med",
        "filterEqOperator": "Er lik",
        "filterGtOperator": "Er større enn",
        "filterGteOperator": "Er større enn eller lik",
        "filterIsEmptyOperator": "Er tom",
        "filterIsFalse": "Er falsk",
        "filterIsNotEmptyOperator": "Er ikke tom",
        "filterIsNotNullOperator": "Er ikke null",
        "filterIsNullOperator": "Er null",
        "filterIsTrue": "Er sant",
        "filterLtOperator": "Er mindre enn",
        "filterLteOperator": "Er mindre enn eller lik",
        "filterNotContainsOperator": "Inneholder ikke",
        "filterNotEqOperator": "Er ikke lik",
        "filterStartsWithOperator": "Begynner med",
        "filterSubmitButton": "Filter",
        "filterTitle": "Filter",
        "groupPanelEmpty": "Dra en kolonneoverskrift og slipp den her for å gruppere etter den kolonnen",
        "noRecords": "Ingen poster tilgjengelig.",
        "pagerFirstPage": "Gå til første side",
        "pagerInfo": "{0} - {1} av {2} elementer",
        "pagerItemsPerPage": "elementer per side",
        "pagerLastPage": "Gå til siste side",
        "pagerNextPage": "Gå til neste side",
        "pagerOf": "av",
        "pagerPage": "Side",
        "pagerPreviousPage": "Gå til forrige side"
      },
      "numerictextbox": {
        "decrement": "Reduser verdien",
        "increment": "Øk verdien"
      },
      "pager": {
        "firstPage": "Gå til første side",
        "info": "{0} - {1} av {2} elementer",
        "itemsPerPage": "elementer per side",
        "lastPage": "Gå til siste side",
        "nextPage": "Gå til neste side",
        "of": "av",
        "page": "Side",
        "previousPage": "Gå til forrige side"
      },
      "scheduler": {
        "agendaViewTitle": "Dagsorden",
        "allDay": "hele dagen",
        "allEvents": "Alle arrangementer",
        "dateTitle": "Dato",
        "dayViewTitle": "Dag",
        "deleteConfirmation": "Er du sikker på at du vil slette denne hendelsen?",
        "deleteDialogTitle": "Slett hendelse",
        "deleteOccurrence": "Slett nåværende forekomst",
        "deleteRecurringConfirmation": "Vil du bare slette denne hendelsen eller hele serien?",
        "deleteRecurringDialogTitle": "Slett tilbakevendende element",
        "deleteSeries": "Slett serien",
        "deleteTitle": "Slett",
        "editorCancel": "Avbryt",
        "editorDelete": "Slett",
        "editorEventAllDay": "Heldags hendelse",
        "editorEventDescription": "Beskrivelse",
        "editorEventEnd": "Slutt",
        "editorEventEndTimeZone": "Slutt tidssone",
        "editorEventSeparateTimeZones": "Avslutt i en annen tidssone",
        "editorEventStart": "Start",
        "editorEventStartTimeZone": "Start tidssone",
        "editorEventTimeZone": "Spesifiser tidssone",
        "editorEventTitle": "Tittel",
        "editorOccurrence": "Rediger gjeldende forekomst",
        "editorRecurringConfirmation": "Vil du redigere bare denne hendelsesforekomsten eller hele serien?",
        "editorRecurringDialogTitle": "Rediger tilbakevendende element",
        "editorSave": "Lagre",
        "editorSeries": "Rediger serien",
        "editorTitle": "Begivenhet",
        "editorValidationEnd": "Sluttid må være etter starttid.",
        "editorValidationRequired": "Feltet er påkrevet.",
        "editorValidationStart": "Starttid må være før sluttid.",
        "eventTitle": "Begivenhet",
        "monthViewTitle": "Måned",
        "nextTitle": "Neste",
        "noEvents": "ingen hendelser",
        "previousTitle": "Tidligere",
        "recurrenceEditorDailyInterval": "dager)",
        "recurrenceEditorDailyRepeatEvery": "Gjenta hver",
        "recurrenceEditorEndAfter": "Etter",
        "recurrenceEditorEndLabel": "Slutt",
        "recurrenceEditorEndNever": "Aldri",
        "recurrenceEditorEndOccurrence": "forekomst (er)",
        "recurrenceEditorEndOn": "På",
        "recurrenceEditorFrequenciesDaily": "Daglig",
        "recurrenceEditorFrequenciesMonthly": "Månedlig",
        "recurrenceEditorFrequenciesNever": "Aldri",
        "recurrenceEditorFrequenciesWeekly": "Ukentlig",
        "recurrenceEditorFrequenciesYearly": "Årlig",
        "recurrenceEditorMonthlyDay": "Dag",
        "recurrenceEditorMonthlyInterval": "måneder)",
        "recurrenceEditorMonthlyRepeatEvery": "Gjenta hver",
        "recurrenceEditorMonthlyRepeatOn": "Gjenta på",
        "recurrenceEditorOffsetPositionsFirst": "Først",
        "recurrenceEditorOffsetPositionsFourth": "Fjerde",
        "recurrenceEditorOffsetPositionsLast": "Siste",
        "recurrenceEditorOffsetPositionsSecond": "Sekund",
        "recurrenceEditorOffsetPositionsThird": "Tredje",
        "recurrenceEditorRepeat": "Gjenta",
        "recurrenceEditorWeekdaysDay": "Dag",
        "recurrenceEditorWeekdaysWeekday": "Ukedag",
        "recurrenceEditorWeekdaysWeekendday": "Helgedag",
        "recurrenceEditorWeeklyInterval": "uke (r)",
        "recurrenceEditorWeeklyRepeatEvery": "Gjenta hver",
        "recurrenceEditorWeeklyRepeatOn": "Gjenta på",
        "recurrenceEditorYearlyInterval": "år",
        "recurrenceEditorYearlyOf": "av",
        "recurrenceEditorYearlyRepeatEvery": "Gjenta hver",
        "recurrenceEditorYearlyRepeatOn": "Gjenta på",
        "showFullDay": "Vis hele dagen",
        "showWorkDay": "Vis åpningstider",
        "timeTitle": "Tid",
        "timelineViewTitle": "Tidslinje",
        "today": "I dag",
        "weekViewTitle": "Uke",
        "workWeekViewTitle": "Arbeidsuke"
      },
      "sortable": {
        "noData": "Ingen data"
      },
      "timepicker": {
        "cancel": "Avbryt",
        "now": "NÅ",
        "selectNow": "Velg nå",
        "set": "Sett",
        "toggleClock": "Veksle klokke",
        "toggleTimeSelector": "Bytt TimeSelector"
      },
      "treelist": {
        "filterAfterOperator": "Er ute etter",
        "filterAfterOrEqualOperator": "Er etter eller lik",
        "filterBeforeOperator": "Er før",
        "filterBeforeOrEqualOperator": "Er før eller lik",
        "filterBooleanAll": "(Alle)",
        "filterClearButton": "Klar",
        "filterContainsOperator": "Inneholder",
        "filterEndsWithOperator": "Slutter med",
        "filterEqOperator": "Er lik",
        "filterGtOperator": "Er større enn",
        "filterGteOperator": "Er større enn eller lik",
        "filterIsEmptyOperator": "Er tom",
        "filterIsFalse": "Er falsk",
        "filterIsNotEmptyOperator": "Er ikke tom",
        "filterIsNotNullOperator": "Er ikke null",
        "filterIsNullOperator": "Er null",
        "filterIsTrue": "Er sant",
        "filterLtOperator": "Er mindre enn",
        "filterLteOperator": "Er mindre enn eller lik",
        "filterNotContainsOperator": "Inneholder ikke",
        "filterNotEqOperator": "Er ikke lik",
        "filterStartsWithOperator": "Begynner med",
        "noRecords": "Ingen poster tilgjengelig"
      },
      "upload": {
        "cancel": "Avbryt",
        "clearSelectedFiles": "Klar",
        "dropFilesHere": "eller slipp filen her",
        "files": "filer",
        "headerStatusUploaded": "Ferdig",
        "headerStatusUploading": "Laster opp ...",
        "invalidFileExtension": "Filtype er ikke tillatt.",
        "invalidFiles": "Ugyldig fil (er). Kontroller kravene til filopplasting.",
        "invalidMaxFileSize": "Filstørrelsen er for stor.",
        "invalidMinFileSize": "Filstørrelsen er for liten.",
        "remove": "Ta vekk",
        "retry": "Prøv på nytt",
        "select": "Velg Fil",
        "total": "Total",
        "uploadSelectedFiles": "Laste opp"
      }
    },
    "loading_batch_entities": {
      "page_of": "Side %{page} av %{total}",
      "title": "Henter sider"
    },
    "loading_batch_modal": {
      "page_of": "Side %{page} av %{total}",
      "title": "Henter sider"
    },
    "login": {
      "again": "For å fortsette, vennligst logg inn igjen",
      "continue_mykaefer": "Fortsett med MyKaefer",
      "copyright": "®%{year} Med enerett. Kaefer®",
      "forgot_password": "Glemt passord?",
      "iframe_info": "Ber om tilgang til siden: %{link}",
      "log_in": "Logg Inn",
      "password": "Passord",
      "title_1": "Et kraftig verktøy",
      "title_2": "for prosjektledelse",
      "username": "Brukernavn",
      "welcome_back": "Velkommen tilbake"
    },
    "main_dialog": {
      "failure_message": "Forespørselen din kunne ikke fullføres",
      "failure_title": "Failure",
      "loading_message": "Vent litt mens vi setter opp ting for deg!",
      "loading_title": "Laster ...",
      "service_title": "Venter på at jobben skal fullføres...",
      "success_message": "Forespørselen din har lyktes! Du kan nå fortsette jobben.",
      "success_title": "Vellykkede!"
    },
    "notification": {
      "access_denied": "Ingen tilgang",
      "action_success": "%{model} %{id} var %{action}",
      "bad_formula_setup": "Det er et oppsettproblem med formelen '%{title}', sjekk oppsettet ditt!",
      "correct_subproject": "Velg riktig delprosjekt for dette elementet",
      "dismiss_all": "Avvis alle",
      "error": "Feil",
      "error_cookies": "Vi klarte ikke å etablere kommunikasjon mellom kryss kilder. Gjennomgå innstillingene for informasjonskapsler i nettleseren din og deaktiver 'Tredjeparts cookies-blokkering'.",
      "error_job": "Mislyktes! Sjekk årsaken på Jobsiden.",
      "error_msg": "Unnskyld! Noe gikk galt!",
      "info": "Info",
      "large_file_body": "Bruk noen filtre for å gjøre utdatafilen mindre.",
      "large_file_title": "Rutenettet har mer enn 1000 poster",
      "page_denied": "Siden kunne ikke lastes inn.",
      "please": "Vær så snill",
      "select_subproject": "Velg et prosjekt og delprosjekt for å fortsette",
      "success": "Suksess",
      "success_msg": "Handling utført med suksess!",
      "warning": "Advarsel"
    },
    "orders": {
      "add_evidence": "Legg til bevis på klientautorisasjonen",
      "attachment": "Vedlegg",
      "comments": "Kommentarer",
      "date": "Dato",
      "drag_and_drop": "Legg til filer ved å dra og slippe",
      "responsible": "Ansvarlig",
      "title": "Bestillinger"
    },
    "performance": {
      "title": "Opptreden"
    },
    "performances": {
      "title": "Fremførelser"
    },
    "performances_wip": {
      "performance_item": "Ytelses Element",
      "title": "Opptreden"
    },
    "planning": {
      "item_no": "Varenr.",
      "service_no": "Tjeneste nr.",
      "title": "Planlegger",
      "view": "Planleggingsvisning"
    },
    "plannings": {
      "title": "Planlegger"
    },
    "progress_histories": {
      "add_new_progress": "Legg til en ny fremgang",
      "empty_historic": "Det er ingen historieelement og det er ikke mulig å lage historier når antallet er null",
      "hours": "Timer",
      "production_factor": "PF",
      "progress": "Framgang",
      "progress_badge": "Vare %{number}",
      "progress_history": "Fremgangshistorie",
      "progress_service_badge": "Tjeneste %{number}",
      "progress_service_history": "Tjenestehistorikk",
      "progress_service_title": "Tjenestehistorier",
      "progress_title": "Varehistorier",
      "quantity": "Mengde",
      "subtitle": "Administrer daglig fremgang basert på aktiviteter som er planlagt av teamet ditt."
    },
    "progress_services": {
      "actual_div_hours": "Faktiske / lagmåltid",
      "actual_div_quantity": "Faktisk / målmengde",
      "actual_hours": "Faktiske timer",
      "actual_quantity": "Faktisk mengde",
      "application_price": "Søknadspris",
      "back": "Alle forestillinger",
      "budget_target_hours": "Budsjettmåltimer",
      "canceled": "Avlyst",
      "choose_modal": {
        "package": "Tjenester fra pakke",
        "package_description": "Oppretting av flere tjenester basert på samme aktivitetsmodell",
        "single": "Enkelte serviceelementer",
        "single_description": "Opprettelse av bare en tjeneste",
        "title": "Lag ny"
      },
      "contract_service": "Kontraktservice",
      "contribution": "Bidrag",
      "contribution_info": "Verdier beregnes automatisk",
      "crew": "mannskap",
      "crew_size": "Mannskapsstørrelse",
      "crews": "mannskaper",
      "date_filter": {
        "all": "Alle",
        "today": "I dag",
        "tomorrow": "I morgen",
        "week": "Uke",
        "yesterday": "I går"
      },
      "delta_hours": "Delta Hours",
      "delta_qtt": "Delta Mengde",
      "description": "Ytelsestjeneste",
      "discipline": "Disiplin",
      "end_date": "Sluttdato",
      "equipment_price": "Utstyrspris",
      "factor": "Faktor",
      "flag": "Flagg",
      "flag_filter": "Flaggfilter",
      "flag_filters": "Flaggfiltre",
      "id": "ID",
      "materials_price": "Materialpris",
      "no_crews": "Antall mannskaper",
      "no_workers": "Antall arbeidere",
      "norm_hours": "Normtimer",
      "not_planned": "Ikke planlagt",
      "other_price": "Annen pris",
      "package_factor": "Faktor",
      "people": "mennesker",
      "person": "person",
      "pf": "PF",
      "pf_doing_bad": "Du har det ikke bra",
      "pf_doing_good": "Du har det kjempebra!",
      "pf_doing_neutral": "Kunne ikke beregne pf",
      "planned": "Planlagt",
      "planning_chart": {
        "actual": "Faktiske",
        "actual_qty": "Faktisk Antall",
        "date": "Dato",
        "forecasted": "Værvarsel",
        "forecasted_qty": "Forventet antall",
        "target": "Mål",
        "target_qty": "Antall mål",
        "title": "Planleggingsdiagram"
      },
      "planning_timeline": {
        "day": "dag",
        "day_advance": "dag i forveien",
        "day_delayed": "dag forsinket",
        "days": "dager",
        "days_advance": "dager i forveien",
        "days_delayed": "dager forsinket",
        "end_date": "Sluttdato",
        "forecasted": "Værvarsel",
        "on_time": "På tide",
        "remaining_days": "Gjenværende dager",
        "start_date": "Startdato",
        "title": "Planlegging av tidslinje"
      },
      "progress": "Framgang",
      "progress_services_detail": {
        "side_panel_title": "Tjenestens detaljer"
      },
      "quantity": "Mengde",
      "responsible": "Ansvarlig",
      "scoped_quantity": "Omfattet mengde",
      "service": "Service",
      "service_package": "Servicepakke",
      "service_planning": "Tjenesteplanlegging",
      "service_progress": "Tjenesteframskritt",
      "service_weight": "Tjenestevekt",
      "start_date": "Startdato",
      "statuses": {
        "canceled": "Avbrutt",
        "done": "Fullført",
        "extra": "Ekstra",
        "in_planning": "Ikke planlagt",
        "planned": "Planlagt"
      },
      "team_target_hours": "Lagets måltimer",
      "title": "Fremgangstjenester",
      "title_packageable": "Ytelsestjenester etter tjenestepakke",
      "total": "Total",
      "total_hours": "Totalt antall timer",
      "unit": "Enhet",
      "worker": "arbeider",
      "workers": "arbeidere",
      "working_hours": "Arbeidstid"
    },
    "progresses": {
      "cancel": "Avbryt",
      "choose_modal": {
        "single": "Nok et fremdriftselement",
        "single_description": "Opprettelse av bare ett fremdriftselement"
      },
      "complete": "Fullstendig",
      "delta_hours": "∆ Timer",
      "delta_qty": "Antall",
      "description": "Beskrivelse",
      "extra": "Ekstra",
      "flag": "Flagg",
      "histories": {
        "item": "Vare %{number}",
        "item_title": "Varehistorier",
        "progress_history": "Fremgangshistorie",
        "service": "Tjeneste %{number}",
        "service_history": "Tjenestehistorikk",
        "service_title": "Tjenestehistorier",
        "subtitle": "Administrer daglig fremgang basert på aktiviteter som er planlagt av teamet ditt."
      },
      "history": "Historie",
      "id": "ID",
      "item": "Punkt",
      "label": "Merkelapp",
      "pf": "PF",
      "progress": "Framgang",
      "progresses_detail": {
        "side_panel_title": "Ytelsesdetaljer"
      },
      "request_status_restriction": "Forespørselen skal ha status i planlegging eller i ytelse",
      "revised": "Revidert",
      "title": "Opptreden"
    },
    "projects": {
      "workdays": {
        "fri": "Fre",
        "mon": "Man",
        "sat": "Lør",
        "sun": "Søn",
        "thu": "Tor",
        "tue": "Tir",
        "wed": "Ons"
      }
    },
    "requests": {
      "actual_hours": "Faktiske timer",
      "actual_progress": "Faktisk fremgang",
      "all": "Alle",
      "are_you_sure_complete": "Er du sikker på å fullføre denne forespørselen?",
      "are_you_sure_complete_no_history": "Er du sikker på å fullføre en forespørsel uten noen gitt fremdrift?",
      "are_you_sure_deletion": "Er du sikker på at du vil slette forespørselen",
      "are_you_sure_revertion": "Er du sikker på at du vil snu og dermed miste dataene som ble lagt inn i denne modulen",
      "area": "Område",
      "authorization_pending": "Tillatelse venter",
      "authorize_form": {
        "moal_title": "Autorisasjon",
        "modal_title": "Autorisasjon",
        "subtitle": "Gi litt informasjon om forespørselen om å fortsette med godkjenning",
        "title": "Autoriser forespørsel"
      },
      "canceled": "avbrutt",
      "clustered": "gruppert",
      "comments": "kommentarer",
      "confirmation_modal": {
        "approve_description": "Forespørselen blir godkjent",
        "approve_title": "Godkjen forespørsel?",
        "cancel_description": "Forespørselen blir kansellert",
        "cancel_title": "Avbryt forespørsel?",
        "complete_description": "Denne forespørselen blir fullført",
        "complete_title": "Fullstendig forespørsel?",
        "confirm_action": "Fortsett med å importere omfang",
        "confirm_cancel": "Ikke nå",
        "confirm_confirmation": "Ja, jeg vil bekrefte",
        "confirm_description": "Vil du bekrefte forespørselen?",
        "confirm_title": "Be om bekreftelse",
        "delete_description": "Fjerningshandlingen kan ikke angres. Er du sikker?",
        "delete_title": "Slett forespørsel?",
        "reject_description": "Denne varen vil gå tilbake til statusen Scoping",
        "reject_title": "Avvis ordre?",
        "revert_description": "Tilbakestillingen kan ikke angres. Er du sikker?",
        "revert_title": "Tilbakestill ordren?"
      },
      "confirmed": "bekreftet",
      "creation_form": {
        "proceed": "Fortsett til trinn 2"
      },
      "creation_page": {
        "add_template": "Legg til mal",
        "first_step": "Opprett forespørsel",
        "second_step": "Importer omfang (valgfritt)",
        "third_step": "Bruk servicepakker (valgfritt)"
      },
      "description": "Be om",
      "discipline": "Disiplin",
      "done": "Ferdig",
      "eav_template": "Mal",
      "eav_template_id": "Mal-ID",
      "edit_dialog": {
        "reason": "Underforespørsel navn"
      },
      "elevation": "Høyde",
      "errors": {
        "not_found": "Kan ikke finne Forespørsel med id: %{id}"
      },
      "estimates_authorization": "Estimatets autorisasjon kreves",
      "forecast_end_date": "Prognosert sluttdato",
      "id": "DPMSID",
      "in_performance": "I ytelse",
      "in_planning": "I planlegging",
      "in_scoping": "I omfanget",
      "information": "Tilleggsinformasjon",
      "notification": {
        "authorization_success": "Forespørselen er godkjent",
        "deleting_sub_request": "Underforespørselen %{id} blir slettet. Vennligst sjekk jobbsiden for å lære om fremdriften",
        "missing_order_error": "Det er ingen ventende ordre knyttet til denne forespørselen"
      },
      "on_hold": "På vent",
      "progress_end_date": "Fremskritt sluttdato",
      "progress_responsible": "Ansvarlig for fremdrift",
      "progress_responsible_id": "Ansvarlig for fremdrift",
      "progress_start_date": "Fremskritt Startdato",
      "reason": "Henvisning",
      "reference": "Referanse",
      "registered": "registrert",
      "request_date": "Dato for forespørsel",
      "request_detail": {
        "edit": "Å redigere",
        "estimates": "estimater",
        "inspections": "inspeksjoner",
        "performance": "Opptreden",
        "planning": "Planlegger",
        "scopes": "Omfang",
        "show": "Showet",
        "side_panel_title": "Forespørsel detaljer"
      },
      "request_end_date": "Forespørsel sluttdato",
      "request_responsible": "Ansvarlig for forespørsel",
      "request_responsible_id": "Ansvarlig for forespørsel",
      "request_start_date": "Forespørsel Startdato",
      "schedule": "Forespørselsplan",
      "schedule_form": {
        "date": "Dato",
        "days": "%{number} dager",
        "performance": "Opptreden",
        "progress": "Framgang",
        "request": "Be om",
        "responsible": "Ansvarlig",
        "schedule": "Rute",
        "scoping": "Omfang",
        "subtitle": "For å lage en tidsplan for denne forespørselen trenger vi først litt tilleggsinformasjon.",
        "title": "Be om tidsplan"
      },
      "scope_end_date": "Omfang av sluttdato",
      "scope_responsible": "Ansvarlig for scoping",
      "scope_responsible_id": "Ansvarlig for scoping",
      "scope_start_date": "Omfang av startdato",
      "scopes": "Omfang",
      "service_desired_date": "Ønsket dato",
      "skip_scoping": "Hopp over omfangsfase",
      "status": "Status",
      "statuses": {
        "authorization_pending": "Avventer",
        "canceled": "Avbrutt",
        "clustered": "Gruppert",
        "confirmed": "I Scoping",
        "deleting": "Sletter",
        "done": "Ferdig",
        "in_performance": "I ytelse",
        "in_planning": "I ytelse",
        "in_scoping": "I Scoping",
        "on_hold": "På vent",
        "registered": "Laget"
      },
      "sub_request": "Underforespørsel",
      "sub_requests": "Underforespørselener",
      "subarea": "Delområde",
      "team_target_hours": "Team Måltider",
      "title": "Forespørsler"
    },
    "scopes": {
      "add": "Legg til omfangsobjekt",
      "choose_modal": {
        "single": "En annen omfangsartikkel",
        "single_description": "Opprettelse av bare ett avgrensningselementer"
      },
      "clusters": {
        "title": "Omfangsklynger"
      },
      "count": "Telle",
      "crew_size": "Mannskap størrelse",
      "crews": "Antall mannskaper",
      "date": "Dato",
      "description": "omfang",
      "hours": "Timer (h)",
      "id": "ID",
      "item": "Punkt",
      "label": "Merkelapp",
      "label_assigner": {
        "success": "Scopes-etikettene dine har blitt tildelt! Du kan fortsette planen din nå."
      },
      "label_error": "Kunne ikke hente etiketter",
      "popup_label": {
        "create_label": "Lag etikett",
        "labels": "etiketter:",
        "name_label": "Navn på etikett",
        "new_label": "Ny etikett"
      },
      "quantity": "Kvantitet",
      "remaining_hours": "Gjenværende Timer",
      "remaining_quantity": "Gjenværende Kvantitet",
      "request_status_restriction": "Forespørselen skal ha status bekreftet eller i omfang",
      "scopes_detail": {
        "side_panel_title": "Omfangets detaljer"
      },
      "title": "Omfang",
      "unit": "Enhet",
      "working_hours": "Arbeidstid"
    },
    "search": {
      "loading": "lasting...",
      "no_result_found": "Ingen resultater funnet",
      "placeholder": "Filter..."
    },
    "settings": {
      "title": "Innstillinger"
    },
    "settings_shortcuts": "Snarveier for innstillinger",
    "shortcuts": {
      "access_control": "Adgangskontroll",
      "areas": "Områder og delområder",
      "areas_subareas": "Områder og delområder",
      "assets": "Eiendeler",
      "background_jobs": "Bakgrunnsjobber",
      "branches": "Grener",
      "cities": "Byer",
      "client_sites": "Klientnettsteder",
      "clients": "Kunder",
      "clients_info": "Klienter Info",
      "columns_renaming": "Gi nytt navn til kolonner",
      "companies": "Bedrifter",
      "contract_services": "Kontrakttjenester",
      "contracts": "Kontrakter",
      "countries": "Land",
      "customer_sites": "Klientnettsteder",
      "customers": "Kunder",
      "disciplines": "Disipliner",
      "employees": "Personale",
      "equipaments": "Utstyr",
      "equipments": "Utstyr",
      "formulas": "Formler",
      "location": "plassering",
      "materials": "Materialer",
      "matrices": "Matriser",
      "measures": "målinger",
      "mobile_details": "Mobildetaljer",
      "position_names": "Posisjonsnavn",
      "preferences": "Preferanser",
      "project_assets": "Eiendeler",
      "project_contracts": "Kontrakter",
      "projects": "Prosjekter og delprosjekter",
      "projects_contracts": "Prosjekter og kontrakter",
      "projects_subprojects": "Prosjekter og delprosjekter",
      "provinces": "Stater / provinser",
      "regions": "Regioner",
      "routes": "Ruter",
      "specialities": "Spesialiteter",
      "staff": "Personale",
      "states_provinces": "Stater / provinser",
      "translations": "Endre navn på kolonne",
      "users": "Brukere",
      "visible_fields": "Mobildetaljer",
      "work_positions": "Posisjonsnavn"
    },
    "side_menu": {
      "contracts": "Kontraktsavtale",
      "datasheets": "Datablad",
      "new": "Ny",
      "report": "Rapportere",
      "search": "Søk",
      "settings": "innstillinger",
      "template": {
        "asset": "Eiendeler",
        "contract_service": "Kontrakttjenester",
        "datasheet": "Dataark",
        "estimate_service": "Anslå tjenester",
        "inspection": "Inspeksjoner",
        "performance": "Opptreden",
        "report": "Rapporter",
        "request": "Forespørsler",
        "scoping": "Omfang",
        "valuation": "Verdsettelse"
      },
      "templates": "maler"
    },
    "sub_requests": {
      "creation_page": {
        "all": "Alle underforespørsler",
        "back": "Alle omfang",
        "title": "underforespørsler"
      },
      "left_panel": {
        "add_scoping": "Legg til omfangsobjekt",
        "allocation": "Tildeling",
        "cards_header_title": "Omfang",
        "summary": "Sammendrag",
        "total_allocation": "Total tildeling"
      },
      "scope_card": {
        "scope": "omfang"
      }
    },
    "tabs": {
      "error": "Det er umulig å laste inn faner"
    },
    "templates": {
      "asset": "Eiendeler",
      "contract_service": "Kontrakttjenester",
      "datasheet": "Dataark",
      "estimate_service": "Anslå tjenester",
      "inspection": "Inspeksjoner",
      "no_template": "Du må lage mal",
      "progress": "Opptreden",
      "progress_service": "Verdsettelse",
      "report": "rapporter",
      "request": "Forespørsler",
      "scoping": "Omfang",
      "title": "Maler for %{type}"
    },
    "top_menu": {
      "authorization": "Autorisasjon",
      "estimates": "estimater",
      "inspections": "inspeksjoner",
      "performance": "Opptreden",
      "planning": "Planlegger",
      "requests": "Forespørsler",
      "scoping": "Omfang"
    },
    "track_block": {
      "id": "DPMSID"
    },
    "user_menu": {
      "about_version": "Om versjon",
      "logout": "Logg ut",
      "profile": "Profil"
    },
    "users": {
      "edit_title": "Oppdater profil"
    }
  },
  "pt-BR": {
    "actions": {
      "approve": "Aprovar",
      "bulk_editing": "Edição em massa",
      "cancel": "Cancelar",
      "choose_entity": "Escolha %{entity}",
      "clear": "Limpar",
      "confirm": "Confirmar",
      "create": "Criar",
      "delete": "Excluir",
      "discard": "Descartar",
      "done": "Completar",
      "duplicate": "Duplicar",
      "edit": "Editar",
      "export": "Exportar para .xls",
      "export_template": "Faça o download do modelo .xls",
      "false": "Não",
      "import": "Importar de .xls",
      "import_file": "Importar arquivo",
      "loading": "Carregando",
      "more": "Mais",
      "new": "Novo",
      "next": "Próximo",
      "no": "Não",
      "past": {
        "approve": "aprovado",
        "cancel": "cancelado",
        "complete": "completado",
        "confirm": "confirmado",
        "delete": "apagado",
        "destroy": "removido",
        "reject": "rejeitado",
        "revert": "revertido",
        "update": "atualizado"
      },
      "prev": "Anterior",
      "reject": "Rejeitar",
      "reset": "Redefinir",
      "revert": "Reverter",
      "save": "Salvar",
      "save_button_keyboard": "Salvar (Ctrl + Enter)",
      "save_exit": "Salvar & Sair",
      "saving": "Salvando ...",
      "schedule": "Cronograma",
      "skip": "Pular",
      "true": "Sim",
      "unsaved_changes": "Alterações não salvas",
      "upload_file": "Fazer upload do arquivo",
      "upload_picture": "Carregar foto",
      "yes": "Sim"
    },
    "api_internal_messages": {
      "cant_duplicate_clustered_request": "Não é possível duplicar uma requisição agrupada",
      "cant_duplicate_progress": "Não é possível duplicar os serviços devido aos seguintes erros ...",
      "cant_duplicate_request": "Não é possível duplicar levantamentos, estimativas e serviços devido aos seguintes erros ...",
      "cant_duplicate_scope": "Não é possível duplicar os serviços devido aos seguintes erros ...",
      "only_estimate_service_copy": "Apenas o item Serviço de estimativa está tentando ser copiado.",
      "only_progress_copy": "Apenas o Progresso está tentando ser copiado.",
      "only_request_copy": "Apenas a Requisição está tentando ser copiada.",
      "only_scope_copy": "Apenas o item de definição do levantamento está tentando ser copiado."
    },
    "cancel_modal": {
      "cancel": "Agora não",
      "description": "Tem certeza de que deseja cancelar o %{model} \"%{id}\"?",
      "ok": "Sim cancele",
      "title": "Cancelar item?"
    },
    "clear_records": {
      "modal": {
        "cancel": "Não, cancele",
        "description": "Tem certeza de que deseja limpar todos os registros %{model}?",
        "ok": "Sim, limpar tudo",
        "title": "Limpar %{model}?"
      },
      "success_message": "%{model} apagado com sucesso!",
      "title": "Limpar registros"
    },
    "collections": {
      "clusters": "Agrupamentos",
      "create_by_package": "Criar por pacote",
      "duplicate": "Duplicar",
      "edit": "Editar",
      "estimate": "Estimativa",
      "new": "Novo",
      "schedule": "Cronograma",
      "sub_requests": "Sub-requisições",
      "view": "Visualização"
    },
    "complete_modal": {
      "cancel": "Agora não",
      "description": "Tem certeza de que deseja marcar %{model} como concluído?",
      "ok": "Sim, marque como concluído",
      "title": "Marcar como concluído?"
    },
    "confirmation_modal": {
      "cancel": "Cancelar",
      "description": "Essa ação não pode ser desfeita!",
      "ok": "Está bem",
      "title": "Você tem certeza?"
    },
    "contract_services": {
      "title": "Serviços do contrato"
    },
    "datasheets": {
      "datasheets_detail": {
        "side_panel_title": "Detalhes da Planilha"
      },
      "id": "ID",
      "item": "item",
      "title": "Planilhas"
    },
    "delete_modal": {
      "cancel": "Não, cancelar",
      "description": "Tem certeza que deseja excluir o %{model} \"%{id}\"?",
      "ok": "Sim, excluir",
      "title": "Excluir item?"
    },
    "delete_recurrencies": {
      "modal": {
        "cancel": "Não, cancelar",
        "description": "Tem certeza de que deseja excluir todas as recorrências relacionadas?",
        "ok": "Sim, apagar tudo",
        "title": "Excluir todas as recorrências?"
      },
      "success_message": "Recorrências excluídas com sucesso!",
      "title": "Excluir todas as recorrências"
    },
    "dialogs": {
      "columns_batch_assigner": {
        "actions": "Ações",
        "add_action": "Adicionar ação",
        "add_value_to": "Adicionar %{value} a",
        "column": "Coluna",
        "done": "Feito",
        "header": "Edição em grupo",
        "multiple_success": "%{numberOfItems} itens foram atualizados.",
        "new_value": "Novo valor",
        "single_success": "1 item foi atualizado.",
        "subtitle": "Atribua um valor ao (s) item (s) %{numberOfItems} selecionado (s)",
        "title": "Criar ação",
        "update": "Atualizar"
      }
    },
    "discard_modal": {
      "cancel": "Continuar editando",
      "description": "Tem certeza de que deseja descartar suas alterações?",
      "ok": "Sim, descartar",
      "title": "Descartar mudanças"
    },
    "eav_templates": {
      "title": "Modelos"
    },
    "empty_state": {
      "empty": "Nenhum %{model}",
      "not_created": "Você ainda não criou nenhum %{model}"
    },
    "environment": {
      "project": {
        "select_project": "Selecione Projeto ..."
      },
      "select_project_subproject": "Selecionar projeto e subprojeto",
      "subproject": {
        "select_subproject": "Selecione Subprojeto ..."
      }
    },
    "estimate": {
      "accept": "Aceitar estimativa",
      "application": "Aplicação",
      "back": "Todas as requisições",
      "budget_hours": "Horas do oçamento",
      "confirmation": {
        "cancel": "Não, Cancelar",
        "confirm": "Sim, Confirmar",
        "description": "Tem certeza de que deseja confirmar esta estimativa para esta requisição?",
        "title": "Confirmar pedido"
      },
      "description": "Você tem serviços %{number} para checkout. Verifique os detalhes abaixo.",
      "equipment": "Equipamento",
      "estimate_details": "Detalhes de estimativa",
      "estimate_success": "Estimativa aprovada com sucesso",
      "info_message": "Este serviço de estimativa já foi aprovado. Ele está disponível somente para leitura.",
      "material": "Material",
      "no_estimate_message": "Não há estimativa associada a esta requisição.",
      "norm_hours": "Horas da norma",
      "others": "Outros preços",
      "save_draft": "Salvar rascunho",
      "service_details": "Detalhes do serviço",
      "team_target_hours": "Meta de horas da equipe",
      "title": "Serviço de check-out",
      "tooltip": {
        "application": "Aplicação",
        "equipment": "Equipamento",
        "material": "Material",
        "others": "Outros"
      },
      "total_request": "Pedido Total"
    },
    "estimate_services": {
      "application_price": "Preço da Aplicação",
      "automatically_prices": "Preços calculados automaticamente",
      "back": "Todos levantamentos",
      "budget_target_hours": "Horas de orçamento",
      "choose_modal": {
        "package": "Serviços do pacote",
        "package_description": "Criação de vários serviços com base no mesmo modelo de atividade",
        "single": "Único item de serviço",
        "single_description": "Criação de apenas um serviço",
        "title": "Crie um novo"
      },
      "contract_service": "Serviços do contrato",
      "crew_size": "Tamanho da equipe",
      "crews": "Número de equipes",
      "description": "Serviço",
      "description_label": "Descrição",
      "discipline": "Disciplina",
      "equipment_price": "Preço do Equipamento",
      "estimate": "Estimativa",
      "estimate_services_detail": {
        "side_panel_title": "Detalhes do serviço"
      },
      "factor": "Fator",
      "id": "ID",
      "materials_price": "Preço dos materiais",
      "no_template": "Você precisa criar um template para serviços",
      "norm_hours": "Horas normais",
      "other_price": "Outros preços",
      "prices": "Preços",
      "quantity": "Quantidade",
      "service": "Serviço",
      "service_package": "Pacote de serviço",
      "team_target_hours": "Meta de horas da equipe",
      "title": "Serviços",
      "title_packageable": "Criar pacote de serviços",
      "total": "Total",
      "unit": "Unidade",
      "weight": "Peso",
      "working_hours": "Horas de trabalho"
    },
    "fields": {
      "image_not_found": "Imagem não encontrada",
      "na": "N/D",
      "signature": "Assinatura.jpg"
    },
    "filter_steps_modal": {
      "filter_result": "Resultado do Filtro",
      "step": "Passo",
      "subtitle": "Forneça algumas informações sobre o %{title}"
    },
    "filtering": {
      "between": " que está entre",
      "contains": " que contém ",
      "eq": " que é igual a ",
      "invalid_date": "Para filtrar corretamente, a data de início e a data de término devem ser preenchidas",
      "invalid_value": "Valor inválido",
      "neq": " que nao é igual a "
    },
    "form": {
      "back": "Todas %{entity}",
      "cancel": "Cancelar",
      "create_entity": "Criar %{entity}",
      "create_error": "%{entity} não pôde ser criado",
      "create_success": "%{entity} foi criado com sucesso",
      "duplicate_entity": "Duplicar %{entity}",
      "duplicate_success": "O %{entity} e suas dependências foram duplicados com sucesso!",
      "general_information": "Informação geral",
      "hide": "Ocultar",
      "inputs": {
        "attachment": {
          "add_error": "Erro ao adicionar arquivo",
          "add_evidence": "Adicionar evidência da autorização do cliente",
          "allowed_extensions": "As extensões permitidas são",
          "drag_drop": "Adicionar arquivos usando arrastar e soltar",
          "invalid_extension": "Tipo de arquivo não permitido.",
          "max_size": "O arquivo deve ser menor que %{max_size}",
          "maximum_num_uploads_error": "Você só pode carregar até %{max_num} arquivos por coluna flexível.",
          "one_file": "Carregar um arquivo por vez",
          "or_drop_file_here": "ou solte o arquivo aqui",
          "remove_error": "Falha ao Remover",
          "remove_success": "Removido com Sucesso",
          "select_files": "Selecionar arquivos",
          "submit": "Envie seu anexo",
          "upload_error": "Falha no upload",
          "upload_success": "Carregado com sucesso"
        },
        "contract_service": {
          "add": "Adicionar um contrato de serviço",
          "subtitle": "Contrato de Serviço"
        },
        "datasheet_filter": {
          "add_filter": "Adicionar Filtro",
          "title": "Filtro de Planilha"
        },
        "date_range": {
          "between": "%{start} até %{end}",
          "end": "Data final",
          "start": "Data de início"
        },
        "drop_formula": {
          "formula_select": "Por favor, selecione uma fórmula ..."
        },
        "errors": {
          "mandatory": "%{field} é obrigatório.",
          "not_found": "'%{value}' não foi encontrado na referência. Selecione um novo."
        },
        "formula": {
          "result": "Resultado"
        },
        "gps": {
          "save_location": "Salvar localização",
          "search": "Pesquisar local ...",
          "without_location": "Sem localização"
        },
        "picture": {
          "edition_only": "Disponível apenas durante a edição",
          "five_pictures_limit_warning": "São permitidas apenas 5 fotos no máximo",
          "no_image_on_clipboard": "Nenhum dado de imagem foi encontrado na sua área de transferência. Por favor, copie uma imagem primeiro.",
          "remove_error": "Falha ao remover",
          "remove_success": "Removido com sucesso",
          "upload_error": "Falha no upload",
          "upload_success": "Carregado com sucesso"
        },
        "placeholder": "Informe %{field} aqui",
        "value_selector": {
          "apply": "Aplicar",
          "done": "Concluído",
          "search": "Pesquise algo aqui ...",
          "waiting": "... Aguarde"
        }
      },
      "loading": "Carregando...",
      "schedule_entity": "Agendar %{entity}",
      "schedule_success": "Sua requisição para agendar %{entity} foi concluída com sucesso",
      "show": "Mostrar",
      "subtitle": "Por favor, Forneça informações básicas sobre %{entity}.",
      "subtitle_error": "Não foi possível carregar o formulário, verifique os dados da %{entity} ou do Modelo.",
      "update_entity": "Editar %{entity}",
      "update_error": "%{entity} não pôde ser atualizado",
      "update_success": "%{entity} foi atualizado com sucesso"
    },
    "formula_modal": {
      "back_button_text": "Voltar para o formulário",
      "calculate": "Calcular",
      "cancel": "Cancelar",
      "mandatory": "Você deve preencher os valores para os seguintes campos: %{fields}",
      "ok": "Salvar fórmula"
    },
    "grid": {
      "all": "Todos",
      "clear_filters": "Limpar Filtros",
      "edit_mode": "Modo de edição",
      "editable": {
        "error": {
          "mandatory": "Pelo menos um campo obrigatório foi deixado em branco"
        },
        "title": "Modo de edição",
        "update": {
          "error": "%{quantity} não pôde ser salvo. Verifique o motivo no lado esquerdo de cada linha destacada",
          "success": "%{quantity} registros foram salvos com sucesso"
        }
      },
      "empty_after_filtering": "Não há registros para os filtros aplicados",
      "grid_filters": {
        "menu_title": "Filtros Personalizados"
      },
      "menu": {
        "visibility": "Colunas"
      },
      "of": "de",
      "other": "Outros",
      "productivity_factor": "Fator de Produtividade",
      "select_items": "Selecionar itens",
      "selected": "Selecionado",
      "selecteds": "Selecionados",
      "totals": "Totais"
    },
    "history_popup": {
      "back": "Voltar",
      "comments": "Comentários",
      "crew_size": "Tamanho da Equipe",
      "daily_hours": "Horas Diárias",
      "date": "Data",
      "form_create_progress_service_title": "Criar histórico do serviço",
      "form_create_progress_title": "Criar histórico do item de execução",
      "form_edit_progress_service_title": "Editar histórico do serviço",
      "form_edit_progress_title": "Editar histórico do item de execução",
      "form_error_body": "Todos os campos obrigatórios do formulário devem ser preenchidos.",
      "form_error_title": "Não foi possível salvar o formulário de histórico",
      "form_subtitle": "Insira um histórico por quantidade ou porcentagem para sua atividade.",
      "modal_create_title": "Criar Histórico",
      "modal_edit_title": "Editar Histórico",
      "number_of_crews": "Nº de Equipes",
      "number_of_workers": "No. de trabalhadores",
      "other_value": "Outro Valor",
      "percentage": "Percentagem",
      "progress_by": "Progresso por",
      "quantity": "Quantidade",
      "singular": "Histórico",
      "working_hours": "Jornada de trabalho"
    },
    "home": {
      "available_on": "disponível no",
      "banner_description": "Gerenciar tarefas, rastrear processos e obter os melhores resultados usando uma única plataforma",
      "create_request": {
        "text": "Em primeiro lugar, você precisa criar uma Requisição do Cliente no DPMS com os detalhes do seu pedido.",
        "title": "Crie uma requisição"
      },
      "dpms_mobile": "DPMS Mobile App",
      "getting_started": "Começando",
      "home_title": "Home",
      "inspection": {
        "text": "Realize inspeções de qualidade no campo e registre os obstáculos.",
        "title": "Inspeção"
      },
      "performance": {
        "text": "Registre o progresso e o tempo com base nas atividades planejadas de cada equipe e meça a produtividade.",
        "title": "Execução"
      },
      "scoping": {
        "text": "Faça o levantamento de uma requisição do cliente. Atribua serviços e pacotes de serviços de seu contrato.",
        "title": "Levantamento"
      },
      "welcome_user": "Bem-vindo, %{user}"
    },
    "importation_drop": {
      "choose_error": "Escolha um item"
    },
    "importation_popup": {
      "file_upload_message": "Clique ou arraste o arquivo para esta área para fazer o upload",
      "importation_error": "Você pode rever esta mensagem na página Trabalhos.",
      "importation_success": "Sucesso na importação.",
      "importation_timeout": "A importação está demorando muito. Verifique seu status na página Trabalhos.",
      "instructions": "Os arquivos devem ser .xls e não maiores que xxmb. <br> Seu arquivo .xls deve conter a coluna - Data, Cliente, Projeto, Tarefa, Notas .... <br> A ordem das colunas deve ser a mesma que a ordem do modelo. <br> A data deve estar em - AAAA / MM / DD.",
      "requirements": "Exigências",
      "submit_spreadsheet": "Envie sua planilha"
    },
    "inspections": {
      "acceptance": "Aceitação",
      "acceptance_modal": {
        "inspection_acceptance": "Aceitação de Inspeção",
        "reject": "Rejeitar",
        "subtitle": "Forneça informações sobre a inspeção para prosseguir com a aceitação."
      },
      "actions": {
        "approve": "Aprovar",
        "more": "Mais",
        "reject": "Rejeitar"
      },
      "choose_modal": {
        "schedule": "Agendar Itens de Inspeção",
        "schedule_description": "Agende uma ou várias inspeções com base num intervalo de datas",
        "single": "Único Item de Inspeção",
        "single_description": "Criação de apenas uma Inspeção",
        "title": "Crie uma inspeção como:"
      },
      "description": "Inspeção",
      "every": "Cada",
      "id": "ID",
      "inspect_date": "Data de inspecção",
      "inspect_status_id": "Status",
      "inspected": "Inspecionado",
      "inspected_detail": "Detalhe inspecionado",
      "inspected_modules": {
        "progress": "Execução",
        "request": "Requisição",
        "scope": "Levantamento"
      },
      "inspections_detail": {
        "side_panel_title": "Detalhes da inspeção"
      },
      "module": "Módulo",
      "notifications": {
        "approve_successful": "A inspeção foi aprovada com sucesso",
        "reject_successful": "A inspeção foi rejeitada com sucesso"
      },
      "recurrency_interval": "Intervalo de recorrência",
      "repeat": "Repetir",
      "repeat_every_value": {
        "day": "Dia",
        "month": "Mês",
        "week": "Semana",
        "year": "Ano"
      },
      "repeats_on": "Repita",
      "request_id": "DPMSID",
      "responsible_id": "Responsável",
      "schedule_detail": "Detalhe da programação",
      "schedule_form": {
        "acceptance_step": "Definir Aceitação (opcional)",
        "detailed_item_step": "Escolha o levantamento da inspeção / item de desempenho",
        "detailed_template_step": "Escolha o levantamento da inspeção / modelo de desempenho",
        "further_step_information": "Os campos flexíveis estão prontos para serem preenchidos individualmente após a etapa de criação de recorrência",
        "general_step": "Definir informações gerais de inspeção",
        "hints": "Para recorrências mensais e anuais, o sistema agendará a inspeção no dia igual a data inicial do campo Intervalo",
        "monthly_hint": "Repete mensalmente todo o dia %{day}",
        "recurrence_step": "Recorrência"
      },
      "scheduled_exception": "Programado",
      "statuses": {
        "approved": "Aprovado",
        "completed": "Completo",
        "pending": "Pendente",
        "progress": "Em Progresso",
        "rejected": "Rejeitado"
      },
      "template": "Escolha o modelo",
      "title": "Inspeções"
    },
    "inspects": {
      "statuses": {
        "approved": "Aprovado",
        "completed": "Completo",
        "pending": "Pendente",
        "progress": "Em Progresso",
        "rejected": "Rejeitado"
      }
    },
    "kendo": {
      "upload": "Clique, arraste ou cole arquivos aqui para fazer upload"
    },
    "kendo_translations": {
      "calendar": {
        "today": "Hoje"
      },
      "dateinput": {
        "decrement": "Diminuir valor",
        "increment": "Aumente o valor"
      },
      "datepicker": {
        "toggleCalendar": "Alternar calendário"
      },
      "daterangepicker": {
        "end": "Fim",
        "separator": 0,
        "start": "Começar",
        "swapStartEnd": "Troque os valores inicial e final"
      },
      "datetimepicker": {
        "cancel": "Cancelar",
        "date": "Data",
        "set": "Aplicar",
        "time": "Tempo",
        "toggleDateTimeSelector": "Alternar seletor de data e hora"
      },
      "dropdowns": {
        "clear": "Limpar",
        "nodata": "Nenhum Dado Encontrado"
      },
      "editor": {
        "addColumnAfter": "Adicionar coluna à direita",
        "addColumnBefore": "Adicionar coluna à esquerda",
        "addRowAfter": "Adicionar linha abaixo",
        "addRowBefore": "Adicionar linha acima",
        "alignCenter": "Texto central",
        "alignJustify": "Justificar",
        "alignLeft": "Alinhar o texto à esquerda",
        "alignRight": "Alinhar o texto à direita",
        "bold": "Negrito",
        "bulletList": "Inserir lista não ordenada",
        "createTable": "Crie uma mesa",
        "deleteColumn": "Apagar coluna",
        "deleteRow": "Apagar linha",
        "deleteTable": "Apagar mesa",
        "fontName": "Nome da fonte",
        "fontSize": "Tamanho da fonte",
        "format": "Formato",
        "hyperlink": "Insira o hyperlink",
        "hyperlink-dialog-cancel": "Cancelar",
        "hyperlink-dialog-content-address": "Endereço da web",
        "hyperlink-dialog-content-newwindow": "Abrir link em nova janela",
        "hyperlink-dialog-content-title": "Título",
        "hyperlink-dialog-insert": "Inserir",
        "hyperlink-dialog-title": "Insira o hyperlink",
        "image": "Inserir Imagem",
        "image-address": "Endereço da web",
        "image-altText": "Texto alternativo",
        "image-cancel": "Cancelar",
        "image-dialog-title": "Inserir Imagem",
        "image-height": "Altura (px)",
        "image-insert": "Inserir",
        "image-title": "Título",
        "image-width": "Largura (px)",
        "indent": "Recuar",
        "insertTableHint": "Crie uma tabela {0} x {1}",
        "italic": "itálico",
        "mergeCells": "Mesclar células",
        "orderedList": "Inserir lista ordenada",
        "outdent": "Outdent",
        "redo": "Refazer",
        "splitCell": "Dividir célula",
        "strikethrough": "Tachado",
        "subscript": "Subscrito",
        "superscript": "Sobrescrito",
        "underline": "Sublinhado",
        "undo": "Desfazer",
        "unlink": "Remover hiperlink",
        "viewHtml": "Ver HTML",
        "viewHtml-cancel": "Cancelar",
        "viewHtml-dialog-title": "Ver HTML",
        "viewHtml-update": "Atualizar"
      },
      "filter": {
        "addExpression": "Adicionar Expressão",
        "addGroup": "Adicionar grupo",
        "afterOperator": "É depois",
        "afterOrEqualOperator": "É posterior ou igual a",
        "andLogic": "E",
        "beforeOperator": "É antes",
        "beforeOrEqualOperator": "É anterior ou igual a",
        "close": "Fechar",
        "containsOperator": "Contém",
        "endsWithOperator": "Termina com",
        "eqOperator": "É igual a",
        "gtOperator": "É melhor que",
        "gteOperator": "É maior que ou igual a",
        "isEmptyOperator": "Está vazia",
        "isFalse": "É falso",
        "isNotEmptyOperator": "Não está vazio",
        "isNotNullOperator": "Não é nulo",
        "isNullOperator": "É nulo",
        "isTrue": "É verdade",
        "ltOperator": "É menos do que",
        "lteOperator": "É menor ou igual a",
        "notContainsOperator": "Não contém",
        "notEqOperator": "Não é igual a",
        "orLogic": "Ou",
        "startsWithOperator": "Começa com"
      },
      "grid": {
        "filterAfterOperator": "É depois",
        "filterAfterOrEqualOperator": "É posterior ou igual a",
        "filterBeforeOperator": "É antes",
        "filterBeforeOrEqualOperator": "É anterior ou igual a",
        "filterBetweenOperator": "Está entre",
        "filterBooleanAll": "(Todos)",
        "filterClearButton": "Limpar",
        "filterContainsOperator": "Contém",
        "filterEndsWithOperator": "Termina com",
        "filterEqOperator": "É igual a",
        "filterGtOperator": "É melhor que",
        "filterGteOperator": "É maior que ou igual a",
        "filterIsEmptyOperator": "Está vazia",
        "filterIsFalse": "É falso",
        "filterIsNotEmptyOperator": "Não está vazio",
        "filterIsNotNullOperator": "Não é nulo",
        "filterIsNullOperator": "É nulo",
        "filterIsTrue": "É verdade",
        "filterLtOperator": "É menos do que",
        "filterLteOperator": "É menor ou igual a",
        "filterNotContainsOperator": "Não contém",
        "filterNotEqOperator": "Não é igual a",
        "filterStartsWithOperator": "Começa com",
        "filterSubmitButton": "Filtro",
        "filterTitle": "Filtro",
        "groupPanelEmpty": "Arraste um cabeçalho de coluna e solte-o aqui para agrupar por essa coluna",
        "noRecords": "Nenhum registro disponível.",
        "pagerFirstPage": "Vá para a primeira página",
        "pagerInfo": "{0} - {1} de {2} itens",
        "pagerItemsPerPage": "itens por página",
        "pagerLastPage": "Vá para a última página",
        "pagerNextPage": "Vá para a página seguinte",
        "pagerOf": "do",
        "pagerPage": "Página",
        "pagerPreviousPage": "Vá para a página anterior"
      },
      "numerictextbox": {
        "decrement": "Diminuir valor",
        "increment": "Aumente o valor"
      },
      "pager": {
        "firstPage": "Vá para a primeira página",
        "info": "{0} - {1} de {2} itens",
        "itemsPerPage": "itens por página",
        "lastPage": "Vá para a última página",
        "nextPage": "Vá para a página seguinte",
        "of": "do",
        "page": "Página",
        "previousPage": "Vá para a página anterior"
      },
      "scheduler": {
        "agendaViewTitle": "Agenda",
        "allDay": "dia todo",
        "allEvents": "Todos os eventos",
        "dateTitle": "Data",
        "dayViewTitle": "Dia",
        "deleteConfirmation": "Tem certeza que deseja deletar este evento?",
        "deleteDialogTitle": "Apagar Evento",
        "deleteOccurrence": "Apagar a ocorrência atual",
        "deleteRecurringConfirmation": "Quer excluir apenas esta ocorrência de evento ou toda a série?",
        "deleteRecurringDialogTitle": "Excluir item recorrente",
        "deleteSeries": "Exclua a série",
        "deleteTitle": "Excluir",
        "editorCancel": "Cancelar",
        "editorDelete": "Excluir",
        "editorEventAllDay": "Evento de dia inteiro",
        "editorEventDescription": "Descrição",
        "editorEventEnd": "Fim",
        "editorEventEndTimeZone": "Fuso Horário Final",
        "editorEventSeparateTimeZones": "Termina em fuso horário diferente",
        "editorEventStart": "Começar",
        "editorEventStartTimeZone": "Fuso horário inicial",
        "editorEventTimeZone": "Especifique o fuso horário",
        "editorEventTitle": "Título",
        "editorOccurrence": "Editar ocorrência atual",
        "editorRecurringConfirmation": "Quer editar apenas esta ocorrência de evento ou toda a série?",
        "editorRecurringDialogTitle": "Editar Item Recorrente",
        "editorSave": "Salve ",
        "editorSeries": "Edite a série",
        "editorTitle": "Evento",
        "editorValidationEnd": "O horário de término deve ser posterior ao horário de início.",
        "editorValidationRequired": "Campo é obrigatório.",
        "editorValidationStart": "O horário de início deve ser anterior ao horário de término.",
        "eventTitle": "Evento",
        "monthViewTitle": "Mês",
        "nextTitle": "Próximo",
        "noEvents": "Sem eventos",
        "previousTitle": "Anterior",
        "recurrenceEditorDailyInterval": "dias)",
        "recurrenceEditorDailyRepeatEvery": "Repita a cada",
        "recurrenceEditorEndAfter": "Depois de",
        "recurrenceEditorEndLabel": "Fim",
        "recurrenceEditorEndNever": "Nunca",
        "recurrenceEditorEndOccurrence": "ocorrência (s)",
        "recurrenceEditorEndOn": "Em",
        "recurrenceEditorFrequenciesDaily": "Diariamente",
        "recurrenceEditorFrequenciesMonthly": "Por mês",
        "recurrenceEditorFrequenciesNever": "Nunca",
        "recurrenceEditorFrequenciesWeekly": "Semanal",
        "recurrenceEditorFrequenciesYearly": "Anual",
        "recurrenceEditorMonthlyDay": "Dia",
        "recurrenceEditorMonthlyInterval": "mês (es)",
        "recurrenceEditorMonthlyRepeatEvery": "Repita a cada",
        "recurrenceEditorMonthlyRepeatOn": "Repita",
        "recurrenceEditorOffsetPositionsFirst": "Primeiro",
        "recurrenceEditorOffsetPositionsFourth": "Quarto",
        "recurrenceEditorOffsetPositionsLast": "Último",
        "recurrenceEditorOffsetPositionsSecond": "Segundo",
        "recurrenceEditorOffsetPositionsThird": "Terceiro",
        "recurrenceEditorRepeat": "Repetir",
        "recurrenceEditorWeekdaysDay": "Dia",
        "recurrenceEditorWeekdaysWeekday": "Dia da semana",
        "recurrenceEditorWeekdaysWeekendday": "Dia de fim de semana",
        "recurrenceEditorWeeklyInterval": "semana (s)",
        "recurrenceEditorWeeklyRepeatEvery": "Repita a cada",
        "recurrenceEditorWeeklyRepeatOn": "Repita",
        "recurrenceEditorYearlyInterval": "anos)",
        "recurrenceEditorYearlyOf": "do",
        "recurrenceEditorYearlyRepeatEvery": "Repita a cada",
        "recurrenceEditorYearlyRepeatOn": "Repita",
        "showFullDay": "Mostrar o dia inteiro",
        "showWorkDay": "Mostrar horário comercial",
        "timeTitle": "Tempo",
        "timelineViewTitle": "Linha do tempo",
        "today": "Hoje",
        "weekViewTitle": "Semana",
        "workWeekViewTitle": "Semana de trabalho"
      },
      "sortable": {
        "noData": "Sem dados"
      },
      "timepicker": {
        "cancel": "Cancelar",
        "now": "AGORA",
        "selectNow": "Selecione agora",
        "set": "Aplicar",
        "toggleClock": "Alternar relógio",
        "toggleTimeSelector": "Alternar Seletor"
      },
      "treelist": {
        "filterAfterOperator": "É depois",
        "filterAfterOrEqualOperator": "É posterior ou igual a",
        "filterBeforeOperator": "É antes",
        "filterBeforeOrEqualOperator": "É anterior ou igual a",
        "filterBooleanAll": "(Todos)",
        "filterClearButton": "Limpar",
        "filterContainsOperator": "Contém",
        "filterEndsWithOperator": "Termina com",
        "filterEqOperator": "É igual a",
        "filterGtOperator": "É melhor que",
        "filterGteOperator": "É maior que ou igual a",
        "filterIsEmptyOperator": "Está vazia",
        "filterIsFalse": "É falso",
        "filterIsNotEmptyOperator": "Não está vazio",
        "filterIsNotNullOperator": "Não é nulo",
        "filterIsNullOperator": "É nulo",
        "filterIsTrue": "É verdade",
        "filterLtOperator": "É menos do que",
        "filterLteOperator": "É menor ou igual a",
        "filterNotContainsOperator": "Não contém",
        "filterNotEqOperator": "Não é igual a",
        "filterStartsWithOperator": "Começa com",
        "noRecords": "Nenhum registro disponível"
      },
      "upload": {
        "cancel": "Cancelar",
        "clearSelectedFiles": "Limpar",
        "dropFilesHere": "ou solte o arquivo aqui",
        "files": "arquivos",
        "headerStatusUploaded": "Feito",
        "headerStatusUploading": "Enviando...",
        "invalidFileExtension": "Tipo de arquivo não permitido.",
        "invalidFiles": "Arquivo (s) inválido (s). Verifique os requisitos de upload de arquivo.",
        "invalidMaxFileSize": "O tamanho do arquivo é muito grande.",
        "invalidMinFileSize": "Tamanho do arquivo muito pequeno.",
        "remove": "Remover",
        "retry": "Tentar novamente",
        "select": "Selecione o arquivo",
        "total": "Total",
        "uploadSelectedFiles": "Envio"
      }
    },
    "loading_batch_entities": {
      "page_of": "Página %{page} de %{total}",
      "title": "Buscando páginas"
    },
    "loading_batch_modal": {
      "page_of": "Página %{page} de %{total}",
      "title": "Buscando páginas"
    },
    "login": {
      "again": "Para continuar, faça o login novamente",
      "continue_mykaefer": "Continue com MyKaefer",
      "copyright": "®%{year} Todos os direitos reservados. Kaefer®",
      "forgot_password": "Esqueceu a senha?",
      "iframe_info": "Solicitando acesso à página: %{link}",
      "log_in": "Conecte-se",
      "password": "Senha",
      "title_1": "Uma ferramenta poderosa",
      "title_2": "para gerenciamento de projetos",
      "username": "Nome do usuário",
      "welcome_back": "Bem vindo de volta"
    },
    "main_dialog": {
      "failure_message": "sua solicitação não pode ser concluida.",
      "failure_title": "Falha",
      "loading_message": "Aguarde enquanto preparamos as coisas para você!",
      "loading_title": "Carregando...",
      "service_title": "Aguardando o término do trabalho...",
      "success_message": "Sua solicitação foi bem sucedida! Agora você pode continuar seu trabalho.",
      "success_title": "Bem sucedido!"
    },
    "notification": {
      "access_denied": "Acesso negado",
      "action_success": "%{model} %{id} foi %{action}",
      "bad_formula_setup": "Há um problema de configuração com a fórmula '%{title}', por favor verifique sua configuração!",
      "correct_subproject": "Selecione o subprojeto correto deste item",
      "dismiss_all": "Fechar todos",
      "error": "Erro",
      "error_cookies": "Não foi possível estabelecer comunicação entre fontes cruzadas. Revise as configurações de cookies em seu navegador e desative o 'bloqueio de cookies de terceiros'.",
      "error_job": "Falhou! Verifique o motivo na página de tafefas em segundo plano.",
      "error_msg": "Desculpe! Algo deu errado!",
      "info": "Informações",
      "large_file_body": "Aplique alguns filtros para tornar o arquivo de saída menor.",
      "large_file_title": "A tabela tem mais de 1000 registros",
      "page_denied": "A página não pôde ser carregada.",
      "please": "Por favor",
      "select_subproject": "Selecione um projeto e subprojeto para continuar",
      "success": "Sucesso",
      "success_msg": "Ação executada com sucesso!",
      "warning": "Atenção"
    },
    "orders": {
      "add_evidence": "Adicione evidência da autorização do cliente",
      "attachment": "Anexo",
      "comments": "Comentários",
      "date": "Data",
      "drag_and_drop": "Adicione arquivos arrastando-os",
      "responsible": "Responsável",
      "title": "Pedidos"
    },
    "performance": {
      "title": "Execução"
    },
    "performances": {
      "title": "Execução"
    },
    "performances_wip": {
      "performance_item": "Item de Execução",
      "title": "Desempenho"
    },
    "planning": {
      "item_no": "Nº do Item",
      "service_no": "Nº do serviço",
      "title": "Planejamento",
      "view": "Visão de planejamento"
    },
    "plannings": {
      "title": "Planejamento"
    },
    "progress_histories": {
      "add_new_progress": "Adicionar um novo progresso",
      "empty_historic": "Não há item de histórico e não é possível criar históricos quando a quantidade é zero",
      "hours": "Horas",
      "production_factor": "PF",
      "progress": "Progresso",
      "progress_badge": "Item %{number}",
      "progress_history": "Histórico do item",
      "progress_service_badge": "Serviço %{number}",
      "progress_service_history": "Histórico do serviço",
      "progress_service_title": "Históricos do serviço",
      "progress_title": "Históricos do item",
      "quantity": "Quantidade",
      "subtitle": "Gerencie o progresso diário com base nas atividades planejadas por sua equipe."
    },
    "progress_services": {
      "actual_div_hours": "Horas reais / Meta de horas da equipe",
      "actual_div_quantity": "Quantidade real / planejada",
      "actual_hours": "Horas realizadas",
      "actual_quantity": "Quantidade realizada",
      "application_price": "Preço da Aplicação",
      "back": "Todas as execuções",
      "budget_target_hours": "Meta de horas do orçamento",
      "canceled": "Cancelado",
      "choose_modal": {
        "package": "Serviços do pacote",
        "package_description": "Criação de vários serviços com base no mesmo modelo de atividade",
        "single": "Único item de serviço",
        "single_description": "Criação de apenas um serviço",
        "title": "Crie um novo"
      },
      "contract_service": "Contrato de Serviço",
      "contribution": "Contribuição",
      "contribution_info": "Valores calculados automaticamente",
      "crew": "equipe",
      "crew_size": "Tamanho da Equipe",
      "crews": "equipes",
      "date_filter": {
        "all": "Todos",
        "today": "Hoje",
        "tomorrow": "Amanhã",
        "week": "Semana",
        "yesterday": "Ontem"
      },
      "delta_hours": "Delta Horas",
      "delta_qtt": "Quantidade Delta",
      "description": "Serviço de Execução",
      "discipline": "Disciplina",
      "end_date": "Data final",
      "equipment_price": "Preço do equipamento",
      "factor": "Fator",
      "flag": "Bandeira",
      "flag_filter": "Filtro de Bandeira",
      "flag_filters": "Filtros de bandeira",
      "id": "ID",
      "materials_price": "Preço dos materiais",
      "no_crews": "Nº de Equipes",
      "no_workers": "Nº de Trabalhadores",
      "norm_hours": "Horário normal",
      "not_planned": "Não planejado",
      "other_price": "Outro Preço",
      "package_factor": "Fator",
      "people": "pessoas",
      "person": "pessoa",
      "pf": "PF",
      "pf_doing_bad": "Você não está indo bem",
      "pf_doing_good": "Você está indo bem!",
      "pf_doing_neutral": "Não foi possível calcular o pf",
      "planned": "Planejado",
      "planning_chart": {
        "actual": "Realizado",
        "actual_qty": "Qtd realizada",
        "date": "Data",
        "forecasted": "Previsto",
        "forecasted_qty": "Qtd Prevista",
        "target": "Meta",
        "target_qty": "Qtd meta",
        "title": "Gráfico de Planejamento"
      },
      "planning_timeline": {
        "day": "dia",
        "day_advance": "dia de antecedência",
        "day_delayed": "dia atrasado",
        "days": "dias",
        "days_advance": "dias de antecedência",
        "days_delayed": "dias atrasados",
        "end_date": "Data final",
        "forecasted": "Previsto",
        "on_time": "Na hora",
        "remaining_days": "Dias restantes",
        "start_date": "Data de início",
        "title": "Cronograma de Planejamento"
      },
      "progress": "Progresso",
      "progress_services_detail": {
        "side_panel_title": "Detalhes do serviço"
      },
      "quantity": "Quantidade",
      "responsible": "Responsável",
      "scoped_quantity": "Quantidade com escopo",
      "service": "Serviço",
      "service_package": "Pacote de serviço",
      "service_planning": "Planejamento de Serviços",
      "service_progress": "Progresso do Serviço",
      "service_weight": "Peso de Serviço",
      "start_date": "Data de início",
      "statuses": {
        "canceled": "Cancelado",
        "done": "Concluído",
        "extra": "Extra",
        "in_planning": "Não planejado",
        "planned": "Planejado"
      },
      "team_target_hours": "Meta de horas da equipe",
      "title": "Serviços de progresso",
      "title_packageable": "Serviços de Execução por pacote de serviços",
      "total": "Total",
      "total_hours": "Total de Horas",
      "unit": "Unidade",
      "worker": "trabalhador",
      "workers": "trabalhadores",
      "working_hours": "Jornada de Trabalho"
    },
    "progresses": {
      "cancel": "Cancelar",
      "choose_modal": {
        "single": "Outro item de progresso",
        "single_description": "Criação de apenas um item de progresso"
      },
      "complete": "Completar",
      "delta_hours": "∆ horas",
      "delta_qty": "∆ Qtd",
      "description": "Descrição",
      "extra": "Extra",
      "flag": "Bandeira",
      "history": "Históricos",
      "id": "Id",
      "item": "Item",
      "label": "Rótulo",
      "pf": "PF",
      "progress": "Progresso",
      "progresses_detail": {
        "side_panel_title": "Detalhes de desempenho"
      },
      "request_status_restriction": "A solicitação deve ter o status Em Planejamento ou Em Execução",
      "revised": "Revisado",
      "title": "Desempenho"
    },
    "projects": {
      "workdays": {
        "fri": "Sex",
        "mon": "Seg",
        "sat": "Sáb",
        "sun": "Dom",
        "thu": "Qui",
        "tue": "Ter",
        "wed": "Qua"
      }
    },
    "requests": {
      "actual_hours": "Horas reais",
      "actual_progress": "Progresso real",
      "all": "Todos",
      "are_you_sure_complete": "Você tem certeza de concluir esta requisição?",
      "are_you_sure_complete_no_history": "Você tem certeza de concluir uma requisição sem nenhum avanço?",
      "are_you_sure_deletion": "Tem certeza de que deseja excluir a requisição?",
      "are_you_sure_revertion": "Tem certeza de que deseja reverter e assim perder os dados que foram inseridos neste módulo?",
      "area": "Área",
      "authorization_pending": "Autorização pendente",
      "authorize_form": {
        "moal_title": "Autorização",
        "modal_title": "Autorização",
        "subtitle": "Forneça algumas informações sobre a requisição para prosseguir com a aprovação",
        "title": "Pedido de autorização"
      },
      "canceled": "Cancelado",
      "clustered": "Agrupamento",
      "comments": "Comentários",
      "confirmation_modal": {
        "approve_description": "O pedido será aprovado",
        "approve_title": "Aprovar requisição?",
        "cancel_description": "O pedido será cancelado",
        "cancel_title": "Cancelar pedido?",
        "complete_description": "Este pedido será completado",
        "complete_title": "Completar requisição?",
        "confirm_action": "Prossesguir para Importar Levantamentos",
        "confirm_cancel": "Agora não",
        "confirm_confirmation": "Sim, quero confirmar",
        "confirm_description": "Quer confirmar o pedido?",
        "confirm_title": "Solicitar confirmação",
        "delete_description": "A ação de remoção não pode ser desfeita. Você tem certeza?",
        "delete_title": "Apagar requisição?",
        "reject_description": "Este item retornará ao status Em Levantamento",
        "reject_title": "Rejeitar pedido?",
        "revert_description": "A ação de reversão não pode ser desfeita. Você tem certeza?",
        "revert_title": "Reverter pedido?"
      },
      "confirmed": "Confirmada",
      "creation_form": {
        "proceed": "Prossiga para a Etapa 2"
      },
      "creation_page": {
        "add_template": "Adicionar modelo",
        "first_step": "Criar requisição",
        "second_step": "Importar levantamentos (opcional)",
        "third_step": "Aplicar pacotes de serviço (opcional)"
      },
      "description": "Requisição",
      "discipline": "Disciplina",
      "done": "Finalizado",
      "eav_template": "Modelo",
      "eav_template_id": "ID do modelo",
      "edit_dialog": {
        "reason": "Nome da Sub-requisição"
      },
      "elevation": "Elevação",
      "errors": {
        "not_found": "Não foi possível encontrar a requisição com o ID: %{id}"
      },
      "estimates_authorization": "É necessária autorização de estimativa",
      "forecast_end_date": "Data de término prevista",
      "id": "DPMSID",
      "in_performance": "Em Avanço",
      "in_planning": "Em Planejamento",
      "in_scoping": "Em Levantamento",
      "information": "Informação adicional",
      "notification": {
        "authorization_success": "A requisição foi autorizada com sucesso",
        "deleting_sub_request": "A Sub Requisição %{id} está sendo excluída. Verifique a página de Trabalhos para saber mais sobre seu progresso",
        "missing_order_error": "Não há nenhum pedido pendente associado a este pedido"
      },
      "on_hold": "Em Espera",
      "progress_end_date": "Data final do Avanço",
      "progress_responsible": "Responsável pelo Avanço",
      "progress_responsible_id": "Responsável pelo Avanço",
      "progress_start_date": "Data de início do Avanço",
      "reason": "Referência",
      "reference": "Referência",
      "registered": "Registrada",
      "request_date": "Data da requisição",
      "request_detail": {
        "edit": "Editar",
        "estimates": "Estimativas",
        "inspections": "Inspeções",
        "performance": "Execução",
        "planning": "Planejamento",
        "scopes": "Levantamentos",
        "show": "Mostrar",
        "side_panel_title": "Detalhes da requisição"
      },
      "request_end_date": "Data de término da Requisição",
      "request_responsible": "Responsável pela Requisição",
      "request_responsible_id": "Responsável pela Requisição",
      "request_start_date": "Data de início da Requisição",
      "schedule": "Agendamento da Requisição",
      "schedule_form": {
        "date": "Data",
        "days": "%{number} dias",
        "performance": "Execução",
        "progress": "Progresso",
        "request": "Requisição",
        "responsible": "Responsável",
        "schedule": "Cronograma",
        "scoping": "Levantamento",
        "subtitle": "Para criar um agendamento para esta requisição, primeiro precisamos de algumas informações.",
        "title": "Agendamento da Requisição"
      },
      "scope_end_date": "Data final do Levantamento",
      "scope_responsible": "Responsável pelo Levantamento",
      "scope_responsible_id": "Responsável pelo Levantamento",
      "scope_start_date": "Data de início do Levantamento",
      "scopes": "Levantamentos",
      "service_desired_date": "Data desejada",
      "skip_scoping": "Pular fase de Levantamento",
      "status": "Status",
      "statuses": {
        "authorization_pending": "Pendente",
        "canceled": "Cancelado",
        "clustered": "Agrupamento",
        "confirmed": "Em Levantamento",
        "deleting": "Excluindo",
        "done": "Feito",
        "in_performance": "Em Avanço",
        "in_planning": "Em Avanço",
        "in_scoping": "Em Levantamento",
        "on_hold": "Em espera",
        "registered": "Criada"
      },
      "sub_request": "Sub-requisição",
      "sub_requests": "Sub-requisições",
      "subarea": "Subárea",
      "team_target_hours": "Meta de horas da equipe",
      "title": "Requisição"
    },
    "scopes": {
      "add": "Adicionar item de levantamento",
      "choose_modal": {
        "single": "Outro item de levantamento",
        "single_description": "Criação de apenas um item de levantamento"
      },
      "clusters": {
        "title": "Grupos de levantamento"
      },
      "count": "Contagem",
      "crew_size": "Tamanho da equipe",
      "crews": "Número de equipes",
      "date": "Data",
      "description": "Levantamento",
      "hours": "Horas (h)",
      "id": "ID",
      "item": "Item",
      "label": "Marcador",
      "label_assigner": {
        "success": "Suas etiquetas de levantamentos foram atribuídas com sucesso! Você pode continuar seu levantamento agora."
      },
      "label_error": "Não foi possível buscar os marcadores",
      "popup_label": {
        "create_label": "Criar etiqueta",
        "labels": "Marcadores:",
        "name_label": "Nome da etiqueta",
        "new_label": "Nova etiqueta"
      },
      "quantity": "Qtd",
      "remaining_hours": "Horas Restantes",
      "remaining_quantity": "Qtd Restante",
      "request_status_restriction": "A requisição deve ter status Confirmado ou Em Levantamento",
      "scopes_detail": {
        "side_panel_title": "Detalhes do levantamento"
      },
      "title": "Levantamento",
      "unit": "Unidade",
      "working_hours": "Jornada de trabalho"
    },
    "search": {
      "loading": "Carregando...",
      "no_result_found": "Nenhum resultado encontrado",
      "placeholder": "Filtro..."
    },
    "settings": {
      "title": "Configurações"
    },
    "settings_shortcuts": "Atalhos de configurações",
    "shortcuts": {
      "access_control": "Controle de acesso",
      "areas": "Áreas e subáreas",
      "areas_subareas": "Áreas e subáreas",
      "assets": "Ativos",
      "background_jobs": "Trabalhos em segundo plano",
      "branches": "Filiais",
      "cities": "Cidades",
      "client_sites": "Plantas do cliente",
      "clients": "Clientes",
      "clients_info": "Informações de clientes",
      "columns_renaming": "Renomeação de Colunas",
      "companies": "Empresas",
      "contract_services": "Serviços do contrato",
      "contracts": "Contratos",
      "countries": "Países",
      "customer_sites": "Sites de clientes",
      "customers": "Clientes",
      "disciplines": "Disciplinas",
      "employees": "Funcionários",
      "equipaments": "Equipamentos",
      "equipments": "Equipamentos",
      "formulas": "Fórmulas",
      "location": "Localização",
      "materials": "Materiais",
      "matrices": "Matrizes",
      "measures": "Medidas",
      "mobile_details": "Detalhes mobile",
      "position_names": "Cargos",
      "preferences": "Preferências",
      "project_assets": "Ativos",
      "project_contracts": "Contratos",
      "projects": "Projetos e subprojetos",
      "projects_contracts": "Projetos e contratos",
      "projects_subprojects": "Projetos e subprojetos",
      "provinces": "Estados / Províncias",
      "regions": "Regiões",
      "routes": "Rotas",
      "specialities": "Especialidades",
      "staff": "Funcionários",
      "states_provinces": "Estados",
      "translations": "Renomeação de colunas",
      "users": "Usuários",
      "visible_fields": "Detalhes mobile",
      "work_positions": "Nomes de Cargos"
    },
    "side_menu": {
      "contracts": "Contratos",
      "datasheets": "Planilhas",
      "new": "Novo",
      "report": "Relatórios",
      "search": "Procurar",
      "settings": "Configurações",
      "template": {
        "asset": "Ativos",
        "contract_service": "Serviços do contrato",
        "datasheet": "Planilhas",
        "estimate_service": "Serviços de estimativa",
        "inspection": "Inspeções",
        "performance": "Execução",
        "report": "Relatórios",
        "request": "Requisições",
        "scoping": "Levantamento",
        "valuation": "Medição"
      },
      "templates": "Modelos"
    },
    "sub_requests": {
      "creation_page": {
        "all": "Todas as Sub Requisições",
        "back": "Todos os levantamentos",
        "title": "sub requisições"
      },
      "left_panel": {
        "add_scoping": "Adicionar item de levantamento",
        "allocation": "Alocação",
        "cards_header_title": "Levantamentos",
        "summary": "Resumo",
        "total_allocation": "Alocação total"
      },
      "scope_card": {
        "scope": "Levantamento"
      }
    },
    "tabs": {
      "error": "Impossível carregar abas"
    },
    "templates": {
      "asset": "Ativos",
      "contract_service": "Serviços do contrato",
      "datasheet": "Planilhas",
      "estimate_service": "Serviços de Estimativa",
      "inspection": "Inspeções",
      "no_template": "Você precisa criar um modelo",
      "progress": "Progresso",
      "progress_service": "Medição",
      "report": "Relatórios",
      "request": "Requisições",
      "scoping": "Levantamento",
      "title": "Modelos para %{type}"
    },
    "top_menu": {
      "authorization": "Autorizações",
      "estimates": "Estimativas",
      "inspections": "Inspeções",
      "performance": "Execução",
      "planning": "Planejamento",
      "requests": "Requisição",
      "scoping": "Levantamento"
    },
    "track_block": {
      "id": "DPMSID"
    },
    "user_menu": {
      "about_version": "Sobre a versão",
      "logout": "Sair",
      "profile": "Perfil"
    },
    "users": {
      "edit_title": "Atualizar perfil"
    }
  },
  "ru": {
    "i18n_tasks": {
      "add_missing": {
        "added": "Добавлены ключи (%{count})"
      },
      "cmd": {
        "args": {
          "default_text": "По умолчанию: %{value}",
          "desc": {
            "all_locales": "Не ожидать, что маски ключа начинаются с локали. Применять маски ко всем локалям.",
            "config": "Путь к файлу конфигурации",
            "confirm": "Подтвердить автоматом",
            "data_format": "Формат данных: %{valid_text}.",
            "keep_order": "Keep the order of the keys",
            "key_pattern": "Маска ключа (например, common.*)",
            "locale": "Язык. По умолчанию: base",
            "locale_to_translate_from": "Язык, с которого переводить (по умолчанию: base)",
            "locales_filter": "Список языков для обработки, разделенный запятыми (,). По умолчанию: все. Специальное значение: base.",
            "missing_types": "Типы недостающих переводов: %{valid}. По умолчанию: все",
            "nostdin": "Не читать дерево из стандартного ввода",
            "out_format": "Формат вывода: %{valid_text}.",
            "pattern_router": "Использовать pattern_router: ключи распределятся по файлам согласно data.write",
            "strict": "Не угадывать динамические использования ключей, например `t(\"category.#{category.key}\")`",
            "translation_backend": "Движок перевода (google или deepl)",
            "value": "Значение, интерполируется с %%{value}, %%{human_key}, %%{key}, %%{default}, %%{value_or_human_key}, %%{value_or_default_or_human_key}"
          }
        },
        "desc": {
          "add_missing": "добавить недостающие ключи к переводам",
          "check_consistent_interpolations": "убедитесь, что во всех переводах используются правильные интерполяционные переменные",
          "check_normalized": "проверить, что все файлы переводов нормализованы",
          "config": "показать конфигурацию",
          "cp": "скопируйте ключи в данных локали, соответствующие заданному шаблону",
          "data": "показать данные переводов",
          "data_merge": "добавить дерево к переводам",
          "data_remove": "удалить ключи, которые есть в дереве, из данных",
          "data_write": "заменить переводы деревом",
          "eq_base": "показать переводы, равные значениям в основном языке",
          "find": "показать, где ключи используются в коде",
          "gem_path": "показать путь к ruby gem",
          "health": "Всё ОК?",
          "irb": "начать REPL сессию в контексте i18n-tasks",
          "missing": "показать недостающие переводы",
          "mv": "переименовать / объединить ключи, которые соответствуют заданному шаблону",
          "normalize": "нормализовать файлы переводов (сортировка и распределение)",
          "remove_unused": "удалить неиспользуемые ключи",
          "rm": "удалить ключи, которые соответствуют заданному шаблону",
          "translate_missing": "перевести недостающие переводы с Google Translate / DeepL Pro",
          "tree_convert": "преобразовать дерево между форматами",
          "tree_filter": "фильтровать дерево по ключу",
          "tree_merge": "объединенить деревья",
          "tree_mv_key": "переименованить / объединить / удалить ключи соответствующие заданному шаблону",
          "tree_set_value": "заменить значения ключей",
          "tree_subtract": "дерево A минус ключи в дереве B",
          "tree_translate": "Перевести дерево при помощи Google Translate на язык корневых узлов",
          "unused": "показать неиспользуемые переводы"
        },
        "encourage": [
          "Хорошая работа!",
          "Отлично!",
          "Прекрасно!"
        ],
        "enum_list_opt": {
          "invalid": "%{invalid} не в: %{valid}."
        },
        "enum_opt": {
          "invalid": "%{invalid} не является одним из: %{valid}."
        },
        "errors": {
          "invalid_format": "Неизвестный формат %{invalid}. Форматы: %{valid}.",
          "invalid_locale": "Неверный язык %{invalid}",
          "invalid_missing_type": {
            "few": "Неизвестные типы: %{invalid}. Типы: %{valid}.",
            "many": "Неизвестные типы: %{invalid}. Типы: %{valid}.",
            "one": "Неизвестный тип %{invalid}. Типы: %{valid}.",
            "other": "Неизвестные типы: %{invalid}. Типы: %{valid}."
          },
          "pass_forest": "Передайте дерево"
        }
      },
      "common": {
        "continue_q": "Продолжить?",
        "key": "Ключ",
        "locale": "Язык",
        "n_more": "ещё %{count}",
        "value": "Значение"
      },
      "data_stats": {
        "text": "%{key_count} ключей в %{locale_count} языках. В среднем, длина строки: %{value_chars_avg}, сегменты ключей: %{key_segments_avg}, ключей в языке %{per_locale_avg}.",
        "text_single_locale": "%{key_count} ключей. В среднем, длина строки: %{value_chars_avg}, сегменты ключей: %{key_segments_avg}.",
        "title": "Данные (%{locales}):"
      },
      "deepl_translate": {
        "errors": {
          "no_api_key": "Задайте ключ API DeepL через переменную окружения DEEPL_AUTH_KEY или translation.deepl_api_key Получите ключ через https://www.deepl.com/pro.",
          "no_results": "DeepL не дал результатов.",
          "specific_target_missing": "You must supply a specific variant for the given target language e.g. en-us instead of en."
        }
      },
      "google_translate": {
        "errors": {
          "no_api_key": "Задайте ключ API Google через переменную окружения GOOGLE_TRANSLATE_API_KEY или translation.google_translate_api_key в config/i18n-tasks.yml. Получите ключ через https://code.google.com/apis/console.",
          "no_results": "Google Translate не дал результатов. Убедитесь в том, что платежная информация добавлена в https://code.google.com/apis/console."
        }
      },
      "health": {
        "no_keys_detected": "Ключи не обнаружены. Проверьте data.read в config/i18n-tasks.yml."
      },
      "inconsistent_interpolations": {
        "none": "Не найдено несогласованных интерполяций."
      },
      "missing": {
        "details_title": "На других языках или в коде",
        "none": "Всё переведено."
      },
      "openai_translate": {
        "errors": {
          "no_api_key": "Установить ключ API Яндекса с помощью переменной среды OPENAI_API_KEY или translation.openai_api_key\n в config / i18n-tasks.yml. Получите ключ по адресу https://openai.com/.",
          "no_results": "Яндекс не дал результатов."
        }
      },
      "remove_unused": {
        "confirm": {
          "few": "Переводы (%{count}) будут удалены из %{locales}.",
          "many": "Переводы (%{count}) будут удалены из %{locales}.",
          "one": "%{count} перевод будут удалён из %{locales}.",
          "other": "Переводы (%{count}) будут удалены из %{locales}."
        },
        "noop": "Нет неиспользуемых ключей",
        "removed": "Удалены ключи (%{count})"
      },
      "translate_missing": {
        "translated": "Переведены ключи (%{count})"
      },
      "unused": {
        "none": "Все переводы используются."
      },
      "usages": {
        "none": "Не найдено использований."
      },
      "yandex_translate": {
        "errors": {
          "no_api_key": "Установить ключ API Яндекса с помощью переменной среды YANDEX_API_KEY или translation.yandex_api_key\n в config / i18n-tasks.yml. Получите ключ по адресу https://tech.yandex.com/translate/.",
          "no_results": "Яндекс не дал результатов."
        }
      }
    }
  },
  "th": {
    "actions": {
      "approve": "อนุมัติ",
      "bulk_editing": "การแก้ไขจำนวนมาก",
      "cancel": "ยกเลิก",
      "choose_entity": "เลือก %{entity}",
      "clear": "ชัดเจน",
      "confirm": "ยืนยัน",
      "create": "สร้าง",
      "delete": "ลบ",
      "discard": "ทิ้ง",
      "done": "เสร็จแล้ว",
      "duplicate": "ทำซ้ำ",
      "edit": "แก้ไข",
      "export": "ส่งออกเป็น .xls",
      "export_template": "ดาวน์โหลดเทมเพลต .xls",
      "import": "นำเข้าจาก .xls",
      "import_file": "นำเข้าไฟล์",
      "loading": "กำลังโหลด",
      "more": "มากกว่า",
      "new": "ใหม่",
      "next": "ต่อไป",
      "no": "ไม่",
      "past": {
        "cancel": "ยกเลิก",
        "complete": "สมบูรณ์",
        "confirm": "ยืนยัน",
        "delete": "ลบแล้ว",
        "destroy": "ลบแล้ว",
        "reject": "ถูกปฏิเสธ",
        "revert": "หวนกลับ",
        "update": "อัพเดท"
      },
      "prev": "ก่อนหน้า",
      "reject": "ปฏิเสธ",
      "reset": "รีเซ็ต",
      "revert": "ย้อนกลับ",
      "save": "บันทึก",
      "save_button_keyboard": "บันทึก (Ctrl + Enter)",
      "save_exit": "บันทึก & ออก",
      "saving": "ประหยัด...",
      "schedule": "กำหนดการ",
      "skip": "ข้าม",
      "unsaved_changes": "การเปลี่ยนแปลงที่ยังไม่ได้บันทึก",
      "upload_file": "อัพโหลดไฟล์",
      "upload_picture": "อัพโหลดรูปภาพ",
      "yes": "ใช่"
    },
    "api_internal_messages": {
      "cant_duplicate_clustered_request": "ไม่สามารถทำซ้ำ Clustered Request",
      "cant_duplicate_progress": "ไม่สามารถทำซ้ำบริการได้เนื่องจากข้อผิดพลาดดังต่อไปนี้...",
      "cant_duplicate_request": "ไม่สามารถทำซ้ำขอบเขต การประมาณการ และบริการ เนื่องจากข้อผิดพลาดดังต่อไปนี้...",
      "cant_duplicate_scope": "ไม่สามารถทำซ้ำบริการได้เนื่องจากข้อผิดพลาดดังต่อไปนี้...",
      "only_estimate_service_copy": "เฉพาะรายการ Estimate Service เท่านั้นที่พยายามจะคัดลอก",
      "only_progress_copy": "เฉพาะความคืบหน้าเท่านั้นที่พยายามจะคัดลอก",
      "only_request_copy": "มีเพียงคำขอเท่านั้นที่พยายามจะคัดลอก",
      "only_scope_copy": "เฉพาะรายการกำหนดขอบเขตเท่านั้นที่พยายามจะคัดลอก"
    },
    "cancel_modal": {
      "cancel": "ไม่ใช่ตอนนี้",
      "description": "คุณแน่ใจหรือไม่ว่าต้องการยกเลิก %{model} \"X_1\"",
      "ok": "ใช่ ยกเลิก",
      "title": "ยกเลิกรายการ?"
    },
    "clear_records": {
      "modal": {
        "cancel": "ไม่ ยกเลิก",
        "description": "คุณแน่ใจหรือไม่ว่าต้องการล้างระเบียน %{model} ทั้งหมด",
        "ok": "ใช่ ล้างทั้งหมด",
        "title": "ล้าง %{model}?"
      },
      "success_message": "เคลียร์ X_0 สำเร็จแล้ว!",
      "title": "ล้างบันทึก"
    },
    "collections": {
      "clusters": "กลุ่ม",
      "create_by_package": "สร้างโดยแพ็คเกจ",
      "duplicate": "ทำซ้ำ",
      "edit": "แก้ไข",
      "estimate": "ประมาณการ",
      "new": "ใหม่",
      "schedule": "กำหนดการ",
      "sub_requests": "คำขอย่อย",
      "view": "ดู"
    },
    "complete_modal": {
      "cancel": "ไม่ใช่ตอนนี้",
      "description": "คุณแน่ใจหรือไม่ว่าต้องการทำเครื่องหมาย %{model} ว่าเสร็จสิ้น",
      "ok": "ใช่ ทำเครื่องหมายว่าเสร็จแล้ว",
      "title": "ทำเครื่องหมายว่าเสร็จแล้ว?"
    },
    "confirmation_modal": {
      "cancel": "ยกเลิก",
      "description": "การดำเนินการนี้ไม่สามารถยกเลิกได้!",
      "ok": "ตกลง",
      "title": "คุณแน่ใจไหม?"
    },
    "contract_services": {
      "title": "บริการทำสัญญา"
    },
    "datasheets": {
      "datasheets_detail": {
        "side_panel_title": "เอกสารข้อมูลรายละเอียด"
      },
      "id": "ไอดี",
      "item": "สิ่งของ",
      "title": "แผ่นข้อมูล"
    },
    "delete_modal": {
      "cancel": "ไม่ ยกเลิก",
      "description": "คุณแน่ใจหรือไม่ว่าต้องการลบ %{model} \"X_1\"",
      "ok": "ใช่ ลบ",
      "title": "ลบรายการ?"
    },
    "delete_recurrencies": {
      "modal": {
        "cancel": "ไม่ยกเลิก",
        "description": "คุณแน่ใจหรือไม่ว่าต้องการลบการเกิดซ้ำที่เกี่ยวข้องทั้งหมด?",
        "ok": "ใช่ ลบทั้งหมด",
        "title": "ลบการเกิดซ้ำทั้งหมดหรือไม่"
      },
      "success_message": "ลบการเกิดซ้ำสำเร็จแล้ว!",
      "title": "ลบการเกิดซ้ำทั้งหมด"
    },
    "dialogs": {
      "columns_batch_assigner": {
        "actions": "การกระทำ",
        "add_action": "เพิ่มการกระทำ",
        "add_value_to": "เพิ่ม %{value} ไปยัง",
        "column": "คอลัมน์",
        "done": "เสร็จแล้ว",
        "header": "แก้ไขแบทช์",
        "multiple_success": "อัปเดต X_0 รายการแล้ว",
        "new_value": "ค่าใหม่",
        "single_success": "อัปเดต 1 รายการแล้ว",
        "subtitle": "กำหนดค่าให้กับ %{numberOfItems} รายการที่เลือก",
        "title": "สร้างการกระทำ",
        "update": "อัปเดต"
      }
    },
    "discard_modal": {
      "cancel": "แก้ไขต่อไป",
      "description": "คุณแน่ใจหรือไม่ว่าต้องการยกเลิกการเปลี่ยนแปลงของคุณ",
      "ok": "ใช่ ทิ้ง",
      "title": "ยกเลิกการเปลี่ยนแปลง"
    },
    "eav_templates": {
      "title": "แม่แบบ"
    },
    "empty_state": {
      "empty": "ไม่มี %{model}",
      "not_created": "คุณยังไม่ได้สร้าง %{model} เลย"
    },
    "environment": {
      "project": {
        "select_project": "เลือกโครงการ..."
      },
      "select_project_subproject": "เลือกโครงการและโครงการย่อย",
      "subproject": {
        "select_subproject": "เลือกโครงการย่อย..."
      }
    },
    "estimate": {
      "accept": "ยอมรับการประมาณการ",
      "application": "ค่าสมัคร",
      "back": "คำขอทั้งหมด",
      "budget_hours": "ชั่วโมงงบประมาณ",
      "confirmation": {
        "cancel": "ไม่ ยกเลิก",
        "confirm": "ใช่ ยืนยัน",
        "description": "คุณแน่ใจหรือไม่ว่าต้องการอนุมัติการประมาณการนี้สำหรับคำขอนี้",
        "title": "ยืนยันรายการ"
      },
      "description": "คุณมีบริการ %{number} สำหรับการชำระเงิน กรุณาตรวจสอบรายละเอียดด้านล่าง",
      "equipment": "ค่าอุปกรณ์",
      "estimate_details": "รายละเอียดการประมาณการ",
      "estimate_success": "อนุมัติประมาณการเรียบร้อยแล้ว",
      "info_message": "การประเมินนี้ได้รับการอนุมัติแล้ว มีให้อ่านเท่านั้น",
      "material": "ค่าวัสดุ",
      "no_estimate_message": "ไม่มีค่าประมาณที่เกี่ยวข้องกับคำขอนี้",
      "norm_hours": "ชั่วโมงปกติ",
      "others": "ค่าใช้จ่ายอื่นๆ",
      "save_draft": "บันทึกร่าง",
      "service_details": "รายละเอียดบริการ",
      "team_target_hours": "ชั่วโมงเป้าหมายของทีม",
      "title": "บริการชำระเงิน",
      "tooltip": {
        "application": "แอปพลิเคชัน",
        "equipment": "อุปกรณ์",
        "material": "วัสดุ",
        "others": "คนอื่น"
      },
      "total_request": "ค่าใช้จ่ายทั้งหมด"
    },
    "estimate_services": {
      "application_price": "ราคาสมัคร",
      "automatically_prices": "ราคาที่คำนวณโดยอัตโนมัติ",
      "back": "ขอบเขตทั้งหมด",
      "budget_target_hours": "งบประมาณเป้าหมายชั่วโมง",
      "choose_modal": {
        "package": "บริการจากแพ็คเกจ",
        "package_description": "การสร้างบริการต่างๆ ตามรูปแบบกิจกรรมเดียวกัน",
        "single": "รายการบริการเดียว",
        "single_description": "สร้างบริการเดียวเท่านั้น",
        "title": "สร้างใหม่"
      },
      "contract_service": "บริการทำสัญญา",
      "crew_size": "ขนาดลูกเรือ",
      "crews": "จำนวนลูกเรือ",
      "description": "บริการประเมินราคา",
      "description_label": "คำอธิบาย",
      "discipline": "การลงโทษ",
      "equipment_price": "ราคาอุปกรณ์",
      "estimate": "ประมาณการ",
      "estimate_services_detail": {
        "side_panel_title": "รายละเอียดบริการ"
      },
      "factor": "ปัจจัย",
      "id": "ไอดี",
      "materials_price": "ราคาวัสดุ",
      "no_template": "คุณต้องสร้างเทมเพลตสำหรับบริการประมาณการ",
      "norm_hours": "ชั่วโมงปกติ",
      "other_price": "ราคาอื่นๆ",
      "prices": "ราคา",
      "quantity": "ปริมาณ",
      "service": "บริการ",
      "service_package": "แพ็คเกจบริการ",
      "team_target_hours": "ชั่วโมงเป้าหมายของทีม",
      "title": "บริการประเมินราคา",
      "title_packageable": "ประเมินบริการตามแพ็คเกจบริการ",
      "total": "ทั้งหมด",
      "unit": "หน่วย",
      "weight": "น้ำหนัก",
      "working_hours": "ชั่วโมงทำงาน"
    },
    "fields": {
      "image_not_found": "ไม่พบภาพ",
      "na": "ไม่มี",
      "signature": "Signature.jpg"
    },
    "filter_steps_modal": {
      "filter_result": "ผลการกรอง",
      "step": "ขั้นตอน",
      "subtitle": "ให้ข้อมูลบางอย่างเกี่ยวกับ %{title}"
    },
    "filtering": {
      "between": " นั่นคือระหว่าง",
      "contains": " ที่ประกอบด้วย",
      "eq": " ที่เท่ากับ",
      "invalid_date": "ในการกรองอย่างถูกต้อง ต้องกรอกวันที่เริ่มต้นและวันที่สิ้นสุด",
      "invalid_value": "ค่าไม่ถูกต้อง",
      "neq": " ที่ไม่เท่ากับ"
    },
    "form": {
      "back": "ทั้งหมด %{entity}",
      "cancel": "ยกเลิก",
      "create_entity": "สร้าง %{entity}",
      "create_error": "ไม่สามารถสร้าง %{entity} ได้",
      "create_success": "สร้าง %{entity} สำเร็จแล้ว",
      "duplicate_entity": "ซ้ำ %{entity}",
      "duplicate_success": "%{entity} และการขึ้นต่อกันถูกทำซ้ำสำเร็จ!",
      "general_information": "ข้อมูลทั่วไป",
      "hide": "ซ่อน",
      "inputs": {
        "attachment": {
          "add_error": "เกิดข้อผิดพลาดในการเพิ่มไฟล์",
          "add_evidence": "เพิ่มหลักฐานการอนุญาตลูกค้า",
          "allowed_extensions": "นามสกุลที่อนุญาตคือ",
          "drag_drop": "เพิ่มไฟล์โดยใช้การลากและวาง",
          "invalid_extension": "ไม่อนุญาตประเภทไฟล์ชนิดนี้.",
          "max_size": "ไฟล์ต้องน้อยกว่า %{max_size}",
          "maximum_num_uploads_error": "คุณสามารถโหลดไฟล์ได้สูงสุด %{max_num} ไฟล์ต่อคอลัมน์แบบยืดหยุ่น",
          "one_file": "อัพโหลดทีละไฟล์",
          "or_drop_file_here": "หรือวางไฟล์ที่นี่",
          "remove_error": "ไม่สามารถลบออก",
          "remove_success": "ลบเรียบร้อยแล้ว",
          "select_files": "เลือกไฟล์",
          "submit": "ส่งเอกสารแนบ",
          "upload_error": "การอัพโหลดล้มเหลว",
          "upload_success": "อัพโหลดสำเร็จ"
        },
        "contract_service": {
          "add": "เพิ่มบริการทำสัญญา",
          "subtitle": "บริการทำสัญญา"
        },
        "datasheet_filter": {
          "add_filter": "เพิ่มตัวกรอง",
          "title": "ตัวกรองแผ่นข้อมูล"
        },
        "date_range": {
          "between": "%{start} ถึง X_1",
          "end": "วันที่สิ้นสุด",
          "start": "วันที่เริ่มต้น"
        },
        "drop_formula": {
          "formula_select": "กรุณาเลือกสูตร..."
        },
        "errors": {
          "mandatory": "ต้องระบุ %{field}",
          "not_found": "ไม่พบ '%{value}' ในข้อมูลอ้างอิง เลือกอันใหม่"
        },
        "formula": {
          "result": "ผลลัพธ์"
        },
        "gps": {
          "save_location": "บันทึกตำแหน่ง",
          "search": "ค้นหาตำแหน่ง...",
          "without_location": "ไม่มีที่ตั้ง"
        },
        "picture": {
          "edition_only": "ใช้ได้เฉพาะเมื่อแก้ไข",
          "five_pictures_limit_warning": "อนุญาตให้มีรูปภาพได้สูงสุด 5 รูปเท่านั้น",
          "no_image_on_clipboard": "ไม่พบข้อมูลรูปภาพในคลิปบอร์ดของคุณ กรุณาคัดลอกรูปภาพก่อน",
          "remove_error": "ไม่สามารถลบออก",
          "remove_success": "ลบเรียบร้อยแล้ว",
          "upload_error": "การอัพโหลดล้มเหลว",
          "upload_success": "อัพโหลดสำเร็จ"
        },
        "placeholder": "ป้อน %{field} ที่นี่",
        "value_selector": {
          "apply": "นำมาใช้",
          "done": "เสร็จแล้ว",
          "search": "ค้นหาบางอย่างที่นี่...",
          "waiting": "... รอ"
        }
      },
      "loading": "กำลังโหลด...",
      "schedule_entity": "กำหนดการ %{entity}",
      "schedule_success": "คำขอกำหนดเวลา %{entity} ของคุณเสร็จสิ้นแล้ว",
      "show": "แสดง",
      "subtitle": "โปรดให้ข้อมูลพื้นฐานเกี่ยวกับ %{entity}",
      "subtitle_error": "ไม่สามารถโหลดแบบฟอร์มได้ โปรดตรวจสอบ %{entity} หรือข้อมูลเทมเพลต",
      "update_entity": "แก้ไข %{entity}",
      "update_error": "ไม่สามารถอัปเดต %{entity} ได้",
      "update_success": "อัปเดต %{entity} เรียบร้อยแล้ว"
    },
    "formula_modal": {
      "back_button_text": "กลับไปที่แบบฟอร์ม",
      "calculate": "คำนวณ",
      "cancel": "ยกเลิก",
      "mandatory": "คุณต้องกรอกค่าสำหรับฟิลด์ต่อไปนี้: %{fields}",
      "ok": "บันทึกสูตร"
    },
    "grid": {
      "all": "ทั้งหมด",
      "clear_filters": "ล้างตัวกรอง",
      "editable": {
        "error": {
          "mandatory": "เว้นช่องที่ต้องกรอกอย่างน้อยหนึ่งช่อง"
        },
        "title": "โหมดแก้ไข",
        "update": {
          "error": "ไม่สามารถบันทึก %{quantity} ได้ ตรวจสอบเหตุผลทางด้านซ้ายของแต่ละแถวที่เน้น",
          "success": "บันทึก %{quantity} สำเร็จแล้ว"
        }
      },
      "empty_after_filtering": "ไม่มีบันทึกสำหรับตัวกรองที่ใช้",
      "grid_filters": {
        "menu_title": "ตัวกรองแบบกำหนดเอง"
      },
      "menu": {
        "visibility": "คอลัมน์"
      },
      "of": "ของ",
      "other": "คนอื่น",
      "productivity_factor": "ปัจจัยการผลิต",
      "select_items": "เลือกรายการ",
      "selected": "เลือกแล้ว",
      "selecteds": "เลือกแล้ว",
      "totals": "ยอดรวม"
    },
    "history_popup": {
      "back": "กลับ",
      "comments": "ความคิดเห็น",
      "crew_size": "ขนาดลูกเรือ",
      "daily_hours": "ชั่วโมงรายวัน",
      "date": "วันที่",
      "form_create_progress_service_title": "สร้างประวัติรายการบริการด้านประสิทธิภาพ",
      "form_create_progress_title": "สร้างประวัติรายการความคืบหน้า",
      "form_edit_progress_service_title": "แก้ไขประวัติรายการบริการด้านประสิทธิภาพ",
      "form_edit_progress_title": "แก้ไขประวัติรายการความคืบหน้า",
      "form_error_body": "ต้องกรอกข้อมูลในช่องที่ต้องกรอกทั้งหมดในแบบฟอร์ม",
      "form_error_title": "ไม่สามารถบันทึกแบบฟอร์มประวัติได้",
      "form_subtitle": "แทรกประวัติตามปริมาณหรือเปอร์เซ็นต์สำหรับกิจกรรมของคุณ",
      "modal_create_title": "สร้างประวัติศาสตร์",
      "modal_edit_title": "แก้ไขประวัติ",
      "number_of_crews": "จำนวนลูกเรือ",
      "number_of_workers": "จำนวนคนงาน",
      "other_value": "ค่าอื่นๆ",
      "percentage": "เปอร์เซ็นต์",
      "progress_by": "ความคืบหน้าโดย",
      "quantity": "ปริมาณ",
      "singular": "ประวัติศาสตร์",
      "working_hours": "ชั่วโมงทำงาน"
    },
    "home": {
      "available_on": "มีจำหน่ายที่",
      "banner_description": "จัดการงาน ติดตามกระบวนการ และรับผลลัพธ์ที่ดีที่สุดโดยใช้แพลตฟอร์มเดียว",
      "create_request": {
        "text": "ก่อนอื่น คุณต้องสร้างคำขอจากลูกค้าใน DPMS พร้อมรายละเอียดเกี่ยวกับคำสั่งซื้อของคุณ",
        "title": "สร้างคำขอ"
      },
      "dpms_mobile": "แอพมือถือ DPMS",
      "getting_started": "เริ่มต้น",
      "home_title": "บ้าน",
      "inspection": {
        "text": "ดำเนินการตรวจสอบคุณภาพในพื้นที่และบันทึกอุปสรรค",
        "title": "การตรวจสอบ"
      },
      "performance": {
        "text": "บันทึกความคืบหน้าและเวลาตามกิจกรรมที่วางแผนไว้ของแต่ละทีมและวัดประสิทธิภาพการทำงาน",
        "title": "ประสิทธิภาพ"
      },
      "scoping": {
        "text": "ขอบเขตคำขอจากลูกค้า กำหนดบริการและแพ็คเกจบริการจากสัญญาของคุณ",
        "title": "การกำหนดขอบเขต"
      },
      "welcome_user": "ยินดีต้อนรับ %{user}"
    },
    "importation_drop": {
      "choose_error": "เลือกรายการ"
    },
    "importation_popup": {
      "file_upload_message": "คลิกหรือลากไฟล์มาที่บริเวณนี้เพื่ออัปโหลด",
      "importation_error": "คุณสามารถตรวจสอบข้อความนี้บนหน้างาน",
      "importation_success": "ความสำเร็จในการนำเข้า",
      "importation_timeout": "การนำเข้าใช้เวลานานเกินไป ตรวจสอบสถานะในหน้างาน",
      "instructions": "ไฟล์ต้องเป็น .xls และไม่เกิน xxmb<br> ไฟล์ .xls ของคุณควรมีคอลัมน์ - Date, Client, Project, Task, Notes....<br> ลำดับของคอลัมน์ควรเหมือนกับ ลำดับของเทมเพลต<br> วันที่ควรเป็น - YYYY/MM/DD",
      "requirements": "ความต้องการ",
      "submit_spreadsheet": "ส่งสเปรดชีตของคุณ"
    },
    "inspections": {
      "acceptance": "การยอมรับ",
      "acceptance_modal": {
        "inspection_acceptance": "การยอมรับการตรวจสอบ",
        "reject": "ปฏิเสธ",
        "subtitle": "ให้ข้อมูลเกี่ยวกับการตรวจสอบเพื่อดำเนินการต่อด้วยการยอมรับ"
      },
      "actions": {
        "approve": "อนุมัติ",
        "more": "มากกว่า",
        "reject": "ปฏิเสธ"
      },
      "choose_modal": {
        "schedule": "รายการตรวจสอบกำหนดการ",
        "schedule_description": "กำหนดการตรวจสอบหนึ่งหรือหลายรายการตามช่วงวันที่",
        "single": "รายการตรวจสอบเดียว",
        "single_description": "สร้างการตรวจสอบเพียงหนึ่งเดียว",
        "title": "สร้างการตรวจสอบเป็น:"
      },
      "description": "การตรวจสอบ",
      "every": "ทั้งหมด",
      "id": "ไอดี",
      "inspect_date": "วันที่ตรวจสอบ",
      "inspect_status_id": "สถานะ",
      "inspected": "ตรวจสอบแล้ว",
      "inspected_detail": "ตรวจสอบรายละเอียด",
      "inspected_modules": {
        "progress": "ประสิทธิภาพ",
        "request": "ขอ",
        "scope": "การกำหนดขอบเขต"
      },
      "inspections_detail": {
        "side_panel_title": "รายละเอียดการตรวจสอบ"
      },
      "module": "โมดูล",
      "notifications": {
        "approve_successful": "การตรวจสอบได้รับการอนุมัติเรียบร้อยแล้ว",
        "reject_successful": "การตรวจสอบถูกปฏิเสธเรียบร้อยแล้ว"
      },
      "recurrency_interval": "ช่วงเวลาการเกิดซ้ำ",
      "repeat": "ทำซ้ำ",
      "repeat_every_value": {
        "day": "วัน",
        "month": "เดือน",
        "week": "สัปดาห์",
        "year": "ปี"
      },
      "repeats_on": "ทำซ้ำใน",
      "request_id": "DPMSID",
      "responsible_id": "รับผิดชอบ",
      "schedule_detail": "รายละเอียดกำหนดการ",
      "schedule_form": {
        "acceptance_step": "กำหนดการยอมรับ (ไม่บังคับ)",
        "detailed_item_step": "เลือกขอบเขตการตรวจสอบ/รายการประสิทธิภาพ",
        "detailed_template_step": "เลือกขอบเขตการตรวจสอบ/เทมเพลตประสิทธิภาพ",
        "further_step_information": "ฟิลด์ที่ยืดหยุ่นพร้อมที่จะกรอกข้อมูลทีละรายการหลังจากขั้นตอนการสร้างซ้ำ",
        "general_step": "กำหนดการตรวจสอบ ข้อมูลทั่วไป",
        "hints": "สำหรับการเกิดซ้ำรายเดือนและรายปี ระบบจะกำหนดเวลาการตรวจสอบที่ตรงกับวันที่เริ่มต้นที่ให้ไว้ใน Interval Field",
        "monthly_hint": "ซ้ำทุกเดือน ทุกวัน %{day}",
        "recurrence_step": "การเกิดซ้ำ"
      },
      "scheduled_exception": "กำหนดการ",
      "statuses": {
        "approved": "ที่ได้รับการอนุมัติ",
        "completed": "สมบูรณ์",
        "pending": "รอดำเนินการ",
        "progress": "กำลังดำเนินการ",
        "rejected": "ถูกปฏิเสธ"
      },
      "template": "เลือกเทมเพลต",
      "title": "การตรวจสอบ"
    },
    "inspects": {
      "statuses": {
        "approved": "ที่ได้รับการอนุมัติ",
        "completed": "สมบูรณ์",
        "pending": "รอดำเนินการ",
        "progress": "กำลังดำเนินการ",
        "rejected": "ถูกปฏิเสธ"
      }
    },
    "kendo": {
      "upload": "คลิกหรือลากไฟล์มาที่นี่เพื่ออัปโหลด"
    },
    "kendo_translations": {
      "calendar": {
        "today": "วันนี้"
      },
      "dateinput": {
        "decrement": "ลดค่า",
        "increment": "เพิ่มมูลค่า"
      },
      "datepicker": {
        "toggleCalendar": "สลับปฏิทิน"
      },
      "daterangepicker": {
        "end": "จบ",
        "separator": 0,
        "start": "เริ่ม",
        "swapStartEnd": "สลับค่าเริ่มต้นและสิ้นสุด"
      },
      "datetimepicker": {
        "cancel": "ยกเลิก",
        "date": "วันที่",
        "set": "ชุด",
        "time": "เวลา",
        "toggleDateTimeSelector": "สลับตัวเลือกวันที่-เวลา"
      },
      "dropdowns": {
        "clear": "ชัดเจน",
        "nodata": "ไม่พบข้อมูล"
      },
      "editor": {
        "addColumnAfter": "เพิ่มคอลัมน์ทางด้านขวา",
        "addColumnBefore": "เพิ่มคอลัมน์ทางด้านซ้าย",
        "addRowAfter": "เพิ่มแถวด้านล่าง",
        "addRowBefore": "เพิ่มแถวด้านบน",
        "alignCenter": "ข้อความตรงกลาง",
        "alignJustify": "ให้เหตุผล",
        "alignLeft": "จัดข้อความชิดซ้าย",
        "alignRight": "จัดข้อความชิดขวา",
        "bold": "ตัวหนา",
        "bulletList": "แทรกรายการที่ไม่เรียงลำดับ",
        "createTable": "สร้างตาราง",
        "deleteColumn": "ลบคอลัมน์",
        "deleteRow": "ลบแถว",
        "deleteTable": "ลบตาราง",
        "fontName": "ชื่อแบบอักษร",
        "fontSize": "ขนาดตัวอักษร",
        "format": "รูปแบบ",
        "hyperlink": "แทรกไฮเปอร์ลิงก์",
        "hyperlink-dialog-cancel": "ยกเลิก",
        "hyperlink-dialog-content-address": "ที่อยู่เว็บ",
        "hyperlink-dialog-content-newwindow": "เปิดลิงก์ในหน้าต่างใหม่",
        "hyperlink-dialog-content-title": "ชื่อ",
        "hyperlink-dialog-insert": "แทรก",
        "hyperlink-dialog-title": "แทรกไฮเปอร์ลิงก์",
        "image": "แทรกรูปภาพ",
        "image-address": "ที่อยู่เว็บ",
        "image-altText": "ข้อความสำรอง",
        "image-cancel": "ยกเลิก",
        "image-dialog-title": "แทรกรูปภาพ",
        "image-height": "ความสูง (px)",
        "image-insert": "แทรก",
        "image-title": "ชื่อ",
        "image-width": "ความกว้าง (px)",
        "indent": "เยื้อง",
        "insertTableHint": "สร้างตาราง 0 x 1",
        "italic": "ตัวเอียง",
        "mergeCells": "ผสานเซลล์",
        "orderedList": "แทรกรายการสั่งซื้อ",
        "outdent": "Outdent",
        "redo": "ทำซ้ำ",
        "splitCell": "แยกเซลล์",
        "strikethrough": "ขีดฆ่า",
        "subscript": "สมัครสมาชิก",
        "superscript": "ตัวยก",
        "underline": "ขีดเส้นใต้",
        "undo": "เลิกทำ",
        "unlink": "ลบไฮเปอร์ลิงก์",
        "viewHtml": "ดู HTML",
        "viewHtml-cancel": "ยกเลิก",
        "viewHtml-dialog-title": "ดู HTML",
        "viewHtml-update": "อัปเดต"
      },
      "filter": {
        "addExpression": "เพิ่มนิพจน์",
        "addGroup": "เพิ่มกลุ่ม",
        "afterOperator": "คือหลังจาก",
        "afterOrEqualOperator": "อยู่หลังหรือเท่ากับ",
        "andLogic": "และ",
        "beforeOperator": "มาก่อน",
        "beforeOrEqualOperator": "อยู่ก่อนหรือเท่ากับ",
        "close": "ปิด I",
        "containsOperator": "ประกอบด้วย",
        "endsWithOperator": "ลงท้ายด้วย",
        "eqOperator": "เท่ากับ",
        "gtOperator": "มีค่ามากกว่า",
        "gteOperator": "มากกว่าหรือเท่ากับ",
        "isEmptyOperator": "มันว่างเปล่า",
        "isFalse": "เป็นเท็จ",
        "isNotEmptyOperator": "ไม่ว่าง",
        "isNotNullOperator": "ไม่เป็นโมฆะ",
        "isNullOperator": "เป็นโมฆะ",
        "isTrue": "เป็นความจริง",
        "ltOperator": "น้อยกว่า",
        "lteOperator": "น้อยกว่าหรือเท่ากับ",
        "notContainsOperator": "ไม่มี",
        "notEqOperator": "ไม่เท่ากับ",
        "orLogic": "หรือ",
        "startsWithOperator": "เริ่มต้นด้วย"
      },
      "grid": {
        "filterAfterOperator": "คือหลังจาก",
        "filterAfterOrEqualOperator": "อยู่หลังหรือเท่ากับ",
        "filterBeforeOperator": "มาก่อน",
        "filterBeforeOrEqualOperator": "อยู่ก่อนหรือเท่ากับ",
        "filterBetweenOperator": "อยู่ระหว่าง",
        "filterBooleanAll": "(ทั้งหมด)",
        "filterClearButton": "ชัดเจน",
        "filterContainsOperator": "ประกอบด้วย",
        "filterEndsWithOperator": "ลงท้ายด้วย",
        "filterEqOperator": "เท่ากับ",
        "filterGtOperator": "มีค่ามากกว่า",
        "filterGteOperator": "มากกว่าหรือเท่ากับ",
        "filterIsEmptyOperator": "มันว่างเปล่า",
        "filterIsFalse": "เป็นเท็จ",
        "filterIsNotEmptyOperator": "ไม่ว่าง",
        "filterIsNotNullOperator": "ไม่เป็นโมฆะ",
        "filterIsNullOperator": "เป็นโมฆะ",
        "filterIsTrue": "เป็นความจริง",
        "filterLtOperator": "น้อยกว่า",
        "filterLteOperator": "น้อยกว่าหรือเท่ากับ",
        "filterNotContainsOperator": "ไม่มี",
        "filterNotEqOperator": "ไม่เท่ากับ",
        "filterStartsWithOperator": "เริ่มต้นด้วย",
        "filterSubmitButton": "กรอง",
        "filterTitle": "กรอง",
        "groupPanelEmpty": "ลากส่วนหัวของคอลัมน์แล้ววางที่นี่เพื่อจัดกลุ่มตามคอลัมน์นั้น",
        "noRecords": "ไม่มีบันทึก",
        "pagerFirstPage": "ไปที่หน้าแรก",
        "pagerInfo": "0 - 1 จาก 2 รายการ",
        "pagerItemsPerPage": "รายการต่อหน้า",
        "pagerLastPage": "ไปที่หน้าสุดท้าย",
        "pagerNextPage": "ไปหน้าถัดไป",
        "pagerOf": "ของ",
        "pagerPage": "หน้าหนังสือ",
        "pagerPreviousPage": "ไปหน้าที่แล้ว"
      },
      "numerictextbox": {
        "decrement": "ลดค่า",
        "increment": "เพิ่มมูลค่า"
      },
      "pager": {
        "firstPage": "ไปที่หน้าแรก",
        "info": "0 - 1 จาก 2 รายการ",
        "itemsPerPage": "รายการต่อหน้า",
        "lastPage": "ไปที่หน้าสุดท้าย",
        "nextPage": "ไปหน้าถัดไป",
        "of": "ของ",
        "page": "หน้าหนังสือ",
        "previousPage": "ไปหน้าที่แล้ว"
      },
      "scheduler": {
        "agendaViewTitle": "กำหนดการ",
        "allDay": "ทั้งวัน",
        "allEvents": "งานกิจกรรมทั้งหมด",
        "dateTitle": "วันที่",
        "dayViewTitle": "วัน",
        "deleteConfirmation": "คุณแน่ใจหรือไม่ว่าต้องการลบกิจกรรมนี้",
        "deleteDialogTitle": "ลบกิจกรรม",
        "deleteOccurrence": "ลบเหตุการณ์ปัจจุบัน",
        "deleteRecurringConfirmation": "คุณต้องการลบเฉพาะเหตุการณ์ที่เกิดขึ้นนี้หรือทั้งชุด?",
        "deleteRecurringDialogTitle": "ลบรายการที่เกิดซ้ำ",
        "deleteSeries": "ลบซีรีส์",
        "deleteTitle": "ลบ",
        "editorCancel": "ยกเลิก",
        "editorDelete": "ลบ",
        "editorEventAllDay": "กิจกรรมทั้งวัน",
        "editorEventDescription": "คำอธิบาย",
        "editorEventEnd": "จบ",
        "editorEventEndTimeZone": "โซนเวลาสิ้นสุด",
        "editorEventSeparateTimeZones": "สิ้นสุดในโซนเวลาที่แตกต่างกัน",
        "editorEventStart": "เริ่ม",
        "editorEventStartTimeZone": "เริ่มเขตเวลา",
        "editorEventTimeZone": "ระบุโซนเวลา",
        "editorEventTitle": "ชื่อ",
        "editorOccurrence": "แก้ไขเหตุการณ์ปัจจุบัน",
        "editorRecurringConfirmation": "คุณต้องการแก้ไขเฉพาะเหตุการณ์ที่เกิดขึ้นหรือทั้งชุด?",
        "editorRecurringDialogTitle": "แก้ไขรายการที่เกิดซ้ำ",
        "editorSave": "บันทึก",
        "editorSeries": "แก้ไขซีรีส์",
        "editorTitle": "เหตุการณ์",
        "editorValidationEnd": "เวลาสิ้นสุดต้องอยู่หลังเวลาเริ่มต้น",
        "editorValidationRequired": "ต้องระบุฟิลด์",
        "editorValidationStart": "เวลาเริ่มต้นต้องมาก่อนเวลาสิ้นสุด",
        "eventTitle": "เหตุการณ์",
        "monthViewTitle": "เดือน",
        "nextTitle": "ต่อไป",
        "noEvents": "ไม่มีเหตุการณ์",
        "previousTitle": "ก่อนหน้า",
        "recurrenceEditorDailyInterval": "วัน",
        "recurrenceEditorDailyRepeatEvery": "ทำซ้ำทุก ๆ",
        "recurrenceEditorEndAfter": "หลังจาก",
        "recurrenceEditorEndLabel": "จบ",
        "recurrenceEditorEndNever": "ไม่เคย",
        "recurrenceEditorEndOccurrence": "เหตุการณ์ที่เกิดขึ้น",
        "recurrenceEditorEndOn": true,
        "recurrenceEditorFrequenciesDaily": "รายวัน",
        "recurrenceEditorFrequenciesMonthly": "รายเดือน",
        "recurrenceEditorFrequenciesNever": "ไม่เคย",
        "recurrenceEditorFrequenciesWeekly": "รายสัปดาห์",
        "recurrenceEditorFrequenciesYearly": "รายปี",
        "recurrenceEditorMonthlyDay": "วัน",
        "recurrenceEditorMonthlyInterval": "เดือน",
        "recurrenceEditorMonthlyRepeatEvery": "ทำซ้ำทุก ๆ",
        "recurrenceEditorMonthlyRepeatOn": "ทำซ้ำใน",
        "recurrenceEditorOffsetPositionsFirst": "อันดับแรก",
        "recurrenceEditorOffsetPositionsFourth": "ที่สี่",
        "recurrenceEditorOffsetPositionsLast": "ล่าสุด",
        "recurrenceEditorOffsetPositionsSecond": "ที่สอง",
        "recurrenceEditorOffsetPositionsThird": "ที่สาม",
        "recurrenceEditorRepeat": "ทำซ้ำ",
        "recurrenceEditorWeekdaysDay": "วัน",
        "recurrenceEditorWeekdaysWeekday": "วันธรรมดา",
        "recurrenceEditorWeekdaysWeekendday": "วันหยุดสุดสัปดาห์",
        "recurrenceEditorWeeklyInterval": "สัปดาห์",
        "recurrenceEditorWeeklyRepeatEvery": "ทำซ้ำทุก ๆ",
        "recurrenceEditorWeeklyRepeatOn": "ทำซ้ำใน",
        "recurrenceEditorYearlyInterval": "ปี)",
        "recurrenceEditorYearlyOf": "ของ",
        "recurrenceEditorYearlyRepeatEvery": "ทำซ้ำทุก ๆ",
        "recurrenceEditorYearlyRepeatOn": "ทำซ้ำใน",
        "showFullDay": "แสดงเต็มวัน",
        "showWorkDay": "แสดงเวลาทำการ",
        "timeTitle": "เวลา",
        "timelineViewTitle": "เส้นเวลา",
        "today": "วันนี้",
        "weekViewTitle": "สัปดาห์",
        "workWeekViewTitle": "สัปดาห์การทำงาน"
      },
      "sortable": {
        "noData": "ไม่มีข้อมูล"
      },
      "timepicker": {
        "cancel": "ยกเลิก",
        "now": "ตอนนี้",
        "selectNow": "เลือกเลย",
        "set": "ชุด",
        "toggleClock": "สลับนาฬิกา",
        "toggleTimeSelector": "Toggle TimeSelector"
      },
      "treelist": {
        "filterAfterOperator": "คือหลังจาก",
        "filterAfterOrEqualOperator": "อยู่หลังหรือเท่ากับ",
        "filterBeforeOperator": "มาก่อน",
        "filterBeforeOrEqualOperator": "อยู่ก่อนหรือเท่ากับ",
        "filterBooleanAll": "(ทั้งหมด)",
        "filterClearButton": "ชัดเจน",
        "filterContainsOperator": "ประกอบด้วย",
        "filterEndsWithOperator": "ลงท้ายด้วย",
        "filterEqOperator": "เท่ากับ",
        "filterGtOperator": "มีค่ามากกว่า",
        "filterGteOperator": "มากกว่าหรือเท่ากับ",
        "filterIsEmptyOperator": "มันว่างเปล่า",
        "filterIsFalse": "เป็นเท็จ",
        "filterIsNotEmptyOperator": "ไม่ว่าง",
        "filterIsNotNullOperator": "ไม่เป็นโมฆะ",
        "filterIsNullOperator": "เป็นโมฆะ",
        "filterIsTrue": "เป็นความจริง",
        "filterLtOperator": "น้อยกว่า",
        "filterLteOperator": "น้อยกว่าหรือเท่ากับ",
        "filterNotContainsOperator": "ไม่มี",
        "filterNotEqOperator": "ไม่เท่ากับ",
        "filterStartsWithOperator": "เริ่มต้นด้วย",
        "noRecords": "ไม่มีบันทึก"
      },
      "upload": {
        "cancel": "ยกเลิก",
        "clearSelectedFiles": "ชัดเจน",
        "dropFilesHere": "หรือวางไฟล์ที่นี่",
        "files": "ไฟล์",
        "headerStatusUploaded": "เสร็จแล้ว",
        "headerStatusUploading": "กำลังอัปโหลด...",
        "invalidFileExtension": "ไม่อนุญาตประเภทไฟล์ชนิดนี้.",
        "invalidFiles": "ไฟล์ไม่ถูกต้อง โปรดตรวจสอบข้อกำหนดในการอัปโหลดไฟล์",
        "invalidMaxFileSize": "ขนาดไฟล์ใหญ่เกินไป",
        "invalidMinFileSize": "ขนาดไฟล์เล็กเกินไป",
        "remove": "ลบ",
        "retry": "ลองอีกครั้ง",
        "select": "เลือกไฟล์",
        "total": "ทั้งหมด",
        "uploadSelectedFiles": "ที่อัพโหลด"
      }
    },
    "loading_batch_entities": {
      "page_of": "หน้า %{page} จาก X_1",
      "title": "กำลังดึงหน้า"
    },
    "login": {
      "again": "หากต้องการดำเนินการต่อ โปรดเข้าสู่ระบบอีกครั้ง",
      "continue_mykaefer": "ดำเนินการต่อด้วย MyKaefer",
      "copyright": "®%{year} สงวนลิขสิทธิ์ Kaefer®",
      "forgot_password": "ลืมรหัสผ่าน?",
      "iframe_info": "กำลังขอเข้าถึงหน้า: %{link}",
      "log_in": "เข้าสู่ระบบ",
      "password": "รหัสผ่าน",
      "title_1": "เครื่องมืออันทรงพลัง",
      "title_2": "สำหรับการบริหารโครงการ",
      "username": "ชื่อผู้ใช้",
      "welcome_back": "ยินดีต้อนรับกลับ"
    },
    "main_dialog": {
      "failure_message": "คำขอของคุณไม่เสร็จสมบูรณ์",
      "failure_title": "ความล้มเหลว",
      "loading_message": "โปรดรอสักครู่ในขณะที่เราจัดเตรียมสิ่งต่างๆ ให้คุณ!",
      "loading_title": "กำลังโหลด...",
      "service_title": "รอจบงาน...",
      "success_message": "คำขอของคุณสำเร็จแล้ว! ตอนนี้คุณสามารถทำงานของคุณต่อไปได้",
      "success_title": "ประสบความสำเร็จ!"
    },
    "notification": {
      "access_denied": "ปฏิเสธการเข้าใช้",
      "action_success": "%{model} X_1 เป็น %{action}",
      "bad_formula_setup": "มีปัญหาในการตั้งค่ากับสูตร '%{title}' โปรดตรวจสอบการตั้งค่าของคุณ!",
      "correct_subproject": "เลือกโปรเจ็กต์ย่อยที่ถูกต้องของรายการนี้",
      "dismiss_all": "ปิดทั้งหมด",
      "error": "ข้อผิดพลาด",
      "error_cookies": "เราไม่สามารถสร้างการสื่อสารระหว่างแหล่งที่มาข้ามได้ ตรวจสอบการตั้งค่าคุกกี้ในเบราว์เซอร์ของคุณและปิดใช้งาน 'การบล็อกคุกกี้ของบุคคลที่สาม'",
      "error_job": "ล้มเหลว! ตรวจสอบเหตุผลในหน้างาน",
      "error_msg": "เสียใจ! บางอย่างผิดพลาด!",
      "info": "ข้อมูล",
      "large_file_body": "โปรดใช้ตัวกรองเพื่อทำให้ไฟล์ที่ส่งออกมีขนาดเล็กลง",
      "large_file_title": "กริดมีมากกว่า 1,000 รายการ",
      "page_denied": "ไม่สามารถโหลดหน้านี้ได้",
      "please": "โปรด",
      "select_subproject": "เลือกโครงการและโครงการย่อยเพื่อดำเนินการต่อ",
      "success": "ความสำเร็จ",
      "success_msg": "ลงมือทำสำเร็จ!",
      "warning": "คำเตือน"
    },
    "orders": {
      "add_evidence": "เพิ่มหลักฐานการอนุญาตลูกค้า",
      "attachment": "เอกสารแนบ",
      "comments": "ความคิดเห็น",
      "date": "วันที่",
      "drag_and_drop": "เพิ่มไฟล์โดยใช้การลากและวาง",
      "responsible": "รับผิดชอบ",
      "title": "คำสั่งซื้อ"
    },
    "performance": {
      "title": "ประสิทธิภาพ"
    },
    "performances": {
      "title": "การแสดง"
    },
    "performances_wip": {
      "performance_item": "รายการประสิทธิภาพ",
      "title": "ประสิทธิภาพ"
    },
    "planning": {
      "item_no": "หมายเลขสินค้า",
      "service_no": "หมายเลขบริการ",
      "title": "การวางแผน",
      "view": "มุมมองการวางแผน"
    },
    "plannings": {
      "title": "การวางแผน"
    },
    "progress_histories": {
      "add_new_progress": "เพิ่มความคืบหน้าใหม่",
      "empty_historic": "ไม่มีรายการประวัติและไม่สามารถสร้างประวัติเมื่อปริมาณเป็นศูนย์",
      "hours": "ชั่วโมง",
      "production_factor": "PF",
      "progress": "ความคืบหน้า",
      "progress_badge": "รายการ %{number}",
      "progress_history": "ประวัติความก้าวหน้า",
      "progress_service_badge": "บริการ %{number}",
      "progress_service_history": "ประวัติการให้บริการ",
      "progress_service_title": "ประวัติการให้บริการ",
      "progress_title": "ประวัติรายการ",
      "quantity": "ปริมาณ",
      "subtitle": "จัดการความคืบหน้ารายวันตามกิจกรรมที่วางแผนโดยทีมของคุณ"
    },
    "progress_services": {
      "actual_div_hours": "ชั่วโมงเป้าหมายจริง / ทีม",
      "actual_div_quantity": "จำนวนจริง / เป้าหมาย",
      "actual_hours": "ชั่วโมงจริง",
      "actual_quantity": "ปริมาณจริง",
      "application_price": "ราคาสมัคร",
      "back": "การแสดงทั้งหมด",
      "budget_target_hours": "งบประมาณเป้าหมายชั่วโมง",
      "canceled": "ยกเลิก",
      "choose_modal": {
        "package": "บริการจากแพ็คเกจ",
        "package_description": "การสร้างบริการต่างๆ ตามรูปแบบกิจกรรมเดียวกัน",
        "single": "รายการบริการเดียว",
        "single_description": "สร้างบริการเดียวเท่านั้น",
        "title": "สร้างใหม่"
      },
      "contract_service": "บริการทำสัญญา",
      "contribution": "ผลงาน",
      "contribution_info": "ค่าที่คำนวณโดยอัตโนมัติ",
      "crew": "ลูกทีม",
      "crew_size": "ขนาดลูกเรือ",
      "crews": "ลูกเรือ",
      "date_filter": {
        "all": "ทั้งหมด",
        "today": "วันนี้",
        "tomorrow": "พรุ่งนี้",
        "week": "สัปดาห์",
        "yesterday": "เมื่อวาน"
      },
      "delta_hours": "ชั่วโมงเดลต้า",
      "delta_qtt": "ปริมาณเดลต้า",
      "description": "ประสิทธิภาพการบริการ",
      "discipline": "การลงโทษ",
      "end_date": "วันที่สิ้นสุด",
      "equipment_price": "ราคาอุปกรณ์",
      "factor": "ปัจจัย",
      "flag": "ธง",
      "flag_filter": "ธงกรอง",
      "id": "ไอดี",
      "materials_price": "ราคาวัสดุ",
      "no_crews": "จำนวนลูกเรือ",
      "no_workers": "จำนวนคนงาน",
      "norm_hours": "ชั่วโมงปกติ",
      "not_planned": "ไม่ได้วางแผน",
      "other_price": "ราคาอื่นๆ",
      "package_factor": "ปัจจัย",
      "people": "ผู้คน",
      "person": "บุคคล",
      "pf": "PF",
      "pf_doing_bad": "คุณทำได้ไม่ดี",
      "pf_doing_good": "คุณทำได้ดีมาก!",
      "pf_doing_neutral": "ไม่สามารถคำนวณ pf",
      "planned": "วางแผน",
      "planning_chart": {
        "actual": "แท้จริง",
        "actual_qty": "จำนวนจริง",
        "date": "วันที่",
        "forecasted": "พยากรณ์",
        "forecasted_qty": "จำนวนที่คาดการณ์",
        "target": "เป้า",
        "target_qty": "จำนวนเป้าหมาย",
        "title": "แผนผังการวางแผน"
      },
      "planning_timeline": {
        "day": "วัน",
        "day_advance": "วันล่วงหน้า",
        "day_delayed": "วันล่าช้า",
        "days": "วัน",
        "days_advance": "วันล่วงหน้า",
        "days_delayed": "วันล่าช้า",
        "end_date": "วันที่สิ้นสุด",
        "forecasted": "พยากรณ์",
        "on_time": "ตรงเวลา",
        "remaining_days": "วันที่เหลืออยู่",
        "start_date": "วันที่เริ่มต้น",
        "title": "ไทม์ไลน์การวางแผน"
      },
      "progress": "ความคืบหน้า",
      "progress_services_detail": {
        "side_panel_title": "รายละเอียดบริการ"
      },
      "quantity": "ปริมาณ",
      "responsible": "รับผิดชอบ",
      "scoped_quantity": "ปริมาณที่กำหนด",
      "service": "บริการ",
      "service_package": "แพ็คเกจบริการ",
      "service_planning": "การวางแผนการบริการ",
      "service_progress": "ความก้าวหน้าของการบริการ",
      "service_weight": "น้ำหนักบริการ",
      "start_date": "วันที่เริ่มต้น",
      "statuses": {
        "canceled": "ยกเลิก",
        "done": "สมบูรณ์",
        "extra": "พิเศษ",
        "in_planning": "ไม่ได้วางแผน",
        "planned": "วางแผน"
      },
      "team_target_hours": "ชั่วโมงเป้าหมายของทีม",
      "title": "บริการด้านประสิทธิภาพ",
      "title_packageable": "ประสิทธิภาพการบริการตามแพ็คเกจบริการ",
      "total": "ทั้งหมด",
      "total_hours": "รวมชั่วโมง",
      "unit": "หน่วย",
      "worker": "คนงาน",
      "workers": "คนงาน",
      "working_hours": "ชั่วโมงทำงาน"
    },
    "progresses": {
      "cancel": "ยกเลิก",
      "choose_modal": {
        "single": "ผลงานการแสดงอีกชิ้น",
        "single_description": "สร้างผลงานเพียงชิ้นเดียว"
      },
      "complete": "สมบูรณ์",
      "delta_hours": "∆ ชั่วโมง",
      "delta_qty": "∆ จำนวน",
      "description": "คำอธิบาย",
      "extra": "พิเศษ",
      "flag": "ธง",
      "history": "ประวัติศาสตร์",
      "id": "ไอดี",
      "item": "สิ่งของ",
      "label": "ฉลาก",
      "pf": "PF",
      "progress": "ความคืบหน้า",
      "progresses_detail": {
        "side_panel_title": "รายละเอียดประสิทธิภาพ"
      },
      "request_status_restriction": "คำขอควรมีสถานะอยู่ในการวางแผนหรืออยู่ในประสิทธิภาพ",
      "revised": "แก้ไข",
      "title": "การแสดง"
    },
    "projects": {
      "workdays": {
        "fri": "ศ",
        "mon": "จันทร์",
        "sat": "นั่ง",
        "sun": "ดวงอาทิตย์",
        "thu": "พฤ",
        "tue": "อ.",
        "wed": "พุธ"
      }
    },
    "requests": {
      "actual_hours": "ชั่วโมงจริง",
      "actual_progress": "ความคืบหน้าที่แท้จริง",
      "all": "ทั้งหมด",
      "are_you_sure_complete": "คุณแน่ใจหรือว่าจะทำตามคำขอนี้",
      "are_you_sure_complete_no_history": "คุณแน่ใจหรือว่าจะทำตามคำขอโดยไม่คืบหน้า?",
      "are_you_sure_deletion": "คุณแน่ใจหรือว่าต้องการลบคำขอ",
      "are_you_sure_revertion": "คุณแน่ใจหรือว่าต้องการเปลี่ยนกลับและทำให้ข้อมูลที่ป้อนในโมดูลนี้หายไป",
      "area": "พื้นที่",
      "authorization_pending": "อยู่ระหว่างรอการอนุมัติ",
      "authorize_form": {
        "modal_title": "การอนุญาต",
        "subtitle": "ให้ข้อมูลบางอย่างเกี่ยวกับคำขอเพื่อดำเนินการด้วยความเห็นชอบ",
        "title": "ขออนุญาติ"
      },
      "canceled": "ยกเลิก",
      "clustered": "คลัสเตอร์",
      "comments": "ความคิดเห็น",
      "confirmation_modal": {
        "cancel_description": "คำขอจะถูกยกเลิก",
        "cancel_title": "ยกเลิกคำขอ?",
        "complete_description": "คำขอนี้จะเสร็จสมบูรณ์",
        "complete_title": "คำขอเสร็จสมบูรณ์?",
        "confirm_action": "ดำเนินการนำเข้าขอบเขต",
        "confirm_cancel": "ไม่ใช่ตอนนี้",
        "confirm_confirmation": "ใช่ ฉันต้องการยืนยัน",
        "confirm_description": "คุณต้องการยืนยันคำขอหรือไม่?",
        "confirm_title": "ขอคำยืนยัน",
        "delete_description": "การดำเนินการนำออกไม่สามารถยกเลิกได้ คุณแน่ใจไหม?",
        "delete_title": "ลบคำขอ?",
        "reject_description": "ไอเทมนี้จะกลับสู่สถานะ In Scoping",
        "reject_title": "ปฏิเสธคำขอ?",
        "revert_description": "การดำเนินการเปลี่ยนกลับไม่สามารถยกเลิกได้ คุณแน่ใจไหม?",
        "revert_title": "ยกเลิกคำขอ?"
      },
      "confirmed": "ยืนยันแล้ว",
      "creation_form": {
        "proceed": "ดำเนินการตามขั้นตอนที่ 2"
      },
      "creation_page": {
        "add_template": "เพิ่มเทมเพลต",
        "first_step": "สร้างคำขอ",
        "second_step": "ขอบเขตการนำเข้า (ไม่บังคับ)",
        "third_step": "ใช้แพ็คเกจบริการ (ไม่บังคับ)"
      },
      "description": "ขอ",
      "discipline": "การลงโทษ",
      "done": "เสร็จแล้ว",
      "eav_template": "แม่แบบ",
      "eav_template_id": "รหัสเทมเพลต",
      "edit_dialog": {
        "reason": "ชื่อคำขอย่อย"
      },
      "elevation": "ระดับความสูง",
      "errors": {
        "not_found": "ไม่พบคำขอที่มีรหัส: %{id}"
      },
      "estimates_authorization": "ประมาณการการอนุมัติที่จำเป็น",
      "forecast_end_date": "วันที่สิ้นสุดที่คาดการณ์ไว้",
      "id": "DPMSID",
      "in_performance": "ในประสิทธิภาพ",
      "in_planning": "ในการวางแผน",
      "in_scoping": "ในการกำหนดขอบเขต",
      "information": "ข้อมูลเพิ่มเติม",
      "notification": {
        "authorization_success": "คำขอได้รับอนุญาตเรียบร้อยแล้ว",
        "deleting_sub_request": "กำลังลบคำขอย่อย %{id} โปรดตรวจสอบหน้างานเพื่อเรียนรู้เกี่ยวกับความคืบหน้า",
        "missing_order_error": "ไม่มีคำสั่งซื้อที่รอดำเนินการใด ๆ ที่เกี่ยวข้องกับคำขอนี้"
      },
      "on_hold": "พักไว้",
      "progress_end_date": "วันที่สิ้นสุดความคืบหน้า",
      "progress_responsible": "รับผิดชอบความก้าวหน้า",
      "progress_responsible_id": "รับผิดชอบความก้าวหน้า",
      "progress_start_date": "วันที่เริ่มต้นความคืบหน้า",
      "reason": "อ้างอิง",
      "reference": "อ้างอิง",
      "registered": "ลงทะเบียน",
      "request_date": "วันที่ขอ",
      "request_detail": {
        "edit": "แก้ไข",
        "estimates": "ประมาณการ",
        "inspections": "การตรวจสอบ",
        "performance": "ประสิทธิภาพ",
        "planning": "การวางแผน",
        "scopes": "ขอบเขต",
        "show": "แสดง",
        "side_panel_title": "ขอรายละเอียด"
      },
      "request_end_date": "ขอวันที่สิ้นสุด",
      "request_responsible": "รับผิดชอบคำขอ",
      "request_responsible_id": "รับผิดชอบคำขอ",
      "request_start_date": "ขอวันที่เริ่มต้น",
      "schedule": "ขอกำหนดการ",
      "schedule_form": {
        "date": "วันที่",
        "days": "%{number} วัน",
        "performance": "ประสิทธิภาพ",
        "progress": "ความคืบหน้า",
        "request": "ขอ",
        "responsible": "รับผิดชอบ",
        "schedule": "กำหนดการ",
        "scoping": "การกำหนดขอบเขต",
        "subtitle": "ในการสร้างกำหนดการสำหรับคำขอนี้ ก่อนอื่นเราต้องการข้อมูลเพิ่มเติม",
        "title": "ขอกำหนดการ"
      },
      "scope_end_date": "วันที่สิ้นสุดขอบเขต",
      "scope_responsible": "รับผิดชอบการกำหนดขอบเขต",
      "scope_responsible_id": "รับผิดชอบการกำหนดขอบเขต",
      "scope_start_date": "วันที่เริ่มต้นขอบเขต",
      "scopes": "ขอบเขต",
      "service_desired_date": "วันที่ต้องการ",
      "skip_scoping": "ข้ามขั้นตอนการกำหนดขอบเขต",
      "status": "สถานะ",
      "statuses": {
        "authorization_pending": "รอดำเนินการ",
        "canceled": "ยกเลิก",
        "clustered": "คลัสเตอร์",
        "confirmed": "ในการกำหนดขอบเขต",
        "deleting": "กำลังลบ",
        "done": "เสร็จแล้ว",
        "in_performance": "ในประสิทธิภาพ",
        "in_planning": "ในประสิทธิภาพ",
        "in_scoping": "ในการกำหนดขอบเขต",
        "on_hold": "พักไว้",
        "registered": "สร้าง"
      },
      "sub_request": "คำขอย่อย",
      "sub_requests": "คำขอย่อย",
      "subarea": "พื้นที่ย่อย",
      "team_target_hours": "ชั่วโมงเป้าหมายของทีม",
      "title": "คำขอ"
    },
    "scopes": {
      "add": "เพิ่มรายการกำหนดขอบเขต",
      "choose_modal": {
        "single": "รายการขอบเขตอื่น",
        "single_description": "การสร้างรายการกำหนดขอบเขตเพียงรายการเดียว"
      },
      "clusters": {
        "title": "การกำหนดขอบเขตคลัสเตอร์"
      },
      "count": "นับ",
      "crew_size": "ขนาดลูกเรือ",
      "crews": "จำนวนลูกเรือ",
      "date": "วันที่",
      "description": "ขอบเขต",
      "hours": "ชั่วโมง (ซ)",
      "id": "ไอดี",
      "item": "สิ่งของ",
      "label": "ฉลาก",
      "label_assigner": {
        "success": "กำหนดขอบเขตขอบเขตของคุณสำเร็จแล้ว! คุณสามารถดำเนินการต่อแผนของคุณตอนนี้"
      },
      "label_error": "ไม่สามารถเรียกป้ายกำกับ",
      "popup_label": {
        "create_label": "สร้างฉลาก",
        "labels": "ป้ายกำกับ:",
        "name_label": "ชื่อฉลาก",
        "new_label": "ป้ายใหม่"
      },
      "quantity": "จำนวน",
      "remaining_hours": "ชั่วโมงที่เหลือ",
      "remaining_quantity": "จำนวนคงเหลือ",
      "request_status_restriction": "คำขอควรมีสถานะยืนยันหรืออยู่ในขอบเขต",
      "scopes_detail": {
        "side_panel_title": "รายละเอียดขอบเขต"
      },
      "title": "ขอบเขต",
      "unit": "หน่วย",
      "working_hours": "ชั่วโมงทำงาน"
    },
    "search": {
      "loading": "กำลังโหลด...",
      "no_result_found": "ไม่พบผลลัพธ์",
      "placeholder": "กรอง..."
    },
    "settings": {
      "title": "การตั้งค่า"
    },
    "settings_shortcuts": "ทางลัดการตั้งค่า",
    "shortcuts": {
      "access_control": "การควบคุมการเข้าถึง",
      "areas": "พื้นที่และพื้นที่ย่อย",
      "areas_subareas": "พื้นที่และพื้นที่ย่อย",
      "assets": "ทรัพย์สิน",
      "background_jobs": "งานเบื้องหลัง",
      "branches": "สาขา",
      "cities": "เมือง",
      "client_sites": "ไซต์ลูกค้า",
      "clients": "ลูกค้า",
      "clients_info": "ข้อมูลลูกค้า",
      "columns_renaming": "การเปลี่ยนชื่อคอลัมน์",
      "companies": "บริษัท",
      "contract_services": "บริการทำสัญญา",
      "contracts": "สัญญา",
      "countries": "ประเทศ",
      "customer_sites": "ไซต์ลูกค้า",
      "customers": "ลูกค้า",
      "disciplines": "วินัย",
      "employees": "พนักงาน",
      "equipaments": "อุปกรณ์",
      "equipments": "อุปกรณ์",
      "formulas": "สูตร",
      "location": "ที่ตั้ง",
      "materials": "วัสดุ",
      "matrices": "เมทริกซ์",
      "measures": "มาตรการ",
      "mobile_details": "รายละเอียดมือถือ",
      "position_names": "ชื่อตำแหน่ง",
      "preferences": "การตั้งค่า",
      "project_assets": "ทรัพย์สิน",
      "project_contracts": "สัญญา",
      "projects": "โครงการและโครงการย่อย",
      "projects_contracts": "โครงการและสัญญา",
      "projects_subprojects": "โครงการและโครงการย่อย",
      "provinces": "รัฐ / จังหวัด",
      "regions": "ภูมิภาค",
      "routes": "เส้นทาง",
      "specialities": "ความชำนาญพิเศษ",
      "staff": "พนักงาน",
      "states_provinces": "รัฐ / จังหวัด",
      "translations": "การเปลี่ยนชื่อคอลัมน์",
      "users": "ผู้ใช้",
      "visible_fields": "รายละเอียดมือถือ",
      "work_positions": "ชื่อตำแหน่ง"
    },
    "side_menu": {
      "contracts": "สัญญา",
      "datasheets": "แผ่นข้อมูล",
      "new": "ใหม่",
      "report": "รายงาน",
      "search": "ค้นหา",
      "settings": "การตั้งค่า",
      "template": {
        "asset": "ทรัพย์สิน",
        "contract_service": "บริการทำสัญญา",
        "datasheet": "แผ่นข้อมูล",
        "estimate_service": "บริการประเมินราคา",
        "inspection": "การตรวจสอบ",
        "performance": "ประสิทธิภาพ",
        "report": "รายงาน",
        "request": "คำขอ",
        "scoping": "การกำหนดขอบเขต",
        "valuation": "การประเมินค่า"
      },
      "templates": "แม่แบบ"
    },
    "sub_requests": {
      "creation_page": {
        "all": "คำขอย่อยทั้งหมด",
        "back": "ขอบเขตทั้งหมด",
        "title": "คำขอย่อย"
      },
      "left_panel": {
        "add_scoping": "เพิ่มรายการกำหนดขอบเขต",
        "allocation": "การจัดสรร",
        "cards_header_title": "ขอบเขต",
        "summary": "สรุป",
        "total_allocation": "การจัดสรรทั้งหมด"
      },
      "scope_card": {
        "scope": "ขอบเขต"
      }
    },
    "tabs": {
      "error": "โหลดแท็บไม่ได้"
    },
    "templates": {
      "asset": "ทรัพย์สิน",
      "contract_service": "บริการทำสัญญา",
      "datasheet": "แผ่นข้อมูล",
      "estimate_service": "บริการประเมินราคา",
      "inspection": "การตรวจสอบ",
      "no_template": "คุณต้องสร้างเทมเพลต",
      "progress": "ประสิทธิภาพ",
      "progress_service": "การประเมินค่า",
      "report": "รายงาน",
      "request": "คำขอ",
      "scoping": "การกำหนดขอบเขต",
      "title": "เทมเพลตสำหรับ %{type}"
    },
    "tickets": {
      "icon_alt": "ไอคอนตั๋ว",
      "title": "ตั๋ว"
    },
    "top_menu": {
      "authorization": "การอนุญาต",
      "estimates": "ประมาณการ",
      "inspections": "การตรวจสอบ",
      "performance": "ประสิทธิภาพ",
      "planning": "การวางแผน",
      "requests": "คำขอ",
      "scoping": "การกำหนดขอบเขต"
    },
    "track_block": {
      "id": "DPMSID"
    },
    "user_menu": {
      "about_version": "เกี่ยวกับเวอร์ชั่น",
      "logout": "ออกจากระบบ",
      "profile": "ประวัติโดยย่อ"
    },
    "users": {
      "edit_title": "อัพเดทโปรไฟล์"
    }
  },
  "zh-CN": {
    "actions": {
      "approve": "批准",
      "bulk_editing": "批量编辑",
      "cancel": "取消",
      "choose_entity": "选%{entity}",
      "clear": "清除",
      "confirm": "确定",
      "create": "创建",
      "delete": "删除",
      "discard": "丢弃",
      "done": "完成了",
      "duplicate": "复制",
      "edit": "编辑",
      "export": "导出 .xls",
      "export_template": "下载模版 .xls",
      "import": "导入 .xls",
      "import_file": "导入文件",
      "loading": "加载中",
      "more": "更多",
      "new": "新的",
      "next": "下一个",
      "no": "不是",
      "past": {
        "cancel": "取消",
        "complete": "已完成",
        "confirm": "已确定",
        "delete": "已删除",
        "destroy": "已删除",
        "reject": "已拒绝",
        "revert": "还原的",
        "update": "更新"
      },
      "prev": "先前",
      "reject": "拒绝",
      "reset": "重启",
      "revert": "还原",
      "save": "保存",
      "save_button_keyboard": "保存 (Ctrl + Enter)",
      "save_exit": "保存后退出",
      "saving": "保存中",
      "schedule": "时间表",
      "skip": "跳跃",
      "unsaved_changes": "未保存的更改",
      "upload_file": "上传文件",
      "upload_picture": "上传图片",
      "yes": "是"
    },
    "api_internal_messages": {
      "cant_duplicate_clustered_request": "无法复制集群要求",
      "cant_duplicate_progress": "无法复杂",
      "cant_duplicate_request": "无法复杂",
      "cant_duplicate_scope": "无法复杂",
      "only_estimate_service_copy": "试图复制仅评估服务项目。",
      "only_progress_copy": "仅尝试复制进度。",
      "only_request_copy": "仅尝试复制请求。",
      "only_scope_copy": "仅作用要求正在尝试复制。"
    },
    "cancel_modal": {
      "cancel": "现在不要",
      "description": "您确定要取消%{model}“ %{id}”吗？",
      "ok": "是的，取消",
      "title": "取消项目？"
    },
    "clear_records": {
      "modal": {
        "cancel": "不，取消",
        "description": "您确定要清除所有%{model}记录吗？",
        "ok": "是的，清除所有",
        "title": "清除%{model}？"
      },
      "success_message": "%{model}成功清除！",
      "title": "清除记录"
    },
    "collections": {
      "clusters": "集群",
      "create_by_package": "按包创建",
      "duplicate": "复制",
      "edit": "编辑",
      "estimate": "估计",
      "new": "新的",
      "schedule": "日程",
      "sub_requests": "子请求",
      "view": "看法"
    },
    "complete_modal": {
      "cancel": "现在不要",
      "description": "您确定要将 %{model} 标记为已完成吗？",
      "ok": "是，标记为已完成",
      "title": "标记为完成？"
    },
    "confirmation_modal": {
      "cancel": "取消",
      "description": "无法撤销此行动",
      "ok": "Ok",
      "title": "请确定"
    },
    "contract_services": {
      "title": "合约服务"
    },
    "datasheets": {
      "datasheets_detail": {
        "side_panel_title": "数据表详细信息"
      },
      "id": "ID",
      "item": "物品",
      "title": "数据表"
    },
    "delete_modal": {
      "cancel": "取消",
      "description": "请确定是否删除%{model} \"%{id}\"?",
      "ok": "是，删除",
      "title": "删除吗?"
    },
    "delete_recurrencies": {
      "modal": {
        "cancel": "否，取消",
        "description": "您确定要删除所有相关的重复吗？",
        "ok": "是的，全部删除",
        "title": "删除所有重复项？"
      },
      "success_message": "重复项删除成功！",
      "title": "删除所有重复项"
    },
    "dialogs": {
      "columns_batch_assigner": {
        "actions": "行动",
        "add_action": "添加",
        "add_value_to": "添加 %{value}  到",
        "column": "柱",
        "done": "完成了",
        "header": "批量编辑",
        "multiple_success": "总共更新了%{numberOfItems}",
        "new_value": "新数据",
        "single_success": "1 项目已更新",
        "subtitle": "为％{numberOfItems}个选定项目填写",
        "title": "创建行动",
        "update": "更新"
      }
    },
    "discard_modal": {
      "cancel": "继续修改",
      "description": "请确定是否废弃您的更改",
      "ok": "是",
      "title": "废弃"
    },
    "eav_templates": {
      "title": "范本"
    },
    "empty_state": {
      "empty": "没有 %{model}",
      "not_created": "您还没创建任何%{model}"
    },
    "environment": {
      "project": {
        "select_project": "选择项目"
      },
      "select_project_subproject": "选择项目",
      "subproject": {
        "select_subproject": "选择子项目"
      }
    },
    "estimate": {
      "accept": "接受估计",
      "application": "申请费用",
      "back": "所有的要求",
      "budget_hours": "预算时间",
      "confirmation": {
        "cancel": "取消",
        "confirm": "是，确定",
        "description": "您确定要为此要求授权此估算吗？",
        "title": "确定"
      },
      "description": "您有 %{number} 个服务等待结帐。 请在下面查看详细信息。",
      "equipment": "设备费用",
      "estimate_details": "估算细节",
      "estimate_success": "成功批准估算",
      "info_message": "这个估算已经被批准。它是只读不能修改。",
      "material": "材料费用",
      "no_estimate_message": "没有与此请求相关的估算值。",
      "norm_hours": "规范时间",
      "others": "其他费用",
      "save_draft": "保存草稿",
      "service_details": "服务详情",
      "team_target_hours": "团队目标时间",
      "title": "结帐服务",
      "tooltip": {
        "application": "应用",
        "equipment": "设备",
        "material": "材料",
        "others": "其他"
      },
      "total_request": "总花费"
    },
    "estimate_services": {
      "application_price": "申请价格",
      "automatically_prices": "自动计算的价格",
      "back": "所有范围",
      "budget_target_hours": "预算目标时间",
      "choose_modal": {
        "package": "套餐服务",
        "package_description": "基于同一活动模型创建多个服务",
        "single": "单一服务项目",
        "single_description": "仅创建一项服务",
        "title": "创建新的"
      },
      "contract_service": "合同服务",
      "crew_size": "员工人数",
      "crews": "员工人数",
      "description": "估算服务",
      "description_label": "描述",
      "discipline": "专业",
      "equipment_price": "设备价格",
      "estimate": "结帐服务",
      "estimate_services_detail": {
        "side_panel_title": "服务详情"
      },
      "factor": "因子",
      "id": "编号",
      "materials_price": "材料价格",
      "no_template": "您需要为估算服务创建模板",
      "norm_hours": "规范时间",
      "other_price": "其他价格",
      "prices": "价格",
      "quantity": "数量",
      "service": "服务",
      "service_package": "服务包",
      "team_target_hours": "团队目标时间",
      "title": "估算服务",
      "title_packageable": "通过服务包估算服务",
      "total": "合计",
      "unit": "单元",
      "weight": "重量",
      "working_hours": "工作小时"
    },
    "fields": {
      "image_not_found": "找不到图片",
      "na": "不适用",
      "signature": "签名"
    },
    "filter_steps_modal": {
      "filter_result": "筛选结果",
      "step": "步骤",
      "subtitle": "请提供关于%{title} 的细节"
    },
    "filtering": {
      "between": " 那是在",
      "contains": " 包含",
      "eq": " 等于",
      "invalid_date": "要正确过滤，必须填写开始日期和结束日期",
      "invalid_value": "无效值",
      "neq": " 那不等于"
    },
    "form": {
      "back": "背部",
      "cancel": "取消",
      "create_entity": "创建 %{entity}",
      "create_error": "无法创建%{entity}",
      "create_success": "成功创建%{entity}",
      "duplicate_entity": "复制%{entity}",
      "duplicate_success": "%{entity} 和其依赖项已成功复制！",
      "general_information": "一般信息",
      "hide": "隐藏",
      "inputs": {
        "attachment": {
          "add_error": "添加文件时出错",
          "add_evidence": "添加业主授权的证据",
          "allowed_extensions": "允许的文件类型是",
          "drag_drop": "使用拖放来添加文件",
          "invalid_extension": "不允许的文件类型",
          "max_size": "该文件必须小于 %{max_size}",
          "maximum_num_uploads_error": "每个灵活列最多只能加载 %{max_num} 个文件。",
          "one_file": "一次上传一个文件",
          "or_drop_file_here": "或将档案放在这里",
          "remove_error": "删除失败",
          "remove_success": "已成功删除",
          "select_files": "选择文件",
          "submit": "提交您的附件",
          "upload_error": "上传失败",
          "upload_success": "成功上传"
        },
        "contract_service": {
          "add": "添加合同服务",
          "subtitle": "合约服务"
        },
        "datasheet_filter": {
          "add_filter": "添加筛选",
          "title": "数据表筛选"
        },
        "date_range": {
          "between": "%{start} 直到 %{end}",
          "end": "结束日期",
          "start": "开始日期"
        },
        "drop_formula": {
          "formula_select": "请选择一个公式..."
        },
        "errors": {
          "mandatory": "请填字%{field}",
          "not_found": "在参考文献中找不到“ %{value}”。选择一个新的。"
        },
        "formula": {
          "result": "结果"
        },
        "gps": {
          "save_location": "保存地点",
          "search": "搜寻位置",
          "without_location": "没有地点"
        },
        "picture": {
          "edition_only": "仅在编辑时可用",
          "five_pictures_limit_warning": "最多只允许5张照片",
          "no_image_on_clipboard": "找不到图像数据。 请先复制图像。",
          "remove_error": "删除失败",
          "remove_success": "已成功删除",
          "upload_error": "上传失败",
          "upload_success": "成功上传"
        },
        "placeholder": "这里输入%{field}",
        "value_selector": {
          "apply": "应用",
          "done": "完成了",
          "search": "搜寻",
          "waiting": "… 稍等"
        }
      },
      "loading": "加载中",
      "schedule_entity": "时间表 %{entity}",
      "schedule_success": "您安排%{entity} 的请求已成功完成",
      "show": "显示",
      "subtitle": "请为 %{entity} 提供基本的信息。",
      "subtitle_error": "无法加载表单，请检查您的%{entity}或模板数据。",
      "update_entity": "编辑%{entity}",
      "update_error": "无法更新%{entity}",
      "update_success": "成功更新%{entity}"
    },
    "formula_modal": {
      "back_button_text": "返回表格",
      "calculate": "计算",
      "cancel": "取消",
      "mandatory": "您必须填写以下字段的值：%{fields}",
      "ok": "保存公式"
    },
    "grid": {
      "all": "全部",
      "clear_filters": "清楚筛选",
      "edit_mode": "编辑模式",
      "editable": {
        "error": {
          "mandatory": "至少有一个必填字段为空"
        },
        "title": "编辑模式",
        "update": {
          "error": "%{quantity} 无法保存。检查突出显示的每一行左侧的原因",
          "success": "%{quantity} 条记录保存成功"
        }
      },
      "empty_after_filtering": "筛选找不到任何记录",
      "grid_filters": {
        "menu_title": "自定义过滤器"
      },
      "menu": {
        "visibility": "列"
      },
      "of": "的",
      "other": "其他",
      "productivity_factor": "生产率系数",
      "select_items": "选择",
      "selected": "选择的",
      "selecteds": "选择的",
      "totals": "合计"
    },
    "history_popup": {
      "back": "后退",
      "comments": "注释",
      "crew_size": "船员人数",
      "daily_hours": "每日营业时间",
      "date": "日期",
      "form_create_progress_service_title": "创建绩效服务项目历史",
      "form_create_progress_title": "创建进度项目历史记录",
      "form_edit_progress_service_title": "编辑绩效服务项目历史",
      "form_edit_progress_title": "编辑进度项目历史",
      "form_error_body": "必须填写表格上的所有必填字段。",
      "form_error_title": "无法保存历史表单",
      "form_subtitle": "按数量或百分比为您的活动插入历史记录。",
      "modal_create_title": "创造历史",
      "modal_edit_title": "编辑历史",
      "number_of_crews": "船员人数",
      "number_of_workers": "工人数量",
      "other_value": "其他价值",
      "percentage": "百分比",
      "progress_by": "进度",
      "quantity": "数量",
      "singular": "历史",
      "working_hours": "工作时间"
    },
    "home": {
      "available_on": "可用",
      "banner_description": "使用单个平台管理任务，跟踪流程并获得最佳结果",
      "create_request": {
        "text": "首先，您需要在DPMS中创建一个来自客户的请求，其中包含有关您的订单的详细信息。",
        "title": "创建一个请求"
      },
      "dpms_mobile": "DPMS移动应用",
      "getting_started": "入门",
      "home_title": "家",
      "inspection": {
        "text": "在现场进行质量检查并记录障碍。",
        "title": "检查"
      },
      "message": "世界你好，我猜",
      "performance": {
        "text": "根据每个团队的计划活动记录进度和时间，并衡量生产力。",
        "title": "表现"
      },
      "scoping": {
        "text": "范围从客户端的请求。从合同中分配服务和服务包。",
        "title": "范围界定"
      },
      "welcome_user": "欢迎您，%{user}"
    },
    "importation_drop": {
      "choose_error": "选择一个项目"
    },
    "importation_popup": {
      "file_upload_message": "单击或拖动文件到这里上传",
      "importation_error": "您可以在工作页面上查看此消息。",
      "importation_success": "成功导入",
      "importation_timeout": "进口时间太长，请查其状态。",
      "instructions": "文件需要是 .xls 此外不能大过 xx兆.<br>",
      "requirements": "要求",
      "submit_spreadsheet": "提交表格"
    },
    "inspections": {
      "acceptance": "接受",
      "acceptance_modal": {
        "inspection_acceptance": "检查验收",
        "reject": "拒绝",
        "subtitle": "提供有关检查的信息以继续进行验收。"
      },
      "actions": {
        "approve": "批准",
        "more": "更多",
        "reject": "拒绝"
      },
      "choose_modal": {
        "schedule": "计划检查项目",
        "schedule_description": "根据日期间隔安排一次或多次检查",
        "single": "单项检查项目",
        "single_description": "仅创建一个检查",
        "title": "将检查创建为："
      },
      "description": "检查",
      "every": "每",
      "id": "编号",
      "inspect_date": "检查日期",
      "inspect_status_id": "状态",
      "inspected": "已检查",
      "inspected_detail": "检查细节",
      "inspected_modules": {
        "progress": "表现",
        "request": "要求",
        "scope": "范围"
      },
      "inspections_detail": {
        "side_panel_title": "检查细节"
      },
      "module": "模块",
      "notifications": {
        "approve_successful": "检验成功通过",
        "reject_successful": "检验被成功拒绝"
      },
      "recurrency_interval": "复发间隔",
      "repeat": "重复",
      "repeat_every_value": {
        "day": "日",
        "month": "月",
        "week": "星期",
        "year": "年"
      },
      "repeats_on": "重复",
      "request_id": "DPMS编号",
      "responsible_id": "负责人",
      "schedule_detail": "时间表细节",
      "schedule_form": {
        "acceptance_step": "定义接受（可选）",
        "detailed_item_step": "选择检验项目（可选）",
        "detailed_template_step": "请选择模版",
        "further_step_information": "在重复创建步骤之后，可以单独填充弹性域",
        "general_step": "创建审查",
        "hints": "关于每月定期和每年定期，系统将自动匹配审查时间表",
        "monthly_hint": "每个月的%{day}重复",
        "recurrence_step": "复发率",
        "schedule_step": "时间表"
      },
      "scheduled_exception": "定于",
      "statuses": {
        "approved": "得到正式认可的",
        "completed": "完全的",
        "pending": "待办的",
        "progress": "进行中",
        "rejected": "被拒绝"
      },
      "template": "选择模块",
      "title": "检查"
    },
    "inspects": {
      "statuses": {
        "approved": "得到正式认可的",
        "completed": "完全的",
        "pending": "待办的",
        "progress": "进行中",
        "rejected": "被拒绝"
      }
    },
    "kendo": {
      "upload": "单击或拖动文件到这里上传"
    },
    "kendo_translations": {
      "calendar": {
        "today": "今天"
      },
      "dateinput": {
        "decrement": "降低价值",
        "increment": "增加价值"
      },
      "datepicker": {
        "toggleCalendar": "切换日历"
      },
      "daterangepicker": {
        "end": "结尾",
        "separator": 0,
        "start": "开始",
        "swapStartEnd": "交换开始和结束值"
      },
      "datetimepicker": {
        "cancel": "取消",
        "date": "日期",
        "set": "放",
        "time": "时间",
        "toggleDateTimeSelector": "切换日期时间选择器"
      },
      "dropdowns": {
        "clear": "清除",
        "nodata": "没有找到数据"
      },
      "editor": {
        "addColumnAfter": "在右侧添加列",
        "addColumnBefore": "在左侧添加列",
        "addRowAfter": "在下面添加行",
        "addRowBefore": "在上方添加行",
        "alignCenter": "中心文字",
        "alignJustify": "证明合法",
        "alignLeft": "左对齐文字",
        "alignRight": "右对齐文字",
        "bold": "胆大",
        "bulletList": "插入无序列表",
        "createTable": "建立表格",
        "deleteColumn": "删除栏",
        "deleteRow": "删除行",
        "deleteTable": "删除表格",
        "fontName": "字体名称",
        "fontSize": "字体大小",
        "format": "格式",
        "hyperlink": "插入超链接",
        "hyperlink-dialog-cancel": "取消",
        "hyperlink-dialog-content-address": "网址",
        "hyperlink-dialog-content-newwindow": "在新窗口中打开链接",
        "hyperlink-dialog-content-title": "标题",
        "hyperlink-dialog-insert": "插入",
        "hyperlink-dialog-title": "插入超链接",
        "image": "插入图片",
        "image-address": "网址",
        "image-altText": "替代文字",
        "image-cancel": "取消",
        "image-dialog-title": "插入图片",
        "image-height": "身高（px）",
        "image-insert": "插入",
        "image-title": "标题",
        "image-width": "宽度（像素）",
        "indent": "缩进",
        "insertTableHint": "创建一个0 x 1表",
        "italic": "斜体",
        "mergeCells": "合并单元格",
        "orderedList": "插入订购清单",
        "outdent": "突出",
        "redo": "重做",
        "splitCell": "分裂细胞",
        "strikethrough": "删除线",
        "subscript": "下标",
        "superscript": "上标",
        "underline": "强调",
        "undo": "撤消",
        "unlink": "删除超链接",
        "viewHtml": "查看HTML",
        "viewHtml-cancel": "取消",
        "viewHtml-dialog-title": "查看HTML",
        "viewHtml-update": "更新"
      },
      "filter": {
        "addExpression": "添加表达",
        "addGroup": "新增群组",
        "afterOperator": "是之后",
        "afterOrEqualOperator": "在之后或等于",
        "andLogic": "和",
        "beforeOperator": "是之前",
        "beforeOrEqualOperator": "在之前或等于",
        "close": "关闭",
        "containsOperator": "包含",
        "endsWithOperator": "以。。结束",
        "eqOperator": "等于",
        "gtOperator": "大于",
        "gteOperator": "大于或等于",
        "isEmptyOperator": "是空的",
        "isFalse": "是假的",
        "isNotEmptyOperator": "不为空",
        "isNotNullOperator": "不为空",
        "isNullOperator": "一片空白",
        "isTrue": "是真的",
        "ltOperator": "小于",
        "lteOperator": "小于或等于",
        "notContainsOperator": "不含",
        "notEqOperator": "不等于",
        "orLogic": "或者",
        "startsWithOperator": "以。。开始"
      },
      "grid": {
        "filterAfterOperator": "是之后",
        "filterAfterOrEqualOperator": "在之后或等于",
        "filterBeforeOperator": "是之前",
        "filterBeforeOrEqualOperator": "在之前或等于",
        "filterBetweenOperator": "在。。。之间",
        "filterBooleanAll": "（全部）",
        "filterClearButton": "清除",
        "filterContainsOperator": "包含",
        "filterEndsWithOperator": "以。。结束",
        "filterEqOperator": "等于",
        "filterGtOperator": "大于",
        "filterGteOperator": "大于或等于",
        "filterIsEmptyOperator": "是空的",
        "filterIsFalse": "是假的",
        "filterIsNotEmptyOperator": "不为空",
        "filterIsNotNullOperator": "不为空",
        "filterIsNullOperator": "一片空白",
        "filterIsTrue": "是真的",
        "filterLtOperator": "小于",
        "filterLteOperator": "小于或等于",
        "filterNotContainsOperator": "不含",
        "filterNotEqOperator": "不等于",
        "filterStartsWithOperator": "以。。开始",
        "filterSubmitButton": "筛选",
        "filterTitle": "筛选",
        "groupPanelEmpty": "拖动列标题并将其放在此处以按该列分组",
        "noRecords": "没有可用的记录。",
        "pagerFirstPage": "转到第一页",
        "pagerInfo": "0-1的2项目",
        "pagerItemsPerPage": "每页项目",
        "pagerLastPage": "转到最后一页",
        "pagerNextPage": "转到下一页",
        "pagerOf": "的",
        "pagerPage": "页",
        "pagerPreviousPage": "转到上一页"
      },
      "numerictextbox": {
        "decrement": "降低价值",
        "increment": "增加价值"
      },
      "pager": {
        "firstPage": "转到第一页",
        "info": "0-1的2项目",
        "itemsPerPage": "每页项目",
        "lastPage": "转到最后一页",
        "nextPage": "转到下一页",
        "of": "的",
        "page": "页",
        "previousPage": "转到上一页"
      },
      "scheduler": {
        "agendaViewTitle": "议程",
        "allDay": "一整天",
        "allEvents": "所有活动",
        "dateTitle": "日期",
        "dayViewTitle": "日",
        "deleteConfirmation": "您确定要删除此事件吗？",
        "deleteDialogTitle": "删除活动",
        "deleteOccurrence": "删除当前事件",
        "deleteRecurringConfirmation": "您只想删除此事件发生还是整个系列？",
        "deleteRecurringDialogTitle": "删除重复项目",
        "deleteSeries": "删除系列",
        "deleteTitle": "删除",
        "editorCancel": "取消",
        "editorDelete": "删除",
        "editorEventAllDay": "全天的活动",
        "editorEventDescription": "描述",
        "editorEventEnd": "结尾",
        "editorEventEndTimeZone": "结束时区",
        "editorEventSeparateTimeZones": "在不同时区结束",
        "editorEventStart": "开始",
        "editorEventStartTimeZone": "开始时区",
        "editorEventTimeZone": "指定时区",
        "editorEventTitle": "标题",
        "editorOccurrence": "编辑当前事件",
        "editorRecurringConfirmation": "您只想编辑此事件发生还是整个系列？",
        "editorRecurringDialogTitle": "编辑定期项目",
        "editorSave": "救",
        "editorSeries": "编辑系列",
        "editorTitle": "事件",
        "editorValidationEnd": "结束时间必须晚于开始时间。",
        "editorValidationRequired": "必填项。",
        "editorValidationStart": "开始时间必须早于结束时间。",
        "eventTitle": "事件",
        "monthViewTitle": "月",
        "nextTitle": "下一个",
        "noEvents": "没有事件",
        "previousTitle": "以前的",
        "recurrenceEditorDailyInterval": "天）",
        "recurrenceEditorDailyRepeatEvery": "重复每一个",
        "recurrenceEditorEndAfter": "后",
        "recurrenceEditorEndLabel": "结尾",
        "recurrenceEditorEndNever": "绝不",
        "recurrenceEditorEndOccurrence": "发生",
        "recurrenceEditorEndOn": true,
        "recurrenceEditorFrequenciesDaily": "日常",
        "recurrenceEditorFrequenciesMonthly": "每月一次",
        "recurrenceEditorFrequenciesNever": "绝不",
        "recurrenceEditorFrequenciesWeekly": "每周",
        "recurrenceEditorFrequenciesYearly": "每年",
        "recurrenceEditorMonthlyDay": "日",
        "recurrenceEditorMonthlyInterval": "一个月",
        "recurrenceEditorMonthlyRepeatEvery": "重复每一个",
        "recurrenceEditorMonthlyRepeatOn": "重复上",
        "recurrenceEditorOffsetPositionsFirst": "第一的",
        "recurrenceEditorOffsetPositionsFourth": "第四",
        "recurrenceEditorOffsetPositionsLast": "最后的",
        "recurrenceEditorOffsetPositionsSecond": "第二",
        "recurrenceEditorOffsetPositionsThird": "第三",
        "recurrenceEditorRepeat": "重复",
        "recurrenceEditorWeekdaysDay": "日",
        "recurrenceEditorWeekdaysWeekday": "平日",
        "recurrenceEditorWeekdaysWeekendday": "周末日",
        "recurrenceEditorWeeklyInterval": "周",
        "recurrenceEditorWeeklyRepeatEvery": "重复每一个",
        "recurrenceEditorWeeklyRepeatOn": "重复上",
        "recurrenceEditorYearlyInterval": "年）",
        "recurrenceEditorYearlyOf": "的",
        "recurrenceEditorYearlyRepeatEvery": "重复每一个",
        "recurrenceEditorYearlyRepeatOn": "重复上",
        "showFullDay": "显示全天",
        "showWorkDay": "显示营业时间",
        "timeTitle": "时间",
        "timelineViewTitle": "时间线",
        "today": "今天",
        "weekViewTitle": "星期",
        "workWeekViewTitle": "工作周"
      },
      "sortable": {
        "noData": "没有数据"
      },
      "timepicker": {
        "cancel": "取消",
        "now": "现在",
        "selectNow": "立即选择",
        "set": "放",
        "toggleClock": "切换时钟",
        "toggleTimeSelector": "切换时间选择器"
      },
      "treelist": {
        "filterAfterOperator": "是之后",
        "filterAfterOrEqualOperator": "在之后或等于",
        "filterBeforeOperator": "是之前",
        "filterBeforeOrEqualOperator": "在之前或等于",
        "filterBooleanAll": "（全部）",
        "filterClearButton": "清除",
        "filterContainsOperator": "包含",
        "filterEndsWithOperator": "以。。结束",
        "filterEqOperator": "等于",
        "filterGtOperator": "大于",
        "filterGteOperator": "大于或等于",
        "filterIsEmptyOperator": "是空的",
        "filterIsFalse": "是假的",
        "filterIsNotEmptyOperator": "不为空",
        "filterIsNotNullOperator": "不为空",
        "filterIsNullOperator": "一片空白",
        "filterIsTrue": "是真的",
        "filterLtOperator": "小于",
        "filterLteOperator": "小于或等于",
        "filterNotContainsOperator": "不含",
        "filterNotEqOperator": "不等于",
        "filterStartsWithOperator": "以。。开始",
        "noRecords": "无可用记录"
      },
      "upload": {
        "cancel": "取消",
        "clearSelectedFiles": "清除",
        "dropFilesHere": "或将档案放在这里",
        "files": "档案",
        "headerStatusUploaded": "完毕",
        "headerStatusUploading": "上载中...",
        "invalidFileExtension": "不允许的文件类型。",
        "invalidFiles": "无效的文件。请检查文件上传要求。",
        "invalidMaxFileSize": "档案太大。",
        "invalidMinFileSize": "文件大小太小。",
        "remove": "去掉",
        "retry": "重试",
        "select": "选择文件",
        "total": "全部的",
        "uploadSelectedFiles": "上载"
      }
    },
    "loading_batch_entities": {
      "page_of": "第 %{page} 页，共 %{total} 页",
      "title": "获取页面"
    },
    "loading_batch_modal": {
      "page_of": "%{page}的页面%{total}",
      "title": "获取页面"
    },
    "login": {
      "again": "要继续，请重新登录",
      "continue_mykaefer": "用mykaefer",
      "copyright": "®%{year}保留所有权利。凯发®",
      "forgot_password": "忘记密码",
      "iframe_info": "要求访问页面：%{link}",
      "log_in": "登录",
      "password": "密码",
      "title_1": "强大的软件系统",
      "title_2": "项目管理",
      "username": "用户名",
      "welcome_back": "欢迎回来"
    },
    "main_dialog": {
      "failure_message": "您的要求无法设为“完成”",
      "failure_title": "失败",
      "loading_message": "请稍等",
      "loading_title": "加载中",
      "service_title": "等待作业完成...",
      "success_message": "您的要求已成功！ 您现在可以继续了。",
      "success_title": "成功"
    },
    "notification": {
      "access_denied": "拒绝访问",
      "action_success": "%{model} %{id} 是 %{action}",
      "bad_formula_setup": "公式“ %{title}”存在设置问题，请检查您的设置！",
      "correct_subproject": "选择此项的正确子项目",
      "dismiss_all": "关闭所有",
      "error": "错误",
      "error_cookies": "该页面无法启用跨源通信",
      "error_job": "失败了！请查看检查原因。",
      "error_msg": "不好意思，出现了一些问题",
      "info": "资料",
      "large_file_body": "请应用一些过滤器以使输出文件更小。",
      "large_file_title": "网格有1000多个记录",
      "page_denied": "无法加载该页面。",
      "please": "请",
      "select_subproject": "选择一个项目和子项目以继续",
      "success": "成功",
      "success_msg": "成功执行",
      "warning": "提醒,"
    },
    "orders": {
      "add_evidence": "添加客户授权的证据",
      "attachment": "附件",
      "comments": "注释",
      "date": "日期",
      "drag_and_drop": "使用拖放添加文件",
      "responsible": "负责任的",
      "title": "订单"
    },
    "performance": {
      "title": "表现"
    },
    "performances": {
      "title": "表演节目"
    },
    "performances_wip": {
      "performance_item": "演出项目",
      "title": "表现"
    },
    "planning": {
      "item_no": "编号。",
      "service_no": "服务编号",
      "title": "规划",
      "view": "规划视图"
    },
    "plannings": {
      "title": "规划"
    },
    "progress_histories": {
      "add_new_progress": "添加新进度",
      "empty_historic": "没有历史项目，数量为零时无法创建历史",
      "hours": "小时",
      "production_factor": "PF",
      "progress": "进步",
      "progress_badge": "项目 %{number}",
      "progress_history": "进展历史",
      "progress_service_badge": "服务 %{number}",
      "progress_service_history": "服务历史",
      "progress_service_title": "服务历史",
      "progress_title": "物品历史",
      "quantity": "数量",
      "subtitle": "根据团队计划的活动管理每日进度。"
    },
    "progress_services": {
      "actual_div_hours": "实际/团队目标小时数",
      "actual_div_quantity": "实际/目标数量",
      "actual_hours": "实际小时数",
      "actual_quantity": "实际数量",
      "application_price": "申请价格",
      "back": "所有表演",
      "budget_target_hours": "预算目标小时数",
      "canceled": "取消",
      "choose_modal": {
        "package": "包中的服务",
        "package_description": "基于相同的活动模型创建多个服务",
        "single": "单项服务",
        "single_description": "仅创建一项服务",
        "title": "创建新的"
      },
      "contract_service": "合约服务",
      "contribution": "贡献",
      "contribution_info": "自动计算的值",
      "crew": "全体人员",
      "crew_size": "船员人数",
      "crews": "船员",
      "date_filter": {
        "all": "全部",
        "today": "今天",
        "tomorrow": "明天",
        "week": "星期",
        "yesterday": "昨天"
      },
      "delta_hours": "三角洲时间",
      "delta_qtt": "增量数量",
      "description": "表演服务",
      "discipline": "纪律",
      "end_date": "结束日期",
      "equipment_price": "设备价格",
      "factor": "因素",
      "flag": "旗帜",
      "flag_filter": "标记过滤器",
      "flag_filters": "标记过滤器",
      "id": "ID",
      "materials_price": "材料价格",
      "no_crews": "船员人数",
      "no_workers": "工人数量",
      "norm_hours": "正常工作时间",
      "not_planned": "未计划",
      "other_price": "其他价格",
      "package_factor": "因素",
      "people": "人们",
      "person": "人",
      "pf": "PF",
      "pf_doing_bad": "你做得不好",
      "pf_doing_good": "你做得很好！",
      "pf_doing_neutral": "无法计算 pf",
      "planned": "计划",
      "planning_chart": {
        "actual": "实际的",
        "actual_qty": "实际数量",
        "date": "日期",
        "forecasted": "预测",
        "forecasted_qty": "预测数量",
        "target": "目标",
        "target_qty": "目标数量",
        "title": "规划图"
      },
      "planning_timeline": {
        "day": "日",
        "day_advance": "提前一天",
        "day_delayed": "延迟了一天",
        "days": "天",
        "days_advance": "提前几天",
        "days_delayed": "延迟天数",
        "end_date": "结束日期",
        "forecasted": "预测",
        "on_time": "准时",
        "remaining_days": "剩余天数",
        "start_date": "开始日期",
        "title": "规划时间表"
      },
      "progress": "进步",
      "progress_services_detail": {
        "side_panel_title": "服务详情"
      },
      "quantity": "数量",
      "responsible": "负责任的",
      "scoped_quantity": "范围内的数量",
      "service": "服务",
      "service_package": "服务包",
      "service_planning": "服务规划",
      "service_progress": "服务进度",
      "service_weight": "服务权重",
      "start_date": "开始日期",
      "statuses": {
        "canceled": "取消",
        "done": "完全的",
        "extra": "额外的",
        "in_planning": "未计划",
        "planned": "计划"
      },
      "team_target_hours": "团队目标时间",
      "title": "进度服务",
      "title_packageable": "服务包的性能服务",
      "total": "全部的",
      "total_hours": "全部小时数",
      "unit": "单元",
      "worker": "工人",
      "workers": "工人",
      "working_hours": "工作时间"
    },
    "progresses": {
      "cancel": "取消",
      "choose_modal": {
        "single": "另一个进度项目",
        "single_description": "仅创建一个进度项"
      },
      "complete": "完全的",
      "delta_hours": "∆小时",
      "delta_qty": "∆数量",
      "description": "描述",
      "extra": "额外的",
      "flag": "旗帜",
      "histories": {
        "item": "项目 %{number}",
        "item_title": "物品历史",
        "progress_history": "进展历史",
        "service": "服务 %{number}",
        "service_history": "服务历史",
        "service_title": "服务历史",
        "subtitle": "根据团队计划的活动管理每日进度。"
      },
      "history": "历史",
      "id": "ID",
      "item": "物品",
      "label": "标签",
      "pf": "PF",
      "progress": "进步",
      "progresses_detail": {
        "side_panel_title": "性能细节"
      },
      "request_status_restriction": "请求的状态应为 In Planning 或 In Performance",
      "revised": "修改",
      "title": "表现"
    },
    "projects": {
      "workdays": {
        "fri": "星期五",
        "mon": "星期一",
        "sat": "星期六",
        "sun": "星期天",
        "thu": "星期四",
        "tue": "星期二",
        "wed": "星期三"
      }
    },
    "requests": {
      "actual_hours": "实际小时",
      "actual_progress": "实际进度",
      "all": "全部",
      "are_you_sure_complete": "请确定是否要把要求设为“完成”",
      "are_you_sure_complete_no_history": "请确定完成要求但是没有任何进展",
      "are_you_sure_deletion": "您确定要删除该要求吗？",
      "are_you_sure_revertion": "您确定要还原并失去数据吗？",
      "area": "区",
      "authorization_pending": "待授权",
      "authorize_form": {
        "modal_title": "授权",
        "subtitle": "请提供要求的细节",
        "title": "授权要求"
      },
      "canceled": "取消的",
      "clustered": "集群",
      "comments": "评论",
      "confirmation_modal": {
        "cancel_description": "该请求将被取消",
        "cancel_title": "取消请求？",
        "complete_description": "此要求将会变成“完成”",
        "complete_title": "完成要求",
        "confirm_action": "继续导入范围",
        "confirm_cancel": "现在不要",
        "confirm_confirmation": "是的，我要确认",
        "confirm_description": "您是否要确定要求%{id}?",
        "confirm_title": "请确定要求",
        "delete_description": "删除操作无法撤消。你确定吗？",
        "delete_title": "删除请求？",
        "reject_description": "该项目将返回到“确定范围”的状态",
        "reject_title": "拒绝？",
        "revert_description": "还原操作无法撤消，您确定吗？",
        "revert_title": "还原吗？"
      },
      "confirmed": "已确定",
      "creation_form": {
        "proceed": "到第二步"
      },
      "creation_page": {
        "add_template": "添加模版",
        "first_step": "创建要求",
        "second_step": "导入范围 ",
        "third_step": "应用服务包（可选）"
      },
      "description": "要求",
      "discipline": "专业",
      "done": "完成了",
      "eav_template": "模板",
      "eav_template_id": "模板编号",
      "edit_dialog": {
        "reason": "子要求名称"
      },
      "elevation": "海拔",
      "errors": {
        "not_found": "找不到要求编号%{id}"
      },
      "estimates_authorization": "估算需要授权",
      "forecast_end_date": "预计结束日期",
      "id": "DPMS编号",
      "in_performance": "表现中",
      "in_planning": "规划中",
      "in_scoping": "范围中",
      "information": "附加信息",
      "notification": {
        "authorization_success": "该请求已成功授权",
        "deleting_sub_request": "子请求%{id}被删除。请检查作业页面以了解进度",
        "missing_order_error": "没有与此请求相关的任何待处理订单"
      },
      "on_hold": "等候",
      "progress_end_date": "进度结束日期",
      "progress_responsible": "负责进度",
      "progress_responsible_id": "负责进度",
      "progress_start_date": "进度开始日期",
      "reason": "参考",
      "reference": "参考",
      "registered": "注册",
      "request_date": "要求日期",
      "request_detail": {
        "edit": "编辑",
        "estimates": "估算",
        "inspections": "检查验收",
        "performance": "表现",
        "planning": "规划",
        "scopes": "范围",
        "show": "显示",
        "side_panel_title": "细节"
      },
      "request_end_date": "要求结束日期",
      "request_responsible": "负责要求",
      "request_responsible_id": "负责要求",
      "request_start_date": "要求开始日期",
      "schedule": "要求时间表",
      "schedule_form": {
        "date": "日期",
        "days": "0天",
        "performance": "表现",
        "progress": "进度",
        "request": "要求",
        "responsible": "负责",
        "schedule": "时间表",
        "scoping": "范围",
        "subtitle": "请提供要求的细节",
        "title": "要求时间表"
      },
      "scope_end_date": "范围结束日期",
      "scope_responsible": "负责范围",
      "scope_responsible_id": "负责范围",
      "scope_start_date": "范围开始日期",
      "scopes": "范围",
      "service_desired_date": "所需日期",
      "skip_scoping": "跳跃范围",
      "status": "状态",
      "statuses": {
        "authorization_pending": "待办的",
        "canceled": "取消",
        "clustered": "聚类",
        "confirmed": "在作用域中",
        "deleting": "删除中",
        "done": "完毕",
        "in_performance": "表演中",
        "in_planning": "表演中",
        "in_scoping": "在作用域中",
        "on_hold": "等候接听",
        "registered": "已建立"
      },
      "sub_request": "子要求",
      "sub_requests": "子要求",
      "subarea": "分区",
      "team_target_hours": "团队目标时间",
      "title": "要求"
    },
    "scopes": {
      "add": "添加范围",
      "choose_modal": {
        "single": "另一个范围项",
        "single_description": "仅创建一个作用域项目"
      },
      "clusters": {
        "title": "范围集群"
      },
      "count": "计数",
      "crew_size": "船员人数",
      "crews": "船员人数",
      "date": "日期",
      "description": "范围",
      "hours": "小时",
      "id": "编号",
      "item": "选项",
      "label": "标签",
      "label_assigner": {
        "success": "您的范围标签已成功分配！ 您可以继续您的计划了。"
      },
      "label_error": "无法获取标签",
      "popup_label": {
        "create_label": "创建标签",
        "labels": "标签：",
        "name_label": "标签名字",
        "new_label": "新标签"
      },
      "quantity": "数量",
      "remaining_hours": "剩下小时",
      "remaining_quantity": "剩下的数量",
      "request_status_restriction": "请求的状态应为“已确认”或“正在确定范围”",
      "scopes_detail": {
        "side_panel_title": "范围细节"
      },
      "title": "范围",
      "unit": "单元",
      "working_hours": "工作时间"
    },
    "search": {
      "loading": "加载中",
      "no_result_found": "没有找到相关的",
      "placeholder": "筛选"
    },
    "settings": {
      "title": "设定值"
    },
    "settings_shortcuts": "设置快捷方式",
    "shortcuts": {
      "access_control": "访问控制",
      "areas": "区域和分区",
      "areas_subareas": "区域和分区",
      "assets": "资产",
      "background_jobs": "后台工作",
      "branches": "分行",
      "cities": "城市",
      "client_sites": "业主",
      "clients": "业主",
      "clients_info": "业主的资料",
      "columns_renaming": "列重命名",
      "companies": "公司",
      "contract_services": "合同服务",
      "contracts": "合同",
      "countries": "国家",
      "customer_sites": "客户网站",
      "customers": "客户群",
      "disciplines": "纪律处分",
      "employees": "职员",
      "equipaments": "配套设备",
      "equipments": "配套设备",
      "formulas": "公式",
      "location": "地方",
      "materials": "材料",
      "matrices": "矩阵",
      "measures": "措施",
      "mobile_details": "移动的细节",
      "position_names": "头衔名称",
      "preferences": "选项",
      "project_assets": "资产",
      "project_contracts": "合约书",
      "projects": "项目和子项目",
      "projects_contracts": "项目和合同",
      "projects_subprojects": "项目和子项目",
      "provinces": "州/省",
      "regions": "区域",
      "routes": "路线",
      "specialities": "特色菜",
      "staff": "员工",
      "states_provinces": "州/省",
      "translations": "列重命名",
      "users": "用户名",
      "visible_fields": "行动详细资料",
      "work_positions": "职位名称"
    },
    "side_menu": {
      "contracts": "合同",
      "datasheets": "数据表",
      "new": "新",
      "report": "报告",
      "search": "搜寻",
      "settings": "设置",
      "template": {
        "asset": "资产",
        "contract_service": "合同服务",
        "datasheet": "数据表",
        "estimate_service": "估算服务",
        "inspection": "检查验收",
        "performance": "表现",
        "report": "报告",
        "request": "要求",
        "scoping": "范围",
        "valuation": "估价"
      },
      "templates": "模板"
    },
    "sub_requests": {
      "creation_page": {
        "all": "所有的子要求",
        "back": "所有范围",
        "title": "子要求"
      },
      "left_panel": {
        "add_scoping": "添加范围",
        "allocation": "分配",
        "cards_header_title": "范围",
        "summary": "总结",
        "total_allocation": "总分配"
      },
      "scope_card": {
        "scope": "范围"
      }
    },
    "tabs": {
      "error": "无法加载标签"
    },
    "templates": {
      "asset": "资产",
      "contract_service": "合约服务",
      "datasheet": "数据表",
      "estimate_service": "估算服务",
      "inspection": "视察",
      "no_template": "您需要创建模板",
      "progress": "表现",
      "progress_service": "估价",
      "report": "报告",
      "request": "要求",
      "scoping": "范围界定",
      "title": "%{type}的模板"
    },
    "top_menu": {
      "authorization": "授权",
      "estimates": "估算",
      "inspections": "检查验收",
      "performance": "表现",
      "planning": "规划",
      "requests": "要求",
      "scoping": "范围"
    },
    "track_block": {
      "id": "用户名"
    },
    "user_menu": {
      "about_version": "关于版本",
      "logout": "推出",
      "profile": "轮廓"
    },
    "users": {
      "edit_title": "更新配置文件"
    }
  }
}

export default I18n
